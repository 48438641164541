// (C) Copyright 2017-2024 Hewlett Packard Enterprise Development LP
import React from 'react';
import { getEnvelopeEndpointWithUrlEncodedAuth } from '@sentry/core';
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router-dom';
import * as Sentry from '@sentry/react';
import { split, startsWith } from 'lodash';
import filter from 'lodash/filter';

const getSentryAPIVersion = () => {
  const envelope = getEnvelopeEndpointWithUrlEncodedAuth({
    protocol: 'https', host: 'HOST', projectId: 'PROJ', publicKey: 'KEY'
  });

  // Splitting the URL by '&' to get individual parameters
  const params = envelope.split('&');
  const filteredParams = filter(params, param => startsWith(param, 'sentry_version='));
  return split(
    filteredParams[0],
    '='
  )[1];
};

/**
 * This will load sentry configuration
 */
export default function loadSentry(sentryBaseUrl) {
  const sentrySDKVersion = Sentry.SDK_VERSION;
  const sentryAPIVersion = getSentryAPIVersion();
  const sentryProject = '5401276';
  const sentryEventUrl = `${sentryBaseUrl}?project=${sentryProject}&version=${sentryAPIVersion}&client=${sentrySDKVersion}`;
  const props = {
    dsn: 'https://12345678912345678912345678912345@test.ingest.host/7654321', // this a fake dsn
    environment: window.config.sentryEnv,

    // we are leaving this at zero, so we only record error sessions:
    replaysSessionSampleRate: 0,

    replaysOnErrorSampleRate: 1,

    integrations: [
      Sentry.reactRouterV6BrowserTracingIntegration({
        useEffect: React.useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      }),
      Sentry.breadcrumbsIntegration({
        console: false
      }),
      Sentry.replayIntegration({
        useCompression: false,
        maskAllText: true,
        maskAllInputs: true,
        blockAllMedia: true,
      }),
    ],
    tracesSampleRate: 0.2,
    ignoreErrors: [
      'ResizeObserver loop limit exceeded',
    ],
    tunnel: sentryEventUrl,
    transportOptions: {
      headers: {
        'Accept': '*/*',
        'Content-Type': 'text/plain;charset=UTF-8'
      },
      fetchOptions: {
        credentials: 'include'
      }
    }
  };

  try {
    Sentry.init(props);
  } catch (error) {
    console.error('Unable to initialize Sentry', error);
  }
}
