// (C) Copyright 2017-2024 Hewlett Packard Enterprise Development LP

import React, { useEffect, useState } from 'react';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import {
  Box,
  Button,
  Table,
  TableBody,
  TableCell,
  TableHeader,
  TableRow,
} from 'grommet';
import {
  Add, Contract, Copy, Edit, Expand, Revert, Trash,
} from 'grommet-icons';
import moment from 'moment';
import CurrencyUtils from '../../../../i18n/CurrencyUtil';
import IDUtil from '../../../../shared/util/IDUtil';
import BaseContainer from './components/BaseContainer';
import MeterRatesEditor from './MeterRatesEditor';
import UserStore from '../../../../stores/UserStore';
import ConfirmationDialog from '../../../../shared/dialogs/ConfirmationDialog';
import GLBMNameValueList from '../../../../shared/component/GLBMNameValueList';
import GLBMTooltip from '../../../../shared/component/GLBMTooltip';
import { StatusIcon } from '../../../../shared/component/StatusIcon';
import { isRateRevisionOverCapactity } from './util';

const MeterRates = (props) => {
  const [layer, setLayer] = useState(undefined);
  const [selectedRevision, setSelectedRevision] = useState(undefined);
  const [selectedIndex, setSelectedIndex] = useState(undefined);
  const [lastEditedRevision, setLastEditedRevision] = useState(undefined);

  useEffect(() => {
    if (lastEditedRevision) setLastEditedRevision(undefined);
  }, [lastEditedRevision]);

  const _onClick = (state) => {
    props.onToggleExpansion(state);
  };

  const _getAddAction = () => {
    setLayer('add');
    setSelectedRevision(undefined);
    setSelectedIndex(undefined);
  };

  const _getEditAction = (revision, index) => {
    setLayer('edit');
    setSelectedRevision(JSON.parse(JSON.stringify(revision)));
    setSelectedIndex(index);
  };

  const _getCopyAction = (revision, index) => {
    setLayer('copy');
    setSelectedRevision(JSON.parse(JSON.stringify(revision)));
    setSelectedIndex(index);
  };

  const _getDeleteAction = (index) => {
    const rate = { ...props.rate };
    rate.rateRevisions.splice(index, 1);
    props.onChange(rate);
  };

  const _getResetMarkupAction = () => {
    setLayer('confirmResetMarkups');
    setSelectedRevision(undefined);
    setSelectedIndex(undefined);
  };

  const _getActions = () => {
    const { expanded } = { ...props };
    const toggle = (expanded === 'NONE' || expanded !== 'RATES'
      ? <Button key='expand' icon={<Expand />} onClick={() => _onClick('RATES')} hoverIndicator={true} />
      : (
        <Button
          key='collapse'
          icon={<Contract />}
          onClick={() => _onClick('NONE')}
          hoverIndicator={true}
        />
      ));
    const addNew = (!props.rate || props.readOnly ? undefined : (
      <Button
        key='addRevision'
        icon={<Add />}
        label='Add Revision'
        id={IDUtil.getId('BillingInfoEditorAddRevisionRates')}
        onClick={_getAddAction}
        hoverIndicator={true}
      />
    ));
    const resetMarkups = (!props.canMarkupRates ? undefined : (
      <Button
        key='resetMarkup'
        icon={<Revert />}
        label='Reset Pricing'
        onClick={_getResetMarkupAction}
        hoverIndicator={true}
      />
    ));

    return ([
      addNew,
      resetMarkups,
      toggle,
    ]);
  };

  const _getCurrencySymbol = (customer) => {
    if (customer) {
      return CurrencyUtils.getCurrencyString(undefined, undefined, customer.locale, customer.contractCurrency).replace(/[0,.]+/gi, '').trim();
    }
    return '$';
  };

  const _isTieredRate = () => props?.rate?.tierType === 'TIERED';

  const _getColumns = () => {
    const { canMarkupRates, readOnly, customer } = props;
    const resolver = new Map([
      [() => canMarkupRates, ['Start Date', 'Bands', `Rate Per Unit (${_getCurrencySymbol(customer)})`, 'Pricing Method', 'Markup', `Customer Rate (${_getCurrencySymbol(customer)})`, 'Action']],
      [() => readOnly, ['Start Date', 'Bands', `Rate Per Unit (${_getCurrencySymbol(customer)})`]],
      [() => true, ['Start Date', 'Bands', `Rate Per Unit (${_getCurrencySymbol(customer)})`, 'Action']],
    ]);

    const [, cols] = [...resolver.entries()].find(([predicate]) => predicate());
    const columns = _isTieredRate() ? ['Status', ...cols] : cols;
    return columns.map(column => (
      <TableCell scope='col' border='bottom'>
        {column}
      </TableCell>
    ));
  };

  const _formatDate = date => new Date(date).toLocaleDateString((window.navigator.languages) ? window.navigator.languages[0] : (window.navigator.userLanguage || window.navigator.language), { timeZone: 'UTC' });

  const canEdit = () => {
    if (!props.permissions) {
      return true;
    }
    return props.permissions.canEditRates;
  };

  const _getBandTd = (threshold, nextThreshold, {
    firstBand, lastBand, multipleBands, name,
  }, index) => {
    if (firstBand && !multipleBands) {
      return (<TableCell id={IDUtil.getId('RatesBand', index)} key={`band_${index}`}>{`${name}: >= 0`}</TableCell>);
    }
    if (firstBand && multipleBands) {
      return (<TableCell id={IDUtil.getId('RatesBand', index)} key={`band_${index}`}>{`${name}: >= 0 to <= ${(nextThreshold)}`}</TableCell>);
    }
    if (!lastBand) {
      return (<TableCell id={IDUtil.getId('RatesBand', index)} key={`band_${index}`}>{`${name}: > ${threshold} to <= ${(nextThreshold)}`}</TableCell>);
    }
    return (<TableCell id={IDUtil.getId('RatesBand', index)} key={`band_${index}`}>{`${name}: > ${threshold}`}</TableCell>);
  };

  const _getUnitTd = (key, rate) => {
    const { meter: { unitLabel }, contractCurrency } = props;
    return (
      <TableCell key={`tdUnit-${key}`} id={IDUtil.getId(`RatesUnit-${key}`)}>
        <Box direction='row' gap='small'>
          <span>
            {rate !== undefined ? `${CurrencyUtils.getNumberString(rate, 8, null, contractCurrency)}` : 'Not Available'}
            {' '}
            /
            {' '}
            {unitLabel}
            {' '}
          </span>
        </Box>
      </TableCell>
    );
  };

  const _getPartnerTd = (key, index, explicitDownstreamRate, markup, rate, downstreamRate) => {
    const { contractCurrency } = props;
    const backgroundColor = { backgroundColor: 'rgba(248, 248, 248, 0.95)' };
    return props.canMarkupRates ? [
      <TableCell key={`tdPartnerPricingMethod-${key}`} style={backgroundColor}>
        {
          index === 0 ? (explicitDownstreamRate === undefined ? '-' : explicitDownstreamRate ? 'Direct Rate' : 'Markup Percentage') : ''
}
      </TableCell>,
      <TableCell key={`tdPartnerMarkup-${key}`} style={backgroundColor}>
        {(!markup || markup === Infinity) || (rate === undefined && downstreamRate !== undefined) ? '-' : `${CurrencyUtils.getNumberString(parseFloat(markup), 4)}%`}
      </TableCell>,
      <TableCell key={`tdPartnerRate-${key}`} style={backgroundColor}>
        {downstreamRate !== undefined ? `${CurrencyUtils.getNumberString(downstreamRate, 8, null, contractCurrency)}` : rate === undefined && markup !== undefined ? 'Not Available' : '-'}
      </TableCell>,
    ] : [];
  };

  const _getDefaultRevision = () => ({
    'effectiveDate': undefined,
    'tiers': {
      '0.0': { 'rate': 0, 'name': 'Band 1' },
    },
  });

  // todo add filler <td colSpan="1" /> as appropriate to fix header/row mismatch
  const _getRows = () => {
    const revisions = props.rate && props.rate.rateRevisions || [];
    const { canMarkupRates } = props;

    return revisions
      .sort((revision, nextRevision) => {
        const momentA = moment(revision.effectiveDate, 'YYYY-MM-DD');
        const momentB = moment(nextRevision.effectiveDate, 'YYYY-MM-DD');
        if (momentA > momentB) return 1;
        if (momentA < momentB) return -1;
        return 0;
      })
      .reduce((_revisions, revision, index) => {
        if (revision.tiers) {
          const bandTableData = Object.entries(revision.tiers || [])
            .sort(([a], [b]) => ((parseFloat(a) < parseFloat(b)) ? -1 : (a === b) ? 0 : 1))
            .map(([threshold, {
              name, rate, markup, downstreamRate,
            }], _index, _bandEntries) => {
              const [nextThreshold] = _bandEntries[_index + 1] || [];
              return [
                _getBandTd(
                  threshold,
                  nextThreshold,
                  {
                    firstBand: _index === 0,
                    lastBand: _index === _bandEntries.length - 1,
                    multipleBands: _bandEntries.length > 1,
                    name,
                  },
                  index,
                ),
                _getUnitTd(revision.effectiveDate + _index, rate),
                ..._getPartnerTd(revision.effectiveDate + index, _index, revision.explicitDownstreamRate, markup, rate, downstreamRate),
              ];
            });

          const [firstBandTd, ...otherBandTd] = bandTableData;
          return [
            ..._revisions,
            _getRevisionHeaderRow(index, revision, firstBandTd, revisions),
            ..._getRevisionBandRows(otherBandTd, revision, canMarkupRates),
          ];
        }
        // create a row with spacers for band + rate per unit:
        return [
          ..._revisions,
          _getRevisionHeaderRow(index, revision, [<td />, <td />], revisions),
        ];
      }, []);
  };

  const _getRevisionBandRows = (otherBandTd = [], revision, canMarkupRates) => otherBandTd.map((bandTd, index) => (
    <TableRow
      key={`${revision.effectiveDate}_step_${index}`}
      style={{
        transition: 'all 3s ease-out',
        backgroundColor: revision === lastEditedRevision ? '#d0f3eb' : 'white',
      }}
    >
      {_isTieredRate() ? <td colSpan='1' /> : undefined}
      <td colSpan='1' />
      {bandTd}
      {(!props.readOnly || props.canMarkupRates) && <td colSpan='1' />}
    </TableRow>
  ));

  const _getStatus = (revision) => {
    const isError = isRateRevisionOverCapactity(revision, props.rate?.capacityRevisions);
    return isError ? (
      <GLBMTooltip content='The start range for one or more pricing bands is higher than the Reserved Capacity in the corresponding Capacities revision. Please remove the offending pricing bands or increase the Reserved Capacity to fix this issue.'>
        <Box data-testId='critical'><StatusIcon value='critical' size='small' /></Box>
      </GLBMTooltip>
    ) : <Box data-testId='ok'><StatusIcon value='ok' size='small' /></Box>;
  };

  const _getRevisionHeaderRow = (index, revision, firstBandTd, revisions) => {
    const { readOnly, canMarkupRates } = props;

    return (
      <TableRow
        key={index}
        style={{
          transition: 'all 3s ease-out',
          backgroundColor: revision === lastEditedRevision ? '#d0f3eb' : 'white',
        }}
      >
        {_isTieredRate() ? (
          <TableCell id={IDUtil.getId('RatesStatus', index)}>{_getStatus(revision)}</TableCell>
        ) : undefined}
        <TableCell id={IDUtil.getId('RatesStartDate', index)} key={`effectiveDate_${index}`}>{_formatDate(revision.effectiveDate)}</TableCell>
        {firstBandTd || <TableCell key={`band_${index}`} />}
        {!firstBandTd && <TableCell key={`tdUnit-${index}`} />}
        {!readOnly
          && (
            <TableCell key={`actions_${index}`} id={IDUtil.getId('RatesActions', index)}>
              <Box direction='row' gap='medium' align='center'>
                <Button
                  size='large'
                  id={IDUtil.getId('RatesListItemEditButton', index)}
                  icon={<Edit style={{ height: '18px', width: '18px' }} />}
                  onClick={() => _getEditAction(revision, index)}
                  style={{ padding: '0' }}
                />
                <Button
                  size='large'
                  id={IDUtil.getId('RatesListItemCopyButton', index)}
                  icon={<Copy style={{ height: '18px', width: '18px' }} />}
                  onClick={() => _getCopyAction(revision, index)}
                  style={{ padding: '0' }}
                />
                <Button
                  size='large'
                  id={IDUtil.getId('RatesListItemDeleteButton', index)}
                  icon={<Trash style={{ height: '18px', width: '18px' }} />}
                  disabled={revisions.length === 1}
                  onClick={() => _getDeleteAction(index)}
                  style={{ padding: '0' }}
                />
              </Box>
            </TableCell>
          )}
        {canMarkupRates && (
          <TableCell key={`actions_${index}`} id={IDUtil.getId('RatesActions', index)}>
            <Button
              size='large'
              id={IDUtil.getId('MeterListItemEditButton', index)}
              icon={<Edit style={{ height: '18px', width: '18px' }} />}
              onClick={() => _getEditAction(revision, index)}
            />
          </TableCell>
        )}
      </TableRow>
    );
  };

  const _onLayerClose = () => {
    setLayer(undefined);
    setSelectedRevision(undefined);
    setSelectedIndex(undefined);
  };

  const _onLayerChange = (revision) => {
    const rate = { ...props.rate };

    if (layer === 'add' || layer === 'copy') {
      rate.rateRevisions.push(revision);
    } else if (layer === 'edit') {
      rate.rateRevisions[selectedIndex] = revision;
    }

    _onLayerClose();
    props.onChange(rate);
    setLastEditedRevision(revision);
  };

  const _onResetMarkupsConfirmed = (data) => {
    if (data.rate) {
      const { rate } = data;
      if (rate.rateRevisions) {
        rate.rateRevisions.forEach((revision) => {
          delete revision.explicitDownstreamRate;
          Object.keys(revision.tiers).forEach((tierValue) => {
            delete revision.tiers[tierValue].markup;
            delete revision.tiers[tierValue].downstreamRate;
            revision.dirty = true;
          });
        });
      }

      _onLayerClose();
      props.onChange(rate);
    }
  };

  const _renderConfirmationDetails = (serviceType, meter) => (
    <Box margin={{ top: 'small' }}>
      <GLBMNameValueList
        title='Selected Meter'
        data={[
          { label: 'Service', value: serviceType.label },
          { label: 'Meter', value: meter.name },
        ]}
      />
    </Box>
  );

  const _renderLayer = () => {
    const { customer, meter, rate } = props;

    const startDates = (rate ? rate.rateRevisions : []).reduce(
      (map, revision) => {
        map.push(revision.effectiveDate);
        return map;
      },
      [],
    );

    if (layer === 'add') {
      return (
        <MeterRatesEditor
          onClose={_onLayerClose}
          revision={_getDefaultRevision()}
          onChange={_onLayerChange}
          capacities={props.rate?.capacityRevisions}
          canEdit={canEdit()}
          preferences={customer.preferences}
          meter={meter}
          startDates={startDates}
          userType={UserStore.getUser().role}
          user={UserStore.getUser()}
          isTieredRate={_isTieredRate()}
        />
      );
    }
    if ((layer === 'edit' || layer === 'copy') && selectedRevision) {
      const customer = { ...props.customer };

      if (layer === 'edit') {
        startDates.splice(startDates.indexOf(selectedRevision.effectiveDate), 1);
      } else {
        selectedRevision.effectiveDate = undefined;
      }

      // fix for missing tiers array:
      if (!selectedRevision.hasOwnProperty('tiers')) {
        selectedRevision.tiers = {};
      }

      return (
        <MeterRatesEditor
          onClose={_onLayerClose}
          revision={selectedRevision}
          capacities={props.rate?.capacityRevisions}
          onChange={_onLayerChange}
          canEdit={canEdit()}
          preferences={customer.preferences}
          meter={meter}
          startDates={startDates}
          userType={UserStore.getUser().role}
          user={UserStore.getUser()}
          isTieredRate={_isTieredRate()}
        />
      );
    } if (layer === 'confirmResetMarkups') {
      return (
        <ConfirmationDialog
          data={{ meter: props.meter, rate: { ...props.rate } }}
          title='Reset Pricing'
          text='Reset all service pricing will clear all entered service pricing for the selected service meter and return back to the initial state.'
          submitLabel='Yes, reset all Service Pricing'
          cancelLabel='Cancel'
          onClose={_onLayerClose}
          onChange={_onResetMarkupsConfirmed}
          details={_renderConfirmationDetails(props.serviceType, props.meter)}
        />
      );
    }
  };

  return (
    <BaseContainer title='Rates' actions={_getActions()} fill='vertical' expanded={props.expanded === 'NONE' || props.expanded === 'RATES'}>
      <Box flex={true} data-e2e='rates'>
        <Table responsive={false} scrollable={true} className='narrow-table'>
          <TableHeader
            sortIndex={0}
            sortAscending={true}
          >
            <TableRow>
              {_getColumns()}
            </TableRow>
          </TableHeader>
          <TableBody>
            {_getRows()}
          </TableBody>
        </Table>
      </Box>
      {_renderLayer()}
    </BaseContainer>
  );
};

const mapStateToProps = ({ service }) => ({
  ...service.details.customer.contractCurrency,
  ...service.details.permissions,
});

const mapDispatchToProps = dispatch => bindActionCreators({
}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(MeterRates);
