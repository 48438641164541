// (C) Copyright 2017-2024 Hewlett Packard Enterprise Development LP

import moment from 'moment';

export function getEffectiveRevision(revisions) {
  if (revisions && revisions.length === 1) {
    return (revisions[0]);
  }
  const sortedRevisions = revisions?.sort((a, b) => (moment(a.effectiveDate).isBefore(b.effectiveDate) ? -1 : 1));
  let effectiveRevision;
  const today = moment();
  if (sortedRevisions && sortedRevisions.length) {
    for (let i = 0; i < sortedRevisions.length; i += 1) {
      const revision = sortedRevisions[i];
      if (moment(revision.effectiveDate)
        .isBefore(today)) {
        effectiveRevision = { ...revision };
      }
    }
  }
  return effectiveRevision;
}

export const sortEffectiveDate = revisions => revisions?.sort(({ effectiveDate: a }, { effectiveDate: b }) => {
  if (a === b) {
    return 0;
  }
  return (moment(a).isBefore(moment(b)) ? -1 : 1);
});

export const adjustEffectiveDate = (revisions, format) => revisions?.reduce((accumulator, currentValue) => {
  accumulator.push({
    ...currentValue,
    effectiveDate: moment(currentValue.effectiveDate).format(format),
  });
  return accumulator;
}, []);

export const getDuplicateRevisions = revisions => (revisions || []).reduce((acc, revision, currentIndex, array) => {
  const { effectiveDate } = revision;
  if (effectiveDate) {
    const firstIndex = array.findIndex(r => r.effectiveDate === effectiveDate);
    // if the current index is not the same as the first one found - then this is a duplicate:
    if (currentIndex !== firstIndex) {
      acc.push(currentIndex);
    }
  }
  return acc;
}, []);
