// (C) Copyright 2017-2024 Hewlett Packard Enterprise Development LP

import React, { useState } from 'react';
import {
  Box, Button, FormField, Spinner,
} from 'grommet';
import isEqual from 'lodash/isEqual';
import { useQueryExtractCustomerRateCards } from '../../../core';
import CustomerSelector from '../../shared/component/CustomerSelector';

const ExtractCustomerRateCards = () => {
  const [filter, setFilter] = useState({ customerId: [] });

  const { mutate: extract, isPending: showSpinner } = useQueryExtractCustomerRateCards(filter.customerId || []);

  const onChange = (value) => {
    const name = 'customerId';
    const updatedFilter = { ...filter };
    if (!value || value === 'All') {
      updatedFilter[name] = [];
    } else {
      if (updatedFilter[name].includes(value)) {
        updatedFilter[name] = updatedFilter[name].filter(el => el !== value);
      } else {
        updatedFilter[name] = [...updatedFilter[name], value];
      }
      if (updatedFilter[name].length === 0) {
        delete updatedFilter[name];
      }
    }
    if (!isEqual(filter, updatedFilter)) {
      setFilter(updatedFilter);
    }
  };

  return (
    <Box border='top' direction='row' pad={{ horizontal: 'small' }} align='center' height={{ min: 'xsmall' }} flex={false}>
      <Box margin={{ right: 'auto' }}>
        <strong>Extract All Rate Card Data</strong>
        <span>Extract all or selected customer rate card data</span>
      </Box>
      <Box direction='row' align='center' gap='medium'>
        <Box direction='row' align='top' gap='medium' style={{ height: '100px' }}>
          <FormField label='Billing Accounts' style={{ minWidth: '160px' }}>
            <CustomerSelector
              multiple={true}
              initialSelection={filter?.customerId}
              onCustomerSelected={(selected, last) => onChange(last)}
              plain={true}
            />
          </FormField>
        </Box>
        <Box flex={false} width='xsmall' align='center'>
          {showSpinner && <Spinner size='small' />}
        </Box>
        <Box direction='row' align='center' flex={true} width={{ min: 'xsmall' }} justify='end'>
          <Button
            label='Extract'
            onClick={() => {
              extract();
            }}
            secondary={true}
            disabled={showSpinner}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default ExtractCustomerRateCards;
