// (C) Copyright 2017-2024 Hewlett Packard Enterprise Development LP
import { Enumify } from 'enumify';

export class BillingType extends Enumify {
  static REQUEST = new BillingType('REQUEST', 'Not Configured', 'unknown', 0);

  static INCOMPLETE = new BillingType('INCOMPLETE', 'Onboarding', 'warning', 1);

  static BILLABLE = new BillingType('BILLABLE', 'In Production', 'ok', 2);

  static ENDED = new BillingType('ENDED', 'Ended', 'disabled', 3);

  static _ = this.closeEnum(); // TypeScript: Color.closeEnum()

  constructor(value, label, severity, order) {
    super();
    this.value = value;
    this.label = label;
    this.severity = severity;
    this.order = order;
  }
}
