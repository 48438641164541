// (C) Copyright 2017-2024 Hewlett Packard Enterprise Development LP

import { useEffect, useRef } from 'react';
import isEqual from 'lodash/isEqual';

export const usePrevious = (value) => {
  const ref = useRef();
  useEffect(() => {
    if (!isEqual(ref.current, value)) {
      ref.current = value;
    }
  });
  return ref.current;
};
