// (C) Copyright 2017-2024 Hewlett Packard Enterprise Development LP
import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import * as Case from 'case';
import Highlight from 'react-highlighter';
import {
  Box,
  CheckBox,
  Text,
} from 'grommet';
import { getColumnLabel, getServiceLabel } from '../Util';
import { AdvancedSwitch, instanceOf, matches } from '../../shared/util/AdvancedEnum';
import IDUtil from '../../shared/util/IDUtil';
import {
  ConfigurableComponent,
  EquipmentColumn,
  EquipmentIdColumn,
  ServiceColumn,
} from '../model';
import GLBMDataTable from '../../shared/component/GLBMDataTable';

const DeleteEquipmentTable = ({
  sort: sortProp = {},
  columns = undefined,
  serviceType = undefined,
  filteredEquipment = undefined,
  onEquipmentSelection,
  searchText = undefined,
}) => {
  const [sort, setSort] = useState(sortProp);

  const onToggleSelected = (event, equipment) => {
    // eslint-disable-next-line no-param-reassign
    equipment.selected = event.target.checked;
    onEquipmentSelection(equipment);
  };

  const getTableDataLabel = (equipment, column, map) => {
    const fieldFn = () => `data.${column.field(serviceType)}`;
    return getServiceLabel(equipment, column, serviceType, map, fieldFn);
  };

  const getDefaultColumnTd = column => ({
    header: column.header(),
    property: column.field(),
    render: (datum) => {
      const equipment = datum;
      const value = getTableDataLabel(equipment, column) || '-';
      return (
        <Text wordBreak='break-all' id={IDUtil.getId('DefaultTd', equipment.index)}>
          <Highlight search={searchText}>{value}</Highlight>
        </Text>
      );
    },
  });

  const getDeleteComponentColumnTd = column => ({
    header: column.header(),
    property: column.name,
    sortable: false,
    render: (datum) => {
      const equipment = datum;
      return (
        <Box key={Case.camel(column.field())} id={IDUtil.getId('DeleteComponentTd', equipment.index)}>
          <Text
            key={Case.camel(column.field())}
            id={equipment.data.equipmentId}
          >
            {equipment.data.includedComponents}
            {' '}
            of
            {' '}
            {equipment.data.components}
            {' '}
            included
          </Text>
        </Box>
      );
    },
  });

  const getSelectColumnTd = column => ({
    header: column.header(),
    property: column.name,
    render: (datum) => {
      const equipment = datum;
      return (
        <Box key={Case.camel(column.name)} id={IDUtil.getId('SelectTd', equipment.index)}>
          <CheckBox
            checked={equipment.selected}
            onChange={event => onToggleSelected(event, equipment)}
          />
        </Box>
      );
    },
  });

  const getEquipmentIdColumnTd = column => ({
    header: column.header(),
    primary: true,
    property: column.field(),
    render: (datum) => {
      const equipment = datum;
      const equipmentColumn = getTableDataLabel(equipment, column);
      return (
        <Box direction='row' align='center' gap='xxsmall' id={IDUtil.getId('EquipmentIdTd', equipment.index)}>
          <Box>
            {equipmentColumn}
          </Box>
        </Box>
      );
    },
  });

  const sortEquipmentList = (equipmentList, sort, locationAndTierMap) => {
    if (!sort) {
      return equipmentList;
    }
    const { property: sortProperty, direction: sortDirection } = sort;
    const columnNames = columns.map((e) => {
      if (e instanceof ServiceColumn) {
        return e.name;
      } if (e instanceof EquipmentIdColumn || e instanceof EquipmentColumn) {
        return e.field();
      }
      return '';
    });
    const columnIndex = columnNames.indexOf(sortProperty);
    const column = columns[columnIndex];
    if (!column) {
      return equipmentList;
    }
    const lookupFn = equipment => getColumnLabel(equipment.data, column, serviceType, locationAndTierMap);
    const ascending = sortDirection === 'asc';
    return equipmentList.advancedSort(ascending, lookupFn).map((el, i) => ({ ...el, index: i }));
  };

  const getTableData = (column) => {
    const {
      SELECT,
    } = ServiceColumn;
    return AdvancedSwitch(column)
      .case(matches(SELECT), getSelectColumnTd)
      .case(instanceOf(EquipmentIdColumn), getEquipmentIdColumnTd)
      .case(instanceOf(ConfigurableComponent), getDeleteComponentColumnTd)
      .default(getDefaultColumnTd)
      .resolveAndInvoke(column);
  };

  const equipmentColumns = useMemo(
    () => columns?.map(column => getTableData(column)),
    [columns, onEquipmentSelection]
  );

  const equipmentList = sortEquipmentList(filteredEquipment, sort);
  const total = (equipmentList && equipmentList.length ? equipmentList.length : undefined);
  return (
    <GLBMDataTable
      pin={true}
      columns={equipmentColumns}
      data={equipmentList}
      total={total}
      onSort={newSort => setSort(newSort)}
      sort={sort}
    />
  );
};

DeleteEquipmentTable.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.object),
  serviceType: PropTypes.object,
  filteredEquipment: PropTypes.arrayOf(PropTypes.object),
  sort: PropTypes.object,
  onEquipmentSelection: PropTypes.func.isRequired,
  searchText: PropTypes.string,
};

export default DeleteEquipmentTable;
