// (C) Copyright 2017-2024 Hewlett Packard Enterprise Development LP
import React, {
  useCallback, useEffect, useMemo, useRef, useState,
} from 'react';
import { AgGridReact } from 'ag-grid-react';
import { Box, Button, Footer } from 'grommet';
import PropTypes from 'prop-types';

const ProcessedFilesGrid = ({
  loading = false, data = undefined, search = undefined, onSearchChange
}) => {
  const gridRef = useRef();
  const [gridApi, setGridApi] = useState(undefined);

  const defaultColDef = useMemo(() => ({
    resizable: true,
  }), []);

  const onGridReady = (params) => {
    setGridApi(params.api);
  };

  useEffect(() => {
    if (gridApi) {
      if (loading) {
        gridApi.showLoadingOverlay();
      } else if (data?.rows?.length === 0) {
        gridApi.showNoRowsOverlay();
      } else {
        gridApi.hideOverlay();
      }
    }
  }, [loading, gridApi, data?.rows?.length]);

  const getExportParams = () => ({});

  const onBtnExportCsv = () => {
    gridApi.exportDataAsCsv(getExportParams());
  };

  const onFirstDataRendered = useCallback((params) => {
    const allColumnIds = [];
    params.api.getColumns().forEach((column) => {
      allColumnIds.push(column.colId);
    });
    params.api.autoSizeColumns(allColumnIds, false);
  }, []);

  const onBtnExportExcel = () => {
    if (gridApi) {
      const exportData = gridApi.getDataAsCsv(getExportParams());
      const blob = new Blob([exportData], { type: 'text/csv;charset=utf-8;' });
      const url = URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.setAttribute('href', url);
      link.setAttribute('download', 'export.xls');
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  const hasRows = data?.rows?.length > 0;

  const refreshRowCount = () => {
    const count = gridRef?.current?.api?.getDisplayedRowCount();
    onSearchChange(count);
  };

  return (
    <Box
      flex={true}
      fill='vertical'
      margin={{ horizontal: 'small' }}
    >
      <div style={{ width: '100%', height: '100%' }} className='ag-theme-balham'>
        <AgGridReact
          ref={gridRef}
          columnDefs={data?.cols}
          gridOptions={data?.gridOptions}
          rowData={data?.rows || []}
          quickFilterText={search}
          onGridReady={onGridReady}
          defaultColDef={defaultColDef}
          onFirstDataRendered={onFirstDataRendered}
          onRowDataUpdated={refreshRowCount}
          onFilterChanged={refreshRowCount}
        />
      </div>
      <Box border='top' pad='small' margin={{ top: 'none' }} flex={false}>
        <Footer flex={false} justify='between'>
          <Box justify='start' gap='small' direction='row'>
            <Button
              label='Export to CSV'
              type='button'
              disabled={!hasRows}
              secondary={true}
              onClick={hasRows ? onBtnExportCsv : undefined}
            />
            <Button
              label='Export to Excel'
              type='button'
              disabled={!hasRows}
              secondary={true}
              onClick={hasRows ? onBtnExportExcel : undefined}
            />
          </Box>
        </Footer>
      </Box>
    </Box>
  );
};

ProcessedFilesGrid.propTypes = {
  loading: PropTypes.bool,
  data: PropTypes.shape({
    cols: PropTypes.arrayOf(PropTypes.shape({
      headerName: PropTypes.string,
      children: PropTypes.arrayOf(PropTypes.shape({
        field: PropTypes.string,
        filter: PropTypes.string,
        flex: PropTypes.bool,
        headerName: PropTypes.string,
        minWidth: PropTypes.number,
        resizable: PropTypes.bool,
        sortable: PropTypes.bool,
        type: PropTypes.string,
        width: PropTypes.number,
      })),
    })),
    rows: PropTypes.array,
    gridOptions: PropTypes.shape({
      animateRows: PropTypes.bool,
      defaultColDef: PropTypes.shape({
        flex: PropTypes.number,
        floatingFilter: PropTypes.bool,
        minWidth: PropTypes.number,
        resizable: PropTypes.bool,
        sortable: PropTypes.bool,
      }),
      enableFilter: PropTypes.bool,
      enableSorting: PropTypes.bool,
    }),
  }),
  search: PropTypes.string,
  onSearchChange: PropTypes.func.isRequired,
};

export default ProcessedFilesGrid;
