// (C) Copyright 2017-2024 Hewlett Packard Enterprise Development LP

import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Box, Button, CheckBoxGroup, Footer, Select, Text,
} from 'grommet';
import GLBMLayer from '../shared/component/GLBMLayer';
import DateRange from '../shared/component/DateRange';
import GLBMFilterPropertyBox from '../shared/component/GLBMFilterPropertyBox';
import IDUtil from '../shared/util/IDUtil';
import ServiceTypeStore from '../stores/ServiceTypeStore';

const UsageFileFilter = ({ filter: filterProp = {}, onChange = null, onClose = null }) => {
  const [filter, setFilter] = useState({
    customerId: filterProp.customerId,
    from: filterProp.from,
    to: filterProp.to,
    stage: (Array.isArray(filterProp.stage)) ? [].concat(filterProp.stage) : undefined,
    source: (filterProp.source ? filterProp.source : undefined),
    service: (Array.isArray(filterProp.service)) ? [].concat(filterProp.service) : undefined,
  });

  const _onChangeOption = useCallback((name, event) => {
    setFilter((prevFilter) => {
      const newFilter = { ...prevFilter };
      if (event.value.length === 0 || event.value[event.value.length - 1] === 'all') {
        delete newFilter[name];
      } else {
        newFilter[name] = event.value.filter(el => el !== 'all');
      }
      return newFilter;
    });
  }, []);

  const _onChangeSourceFilter = useCallback((name, isMultiple, event) => {
    setFilter((prevFilter) => {
      const newFilter = { ...prevFilter };
      if (!event.option.value) {
        delete newFilter[name];
      } else if (isMultiple) {
        newFilter[name] = event.value.map(value => (
          typeof value === 'object' ? value.value : value));
        if (newFilter[name].length === 0) {
          delete newFilter[name];
        }
      } else {
        newFilter[name] = event.option.value;
        if (newFilter[name].length === 0) {
          delete newFilter[name];
        }
      }
      return newFilter;
    });
  }, []);

  const _setFromDate = useCallback((value) => {
    setFilter(prevFilter => ({ ...prevFilter, from: value }));
  }, []);

  const _setToDate = useCallback((value) => {
    setFilter(prevFilter => ({ ...prevFilter, to: value }));
  }, []);

  const _onSubmit = useCallback(() => {
    if (filter.from && filter.to) {
      onChange?.(filter);
    }
  }, [filter, onChange]);

  const _renderServiceOptions = useCallback(() => {
    const options = ServiceTypeStore.getServices().map(({ type, label }) => ({
      label,
      value: type,
    })).sort((a, b) => a.label.localeCompare(b.label));

    return (
      <CheckBoxGroup
        name='service'
        id='service'
        value={filter.service ? filter.service : ['all']}
        options={[{ label: <Text weight={500}>All</Text>, value: 'all' }].concat(options)}
        onChange={ev => _onChangeOption('service', ev)}
        labelKey='label'
        valueKey='value'
      />
    );
  }, [_onChangeOption, filter.service]);

  const _renderTypeOptions = useCallback(() => (
    <CheckBoxGroup
      name='format'
      id='format'
      value={filter.stage ? filter.stage : ['all']}
      labelKey='label'
      valueKey='value'
      options={[
        { label: <Text weight={500}>All</Text>, value: 'all' },
        { label: 'Raw (raw)', value: 'raw' },
        { label: 'Archive (zip)', value: 'zip' },
      ]}
      onChange={ev => _onChangeOption('stage', ev)}
    />
  ), [_onChangeOption, filter.stage]);

  const _renderSourceOptions = useCallback(() => {
    const options = [{ label: 'All', value: 0 }];
    options.push({ label: 'Actual', value: 'actual' });
    options.push({ label: 'Estimated', value: 'estimated' });

    let value;
    if (filter?.source) {
      const c = options.filter(option => option.value === filter.source);
      if (c) {
        // eslint-disable-next-line prefer-destructuring
        value = c[0];
      }
    } else {
      value = { label: 'All', value: 0 };
    }

    return (
      <Select
        name='content'
        id='content'
        labelKey='label'
        valueKey={{ key: 'value', reduce: true }}
        style={{ width: '100%' }}
        multiple={false}
        value={value?.value}
        options={options}
        onChange={ev => _onChangeSourceFilter('source', false, ev)}
      />
    );
  }, [_onChangeSourceFilter, filter]);

  const _onClear = () => {
    setFilter((prevFilter) => {
      const newFilter = { ...prevFilter };
      delete newFilter.stage;
      delete newFilter.source;
      delete newFilter.service;
      return newFilter;
    });
  };

  return (
    <GLBMLayer
      position='right'
      full='vertical'
      flush={true}
      closer={true}
      overlayClose={false}
      onClose={onClose}
      style={{ minWidth: '400px' }}
      title='Filter Usage Files'
    >
      <Box flex={true} overflow='auto'>
        <Box flex={false}>
          <GLBMFilterPropertyBox label='Usage Period' contentProps={{ pad: { horizontal: 'small' } }} required={true}>
            <DateRange filter={filter} setFromDate={_setFromDate} setToDate={_setToDate} startLabel='Start' endLabel='End' />
          </GLBMFilterPropertyBox>
          <GLBMFilterPropertyBox label='Content'>
            {_renderSourceOptions()}
          </GLBMFilterPropertyBox>
          <GLBMFilterPropertyBox label='Format'>
            {_renderTypeOptions()}
          </GLBMFilterPropertyBox>
          <GLBMFilterPropertyBox label='Service'>
            {_renderServiceOptions()}
          </GLBMFilterPropertyBox>
        </Box>
      </Box>
      <Box border='top' pad='small' margin={{ top: 'none' }} flex={false}>
        <Footer flex={false} justify='between'>
          <Box justify='start' gap='small' direction='row'>
            <Button
              label='Apply'
              type='button'
              primary={true}
              onClick={_onSubmit}
            />
            <Button
              label='Cancel'
              type='button'
              secondary={true}
              onClick={onClose}
            />
          </Box>
          <Button
            label='Clear Filters'
            id={IDUtil.getId('ResetBtn')}
            onClick={_onClear}
          />
        </Footer>
      </Box>
    </GLBMLayer>
  );
};

UsageFileFilter.propTypes = {
  onClose: PropTypes.func,
  onChange: PropTypes.func,
  filter: PropTypes.object,
};

export default UsageFileFilter;
