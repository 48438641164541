// (C) Copyright 2017-2024 Hewlett Packard Enterprise Development LP

import React, { useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import {
  Box,
  Button,
  Footer,
  FormField,
  RadioButton,
  TextInput,
} from 'grommet';
import PropTypes from 'prop-types';
import IDUtil from '../shared/util/IDUtil';
import { getError } from '../shared/util/FormUtil';
import GLBMLayer from '../shared/component/GLBMLayer';

const ServiceEditor = ({
  customer, serviceId = undefined, services = undefined, onClose, onSubmit
}) => {
  const [service, setService] = useState({});
  const [errors, setErrors] = useState({
    description: [],
    location: [],
  });

  useEffect(() => {
    if (serviceId !== undefined && services) {
      setService({ ...services.find(el => el.meterId === serviceId) });
    } else {
      setService({
        meterId: uuidv4(),
        description: '',
        type: 'CUSTOMER',
        locationId: null,
        rateRevisions: [],
      });
    }
  }, []);

  const getTotalErrors = () => {
    let totalErrors = 0;
    // eslint-disable-next-line guard-for-in,no-restricted-syntax
    for (const property in errors) {
      totalErrors += (errors[property].length);
    }
    return totalErrors;
  };

  const onApply = () => {
    const updatedErrors = { ...errors };
    let validationErrors = getTotalErrors() > 0;

    if (!service.description && !updatedErrors.description.includes('Description is required')) {
      updatedErrors.description.push('Description is required');
      validationErrors = true;
    }

    if (validationErrors) {
      setErrors(updatedErrors);
      return;
    }
    onSubmit(service);
  };

  const onChange = (event) => {
    const updatedService = { ...service };
    const updatedErrors = { ...errors };
    const { locations } = customer;
    const attribute = event.target.getAttribute('name');

    switch (attribute) {
      case 'description':
        updatedService[attribute] = event.target.value;
        break;
      case 'CUSTOMER':
        updatedService.type = event.target.name;
        updatedService.locationId = null;
        break;
      case 'LOCATION':
        updatedService.type = event.target.name;
        updatedService.locationId = locations.length > 0 ? locations[0].id : null;
        break;
      default:
        updatedService[attribute] = event.value.value;
    }

    delete updatedErrors[attribute];
    updatedErrors[attribute] = [];

    // validate:
    switch (attribute) {
      case 'description':
        // eslint-disable-next-line no-case-declarations
        const duplicateName = services
          .filter(s => s.type === updatedService.type)
          .filter(s => !updatedService.locationId || s.locationId === updatedService.locationId)
          .filter(s => s.description === updatedService.description)
          .length > 0;

        if (updatedService.description.trim() === '' && !updatedErrors[attribute].includes('Description is required')) {
          updatedErrors[attribute].push('Description is required');
        } else if (duplicateName && !updatedErrors[attribute].includes('Duplicate name')) {
          updatedErrors[attribute].push('Duplicate name');
        }

        break;
      default:
        break;
    }

    setService(updatedService);
    setErrors(updatedErrors);
  };

  const onLocationChange = (event) => {
    setService({ ...service, locationId: event.target.name });
  };

  return (
    <GLBMLayer
      position='right'
      full='vertical'
      closer={true}
      onClose={onClose}
      onClickOutside={onClose}
      onEsc={onClose}
      flush={true}
      title={serviceId !== undefined ? 'Edit Service' : 'Add New Service'}
    >
      <Box pad='none' direction='column' fill='vertical' style={{ 'maxWidth': '575px', 'minWidth': '575px' }}>
        <Box flex={true}>
          <Box flex={false} pad={{ horizontal: 'medium' }}>
            <FormField label='Description' error={getError(errors.description)} htmlFor={IDUtil.getId('NonMeterServiceEditDescription')}>
              <TextInput
                id={IDUtil.getId('NonMeterServiceEditDescription')}
                name='description'
                value={service.description}
                onChange={onChange}
              />
            </FormField>
            {serviceId === undefined && (
            <Box>
              <FormField label='Apply Charge To'>
                <RadioButton
                  id={IDUtil.getId('NonMeterServiceEditApplyCharges-CUSTOMER')}
                  name='CUSTOMER'
                  label='Billing Account'
                  checked={service.type === 'CUSTOMER'}
                  onChange={onChange}
                />
                <RadioButton
                  id={IDUtil.getId('NonMeterServiceEditApplyCharges-LOCATION')}
                  name='LOCATION'
                  label='Location'
                  checked={service.type === 'LOCATION'}
                  onChange={onChange}
                />
              </FormField>
              { service.type === 'LOCATION' && (
              <FormField label='Location' error={getError(errors.location)}>
                {customer?.locations.map(location => (
                  <RadioButton
                    key={location.id}
                    id={IDUtil.getId(`NonMeterServiceEditLocation-${location.id}`)}
                    name={location.id}
                    label={location.name}
                    checked={service.locationId === location.id}
                    onChange={onLocationChange}
                  />
                ))}
              </FormField>
              )}
            </Box>
            )}
          </Box>
          <Box flex={false} pad={{ horizontal: 'medium' }} margin={{ top: 'small' }} />
        </Box>
        <Box border='top' pad='small' margin={{ top: 'none' }} flex={false}>
          <Footer flex={false} justify='start' gap='small'>
            <Button
              label='OK'
              type='button'
              primary={true}
              onClick={onApply}
            />
            <Button
              label='Cancel'
              type='button'
              secondary={true}
              onClick={onClose}
            />
          </Footer>
        </Box>
      </Box>
    </GLBMLayer>
  );
};

ServiceEditor.propTypes = {
  customer: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  serviceId: PropTypes.string,
  services: PropTypes.array,
};

export default ServiceEditor;
