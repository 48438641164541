// (C) Copyright 2017-2024 Hewlett Packard Enterprise Development LP

import React from 'react';

import {
  Box, Button, Footer, Text,
} from 'grommet';
import PropTypes from 'prop-types';
import IDUtil from '../util/IDUtil';
import { StatusIcon } from '../component/StatusIcon';
import GLBMLayer from '../component/GLBMLayer';

const ConfirmationDialog = ({
  title,
  submitLabel,
  data = undefined,
  onChange,
  onClose,
  text = undefined,
  text2 = undefined,
  cancelLabel = 'Cancel',
  details = undefined,
  status = undefined,
}) => {
  const onSubmit = () => {
    onChange(data);
  };

  const secondaryControl = (
    <Button
      secondary={true}
      {...{ 'data-testid': IDUtil.getId('ConfirmationDialogCancelBtn') }}
      label={cancelLabel}
      onClick={onClose}
    />
  );

  return (
    <GLBMLayer
      position='right'
      full='vertical'
      closer={true}
      onClose={onClose}
      onEsc={onClose}
      onClickOutside={onClose}
      overlayClose={true}
      flush={true}
      title={title}
    >
      <Box
        pad='none'
        style={{ 'width': '600px', 'minWidth': '600px' }}
        direction='column'
        flex={true}
        fill='vertical'
      >
        <Box pad='medium' flex={true}>
          {(status || text)
            && (
              <Box direction='row' align='start' margin={{ vertical: 'medium' }} gap='small'>
                {status
                  && <StatusIcon value={status} size='medium' />}
                <Box direction='column' align='start' margin={{ vertical: 'medium' }}>
                  <Text weight={100} margin={{ top: 'none' }}>
                    {text}
                  </Text>
                  {text2 && (
                  <Text weight={100} margin={{ top: 'none' }}>
                    {text2}
                  </Text>
                  )}
                </Box>
              </Box>
            )}
          {details && details}
        </Box>
      </Box>
      <Box border='top' pad='small' margin={{ top: 'none' }} flex={false}>
        <Footer flex={false} justify='start' gap='small'>
          <Button primary={true} onClick={onSubmit} label={submitLabel} {...{ 'data-testid': IDUtil.getId('ConfirmationDialogSubmitBtn') }} />
          {secondaryControl}
        </Footer>
      </Box>
    </GLBMLayer>
  );
};

ConfirmationDialog.propTypes = {
  title: PropTypes.string.isRequired,
  submitLabel: PropTypes.string.isRequired,
  data: PropTypes.shape(),
  onChange: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  text: PropTypes.string,
  text2: PropTypes.string,
  cancelLabel: PropTypes.string,
  details: PropTypes.node,
  status: PropTypes.string,
};

export default ConfirmationDialog;
