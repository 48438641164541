// (C) Copyright 2017-2024 Hewlett Packard Enterprise Development LP
export function AdvancedSwitch(item) {
  const resultMap = new Map();

  const defaultCase = (result) => {
    resultMap.set(() => true, result);
    return { resolve, resolveAndInvoke };
  };

  const caseOperator = (predicate, result) => {
    resultMap.set(predicate, result);
    return {
      case: caseOperator, default: defaultCase, resolve, resolveAndInvoke,
    };
  };

  const resolve = (...args) => {
    try {
      const [predicate, result] = [...resultMap].find(([_predicate]) => _predicate(item));
      return result;
    } catch (e) {
      console.warn('No Resolution Found');
      throw e;
    }
  };

  const resolveAndInvoke = (...args) => {
    try {
      const [predicate, result] = [...resultMap].find(([_predicate]) => _predicate(item));
      return result.apply(result, args);
    } catch (e) {
      console.warn('No Resolution Found');
      throw e;
    }
  };

  return {
    case: caseOperator, default: defaultCase, resolve, resolveAndInvoke,
  };
}

export const matches = comparable => (item => item === comparable);
export const includes = (...list) => (item => list.includes(item));
export const instanceOf = classOrFunction => (item => item instanceof classOrFunction);
