// (C) Copyright 2017-2024 Hewlett Packard Enterprise Development LP
import {
  Box, Button, Footer, Spinner, Text
} from 'grommet';
import PropTypes from 'prop-types';
import * as React from 'react';
import { useMemo, useState } from 'react';
import { runAction } from '../../../core';
import MigrationOptions
  from '../../shared/component/MigrationOptions/MigrationOptions';
import {
  areRequiredInputsAvailable,
  hydratePayload,
  setFormValuesInitialState,
} from './utils';

const DynamicAction = ({
  action,
  onExecute,
  onClose,
}) => {
  const hasInputs = useMemo(() => action?.inputs, [action]);
  const [formValues, setFormValues] = useState(hasInputs && setFormValuesInitialState(action.inputs));
  const [isPending, setIsPending] = useState(false);
  const isReady = useMemo(() => areRequiredInputsAvailable(action.inputs, formValues), [action.inputs, formValues]);

  const executeAction = async () => {
    setIsPending(true);
    const body = hydratePayload(formValues);
    await onExecute(async () => runAction(action.method, action.path, body));
    setIsPending(false);
  };

  return (
    <>
      <Box flex='grow' margin='small'>
        <MigrationOptions
          data={action}
          values={formValues}
          onChange={setFormValues}
        />
      </Box>
      <Box border='top' pad='small' margin={{ top: 'none' }} flex={false}>
        <Footer flex={false} justify='between'>
          <Box justify='start' gap='small' direction='row'>
            <Button
              label='Run'
              type='button'
              primary={true}
              onClick={executeAction}
              disabled={isPending || !isReady}
            />
            <Button
              label='Cancel'
              type='button'
              secondary={true}
              onClick={onClose}
            />
            {isPending && (
              <Box direction='row' gap='small' pad={{ left: 'medium' }}>
                <Spinner />
                <Text>Running. Please wait ...</Text>
              </Box>
            )}
          </Box>
        </Footer>
      </Box>
    </>
  );
};

DynamicAction.propTypes = {
  action: PropTypes.object.isRequired,
  onExecute: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default DynamicAction;
