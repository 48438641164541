// (C) Copyright 2017-2024 Hewlett Packard Enterprise Development LP

import React, { Component } from 'react';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import {
  setDirtyRates, setOptions, setRates,
} from 'services/redux/ServiceActions';

import { Box } from 'grommet';
import MeterSettings from './MeterSettings';
import MeterCapacities from './MeterCapacities';
import MeterRates from './MeterRates';
import { CapacityOption, MeterOption, RatesOption } from '../../../model';
import { CommitmentType } from '../../../model/CommitmentType';

class MeterDetails extends Component {
  constructor(props) {
    super(props);

    this._onToggleExpansion = this._onToggleExpansion.bind(this);

    this.state = {
      expanded: 'NONE', // 'NONE', 'FIXED', 'CAPACITY', 'RATES'
    };
  }

  _onToggleExpansion(state) {
    this.setState({ expanded: state });
  }

  _hasMeterCapacities() {
    if (this.props.options?.config?.reportBy === 'LOCATION' && this.props.serviceType?.commitmentType === CommitmentType.MONETARY) {
      return false;
    }

    const capacityOptions = (this.props.serviceType.rateManagement ? this.props.serviceType.rateManagement.capacityOptions : []);
    return !(capacityOptions.includes(CapacityOption.NONE));
  }

  _isReadOnlySettings() {
    const options = (this.props.serviceType.rateManagement ? this.props.serviceType.rateManagement.meterOptions : []);
    return ((options || []).includes(MeterOption.READ_ONLY));
  }

  _isReadOnlyCapacities() {
    const options = (this.props.serviceType.rateManagement ? this.props.serviceType.rateManagement.capacityOptions : []);
    return ((options || []).includes(CapacityOption.READ_ONLY));
  }

  _isReadOnlyRates() {
    const options = (this.props.serviceType.rateManagement ? this.props.serviceType.rateManagement.ratesOptions : []);
    return ((options || []).includes(RatesOption.READ_ONLY));
  }

  render() {
    const {
      meter, rate, onChange, customer, serviceType, readOnly, options,
    } = this.props;

    return (
      <Box
        direction='column'
        flex={true}
        pad={{ vertical: 'small' }}
      >
        <Box
          direction='column'
          flex={true}
          gap='small'
        >
          <MeterSettings
            key='settings'
            meter={meter}
            rate={rate}
            expanded={this.state.expanded}
            onToggleExpansion={this._onToggleExpansion}
            onChange={onChange}
            customer={customer}
            serviceType={serviceType}
            readOnly={readOnly || this._isReadOnlySettings()}
            options={options}
          />
          {this._hasMeterCapacities()
          && (
          <MeterCapacities
            key='capacities'
            meter={meter}
            rate={rate}
            expanded={this.state.expanded}
            onToggleExpansion={this._onToggleExpansion}
            onChange={onChange}
            customer={customer}
            serviceType={serviceType}
            readOnly={readOnly || this._isReadOnlyCapacities()}
            options={options}
            userType={this.props.userType}
          />
          )}
          <MeterRates
            key='rates'
            meter={meter}
            rate={rate}
            expanded={this.state.expanded}
            onToggleExpansion={this._onToggleExpansion}
            onChange={onChange}
            customer={customer}
            serviceType={serviceType}
            readOnly={readOnly || this._isReadOnlyRates()}
            options={options}
            userType={this.props.userType}
          />
        </Box>
      </Box>
    );
  }
}

const mapStateToProps = ({
  service: {
    details: {
      customer, options, rates, permissions, migration, validation,
    },
  },
}) => ({

});

const mapDispatchToProps = dispatch => bindActionCreators({
  setRates,
  setOptions,
  setDirtyRates,
}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(MeterDetails);
