// (C) Copyright 2017-2024 Hewlett Packard Enterprise Development LP
import { Enumify } from 'enumify';

export class ServiceCategory extends Enumify {
  static COMPUTE = new ServiceCategory(() => 'Compute');

  static NETWORK = new ServiceCategory(() => 'Network');

  static NETWORKING = new ServiceCategory(() => 'Networking');

  static STORAGE = new ServiceCategory(() => 'Storage');

  static APPLICATION = new ServiceCategory(() => 'Application');

  static DATABASE = new ServiceCategory(() => 'Database');

  static MESSAGING = new ServiceCategory(() => 'Messaging');

  static OPERATIONS = new ServiceCategory(() => 'Operations');

  static ORCHESTRATION = new ServiceCategory(() => 'Orchestration');

  static ANALYTICS = new ServiceCategory(() => 'Analytics');

  static AUTHENTICATION = new ServiceCategory(() => 'Authentication');

  static REGIONALSUPPORT = new ServiceCategory(() => 'Regional Support');

  static _ = this.closeEnum(); // TypeScript: Color.closeEnum()

  constructor(label) {
    super();
    this.label = label;
  }
}
