// (C) Copyright 2017-2024 Hewlett Packard Enterprise Development LP
import { Enumify } from 'enumify';

export class MeterUnit extends Enumify {
  static PB = new MeterUnit('PB');

  static PiB = new MeterUnit('PiB');

  static TB = new MeterUnit('TB');

  static TiB = new MeterUnit('TiB');

  static GB = new MeterUnit('GB');

  static GiB = new MeterUnit('GiB');

  static MB = new MeterUnit('MB');

  static Mib = new MeterUnit('Mib');

  static KB = new MeterUnit('KB');

  static KiB = new MeterUnit('KiB');

  static Bytes = new MeterUnit('Bytes');

  static Byte = new MeterUnit('Byte');

  static Each = new MeterUnit('Each');

  static _ = this.closeEnum(); // TypeScript: Color.closeEnum()

  constructor(label) {
    super();
    this.label = label;
  }
}
