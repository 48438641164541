// (C) Copyright 2017-2024 Hewlett Packard Enterprise Development LP

import toNumber from 'lodash/toNumber';
import isNaN from 'lodash/isNaN';
import keys from 'lodash/keys';
import type { AllCategoriesExceptionDef } from '../../core/types';

export const validateNumber = (value) => {
  if (value) {
    const numVal = toNumber(value);
    if (isNaN(numVal)) {
      return 'Enter number';
    }
  }
  return undefined;
};

export const validateNumber0to100 = (value) => {
  if (value) {
    const numVal = toNumber(value);
    if (isNaN(numVal) || numVal < 0 || numVal > 100) {
      return 'Enter number between 0 and 100';
    }
  }
  return undefined;
};

export const isCategoryDisabled = (type: string, definitions: AllCategoriesExceptionDef, isFetching: boolean) => {
  if (isFetching || !definitions) {
    return true;
  }
  return !keys(definitions[type]).length;
};
