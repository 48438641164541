// (C) Copyright 2017-2024 Hewlett Packard Enterprise Development LP
import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { setDirtyOptions, setOptions } from 'services/redux/ServiceActions';
import {
  Box, FormField, Text, TextInput,
} from 'grommet';
import PropTypes from 'prop-types';
import ReadOnlyField from '../../../../../shared/component/ReadOnlyField';

class WindowsBillingMethodField extends Component {
  _isNumber(n) {
    return !isNaN(parseFloat(n)) && isFinite(n);
  }

  _onChangeUtilization(value) {
    const { options } = this.props;

    if (value && value.length >= 2 && value.startsWith('0')) {
      value = value.slice(1);
    }

    if (this._isNumber(value)) {
      if (parseFloat(value) > 100) {
        options.config.billingMethodUtilization = 100;
      } else if (parseFloat(value) < 0) {
        options.config.billingMethodUtilization = 0;
      } else {
        options.config.billingMethodUtilization = value;
      }
    } else if (value === '') {
      options.config.billingMethodUtilization = 0;
    }
    this.props.setOptions(options);
    this.props.setDirtyOptions();
    this.props.setDirty();
  }

  _getEditableLayout() {
    return (
      <FormField label={this.props.labelUtilization ?? 'Per core utilization'} help={this.props.noteUtilization}>
        <Box flex='grow' className='tiny-inputs' direction='row' gap='small' align='center' pad={{ vertical: 'small' }}>
          <Box style={{ minWidth: '12px' }} />
          <div style={{ border: '1px solid rgba(0, 0, 0, 0.15)' }}>
            <TextInput
              value={this.props.options.config.billingMethodUtilization}
              name='perCoreUtilization'
              onChange={(event) => {
                this._onChangeUtilization(event.target.value);
              }}
              id='perCoreUtilization'
              plain={true}
            />
          </div>
          <Text weight={100} size='small'>Utilization %</Text>
        </Box>
      </FormField>
    );
  }

  _getReadOnlyLayout() {
    const labelFromBillingMethodMap = new Map()
      .set('PER_CORE_UTILIZATION', 'Per core utilization');

    const { windowsUtilBillingMethod } = this.props.options.config;

    return (
      <ReadOnlyField label='Per core utilization' value={labelFromBillingMethodMap.get(windowsUtilBillingMethod)} />
    );
  }

  render() {
    return this.props.readOnly ? this._getReadOnlyLayout() : this._getEditableLayout();
  }
}

WindowsBillingMethodField.propTypes = {
  setOptions: PropTypes.func.isRequired,
  setDirtyOptions: PropTypes.func.isRequired,
  setDirty: PropTypes.func.isRequired,
  options: PropTypes.shape({
    config: PropTypes.shape({
      windowsUtilBillingMethod: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      capUsage: PropTypes.bool,
      billingMethodUtilization: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }),
  }),
};

const mapStateToProps = ({ service: { details: { options } } }) => ({ options });

const mapDispatchToProps = dispatch => bindActionCreators({
  setOptions,
  setDirtyOptions,
}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(WindowsBillingMethodField);
