// (C) Copyright 2017-2024 Hewlett Packard Enterprise Development LP
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { useOktaAuth } from '@okta/okta-react';
import { FormNextLink } from 'grommet-icons';
import {
  Box,
  Button,
  Header,
  Heading,
} from 'grommet';
import IDUtil from '../shared/util/IDUtil';
import MarketingHeader from '../headers/MarketingHeader';
import MarketingFooter from '../headers/MarketingFooter';
import TimeOutModal from './TimeOutModal';

const LoginPage = () => {
  // eslint-disable-next-line no-console
  console.log('==> LoginPage');
  const [loggingIn, setLoggingIn] = useState(false);
  const { oktaAuth } = useOktaAuth();

  const navigate = useNavigate();
  const [source, setSource] = useState(undefined);

  useEffect(() => {
    localStorage.removeItem('timeout');
  }, []);

  useEffect(() => {
    if (oktaAuth && oktaAuth.authStateManager) {
      const authState = oktaAuth.authStateManager.getAuthState();
      if (authState && authState.isAuthenticated) {
        navigate('/login');
      }
    }
  }, [navigate, oktaAuth]);

  useEffect(() => {
    if (loggingIn) {
      if (oktaAuth) {
        oktaAuth.signInWithRedirect();
      }
    }
  }, [loggingIn]);

  const onSSOLogin = () => {
    setLoggingIn(true);
  };

  return (
    <Box
      fill={true}
      direction='column'
    >
      <TimeOutModal show={source === 'login' || source === 'sso'} source={source} close={() => setSource(undefined)} />
      <MarketingHeader />
      <Box fill={true} background='#425563'>
        <Box fill={true} direction='row' justify='center'>
          <Box fill='vertical' justify='center'>
            <Box
              overflow='auto'
              direction='column'
            >
              <Box
                style={{ overflow: 'hidden' }}
                background='white'
                elevation='xlarge'
                round={true}
              >
                <Box id={IDUtil.getId('LoginFormContainer')}>
                  <Box pad='large'>
                    <Box
                      pad='medium'
                      id='loginForm'
                      className={`${IDUtil.getClass('LoginFormClass')}`}
                    >
                      <Header flex={false} direction='column'>
                        <Box style={{ marginBottom: '0px' }}>
                          <img src='/img/hpe_pri_grn_pos_rgb.svg' height='177' alt='HPE Logo' />
                        </Box>
                        <Heading
                          style={{ fontSize: '34px' }}
                          strong={true}
                          uppercase={false}
                          truncate={false}
                        >
                          HPE GreenLake Billing Manager
                        </Heading>
                      </Header>
                    </Box>
                    <Box align='end' pad={{ horizontal: 'medium' }} margin={{ vertical: 'large' }}>
                      <Button
                        primary={true}
                        fill={true}
                        onClick={onSSOLogin}
                        icon={<FormNextLink size='small' />}
                        label='HPE Employee sign in'
                        busy={loggingIn}
                      />
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
      <MarketingFooter />
    </Box>
  );
};

export default LoginPage;
