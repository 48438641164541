// (C) Copyright 2017-2024 Hewlett Packard Enterprise Development LP
import { validateOptions, validateRates, validateResources } from './utils';
import { ServiceStep } from '../model';
import { ValidationError } from './ValidationErrors';
import ServiceTypeStore from '../../stores/ServiceTypeStore';

export class ServiceValidator {
  static validate(step, results, customer, options, equipment, meters, rates, locationRates, context) {
    const props = new ValidationError({});
    const {
      reportBy, tierType, location,
    } = options.config;
    const {
      serviceType: type,
    } = options;
    const {
      tenant,
    } = context;

    const serviceType = ServiceTypeStore.getService(type);

    /**
     * TODO refactor this to use Map/Object/Switch to delegate functionality to separate Validator classes for each step.
     *  This way we don't balloon this validate method with more switch cases, making readability/scan-ability much more
     *  difficult. This process is started for options, but the pattern should continue for mappings and rates as well.
     *  For example, the ServiceValidator validate method map might look like this:
     *
     *  const stepValidationMap = new Map()
     *    .set(ServiceStep.option, getOptionsValidation())
     *    .set(ServiceStep.mappings, getMappingsValidation())
     *    .set(ServiceStep.rates, getRatesValidation());
     *
     *    return stepValidationMap.get(step);
     */

    switch (step) {
      case ServiceStep.OPTIONS:
        return validateOptions(options, results, customer);
      case ServiceStep.CONFIGURE_RESOURCES:
        validateResources(customer, options, equipment, serviceType, reportBy, tierType, location, props);
        break;
      case ServiceStep.RATES:
        validateRates(customer, props, meters, rates, tenant, locationRates, serviceType);
        break;
      default:
        console.error(`Unsupported step type: ${step}`);
        break;
    }
    return props;
  }
}
