// (C) Copyright 2017-2024 Hewlett Packard Enterprise Development LP
import { Search } from 'grommet-icons';
import React, { useMemo, useState } from 'react';
import 'ag-grid-community/styles/ag-grid.css'; // Core grid CSS, always needed
import 'ag-grid-community/styles/ag-theme-balham.css'; // Optional theme CSS
import {
  Box, Button, Footer, TextInput,
} from 'grommet';

import PropTypes from 'prop-types';
import moment from 'moment';
import GLBMLayer from '../../shared/component/GLBMLayer';
import IDUtil from '../../shared/util/IDUtil';
import { useToast } from '../../shared/toast/ToastProvider';
import GLBMDataTable from '../../shared/component/GLBMDataTable';
import { useUserHistoryQuery } from '../../../core';

const ViewAccessRequestDetails = ({ onClose, user = {} }) => {
  const [searchText, setSearchText] = useState('');
  const [sort, setSort] = useState({ property: 'time', direction: 'desc', external: undefined });
  const { addToast } = useToast();
  const {
    isLoading,
    data: historyData,
  } = useUserHistoryQuery(user?.user.id, {
    onError: ((err) => {
      addToast({
        status: 'critical',
        title: 'Fetch Customers Error',
        message: err?.message,
      });
    })
  });
  const columns = useMemo(() => [
    {
      property: 'time',
      header: 'Date',
      primary: true,
      size: 'medium',
      render: ({ time }) => moment(time).format('ll LTS')
    },
    {
      property: 'userEmail',
      header: 'User',
      primary: false,
      size: 'small',
    }, {
      property: 'description',
      header: 'Description',
      primary: false,
    },
  ], []);
  const filteredHistory = useMemo(() => {
    if (!searchText) {
      return historyData || [];
    }

    return historyData.filter(({
      time, description, userEmail,
    }) => {
      const searchableText = `${time + description + userEmail}`;
      return searchableText.toLowerCase().includes(searchText.toLowerCase());
    }).map((el, index) => ({ ...el, index }));
  }, [searchText, historyData]);

  return (
    <GLBMLayer
      flush={true}
      overlayClose={true}
      onEsc={onClose}
      onClickOutside={onClose}
      title='Access Request Details'
      full={true}
      margin='xlarge'
    >
      <Box direction='column' fill={true} pad='medium'>
        <Box
          flex={true}
          pad={{ horizontal: 'small' }}
          direction='column'
          fill='vertical'
          overflow='hidden'
          gap='xsmall'
        >
          <TextInput
            id={IDUtil.getId('AccessRequestDetailsToolbarSearchInput')}
            size='medium'
            placeholder='Search'
            icon={<Search />}
            value={searchText || ''}
            onChange={event => setSearchText(event.target.value)}
            data-testid='AccessRequestDetailsToolbarSearchInput'
          />
          <GLBMDataTable
            searchText={searchText || ''}
            data={isLoading ? [] : filteredHistory}
            columns={columns}
            loading={isLoading}
            total={isLoading ? 0 : filteredHistory.length}
            sort={sort}
            onSort={setSort}
          />
        </Box>
        <Box border='top' pad='small' margin={{ top: 'small' }} flex={false}>
          <Footer flex={false} justify='start' gap='small'>
            <Button
              label='Close'
              key='closeButton'
              secondary={true}
              onClick={onClose}
            />
          </Footer>
        </Box>
      </Box>
    </GLBMLayer>
  );
};

ViewAccessRequestDetails.propTypes = {
  onClose: PropTypes.func.isRequired,
  user: PropTypes.object
};

export default ViewAccessRequestDetails;
