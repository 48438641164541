// (C) Copyright 2017-2024 Hewlett Packard Enterprise Development LP
import { EventEmitter } from 'events';
import AppDispatcher from '../dispatcher/AppDispatcher';
import ServicesConstants from '../constants/ServicesConstants';
import {
  debugServices,
  transformServiceTypesToEnums,
  validateService,
} from './Util';
import UserStore from './UserStore';
import { isUnassignedRole } from '../shared/constants/UserType';

const CHANGE_EVENT = 'change';

class ServiceTypeStoreClass extends EventEmitter {
  constructor() {
    super();
    this.services = [];
  }

  emitChange() {
    this.emit(CHANGE_EVENT);
  }

  addChangeListener(callback) {
    this.on(CHANGE_EVENT, callback);
  }

  removeChangeListener(callback) {
    this.removeListener(CHANGE_EVENT, callback);
  }

  getServices() {
    if (!this.services) {
      return [];
    }
    return this.services;
  }

  getService(type) {
    const currentUser = UserStore.getUser();
    if (this.services && this.services.length > 0 && type) {
      const match = this.services.find(service => type === service.type);
      if (isUnassignedRole(currentUser?.role)) {
        return match;
      }
      if (match) {
        if (validateService(match)) {
          return match;
        }
      }
    }
    return undefined;
  }

  setServices(services) {
    this.services = transformServiceTypesToEnums(services);
    debugServices(this.services);
  }
}

const ServiceTypeStore = new ServiceTypeStoreClass();

ServiceTypeStore.dispatchToken = AppDispatcher.register((action) => {
  switch (action.actionType) {
    case ServicesConstants.LOAD_SERVICE_DEFINITIONS:
      ServiceTypeStore.setServices(action.types);
      ServiceTypeStore.emitChange();
      break;
    default:
  }
});

export default ServiceTypeStore;
