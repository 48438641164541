// (C) Copyright 2017-2024 Hewlett Packard Enterprise Development LP
import { updateStateAndSession } from '../../session/sessionUtil';

export const INVALIDATE_JOBS = 'INVALIDATE_JOBS';
export const REQUEST_JOBS = 'REQUEST_JOBS';
export const SUCCESS_JOBS = 'SUCCESS_JOBS';
export const FAILURE_JOBS = 'FAILURE_JOBS';
export const UPDATE_JOBS_FILTERS = 'UPDATE_JOBS_FILTERS';

export function updateFilters(filters) {
  return {
    type: UPDATE_JOBS_FILTERS,
    filters,
  };
}

export const updateFiltersAndSession = updateStateAndSession(updateFilters);
