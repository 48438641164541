// (C) Copyright 2017-2024 Hewlett Packard Enterprise Development LP
import { Box, Text } from 'grommet';
import React from 'react';

function SidebarHeader() {
  return (
    <Box align='center' gap='small' direction='column' margin={{ bottom: 'none' }} pad='medium'>
      <img src='/img/hpe_pri_grn_rev_rgb.svg' height='150' alt='HPE Logo' />
      <Text weight='bold' className='greenlake-nav-title'>HPE GreenLake Billing Manager</Text>
    </Box>
  );
}

export default SidebarHeader;
