// (C) Copyright 2017-2024 Hewlett Packard Enterprise Development LP
import { useQueryClient } from '@tanstack/react-query';
import { Box, Button, Heading } from 'grommet';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import IDUtil from '../../shared/util/IDUtil';
import {
  useCustomerQuery,
  useCustomerUpdateMutate,
  usePSADataQuery,
} from '../../../core';
import GLBMHeading from '../../shared/component/GLBMHeading';
import GLBMNameValueList from '../../shared/component/GLBMNameValueList';
import GLBMSaving from '../../shared/component/GLBMSaving';
import { UserType } from '../../shared/constants/UserType';
import ValidationDialog from '../../shared/dialogs/ValidationDialog';
import UserStore from '../../stores/UserStore';
import PSAProjectInfoPanel from './PSAProjectInfoPanel';

const getCustomerServiceTypes = ({ services }) => (services || []).map(({ type }) => type);

const PSAManagePage = () => {
  const { customerId } = useParams();
  const navigate = useNavigate();

  // State:
  const [isSaving, setIsSaving] = useState(false);
  const [psaProject, setPsaProject] = useState(undefined);
  const [psaProjectId, setPsaProjectId] = useState(undefined);
  const [psaNotRequired, setPsaNotRequired] = useState(false);
  const [psaProjectServices, setPsaProjectServices] = useState(undefined);
  const [errors, setErrors] = useState({ errors: { psaProjectId: [] } });
  const [showValidation, setShowValidation] = useState(false);
  const [validations, setValidations] = useState([]);

  const {
    data: customer,
    isSuccess,
  } = useCustomerQuery(customerId, {
    enabled: !!customerId,
  });

  useEffect(() => {
    if (isSuccess) {
      setPsaProject(customer.psaProject);
      setPsaProjectId(customer.psaProject ? customer.psaProject.projectId : undefined);
      setPsaNotRequired(customer.psaNotRequired);
      // eslint-disable-next-line no-nested-ternary
      setPsaProjectServices(customer.psaProject ? customer.psaProjectServices : (customer.psaNotRequired ? [] : getCustomerServiceTypes(customer)));
    }
  }, [isSuccess, customer]);

  const onCustomerSaveSuccess = useCallback(() => {
    setIsSaving(false);
    setTimeout(() => {
      navigate('/customers');
    });
  }, [navigate]);

  const onCustomerSaveError = useCallback((error) => {
    // eslint-disable-next-line no-console
    console.log(error);
    setIsSaving(false);
  }, []);

  const {
    mutate: updateCustomer,
  } = useCustomerUpdateMutate(customerId, {
    onSuccess: onCustomerSaveSuccess,
    onError: onCustomerSaveError,
  });

  // create the query object to hold all the needed query for this components:
  const queryClient = useQueryClient();
  const query = usePSADataQuery(psaProjectId, {
    retry: false,
    enabled: false, // using button click to call query.refetch() instead...
  });

  useEffect(() => {
    if (query.isSuccess) {
      setPsaProject(query.data);
    } else if (query.isError) {
      console.error('psaProject.err', query.error);
      setPsaProject(undefined);
    }
  }, [query.isSuccess, query.isError, query.data, query.error]);

  const onClickCancel = () => {
    navigate('/customers/');
  };

  const onSubmit = () => {
    if (isSaving) {
      console.warn('Already saving customer...');
      return;
    }

    setIsSaving(true);

    const modifiedCustomer = { ...customer };
    modifiedCustomer.psaNotRequired = psaNotRequired;
    modifiedCustomer.psaProject = !psaNotRequired ? psaProject : undefined;
    modifiedCustomer.psaProjectServices = !psaNotRequired ? psaProjectServices : undefined;

    updateCustomer(modifiedCustomer);
  };

  /**
   * TODO This will need to change once we decide how we will do this..
   * @param callBackFn
   * @private
   */
  const performValidation = (callBackFn) => {
    let validationErrors = false;
    const validationsData = [];

    // if missing psa base project id:
    if (!psaProjectId) {
      errors.psaProjectId = ['Required'];
      validationErrors = true;
    }
    validationsData.push({
      severity: (!psaProjectId ? 'critical' : 'ok'),
      text: 'Base Project ID entered',
    });

    // if psa data not loaded:
    if (!psaProject) {
      validationErrors = true;
    }
    validationsData.push({
      severity: (!query.data ? 'critical' : 'ok'),
      text: 'PSA Data successfully retrieved',
    });

    // if missing services:
    if (!psaProjectServices || psaProjectServices.length === 0) {
      errors.psaProjectServices = ['Required'];
      validationErrors = true;
    }
    validationsData.push({
      severity: (!psaProjectServices || psaProjectServices.length === 0 ? 'critical' : 'ok'),
      text: 'One or more project services selected',
    });

    setShowValidation(validationErrors);
    setValidations(validationsData);

    if (validationErrors) {
      return;
    }

    // we have no validation issues, lets call the callback function:
    callBackFn();
  };

  const renderLayers = () => showValidation
    && (
      <ValidationDialog
        title='Unable to apply PSA Project Info changes'
        subTitle='The following situations were checked and found:'
        hint='All critical issues must be resolved before applying PSA Base Project ID changes.'
        cancelLabel='OK'
        onCancel={() => setShowValidation(false)}
        data={validations}
      />
    );

  function renderNavButtons(isFetching) {
    return (
      <Box
        direction='row'
        gap='small'
        pad={{
          horizontal: 'small',
          vertical: 'small',
        }}
      >
        <Button
          label='Save'
          type='button'
          primary={true}
          id={IDUtil.getId('EditorViewToolbarSaveButton')}
          onClick={(!isFetching && !isSaving && customer) ? () => {
            if (psaNotRequired) {
              onSubmit();
            } else {
              performValidation(onSubmit);
            }
          } : undefined}
        />
        <Button
          label='Cancel'
          type='button'
          secondary={true}
          id={IDUtil.getId('EditorViewToolbarCancelButton')}
          onClick={onClickCancel}
        />
        <GLBMSaving saving={isSaving} />
      </Box>
    );
  }

  // initialize:
  useEffect(() => {
    if (psaProjectId !== null && psaProjectId !== '' && psaProject !== undefined) {
      query.refetch(); // execute query:
    } else if (psaProjectId && psaProject) {
      queryClient.setQueryData(['GLBM:CUSTOMER-PSA-DATA', psaProjectId], psaProject);
    }
  }, [customer]); // fires when loading + psaProjectId is modified...

  const isAdmin = UserStore.getUser().role === UserType.SUPER.enumKey || UserStore.getUser().role === UserType.SERVICE_DEV.name;

  return (
    <Box direction='column' fill='vertical'>
      <GLBMHeading
        back='/customers'
        title={`Manage PSA Project Info: ${customer ? `${customer.name} (${customer.id})` : ''}`}
      />
      <Box
        flex={true}
        pad={{ horizontal: 'medium' }}
        overflow='auto'
        gap='medium'
      >
        <Box flex={false}>
          <GLBMNameValueList
            title='Billing Account Information'
            data={[
              {
                label: 'Name',
                value: customer && customer.name,
              },
              {
                label: 'Billing ID',
                value: customer && customer.id,
              },
            ]}
          />
        </Box>
        <Box flex={false}>
          <Heading level='3' size='medium'>PSA Project Information</Heading>
          <PSAProjectInfoPanel
            psaProjectId={psaProjectId}
            setPsaProjectId={(id) => {
              setPsaProject(undefined);
              setPsaProjectId(id);
            }}
            psaProjectServices={psaProjectServices}
            setPsaProjectServices={setPsaProjectServices}
            errors={errors}
            setErrors={setErrors}
            queryError={query.error}
            psaProject={psaProject}
            isFetching={query.isFetching}
            refresh={query.refetch}
            psaNotRequired={psaNotRequired}
            setPsaNotRequired={setPsaNotRequired}
            isAdmin={isAdmin}
            showPsaPortalActivationDate={true}
            psaPortalActivationDate={customer && customer.psaPortalActivationDate}
          />
        </Box>
      </Box>
      <Box border='top'>
        {renderNavButtons(query.isFetching)}
      </Box>
      {renderLayers()}
    </Box>
  );
};

PSAManagePage.contextTypes = {
  router: PropTypes.object,
};

export default PSAManagePage;
