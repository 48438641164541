// (C) Copyright 2017-2024 Hewlett Packard Enterprise Development LP
class ExportUtil {
  constructor() {
    this.mapper = [['\n', /\n/gm, '\\n'],
      ['\r', /\r/gm, '\\r'],
      ['"', /\"/gm, '""']];
  }

  escape(data) {
    if (data === void 0 || data === null) {
      return '""';
    } if (typeof data === 'string') {
      return this.escapeStr(data);
    } if (!isNaN(parseFloat(data)) && isFinite(parseFloat(data))) {
      return `${data}`;
    }
    return this.escapeStr(`${data}`);
  }

  escapeStr(data) {
    for (let i = 0; i < this.mapper.length; i += 1) {
      if (data.indexOf(this.mapper[i][0]) !== -1) {
        data = data.replace(this.mapper[i][1], this.mapper[i][2]);
      }
    }
    return `"${data}"`;
  }

  fetch(obj, property) {
    if (property.indexOf('.') === -1) {
      return this.escape(obj[property]);
    }
    const properties = property.split('.');

    let result = obj[properties[0]];

    if (result === void 0) {
      return '""';
    }

    for (let i = 1; i < properties.length; i += 1) {
      result = result[properties[i]];
      if (result === void 0) {
        return '""';
      }
    }
    return this.escape(result);
  }

  supportsDataUri() {
    return !(document.documentMode || /Edge/.test(navigator.userAgent)); // Return true if not any IE
  }

  generateCsv(arr, fields, headers) {
    let csv = ''; let
      i = 0;
    if (headers) {
      for (i = 0; i < headers.length; i += 1) {
        if (i > 0) {
          csv += ',';
        }
        csv += this.escape(headers[i]);
      }
      csv += '\n';
    }

    arr.forEach((obj) => {
      for (i = 0; i < fields.length; i += 1) {
        if (i > 0) {
          csv += ',';
        }
        csv += this.fetch(obj, fields[i]);
      }
      csv += '\n';
    });
    return csv;
  }

  downloadCSV(fileName, csvData) {
    const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' });
    if (navigator.msSaveBlob) { // IE 10+
      navigator.msSaveBlob(blob, fileName);
    } else {
      const link = document.createElement('a');
      if (link.download !== undefined) { // feature detection
        // Browsers that support HTML5 download attribute
        const url = URL.createObjectURL(blob);
        link.setAttribute('href', url);
        link.setAttribute('download', fileName);
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    }
  }

  csv(arr, fields, fileName, headers) {
    try {
      const csvData = this.generateCsv(arr, fields, headers);
      this.downloadCSV(fileName, csvData);
    } catch (e) {
      console.error(e);
    }
  }
}
const ExportUtilSingleton = new ExportUtil();
export default ExportUtilSingleton;
