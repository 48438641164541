// (C) Copyright 2017-2024 Hewlett Packard Enterprise Development LP

import { useMemo } from 'react';

export const useAuditTypeMap = types => useMemo(() => {
  if (types) {
    return types.reduce(
      (newTypes, c) => {
        // eslint-disable-next-line no-param-reassign
        newTypes[c.id] = c;
        return newTypes;
      },
      {},
    );
  }
  return {};
}, [types]);
