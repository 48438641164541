// (C) Copyright 2017-2024 Hewlett Packard Enterprise Development LP

import React, { Component } from 'react';

import { connect } from 'react-redux';
import {
  Box,
  Button,
  FormField,
  Header, Heading,
  Table,
  TableBody,
  TableCell,
  TableHeader,
  TableRow,
  TextInput,
} from 'grommet';
import { Add, FormTrash } from 'grommet-icons';
import CurrencyUtils from '../../../../../i18n/CurrencyUtil';
import { UserType } from '../../../../../shared/constants/UserType';
import IDUtil from '../../../../../shared/util/IDUtil';
import { MarkupType } from '../../../../../constants/MarkupType';
import {
  calculateDownstreamRate,
  calculateMarkup,
  isLessThanFiveDecimalPlaces,
  isLessThanNineDecimalPlaces,
  isNumber,
} from '../util';

function TableData({ style, children }) {
  return (
    <TableCell
      className='rateTableRow'
      style={{
        verticalAlign: 'baseline',
        alignItems: 'baseline',
        maxWidth: 115,
        ...style,
      }}
      align='start'
      flex={true}
      fill={true}
    >
      {children}
    </TableCell>
  );
}

class RatesStepEditor extends Component {
  constructor(props) {
    super(props);

    this._onStepAdd = this._onStepAdd.bind(this);
    this._onChange = this._onChange.bind(this);
    this._onStepRemove = this._onStepRemove.bind(this);
    this._updateMaster = this._updateMaster.bind(this);
    this.startBandRenderer = this.startBandRenderer.bind(this);
    this.endBandRenderer = this.endBandRenderer.bind(this);
    this.rateRenderer = this.rateRenderer.bind(this);
    this.removeBand = this.removeBand.bind(this);
    this._getTableHeaders = this._getTableHeaders.bind(this);
    this._getPartnerRowTd = this._getPartnerRowTd.bind(this);
    this._getMarkup = this._getMarkup.bind(this);
    this._getCustomerRate = this._getCustomerRate.bind(this);
    this._getCurrencySymbol = this._getCurrencySymbol.bind(this);

    this.state = {
      steps: Object.entries(props.steps)
        .sort(([a], [b]) => ((parseFloat(a) < parseFloat(b)) ? -1 : (a === b) ? 0 : 1))
        .reduce((arr, [threshold, {
          rate, name, markup, downstreamRate,
        }]) => (
          [...arr, {
            threshold, rate, name, markup, downstreamRate,
          }]
        ), []),
      master: props.steps,
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.markupType !== prevProps.markupType) {
      const steps = Object.entries(this.props.steps)
        .sort(([a], [b]) => ((parseFloat(a) < parseFloat(b)) ? -1 : (a === b) ? 0 : 1))
        .reduce((arr, [threshold, {
          rate, name, markup, downstreamRate,
        }]) => (
          [...arr, {
            threshold, rate, name, markup, downstreamRate,
          }]
        ), []);
      this.setState({ steps, master: this.props.steps });
    }
  }

  _getRows() {
    return this.state.steps.map((step, index, steps) => (
      <TableRow key={index} style={{ verticalAlign: 'top' }}>
        <TableData>{this.nameBandRenderer(step, index)}</TableData>
        <TableData>{this.startBandRenderer(step, index)}</TableData>
        <TableData>{this.endBandRenderer(step, index, steps)}</TableData>
        <TableData>{this.rateRenderer(step, index)}</TableData>
        {this._getPartnerRowTd(step, index)}
        <TableData style={{ maxWidth: 50 }}>{this.removeBand(index)}</TableData>
      </TableRow>
    ));
  }

  nameBandRenderer(step, index) {
    return (
      <Box direction='row'>
        <Box flex={true} style={{ whiteSpace: 'nowrap' }}>
          {
            (this.props.canEdit && (this.props.userType === UserType.SUPER.enumKey || this.props.userType === UserType.SERVICE_DEV.enumKey)) ? (
              <FormField required={true} error={!step.name || step.name === undefined ? 'Required' : undefined}>
                <TextInput
                  value={step.name}
                  name='name'
                  onChange={(event) => {
                    this._onChange(event, index);
                  }}
                  id={IDUtil.getId('MeterStepEditorNameInput', index)}
                />
              </FormField>
            ) : (<Box pad={{ top: 'small' }}>{step.name}</Box>)
          }
        </Box>
      </Box>
    );
  }

  _onBlur({ target: { value, name } } = { target: { name: null } }, index) {
    const steps = [...this.state.steps];
    if (steps && steps.length) {
      const step = steps[index];
      const value = step[name];
      if (!isNumber(value)) {
        switch (name) {
          case 'rate':
            step[name] = 0;
            break;
          default:
            step[name] = undefined;
        }
      }
    }

    this._updateMaster(steps);
    this.setState({ steps });
    this.props.onChange();
  }

  _getMarkup(step, index) {
    const { markup } = step;
    const { markupType } = this.props;
    if (markupType === MarkupType.MARKUP_PERCENT) {
      return (
        <Box direction='row'>
          <Box flex={true}>
            <TextInput
              value={markup === undefined ? '' : markup}
              name='markup'
              style={{ background: 'white' }}
              onChange={(event) => {
                this._onChange(event, index);
              }}
              onBlur={event => this._onBlur(event, index)}
              id={IDUtil.getId('MeterStepEditorThresholdInput', index)}
            />
          </Box>
          <Box margin={{ left: 'small' }}>%</Box>
        </Box>
      );
    }

    if (markupType === MarkupType.DIRECT_RATE) {
      if (markup !== undefined) {
        return (
          <Box direction='row'>
            <Box
              margin={{ left: 'small' }}
              flex={true}
            >
              {(!markup || markup === Infinity) ? '-' : `${CurrencyUtils.getNumberString(parseFloat(markup), 4)}%`}
            </Box>
          </Box>
        );
      }
    }
    return (
      <Box direction='row'>
        <Box margin={{ left: 'small' }} flex={true}>
          -
        </Box>
      </Box>
    );
  }

  _getCustomerRate({ markup, downstreamRate }, index) {
    const { markupType, contractCurrency } = this.props;

    if (markupType === MarkupType.DIRECT_RATE) {
      return (
        <Box direction='row'>
          <Box flex={true}>
            <TextInput
              value={downstreamRate === undefined ? '' : downstreamRate}
              name='downstreamRate'
              style={{ background: 'white' }}
              onChange={(event) => {
                this._onChange(event, index);
              }}
              onBlur={event => this._onBlur(event, index)}
              id={IDUtil.getId('MeterStepEditorThresholdInput', index)}
            />
          </Box>
        </Box>
      );
    }

    if (markupType === MarkupType.MARKUP_PERCENT) {
      if (downstreamRate !== undefined) {
        return (
          <Box direction='row'>
            <Box
              margin={{ left: 'small' }}
              flex={true}
            >
              {`${CurrencyUtils.getNumberString(parseFloat(downstreamRate), 8, null, contractCurrency)}`}
            </Box>
          </Box>
        );
      }
    }

    return 'Not Available';
  }

  _getPartnerRowTd(step, index) {
    const backgroundColor = { backgroundColor: 'rgba(248, 248, 248, 0.95)' };
    return this.props.canMarkupRates ? [
      <TableData style={backgroundColor}>{this._getMarkup(step, index)}</TableData>,
      <TableData style={backgroundColor}>{this._getCustomerRate(step, index)}</TableData>,
    ] : [];
  }

  startBandRenderer(step, index) {
    const { threshold } = step;
    const textBand = `>= ${threshold}`;
    const inputBand = (
      <Box direction='row' style={{ alignItems: 'baseline' }}>
        { index === 0
          ? (
            <Box
              flex={true}
              fill={true}
              pad={{ top: 'small' }}
            >
              {textBand}
            </Box>
          ) : (
            <Box flex={true} direction='row' style={{ alignItems: 'baseline' }}>
              <Box margin={{ right: 'small' }}>{'>'}</Box>
              {
            this.props.canEdit ? (
              <FormField required={true} error={!threshold || threshold === undefined ? 'Required' : undefined}>
                <TextInput
                  value={threshold}
                  name='threshold'
                  onChange={(event) => {
                    this._onChange(event, index);
                  }}
                  id={IDUtil.getId('MeterStepEditorThresholdInput', index)}
                />
              </FormField>
            ) : threshold
          }
            </Box>
          )}
      </Box>
    );
    return inputBand;
  }

  endBandRenderer(step, index, steps) {
    const { threshold } = steps[index + 1] || {};
    return (
      <Box
        direction='column'
        style={{ alignItems: 'baseline' }}
        fill={true}
        pad={{ top: 'small' }}
      >
        <Box margin={{ right: 'small' }} fill={true}>{threshold ? `and <= ${threshold}` : 'and up'}</Box>
      </Box>
    );
  }

  rateRenderer({ rate }, index) {
    const { contractCurrency } = this.props;
    return (
      <Box flex={true}>
        {
          this.props.canEdit ? (
            <FormField required={true} error={rate === undefined ? 'Required' : undefined}>
              <TextInput
                value={rate === undefined ? '' : rate}
                name='rate'
                onChange={(event) => {
                  this._onChange(event, index);
                }}
                onBlur={event => this._onBlur(event, index)}
                id={IDUtil.getId('MeterStepEditorRateInput', index)}
              />
            </FormField>
          ) : (rate !== undefined ? `${CurrencyUtils.getNumberString(rate, 8, null, contractCurrency)}` : 'Not Available')
        }
      </Box>
    );
  }

  removeBand(index) {
    return this.props.canEdit ? (
      <Box pad={{ top: 'xsmall' }}>
        <Button
          icon={<FormTrash />}
          key={index}
          onClick={() => this._onStepRemove(index)}
          id={IDUtil.getId('MeterStepEditorDeleteButton', index)}
          a11yTitle='Delete Step'
        />
      </Box>
    ) : null;
  }

  _onStepAdd() {
    const steps = [...this.state.steps];
    if (steps.length) {
      steps.push({
        name: `Band ${steps.length + 1}`,
        threshold: parseFloat(steps[steps.length - 1].threshold || 0) + 1,
        rate: steps[steps.length - 1].rate,
      });
    } else {
      steps.push({ name: 'Band 1', threshold: 0, rate: 0 });
    }

    this._updateMaster(steps);
    this.setState({ steps });
    this.props.onChange();
  }

  _onStepRemove(index) {
    const steps = [...this.state.steps];
    steps.splice(index, 1);
    if (steps.length) {
      steps[0].threshold = 0;
    }
    if (steps.length > 0) {
      this._updateMaster(steps);
      this.setState({ steps });
      this.props.onChange();
    } else {
      this.props.showNoBandsError();
    }
  }

  _onChange({ target: { value, name } } = { target: { name: null } }, index) {
    const steps = [...this.state.steps];

    if (name) {
      switch (name) {
        case 'name':
          steps[index][name] = value;
          break;
        case 'markup':
        case 'downstreamRate':
          // if user presses minus and not at the beginning of the string
          const minusCount = (value.match(/-/g) || []).length;
          if (minusCount === 1) {
            const minusIndex = value.indexOf('-');
            if (minusIndex !== -1 && minusIndex > 0) {
              value = `-${value.split('-').join('')}`;
            }
          } else if (minusCount === 2) {
            // if user presses minus a second time:
            value = value.split('-').join('');
          }

          if (value === '-') {
            steps[index][name] = value;
          } else if (value === '') {
            steps[index][name] = undefined;
          } else if (isNumber(value)) {
            if (name === 'markup' && isLessThanFiveDecimalPlaces(value)) {
              steps[index][name] = value;
            } else if (name === 'downstreamRate' && isLessThanNineDecimalPlaces(value)) {
              steps[index][name] = value;
            }
          }

          // calculate and set the 'other' value:
          if (name === 'markup') {
            steps[index].downstreamRate = calculateDownstreamRate(steps[index].markup, steps[index].rate);
          } else if (name === 'downstreamRate') {
            steps[index].markup = calculateMarkup(steps[index].downstreamRate, steps[index].rate);
          }

          break;
        default:
          if (!value || value === '.' || (isNumber(value) && isLessThanNineDecimalPlaces(value))) {
            steps[index][name] = value;
          }
          break;
      }
    }
    this._updateMaster(steps);
    this.setState({ steps });
    this.props.onChange();
  }

  _updateMaster(steps) {
    Object.keys(this.props.steps).forEach((key) => {
      delete this.props.steps[key];
    });
    Object.assign(this.props.steps, steps.reduce((obj, {
      threshold, rate, name, markup, downstreamRate,
    }) => {
      obj[threshold] = {
        rate: rate !== undefined ? parseFloat(rate) : undefined,
        name,
        markup: markup !== undefined ? parseFloat(markup) : undefined,
        downstreamRate: downstreamRate !== undefined ? parseFloat(downstreamRate) : undefined,
      };
      return obj;
    }, {}));
  }

  _getCurrencySymbol(contractCurrency, locale) {
    if (contractCurrency) {
      return CurrencyUtils.getCurrencyString(undefined, undefined, locale, contractCurrency).replace(/[0,.]+/gi, '').trim();
    }
    return '$';
  }

  _getTableHeaders() {
    const TableHeading = ({ children }) => (
      <TableCell scope='col' border='bottom'>
        {children}
      </TableCell>
    );
    const { canMarkupRates, contractCurrency, locale } = this.props;
    const partnerHeaders = canMarkupRates ? ['Markup', `Customer Rate (${this._getCurrencySymbol(contractCurrency, locale)})`] : [];
    return ['Name', 'Start', 'End', `Rate Per Unit (${this._getCurrencySymbol(contractCurrency, locale)})`, ...partnerHeaders, ''].map(label => <TableHeading>{label}</TableHeading>);
  }

  render() {
    return (
      <Box flex='grow' className='rates-steps-editor tiny-inputs'>
        <Header size='small' justify='between'>
          <Heading level='3'>Steps and Rates</Heading>
          {this.props.canEdit
            ? (
              <Button
                icon={<Add />}
                onClick={this.props.canEdit ? this._onStepAdd : undefined}
                id={IDUtil.getId('MeterStepEditorAddButton')}
                a11yTitle='Add Step'
              />
            ) : undefined}
        </Header>
        <Table responsive={false} scrollable={false} data-e2e='steps-and-rates'>
          <TableHeader pad='small'>
            <TableRow>
              {this._getTableHeaders()}
            </TableRow>
          </TableHeader>
          <TableBody>
            {this._getRows()}
          </TableBody>
        </Table>
      </Box>
    );
  }
}

const mapStateToProps = ({ service }) => ({
  ...service.details.customer,
  ...service.details.permissions,
});

export default connect(mapStateToProps)(RatesStepEditor);
