// (C) Copyright 2017-2024 Hewlett Packard Enterprise Development LP
import { Enumify } from 'enumify';

export class PurposeType extends Enumify {
  static TRIALS = new PurposeType('Trial');

  static TEST = new PurposeType('Test');

  static DEMO = new PurposeType('Demo');

  static PRODUCTION = new PurposeType('Production');

  static UNKNOWN = new PurposeType('Unknown');

  static _ = this.closeEnum(); // TypeScript: Color.closeEnum()

  constructor(label) {
    super();
    this.label = label;
  }

  static values() {
    return [this.TRIALS, this.TEST, this.DEMO, this.PRODUCTION, this.UNKNOWN];
  }
}
