// (C) Copyright 2017-2024 Hewlett Packard Enterprise Development LP
import { Enumify } from 'enumify';
import { ValueTypes } from './ValueTypes';

export class ServiceColumn extends Enumify {
  static ACTION = new ServiceColumn(() => 'Action');

  static INCLUDE = new ServiceColumn(() => 'Include', () => 'include', () => ValueTypes.STRING);

  static TIER = new ServiceColumn(() => 'Tier', () => 'mappedTierId', () => ValueTypes.STRING);

  static LOCATION = new ServiceColumn(() => 'Location', () => 'locationId', () => ValueTypes.STRING);

  static SELECT = new ServiceColumn(() => 'Select', () => 'selected', () => ValueTypes.STRING);

  static _ = this.closeEnum(); // TypeScript: Color.closeEnum()

  constructor(header, field, type) {
    super();
    this.header = header;
    this.field = field;
    this.type = type;
  }
}
