// (C) Copyright 2017-2024 Hewlett Packard Enterprise Development LP
import { updateStateAndSession } from '../../session/sessionUtil';

export const UPDATE_ADMINISTRATION_PAGE_FILTERS = 'UPDATE_ADMINISTRATION_PAGE_FILTERS';
export const UPDATE_PROCESSED_FILE_PAGE_FILTERS = 'UPDATE_PROCESSED_FILE_PAGE_FILTERS';

function updateBillingAccountFilters(filters) {
  return {
    type: UPDATE_ADMINISTRATION_PAGE_FILTERS,
    filters,
  };
}

export const updateAdminBillingAccountFiltersAndSession = updateStateAndSession(updateBillingAccountFilters);

function updateProcessedFilesFilters(filters) {
  return {
    type: UPDATE_PROCESSED_FILE_PAGE_FILTERS,
    filters,
  };
}

export const updateAdminProcessedFilesFiltersAndSession = updateStateAndSession(updateProcessedFilesFilters);
