// (C) Copyright 2017-2024 Hewlett Packard Enterprise Development LP
import PropTypes from 'prop-types';
import React from 'react';
import { Tip } from 'grommet';

const GLBMTooltip = ({
  content = undefined,
  children,
  ...rest
}) => (
  // eslint-disable-next-line react/jsx-no-useless-fragment
  (
    <>
      {content
        ? (
          <Tip
            content={content}
            {...rest}
          >
            {children}
          </Tip>
        ) : children}
    </>
  )
);

GLBMTooltip.propTypes = {
  content: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node]),
  children: PropTypes.node.isRequired,
};

export default GLBMTooltip;
