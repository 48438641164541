// (C) Copyright 2017-2024 Hewlett Packard Enterprise Development LP
import React, { useContext, useEffect, useState } from 'react';
import {
  Box, Button, ThemeContext,
} from 'grommet';
import { Filter } from 'grommet-icons';
import PropTypes from 'prop-types';

const FilterControl = ({
  filters = undefined,
  onFilter = undefined,
  onClear = undefined,
  ignoreProps = [],
}) => {
  const [filterCount, setFilterCount] = useState(0);
  const theme = useContext(ThemeContext);

  // Provide indication for the number of filters applied
  useEffect(() => {
    let count = 0;
    if (filters) {
      Object.entries(filters)
        .filter(filter => !ignoreProps?.includes(filter[0]))
        .forEach((filter) => {
          if (filter[1]?.length > 0 || (filter[1]?.value && filter[1]?.value.length)) {
            count += 1;
          }
        });
    }
    setFilterCount(count);
  }, [filters, ignoreProps, setFilterCount]);

  return (
    <Box direction='row' align='center' gap='small' flex={false}>
      <Button
        a11yTitle='Filter'
        id='hpeFCFilterBtn'
        kind='toolbar'
        badge={
            filterCount > 0
              ? {
                background: theme.global.colors.text,
                value: filterCount,
              }
              : null
          }
        icon={<Filter />}
        tip={
            filterCount > 0
              ? `Open filters, ${filterCount} filters applied`
              : 'Open filters'
          }
        onClick={onFilter}
      />
      {filterCount > 0 && (
      <Button
        label='Clear Filters'
        onClick={onClear}
      />
      )}
    </Box>
  );
};

FilterControl.propTypes = {
  filters: PropTypes.object,
  onFilter: PropTypes.func,
  onClear: PropTypes.func,
  ignoreProps: PropTypes.arrayOf(PropTypes.string),
};

export default FilterControl;
