// (C) Copyright 2017-2024 Hewlett Packard Enterprise Development LP

import * as Case from 'case';
import {
  Box,
  FormField,
  Heading,
  Paragraph,
  RadioButton,
  TextInput,
} from 'grommet';
import CurrencyUtils from 'i18n/CurrencyUtil';
import PropTypes from 'prop-types';
import React from 'react';
import ReadOnlyField from '../shared/component/ReadOnlyField';
import IDUtil from '../shared/util/IDUtil';
import { ReportBy } from '../services';

const DefaultServiceForm = ({
  readOnly = false,
  onChange,
  defaultServiceConfig,
}) => {
  const {
    unitPrecision,
    ratePrecision,
    reportBy,
    maxShrink,
    committedPercent,
  } = defaultServiceConfig;

  const getBillingPrecisionHelp = (precision) => {
    const example = CurrencyUtils.getNumberString(0.123456789, parseInt(precision, 10));
    if (example) {
      return `Example: ${example}`;
    }
    return '';
  };

  const renderRatePrecision = () => {
    const label = 'Rate Precision';
    const readOnlyField = <ReadOnlyField label={label} value={ratePrecision} />;
    const editableField = (
      <FormField
        key='ratePrecision'
        htmlFor='ratePrecision'
        help={getBillingPrecisionHelp(ratePrecision)}
        label={label}
      >
        <TextInput
          type='number'
          plain={true}
          id={IDUtil.getId('BillingInfoEditorRatePrecision')}
          name='ratePrecision'
          value={ratePrecision}
          min={0}
          max={9}
          onChange={onChange}
        />
      </FormField>
    );

    return readOnly ? readOnlyField : editableField;
  };

  const renderUnitPrecision = () => {
    const label = 'Unit Precision';
    const readOnlyField = <ReadOnlyField label={label} value={unitPrecision} />;
    const editableField = (
      <FormField
        key='unitPrecision'
        htmlFor='unitPrecision'
        help={getBillingPrecisionHelp(unitPrecision)}
        label={label}
      >
        <TextInput
          type='number'
          plain={true}
          id={IDUtil.getId('BillingInfoEditorUnitPrecision')}
          name='unitPrecision'
          value={unitPrecision}
          min={0}
          max={9}
          onChange={onChange}
        />
      </FormField>
    );

    return readOnly ? readOnlyField : editableField;
  };

  const renderCommittedPercent = () => {
    const label = 'Reserved Percent (%)';
    const readOnlyField = (
      <ReadOnlyField
        label={label}
        value={committedPercent}
      />
    );
    const editableField = (
      <FormField
        key='committedPercent'
        htmlFor='committedPercent'
        label={label}
      >
        <TextInput
          type='number'
          plain={true}
          id={IDUtil.getId('BillingInfoEditorCommittedPercent')}
          name='committedPercent'
          value={committedPercent}
          min={0}
          onChange={onChange}
        />
      </FormField>
    );

    return readOnly ? readOnlyField : editableField;
  };

  const renderMaxShrink = () => {
    const label = 'Max Shrink (%)';
    const readOnlyField = (
      <ReadOnlyField
        label={label}
        value={maxShrink}
        id={IDUtil.getId('BillingInfoEditorMaxShrink')}
      />
    );
    const editableField = (
      <FormField
        key='maxShrink'
        htmlFor='maxShrink'
        label={label}
      >
        <TextInput
          type='number'
          plain={true}
          id={IDUtil.getId('BillingInfoEditorMaxShrink')}
          name='maxShrink'
          value={maxShrink}
          min={0}
          onChange={onChange}
        />
      </FormField>
    );

    return readOnly ? readOnlyField : editableField;
  };

  const renderReportByRadioButtons = () => ReportBy.enumValues.filter(e => e !== ReportBy.NONE)
    .map(({
      enumKey,
      label,
    }) => {
      const id = IDUtil.getId(`BillingInfoEditorReportBy${Case.pascal(label)}`);
      return (
        <RadioButton
          key={enumKey}
          id={id}
          name={`reportBy:${enumKey}`}
          label={label}
          checked={reportBy === enumKey}
          onChange={onChange}
        />
      );
    });

  const renderReportBy = () => {
    const reportByEnum = ReportBy.enumValueOf(reportBy);
    const readOnlyField = (
      <ReadOnlyField
        label='Report by'
        value={reportByEnum.label}
      />
    );
    const editableField = (
      <FormField label='Report by'>
        {renderReportByRadioButtons()}
        <Box
          margin={{
            top: 'small',
            bottom: 'small',
            left: 'medium',
          }}
        >
          <Paragraph margin='none'>{reportByEnum.description}</Paragraph>
        </Box>
      </FormField>
    );

    return readOnly ? readOnlyField : editableField;
  };

  return (
    <Box margin={{ top: 'small' }} flex={false}>
      <Heading level='2' pad='none' size='small' weight='bold'>
        Default service settings
      </Heading>
      <Paragraph margin='none'>
        These defaults are used as initial settings when you configure a new
        service. Changing them does not affect existing services.
      </Paragraph>
      <Box pad={{ vertical: 'none' }}>
        {renderReportBy()}
        {renderMaxShrink()}
        {renderCommittedPercent()}
        {renderUnitPrecision()}
        {renderRatePrecision()}
      </Box>
    </Box>
  );
};

DefaultServiceForm.propTypes = {
  readOnly: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  defaultServiceConfig: PropTypes.shape({
    unitPrecision: PropTypes.number,
    ratePrecision: PropTypes.number,
    reportBy: PropTypes.string,
    maxShrink: PropTypes.number,
    committedPercent: PropTypes.number,
  }).isRequired,
};

export default DefaultServiceForm;
