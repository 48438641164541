// (C) Copyright 2017-2024 Hewlett Packard Enterprise Development LP
import { EventEmitter } from 'events';
import AppDispatcher from '../dispatcher/AppDispatcher';
import UserConstants from '../constants/UserConstants';
import UserStore from '../stores/UserStore';
import { isUnassignedRole } from '../shared/constants/UserType';
import { executeGet } from '../../core/api';

class ServiceTypeServiceClass extends EventEmitter {
  constructor() {
    super();
    this.initialize = this.initialize.bind(this);
    this.setApiDefs = this.setApiDefs.bind(this);
    this.apiDefs = undefined;
  }

  initialize() {}

  setApiDefs(apiDefs) {
    this.apiDefs = apiDefs;
    return new Promise((resolve) => {
      const current = UserStore.getUser();
      const isUnassigned = isUnassignedRole(current?.role);
      const api = isUnassigned ? this.apiDefs?.['service.simpleServiceTypes.read.path'] : this.apiDefs?.['service.serviceTypes.read.path'];
      executeGet(api).then((types) => {
        AppDispatcher.dispatch({
          actionType: 'LOAD_SERVICE_DEFINITIONS',
          types: isUnassigned
            ? Object.entries(types).map(([type, displayName]) => ({ type, displayName, label: displayName }))
            : types,
        });
        resolve(types);
      }, (error) => {
        console.error('ServiceTypeService', 'fetching service types failed', error);
        AppDispatcher.dispatch({
          actionType: 'LOAD_SERVICE_DEFINITIONS',
          types: [],
        });
        resolve([]);
      });
    });
  }
}

const ServiceTypeService = new ServiceTypeServiceClass();

AppDispatcher.register((action) => {
  if (action.actionType === UserConstants.LOGIN_SUCCESS) {
    ServiceTypeService.initialize();
  }
});

export default ServiceTypeService;
