// (C) Copyright 2017-2024 Hewlett Packard Enterprise Development LP

import React from 'react';
import { Box } from 'grommet';
import GLBMHeading from '../../../../../shared/component/GLBMHeading';

const BaseContainer = props => (
  <Box
    direction='column'
    fill={props?.fill && props?.expanded ? props?.fill : false}
    flex={props?.fill && props?.expanded}
    border={true}
  >
    <Box flex={false} border='bottom'>
      <GLBMHeading
        title={props?.title}
        actions={props?.actions}
        pad={{ vertical: 'none', horizontal: 'small' }}
        height='42px'
        level='3'
        inline={true}
      />
    </Box>
    {props?.expanded
        && (
        <Box flex={true} pad='xsmall' overflow='auto'>
          {props?.children}
        </Box>
        )}
  </Box>
);

export default BaseContainer;
