// (C) Copyright 2017-2024 Hewlett Packard Enterprise Development LP

import { UserType } from '../constants/UserType';

export const getASMCustomerRole = (user, customer) => {
  if (user && customer) {
    const myId = user.id;
    // eslint-disable-next-line default-case
    switch (user.role) {
      case UserType.ASM.value:
        // eslint-disable-next-line no-case-declarations
        const { asmConfig } = customer;
        if (asmConfig && asmConfig.asms) {
          const myAsmRoles = asmConfig.asms.filter(asm => asm.id === myId);
          return myAsmRoles && myAsmRoles.length ? myAsmRoles[0].roles[0] : undefined;
        }
        break;
      case UserType.SERVICE_DEV.value:
        // eslint-disable-next-line no-case-declarations
        const { assignedCustomers } = user;
        if (assignedCustomers && assignedCustomers.length) {
          const assignedCustomer = assignedCustomers.filter(({ customerId }) => customerId === customer.id)[0];
          return assignedCustomer ? assignedCustomer.asmRole : undefined;
        }
        break;
    }
  }
  return undefined;
};
