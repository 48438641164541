// (C) Copyright 2017-2024 Hewlett Packard Enterprise Development LP

import { Enumify } from 'enumify';

class SaveOptions extends Enumify {
  static OPTIONS = new SaveOptions();

  static EQUIPMENT = new SaveOptions();

  static COMPONENT = new SaveOptions();

  static RATES = new SaveOptions();

  static _ = this.closeEnum(); // TypeScript: Color.closeEnum()
}

export default SaveOptions;
