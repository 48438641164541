// (C) Copyright 2017-2024 Hewlett Packard Enterprise Development LP

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Anchor,
  Box, Button, Spinner, Text,
} from 'grommet';
import Toast from '../../shared/component/Toast';
import AdministrationResponseDetails from '../AdministrationResponseDetails';
import { useMutationCustomerAction } from '../../../core';
import URLUtil from '../../shared/util/URLUtil';

const onShowCustomerGetActionResults = (urlTemplate, parameters) => {
  const queryParams = URLUtil.objectToQueryParams(parameters);
  const windowTab = window.open((`${window.APIURL}${urlTemplate}${queryParams}`), '_blank');
  if (windowTab) {
    windowTab.focus();
  }
};

const CustomersOption = ({ option, context }) => {
  const customerId = context.selectedCustomer;
  const customerName = context.selectedCustomerName;
  const { action, parameters } = { ...option };
  const urlTemplate = option.urlTemplate.replace('{customerId}', customerId);
  const [isActive, setIsActive] = useState(false);
  const [response, setResponse] = useState(undefined); // toast object
  const [result, setResult] = useState(undefined); // fetch endpoint result
  const [layer, setLayer] = useState(undefined);

  const { mutate: runCustomerAction } = useMutationCustomerAction(option, urlTemplate, {
    onSuccess: (results) => {
      setIsActive(false);
      setResult(results);
      setResponse({
        status: 'ok',
        text: customerName
          ? `${option.name} initiated for ${customerName} (${customerId})`
          : `${option.name} initiated for ${customerId}`,
      });
    },
    onError: (error) => {
      setIsActive(false);
      setResult(error.response?.data || error.response);
      console.error('results', error);
      setResponse({
        status: 'critical',
        text: `Unable to ${option.name}. Error: ${error.message}`,
      });
    }
  });

  const onSubmitCustomerAction = () => {
    switch (action) {
      case 'GET':
        onShowCustomerGetActionResults(urlTemplate, parameters);
        setIsActive(false);
        break;
      case 'POST':
      case 'PUT':
        runCustomerAction();
        break;
      default:
        console.warn('Unexpected action', action);
    }
  };

  const layerClosed = () => {
    setLayer(undefined);
    setResponse(undefined);
    setResult(undefined);
  };

  const renderLayer = () => {
    let layerComponent;
    if (layer) {
      if (layer === 'showResultsAsJSON') {
        // const { operation } = this.state;
        layerComponent = (
          <AdministrationResponseDetails
            option={option}
            response={result}
            onClose={layerClosed}
          />
        );
      }
    }
    return layerComponent;
  };

  const onShowResponseResultClicked = () => {
    setLayer('showResultsAsJSON');
    setResponse(undefined);
  };

  const onToastClose = () => {
    setResponse(undefined);
    setResult(undefined);
  };

  const renderToast = () => {
    let message = '';

    if (response) {
      message = (
        <Toast
          open={response}
          status={(response.status ? response.status : 'critical')}
          onClose={onToastClose}
        >
          {response.text}
          &nbsp;&nbsp;
          {result && <Anchor onClick={onShowResponseResultClicked}>Show Results</Anchor>}
        </Toast>
      );
    }
    return message;
  };

  const onClick = () => {
    setIsActive(true);
    onSubmitCustomerAction();
  };

  return (
    <Box
      justify='between'
      flex={false}
      direction='row'
      border='top'
      pad='small'
      align='center'
    >
      <Box align='start' flex={true}>
        <Text weight='bold'>{option.name}</Text>
        <Text>{option.description}</Text>
      </Box>
      <Box flex={false} margin={{ horizontal: 'medium' }}>
        {isActive && <Spinner size='small' />}
      </Box>
      <Button
        label={option.label}
        disabled={isActive}
        onClick={onClick}
        secondary={true}
        style={{ height: '36px', width: '120px' }}
      />
      {renderToast()}
      {renderLayer()}
    </Box>
  );
};

CustomersOption.propTypes = {
  option: PropTypes.shape({
    action: PropTypes.string,
    parameters: PropTypes.object,
    urlTemplate: PropTypes.string.isRequired,
    name: PropTypes.string,
    description: PropTypes.string,
    label: PropTypes.string,
  }).isRequired,
  context: PropTypes.shape({
    selectedCustomer: PropTypes.string,
    selectedCustomerName: PropTypes.string,
  }).isRequired,
};

export default CustomersOption;
