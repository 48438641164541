// (C) Copyright 2017-2024 Hewlett Packard Enterprise Development LP
import React from 'react';

import {
  Box,
  Button,
} from 'grommet';
import PropTypes from 'prop-types';
import GLBMLayer from '../shared/component/GLBMLayer';

function TimeOutModal({ show, source = '', close = undefined }) {
  return show ? (
    <GLBMLayer>
      <Box
        flex={true}
        pad='medium'
      >
        <Box margin={{ bottom: 'small' }}>
          <h3 style={{ margin: '0px' }}>
            Session Expired
          </h3>
        </Box>
        <Box margin={{ top: 'none', bottom: 'medium' }}>
          <div>
            Your session has expired due to inactivity.
          </div>
          <div>
            {source === 'sso' ? ' Please login again from GreenLake Central.' : ' Please login again from GreenLake Billing Manager.'}
          </div>
        </Box>
        <Box direction='row' flex={true} justify='center'>
          <Button label='Ok' primary={true} onClick={() => close()} />
        </Box>
      </Box>
    </GLBMLayer>
  ) : null;
}

TimeOutModal.propTypes = {
  show: PropTypes.bool.isRequired,
  source: PropTypes.string,
  close: PropTypes.func
};

export default TimeOutModal;
