// (C) Copyright 2017-2024 Hewlett Packard Enterprise Development LP
import { EventEmitter } from 'events';
import * as Sentry from '@sentry/react';
import AppDispatcher from '../dispatcher/AppDispatcher';
import UserConstants from '../constants/UserConstants';

const CHANGE_EVENT = 'change';

class UserStoreClass extends EventEmitter {
  constructor() {
    super();
    this.user = undefined;
    this.source = undefined;
    this.logoutUrl = undefined;
  }

  emitChange() {
    this.emit(CHANGE_EVENT);
  }

  addChangeListener(callback) {
    this.on(CHANGE_EVENT, callback);
  }

  removeChangeListener(callback) {
    this.removeListener(CHANGE_EVENT, callback);
  }

  getUser() {
    return this.user;
  }

  getSource() {
    return this.source;
  }

  getLogoutUrl() {
    return this.logoutUrl;
  }

  setUser(user, source, logoutUrl) {
    if (typeof user === 'string') {
      this.user = JSON.parse(user);
    } else if (typeof user === 'object') {
      this.user = user;
    }
    this.source = source;
    this.logoutUrl = logoutUrl;
  }

  setLogoutUrl(url) {
    this.logoutUrl = url;
  }

  // configure Sentry.io:
  configureSentry(user) {
    const scope = Sentry.getCurrentScope();
    if (user) {
      scope.setUser({
        id: user.id,
        email: user.email.replace(/.*@/, ''),
      });
    } else {
      scope.setUser(null);
    }
  }
}

const UserStore = new UserStoreClass();

UserStore.dispatchToken = AppDispatcher.register((action) => {
  switch (action.actionType) {
    case UserConstants.LOGIN_SUCCESS:
      UserStore.setUser(action.user, action.source, action.logout);
      UserStore.configureSentry(action.user);
      UserStore.emitChange();
      break;
    default:
  }
});

const _user = (typeof (Storage) !== 'undefined' && Object.prototype.hasOwnProperty.call(window.sessionStorage, 'user')) ? JSON.parse(window.sessionStorage.getItem('user')) : {};
UserStore.setUser(_user, 'session');

export default UserStore;

export const useUser = () => UserStore.getUser();
