// (C) Copyright 2017-2024 Hewlett Packard Enterprise Development LP
import { updateStateAndSession } from 'session/sessionUtil';

export const INVALIDATE_CUSTOMERS = 'INVALIDATE_CUSTOMERS';
export const REQUEST_CUSTOMERS = 'REQUEST_CUSTOMERS';
export const RECEIVE_CUSTOMERS = 'RECEIVE_CUSTOMERS';
export const INVALIDATE_CUSTOMER = 'INVALIDATE_CUSTOMER';
export const REQUEST_CUSTOMER = 'REQUEST_CUSTOMER';
export const RECEIVE_CUSTOMER = 'RECEIVE_CUSTOMER';
export const REQUEST_CUSTOMER_METERS = 'REQUEST_CUSTOMER_METERS';
export const RECEIVE_CUSTOMER_METERS = 'RECEIVE_CUSTOMER_METERS';
export const UPDATE_CUSTOMER_LIST_PAGE_FILTERS = 'UPDATE_CUSTOMER_LIST_PAGE_FILTERS';
export const REQUEST_PARTNER_CONFIG = 'REQUEST_PARTNER_CONFIG';
export const RECEIVE_PARTNER_CONFIG = 'RECEIVE_PARTNER_CONFIG';

function updateFilters(filters) {
  return {
    type: UPDATE_CUSTOMER_LIST_PAGE_FILTERS,
    filters,
  };
}

export const updateFiltersAndSession = updateStateAndSession(updateFilters);

export const SAVE_CUSTOMER_START = 'SAVE_CUSTOMER_START';
export const SAVE_CUSTOMER_FINISH = 'SAVE_CUSTOMER_FINISH';
export const BILLING_EDITOR = 'BILLING_EDITOR';
