// (C) Copyright 2017-2024 Hewlett Packard Enterprise Development LP
import React from 'react';
import { Box, Button, Spinner } from 'grommet';
import { useQueryExtractAssignedUsersList } from '../../../core';

function ExtractAssignedUsersList() {
  const { mutate: extract, isPending: showSpinner } = useQueryExtractAssignedUsersList();
  return (
    <Box border='top' direction='row' pad={{ horizontal: 'small' }} align='center' height={{ min: 'xsmall' }} flex={false}>
      <Box style={{ marginRight: 'auto' }}>
        <strong>Extract Assigned Users List</strong>
        <span>Extract all Assigned users list in CSV format. Unassigned users will be excluded.</span>
      </Box>
      <Box direction='row' align='center' gap='medium'>
        <Box direction='row' align='top' gap='medium' style={{ height: '100px' }} />
        <Box flex={false} width='xsmall' align='center'>
          {showSpinner && <Spinner size='small' />}
        </Box>
        <Box direction='row' align='center' flex={true} width={{ min: 'xsmall' }} justify='end'>
          <Button
            label='Extract'
            onClick={() => {
              extract();
            }}
            secondary={true}
            disabled={showSpinner}
          />
        </Box>
      </Box>
    </Box>
  );
}

export default ExtractAssignedUsersList;
