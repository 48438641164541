// (C) Copyright 2017-2024 Hewlett Packard Enterprise Development LP

import React, { useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as Case from 'case';
import pluralize from 'pluralize';

import {
  Box,
  Button,
  Footer,
  Meter,
  Text,
} from 'grommet';
import { Checkmark, Save } from 'grommet-icons';
import GLBMLayer from '../../shared/component/GLBMLayer';
import IDUtil from '../../shared/util/IDUtil';
import { useCustomerServicesUpdateMutate } from '../../../core';
import ServiceTypeStore from '../../stores/ServiceTypeStore';
import { StatusIcon } from '../../shared/component/StatusIcon';
import SaveOptions from './SaveOptions';

const ServiceSaveDialog = (props) => {
  const {
    customer,
    serviceId,
    serviceEditor,
  } = props;

  // const [showDriveErrors, setShowDriveErrors] = useState(false);
  const serviceType = useMemo(() => ServiceTypeStore.getService(serviceId), [serviceId]);

  const {
    options: mutateOptions,
    mappings: mutateMappings,
    component: mutateComponents,
    partnerRates: mutatePartnerRates,
    rates: mutateRates,
    mutate: saveService,
  } = useCustomerServicesUpdateMutate(customer?.id, serviceId, {
    onSucces: (_data) => {},
    onError: (_error) => {},
  });

  const isSaving = useMemo(
    () => mutateOptions.isPending
      || mutateMappings.isPending
      || mutateRates.isPending
      || mutateComponents.isPending
      || mutatePartnerRates.isPending,
    [mutateOptions, mutateMappings, mutateRates, mutateComponents, mutatePartnerRates],
  );

  // when this component is showing - save right away:
  useEffect(() => {
    saveService(serviceEditor);
  }, []);

  const _onSubmit = () => {
    props.onClose(true);
  };

  const _renderSaveComponent = (key, query, data) => {
    let meter;
    let title;
    let status;
    const serviceType = ServiceTypeStore.getService(serviceId);

    // eslint-disable-next-line default-case
    switch (key) {
      case SaveOptions.OPTIONS:
        title = 'Options';
        meter = (
          <Meter
            color='brand'
            size='large'
            value={query.isSuccess ? 1 : 0}
            min={0}
            max={1}
          />
        );
        if (!query.isPending) {
          status = (
            <StatusIcon
              value={query.isSuccess ? 'ok' : 'critical'}
              size='medium'
            />
          );
        }
        break;
      case SaveOptions.EQUIPMENT:
        title = 'Resources';
        meter = (
          <Meter
            color='brand'
            size='large'
            value={query.isSuccess ? data?.length : 0}
            min={0}
            max={data?.length}
          />
        );
        if (data?.length > 0) {
          if (!query.isSaving) {
            status = <StatusIcon value={query.isSuccess ? 'ok' : 'critical'} />;
          }
        }
        break;
      case SaveOptions.COMPONENT:
        title = pluralize.plural(Case.capital(serviceType.component));
        if (data?.length === 0) {
          meter = (
            <Meter color='brand' size='large' value={0} min={0} max={0} />);
        } else {
          meter = (
            <Meter
              color='brand'
              size='large'
              value={data?.length}
              min={0}
              max={data?.length}
            />
          );
        }
        if (data?.length > 0) {
          if (!query.isSaving) {
            status = (<StatusIcon value={query.isSuccess ? 'ok' : 'critical'} />);
          }
        }
        break;
      case SaveOptions.RATES:
        title = 'Rates';
        meter = (
          <Meter
            color='brand'
            size='large'
            value={query.isSuccess ? 1 : 0}
            min={0}
            max={1}
          />
        );
        if (!query.isPending) {
          status = <StatusIcon value={query.isSuccess ? 'ok' : 'critical'} />;
        }
        break;
    }

    return (
      <Box key={key} pad={{ 'vertical': 'small' }}>
        <Box
          direction='row'
          justify='between'
          align='center'
          gap='small'
          announce={true}
          responsive={false}
        >
          <Text weight='bold'>{title}</Text>
          {status}
        </Box>
        {meter}
      </Box>
    );
  };

  const _renderSaveContent = () => {
    const { dirtyComponents, dirtyEquipment } = props.serviceEditor;
    const { permissions } = props.serviceEditor;

    const content = [];

    if (permissions.canEditOptions && mutateOptions) {
      content.push(_renderSaveComponent(SaveOptions.OPTIONS, mutateOptions));
    }
    if (permissions.canEditMappings && mutateMappings) {
      content.push(_renderSaveComponent(SaveOptions.EQUIPMENT, mutateMappings, dirtyEquipment));
      if (serviceType.component && mutateComponents) {
        content.push(_renderSaveComponent(SaveOptions.COMPONENT, mutateComponents, dirtyComponents));
      }
    }
    if (permissions.canEditRates && mutateRates) {
      content.push(_renderSaveComponent(SaveOptions.RATES, mutateRates));
    }
    if (permissions.canMarkupRates && mutatePartnerRates) {
      content.push(_renderSaveComponent(SaveOptions.RATES, mutatePartnerRates));
    }

    return content;
  };

  const _getHeading = () => {
    const serviceDef = ServiceTypeStore.getService(props.serviceId);
    if (serviceDef) {
      return `Saving ${serviceDef.label}`;
    }
    return 'Saving';
  };

  return (
    <GLBMLayer
      position='right'
      submitLabel='OK'
      onEsc={props.onClose}
      onClickOutside={props.onClose}
      onClose={props.onClose}
      full='vertical'
      title={_getHeading()}
    >
      <Box flex={true} pad='medium'>
        {_renderSaveContent()}
      </Box>
      <Footer pad='medium'>
        <Button
          label={isSaving ? 'Saving ...' : 'Saved'}
          id={IDUtil.getId('ServiceSaveDialogOKButton')}
          primary={!isSaving}
          icon={isSaving ? <Save /> : <Checkmark />}
          onClick={_onSubmit}
        />
      </Footer>
    </GLBMLayer>
  );
};

/**
 * This function is called to let us know about the state changes in the store. The goal here is to return from the overall store, what
 * THIS component cares about from the uber-store. In this case, this panel wants to for now only see the 'service' options (ie. first step in 3PAR wizard):
 */
const mapStateToProps = store => ({
  serviceEditor: store.service.details,
  customer: store.service.details.customer,
});

const mapDispatchToProps = dispatch => bindActionCreators({}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ServiceSaveDialog);
