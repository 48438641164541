// (C) Copyright 2017-2024 Hewlett Packard Enterprise Development LP

import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useContext } from 'react';
import { ApiContext } from '../../AppContext';
import { useErrorToast } from '../../components/shared/hooks';
import { useToast } from '../../components/shared/toast/ToastProvider';
import { executeGet, executePut } from '../api';

export const useAccessRequestsQuery = (options) => {
  const path = useContext(ApiContext)('access.requests.path');
  return useErrorToast(useQuery({
    queryKey: ['GLBM: ACCESS-REQUESTS-LIST'],
    queryFn: () => executeGet(path),
    ...(options || []),
  }), 'Fetch Access Requests Error');
};
export const useAccessRequestStatusUpdateMutate = (options) => {
  const queryClient = useQueryClient();
  const { addToast } = useToast();
  return useMutation({
    mutationFn: ({
      payload,
      urlTemplate
    }) => executePut(urlTemplate, payload),
    ...(options || {}),
    onSuccess: () => {
      queryClient.removeQueries({
        queryKey: ['GLBM: ACCESS-REQUESTS-LIST']
      });
      options?.onSuccess?.();
    },
    onError: (data) => {
      addToast({
        status: 'critical',
        title: 'Failed to update request status',
        message: data.response.data.message,
      });
    },
  });
};
