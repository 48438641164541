// (C) Copyright 2017-2024 Hewlett Packard Enterprise Development LP

// eslint-disable-next-line @typescript-eslint/default-param-last
export function psa(state = {
  isFetching: false,
  data: undefined,
  error: undefined,
}, action) {
  switch (action.type) {
    default:
      return state;
  }
}
