// (C) Copyright 2017-2024 Hewlett Packard Enterprise Development LP

import React, { useEffect, useMemo } from 'react';
import {
  Box, Button, Footer, Paragraph,
} from 'grommet';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import GLBMLayer from '../../shared/component/GLBMLayer';
import {
  useCompanyDeleteMutate,
  useCompanyQuery,
  useCustomersQuery,
} from '../../../core';
import GLBMNameValueList from '../../shared/component/GLBMNameValueList';
import GLBMSaving from '../../shared/component/GLBMSaving';
import OptionalAnchor from '../../shared/component/OptionalAnchor';
import { StatusIcon } from '../../shared/component/StatusIcon';
import { pagePermissions } from '../../shared/constants/Permissions';
import Loader from '../../shared/loader';
import IDUtil from '../../shared/util/IDUtil';

const CompanyDeleteConfirmationDialog = ({
  companyId,
  onClose,
}) => {
  const navigate = useNavigate();

  const {
    data: companyData,
    isFetching: isFetchingCompany,
    isSuccess: isCompanySuccess,
  } = useCompanyQuery(companyId);

  const {
    data: customerData,
    isFetching: isFetchingCustomers,
    isSuccess: isCustomersSuccess,
  } = useCustomersQuery();

  const isLoading = useMemo(() => isFetchingCompany || isFetchingCustomers, [isFetchingCompany, isFetchingCustomers]);

  const {
    mutate: deleteCompany,
    isPending: isDeletingCompany,
    error: deletingCompanyError,
    isSuccess: isDeletingCompanySuccess,
  } = useCompanyDeleteMutate();

  useEffect(() => {
    if (isDeletingCompanySuccess) {
      onClose();
    }
  }, [isDeletingCompanySuccess]);

  const assignedBillingAccounts = useMemo(
    () => !isLoading && customerData?.filter(customer => customer.companyId === companyId)
      .map(customer => ({
        label: (customer.id),
        value: <OptionalAnchor
          onClick={() => navigate(`/customers/${customer.id}`)}
          permissions={pagePermissions.customers.view.page}
        >
          {customer.name}
        </OptionalAnchor>,
      })),
    [isLoading, customerData, companyId],
  );

  const isAssigned = useMemo(() => assignedBillingAccounts.length > 0, [assignedBillingAccounts]);

  return (
    <GLBMLayer
      position='right'
      full='vertical'
      closer={true}
      onClose={onClose}
      onEsc={onClose}
      onClickOutside={onClose}
      overlayClose={true}
      flush={true}
      title={isLoading ? '' : (isAssigned ? 'Usage Detected' : 'Are you sure?')}
    >
      <Box
        pad='none'
        style={{
          'width': '600px',
          'minWidth': '600px',
        }}
        direction='column'
        flex={true}
        fill='vertical'
      >
        {(isLoading) && (
          <Box
            direction='row'
            align='center'
            gap='small'
            justify='center'
            fill={true}
          >
            <Loader text='Loading. Please wait ...' />
          </Box>
        )}
        {(isCompanySuccess && isCustomersSuccess) && (
          <Box pad='medium' flex={true} gap='medium'>
            <Box direction='row' align='top'>
              <StatusIcon value='warning' size='medium' />
              <Box margin={{ left: 'small' }}>
                <Paragraph margin={{ top: 'none' }}>
                  {/* eslint-disable-next-line react/no-unescaped-entities */}
                  {isAssigned
                    ? 'Companies with existing customer associations cannot be deleted. Please remove the company from all billing accounts before attempting to delete.'
                    : 'Deleting a company will permanently remove this company. Please ensure you are intending to completely remove this company before choosing \'Delete\' below.'}
                </Paragraph>
              </Box>
            </Box>
            <Box>
              <GLBMNameValueList
                title='Selected Company'
                data={[
                  {
                    label: 'Name',
                    value: companyData?.name,
                  },
                ]}
              />
            </Box>
            {isAssigned && (
              <Box>
                <GLBMNameValueList
                  title='Assigned Billing Accounts'
                  data={assignedBillingAccounts}
                />
              </Box>
            )}
          </Box>
        )}
      </Box>
      <Box border='top' pad='small' margin={{ top: 'none' }} flex={false}>
        <Footer flex={false} justify='start' gap='small'>
          {!isAssigned && (
            <Button
              primary={true}
              onClick={() => deleteCompany(companyId)}
              label='Delete'
              {...{ 'data-testid': IDUtil.getId('CompanyDeleteConfirmationDialogSubmitBtn') }}
              disabled={isDeletingCompany}
            />
          )}
          <Button
            secondary={true}
            onClick={onClose}
            label='Cancel'
            {...{ 'data-testid': IDUtil.getId('CompanyDeleteConfirmationDialogCancelBtn') }}
            disabled={isDeletingCompany}
          />
          <GLBMSaving
            saving={isDeletingCompany}
            saved={isDeletingCompanySuccess}
            error={deletingCompanyError}
          />
        </Footer>
      </Box>
    </GLBMLayer>
  );
};

CompanyDeleteConfirmationDialog.propTypes = {
  companyId: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default CompanyDeleteConfirmationDialog;
