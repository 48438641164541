// (C) Copyright 2017-2024 Hewlett Packard Enterprise Development LP

import { ServiceValidator } from '../validation/ServiceValidator';
import { extendType } from '../../redux/higher-order-reducers/extendType';
import { SET_CUSTOMER } from '../../redux/higher-order-reducers/actions';

// sub-action type
export const SERVICE_EDITOR = 'SERVICE_EDITOR';

/*
 * action types
 */
export const SET_PERMISSIONS = 'SET_PERMISSIONS';
export const SET_OPTIONS = 'SET_OPTIONS';
export const INITIALIZE_SERVICE_OPTIONS = 'INITIALIZE_SERVICE_OPTIONS';
export const INITIALIZE_SERVICE_EDITOR = 'INITIALIZE_SERVICE_EDITOR';
export const REQUEST_EQUIPMENT = 'REQUEST_EQUIPMENT';
export const RECEIVE_EQUIPMENT = 'RECEIVE_EQUIPMENT';
export const SET_EQUIPMENT = 'SET_EQUIPMENT';
export const SET_METERS = 'SET_METERS';
export const SET_RATES = 'SET_RATES';
export const SET_LOCATION_RATES = 'SET_LOCATION_RATES';
export const SET_CONTEXT = 'SET_CONTEXT';
export const SET_DIRTY_EQUIPMENT = 'SET_DIRTY_EQUIPMENT';
export const SET_DIRTY_COMPONENT = 'SET_DIRTY_COMPONENT';
export const LOCATION_TYPE_CHANGED = 'LOCATION_TYPE_CHANGED';
export const INVALIDATE_EQUIPMENT = 'INVALIDATE_EQUIPMENT';
export const SET_EQUIPMENT_FILTER = 'SET_EQUIPMENT_FILTER';

// VALIDATION:
export const INVALIDATE_VALIDATION = 'INVALIDATE_VALIDATION';
export const RECEIVE_VALIDATION = 'RECEIVE_VALIDATION';

// DIRTY:
export const SET_DIRTY_OPTIONS = 'SET_DIRTY_OPTIONS';
export const SET_DIRTY_RATES = 'SET_DIRTY_RATES';

function handleErrors(response) {
  if (!response.ok) {
    throw Error(response.statusText);
  }
  return response;
}

function returnErrors(error) {
  // eslint-disable-next-line no-console
  console.log('There has been a problem with your fetch operation: ', error);
  return Promise.reject(error);
}

/*
 * action creators
 */

export function setPermissions({
  user, isOneOfRoles, customer, hasPermissions, serviceId, tenant,
}) {
  return {
    type: SET_PERMISSIONS,
    user,
    isOneOfRoles,
    customer,
    hasPermissions,
    serviceId,
    tenant,
  };
}

export function setCustomerForServiceEditor(customer) {
  return { type: extendType(SERVICE_EDITOR, SET_CUSTOMER), customer };
}

export function setOptions(options) {
  return { type: SET_OPTIONS, options };
}

export function locationTypeChanged(locationType) {
  return { type: LOCATION_TYPE_CHANGED, locationType };
}

export function initializeServiceEditor() {
  return { type: INITIALIZE_SERVICE_EDITOR };
}

export function initializeServiceOptions(options) {
  return { type: INITIALIZE_SERVICE_OPTIONS, options };
}

export function invalidateEquipment() {
  return { type: INVALIDATE_EQUIPMENT };
}

export function requestEquipment(customerId, serviceType) {
  return { type: REQUEST_EQUIPMENT, customerId, serviceType };
}

export function receiveEquipment(customerId, serviceType, items) {
  return {
    type: RECEIVE_EQUIPMENT,
    customerId,
    serviceType,
    items,
    receivedAt: Date.now(),
  };
}

export function setEquipmentFilter(filter) {
  return { type: SET_EQUIPMENT_FILTER, filter };
}

export function setEquipment(equipment) {
  return { type: SET_EQUIPMENT, equipment };
}

export function setMeters(meters) {
  return { type: SET_METERS, meters };
}

export function setRates(rates) {
  return { type: SET_RATES, rates };
}

export function setLocationRates(rates) {
  return { type: SET_LOCATION_RATES, rates };
}

export function setDirtyEquipment(equipment) {
  return { type: SET_DIRTY_EQUIPMENT, equipment };
}

export function setDirtyComponent(component) {
  return { type: SET_DIRTY_COMPONENT, component };
}

export function setContext(context) {
  return { type: SET_CONTEXT, context };
}

// ///////////////////////////////////
// Validation Actions
// ///////////////////////////////////

export function invalidateValidation() {
  return { type: INVALIDATE_VALIDATION };
}

export function receiveValidation(step, state, customerId, serviceType, json, error) {
  // TODO:::: NEEDS TO BE REFACTORED WHEN UPGRADING TO NEW VALIDATE ENDPOINT
  const results = (json ? (json.unconfiguredService || json.unconfiguredDetails) : undefined);
  const {
    customer, options, equipment, meters, rates, locationRates, context
  } = state.service.details;
  const properties = ServiceValidator.validate(step, results, customer, options, equipment, meters, rates, locationRates, context);

  return {
    type: RECEIVE_VALIDATION,
    customerId,
    serviceType,
    results,
    properties,
    receivedAt: Date.now(),
    errors: (error ? error.message : undefined),
  };
}

function status(res) {
  if (!res.ok) {
    throw new Error(res.statusText);
  }
  return res;
}

// WARNING STATE DIRTY :
export function setDirtyOptions() {
  return { type: SET_DIRTY_OPTIONS, dirty: true };
}

export function setDirtyRates() {
  return { type: SET_DIRTY_RATES, dirty: true };
}
