// (C) Copyright 2017-2024 Hewlett Packard Enterprise Development LP

import React from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Text,
} from 'grommet';

const ChartTooltip = ({
  title,
  groups,
  unit = undefined,
  formatValue,
  formatPercent,
  forwardRef = undefined,
}) => {
  const cellProps = {
    pad: { horizontal: 'xsmall', vertical: 'hair' },
  };

  const tooltipProps = {
    background: 'background',
    border: { color: 'border-weak' },
    color: 'text',
    elevation: 'small',
    opacity: 0.6,
    pad: { vertical: 'none', horizontal: 'small' },
    round: 'xsmall',
  };

  return (
    <Box {...tooltipProps} ref={forwardRef}>
      {title && (
        <Box border='bottom'>
          <Text margin={{ left: 'xsmall', right: 'xsmall' }}>{title}</Text>
        </Box>
      )}
      <Table pad='xxsmall'>
        <TableBody>
          {groups.map((group, idx) => {
            const rows = group.map(el => (
              <TableRow key={el.label}>
                <TableCell {...cellProps}><Text style={{ whiteSpace: 'nowrap' }}>{el.label}</Text></TableCell>
                <TableCell align='right' {...cellProps}>
                  <Text style={{ whiteSpace: 'nowrap' }}>{formatValue(el.value, unit)}</Text>
                </TableCell>
                <TableCell align='right' {...cellProps}>
                  <Text style={{ whiteSpace: 'nowrap' }}>{formatPercent(el.pct)}</Text>
                </TableCell>
              </TableRow>
            ));
            if (idx !== 0) {
              return [<TableRow key={`spacer${group[0].label}`}><TableCell colSpan={3} pad='none' border='bottom'> </TableCell></TableRow>, ...rows];
            }
            return rows;
          })}
        </TableBody>
      </Table>
    </Box>
  );
};

ChartTooltip.propTypes = {
  title: PropTypes.string.isRequired,
  groups: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string.isRequired,
    value: PropTypes.number.isRequired,
    pct: PropTypes.number,
  }))).isRequired,
  unit: PropTypes.string,
  formatValue: PropTypes.func.isRequired,
  formatPercent: PropTypes.func.isRequired,
  forwardRef: PropTypes.object,
};

export default React.forwardRef((props, ref) => <ChartTooltip {...props} forwardRef={ref} />);
