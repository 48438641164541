// (C) Copyright 2017-2024 Hewlett Packard Enterprise Development LP
import axios from 'axios';
import { axiosConfig, axiosConfigUpload } from '../utils';

export const uploadFile = async (method, uri, file) => {
  const url = `${window.APIURL}${uri}`;
  const data = new FormData();
  data.append('file', file);
  const config = {
    ...axiosConfigUpload,
    method,
    url,
    data,
  };
  return axios.request(config);
};

const methodSupportsBody = method => method && /(POST|PUT|PATCH)/i.test(method);

export const runAction = (method, uri, body) => {
  const url = `${window.APIURL}${uri}`;
  const config = {
    ...axiosConfig,
    method,
    url,
  };
  if (body && methodSupportsBody(method)) {
    config.data = body;
  }
  return axios.request(config);
};
