// (C) Copyright 2017-2024 Hewlett Packard Enterprise Development LP
import React, { useMemo } from 'react';

import {
  Box,
} from 'grommet';
import PropTypes from 'prop-types';
import { useCustomersQueryMap } from '../../core';
import GLBMDataTable from '../shared/component/GLBMDataTable';
import GLBMHeading from '../shared/component/GLBMHeading';

const PartnerCustomers = ({
  customers = undefined,
}) => {
  const {
    data: customerMap,
    isFetching: loadingCustomers,
  } = useCustomersQueryMap();

  const rows = useMemo(() => {
    if (customers?.length && customerMap) {
      return customers.map(customer => ({
        name: Object.hasOwn(customerMap, customer) ? customerMap[customer].name : '-',
        id: customer,
      }));
    }
    return [];
  }, [customers, customerMap]);

  const columns = useMemo(() => ([{
    property: 'name',
    header: 'Billing Account Name',
    primary: true,
  }, {
    property: 'id',
    header: 'Billing ID',
    primary: false,
  }]), []);

  return (
    <Box margin={{ top: 'large' }}>
      <GLBMHeading title='Assigned Billing Accounts' />
      <Box flex={true} fill={true}>
        <GLBMDataTable
          data={rows}
          columns={columns}
          loading={loadingCustomers}
          sort={{
            property: 'name',
            direction: 'asc'
          }}
        />
      </Box>
    </Box>
  );
};

PartnerCustomers.contextTypes = {
  router: PropTypes.object,
};

PartnerCustomers.propTypes = {
  customers: PropTypes.arrayOf(PropTypes.string),
};

export default PartnerCustomers;
