// (C) Copyright 2017-2024 Hewlett Packard Enterprise Development LP

import React from 'react';
import PropTypes from 'prop-types';
import ReactJson from 'react-json-view';
import { Box, Button } from 'grommet';
import GLBMLayer from '../shared/component/GLBMLayer';

const AdministrationResponseDetails = ({ option, response, onClose }) => (
  <GLBMLayer
    title={`Response Details: ${option.name}`}
    flush={true}
    className='full-hack'
  >
    <Box flex={true} pad='medium' border='top' style={{ minWidth: '900px', width: '900px' }}>
      <Box direction='row'>
        <Box flex={true}>
          <ReactJson src={response} />
        </Box>
      </Box>
    </Box>
    <Box flex={false} direction='row' align='center' justify='start' pad={{ horizontal: 'small', vertical: 'small' }} gap='small' border='top'>
      <Button secondary={true} label='Cancel' onClick={onClose} />
    </Box>
  </GLBMLayer>
);

AdministrationResponseDetails.propTypes = {
  option: PropTypes.shape({
    name: PropTypes.string
  }).isRequired,
  response: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default AdministrationResponseDetails;
