// (C) Copyright 2017-2024 Hewlett Packard Enterprise Development LP

import React, { createRef, useEffect, useState } from 'react';
import Dropzone from 'react-dropzone';
import {
  Box, Button, Footer, Spinner, Text,
} from 'grommet';
import PropTypes from 'prop-types';
import {
  StatusCritical, Trash,
} from 'grommet-icons';
import { v1 as uuid } from 'uuid';
import IDUtil from '../util/IDUtil';
import GLBMLayer from './GLBMLayer';

const UploadJSONFlow = ({
  onClose = () => undefined,
  title = undefined,
  message = undefined,
  onVerify = () => undefined,
  isLoading = false,
  error = undefined,
  clear = () => undefined,
}) => {
  const dropzoneRef = createRef();
  const [file, setFile] = useState();
  const [noFileError, setNoFileError] = useState(false);

  useEffect(() => {
    clear();
    setNoFileError(false);
  }, [file]);

  const _onDrop = (acceptedFile) => {
    if (acceptedFile) {
      setFile(acceptedFile[0]);
    }
  };

  function _renderUploadButton() {
    if (isLoading) {
      return (<Button primary={true} label='Uploading...' />);
    }
    return (
      <div>
        <Button primary={true} label='Upload' onClick={() => verifyFile()} />
        {noFileError && <span className='critical'>&nbsp;&nbsp;&nbsp;File is required.</span>}
        {!noFileError && error && !isLoading && <span className='critical'>&nbsp;&nbsp;&nbsp;Please fix problems with file before uploading.</span>}
      </div>
    );
  }

  function _renderFiles() {
    return file && (
    <Box key={uuid()} direction='row' alignContent='center' justify='start'>
      <Box direction='column' justify='center' alignContent='center'>
        <Box justify='between' alignContent='center' direction='column' style={{ maxWidth: '435px' }}>
          <Text truncate={true} size='small'><strong>{file.name}</strong></Text>
          {!error && !isLoading && (
            <Text truncate={true} size='small'>
              <em>
                {file.size}
                {' '}
                bytes
              </em>
            </Text>
          )}
          {error && <span className='critical wrap'><em>{error && error.message}</em></span>}
          {isLoading && <span><em>Validating filename...</em></span>}
        </Box>
      </Box>
      {!isLoading && <Box><Button icon={<Trash />} href='#' onClick={() => setFile()} /></Box>}
      {isLoading && <Spinner />}
      {!isLoading && error && <StatusCritical color='status-critical' />}
    </Box>
    );
  }

  const verifyFile = () => {
    if (file) {
      setNoFileError(false);
      const formData = new FormData();
      formData.append('file', file);
      onVerify(formData);
    } else {
      setNoFileError(true);
    }
  };

  return (
    <GLBMLayer
      position='right'
      title={title}
      closer={true}
      onClose={onClose}
      onEsc={onClose}
      onClickOutside={onClose}
      overlayClose={true}
      flush={true}
      full='vertical'
    >
      <Box pad={{ 'horizontal': 'medium' }} flex={true}>
        <Box flex={false}>
          <p>{message}</p>
        </Box>
        <Box flex={false}>
          <Dropzone
            className='file-upload-target'
            activeClassName='file-upload-target-active'
            rejectClassName='file-upload-target-reject'
            ref={dropzoneRef}
            id={IDUtil.getId('UploadDropZone')}
            onDrop={_onDrop}
          >
            {({ getRootProps, getInputProps }) => (
              <Box align='center' pad={{ vertical: 'medium' }} {...getRootProps()}>
                Try dropping a file here, or click to select a file to upload.
                <input {...getInputProps()} />
              </Box>
            )}
          </Dropzone>
          <Box pad={{ vertical: 'small' }} basis='full'>
            <Button
              fill={true}
              label='Select a file'
              id={IDUtil.getId('UploadSelectButton')}
              onClick={() => dropzoneRef.current.open()}
            />
          </Box>
        </Box>
        {_renderFiles()}
      </Box>
      <Footer pad='small' flex={false}>
        {_renderUploadButton()}
      </Footer>
    </GLBMLayer>
  );
};

UploadJSONFlow.propTypes = {
  onClose: PropTypes.func,
  title: PropTypes.string,
  message: PropTypes.string,
  onVerify: PropTypes.func,
  isLoading: PropTypes.bool,
  error: PropTypes.object,
  clear: PropTypes.func,
};

export default UploadJSONFlow;
