// (C) Copyright 2017-2024 Hewlett Packard Enterprise Development LP

import React, { useMemo, useState } from 'react';
import {
  Box,
  Button,
  FormField,
} from 'grommet';
import {
  Next, Previous, Refresh,
} from 'grommet-icons';
import moment from 'moment';
import GLBMDataSummary from '../../shared/component/GLBMDataSummary';
import GLBMSearch from '../../shared/component/GLBMSearch';
import IDUtil from '../../shared/util/IDUtil';
import GLBMHeading from '../../shared/component/GLBMHeading';
import { useEmailQuery } from '../../../core';
import DateTime from '../../shared/component/DateTime';
import EmailGrid from './EmailGrid';
import CustomerSelector from '../../shared/component/CustomerSelector';

const EmailList = () => {
  const [searchText, setSearchText] = useState('');
  const [accountId, setAccountId] = useState('');
  const [selectedDate, setSelectedDate] = useState(moment()
    .format('YYYY-MM-DD'));
  const [searchCount, setSearchCount] = useState();

  const {
    data: emailData,
    refetch: refreshEmails,
    isFetching: emailLoading,
  } = useEmailQuery(accountId, selectedDate, selectedDate, {
    enabled: (!!(selectedDate && moment(selectedDate, 'YYYY-MM-DD', true).isValid())),
  });

  const list = useMemo(() => (emailData?.length ? emailData.map((el, i) => ({
    ...el,
    index: i,
  })) : []), [emailData]);

  const selectedDateError = useMemo(() => {
    if (!selectedDate || selectedDate === '') {
      return 'Required';
    }
    if (!moment(selectedDate, 'YYYY-MM-DD', true)
      .isValid()) {
      return 'Invalid (YYYY-MM-DD)';
    }
    return undefined;
  }, [selectedDate]);
  return (
    <Box direction='column' fill='vertical'>
      <GLBMHeading
        search={(
          <Box>
            <Box direction='row' align='end' gap='small'>
              <Box data-testid='billingAccountWrapper' style={{ minWidth: '160px' }}>
                <FormField label='Billing Account'>
                  <CustomerSelector
                    enableAllOption={true}
                    onCustomerSelected={value => setAccountId(value === 'All' ? '' : value)}
                    plain={true}
                  />
                </FormField>
              </Box>
              <Box>
                <Box
                  direction='row'
                  align='end'
                  gap='xsmall'
                >
                  <FormField
                    width='175px'
                    error={selectedDateError}
                    label='Date'
                  >
                    <DateTime
                      id='emailListDateTime'
                      name='effectiveDay'
                      format='YYYY-MM-DD'
                      onChange={date => setSelectedDate(date)}
                      value={selectedDate}
                      required={true}
                      data-testid='effectiveDay'
                    />
                  </FormField>
                  <Box
                    direction='row'
                    gap='small'
                    style={{
                      marginTop: '6px',
                      marginLeft: '4px',
                    }}
                    align='end'
                    pad={{ bottom: 'xsmall' }}
                  >
                    <Button
                      kind='toolbar'
                      icon={<Previous />}
                      onClick={() => {
                        setSelectedDate(moment(selectedDate)
                          .startOf('day')
                          .subtract(1, 'days')
                          .format('YYYY-MM-DD'));
                      }}
                      tip='Previous Day'
                      data-testid='previousDayBtn'
                    />
                    <Button
                      kind='toolbar'
                      icon={<Next />}
                      onClick={() => {
                        setSelectedDate(moment(selectedDate)
                          .startOf('day')
                          .add(1, 'days')
                          .format('YYYY-MM-DD'));
                      }}
                      tip='Next Day'
                      data-testid='nextDayBtn'
                    />
                  </Box>
                </Box>
              </Box>
              <Box direction='column' pad={{ bottom: 'xsmall' }}>
                <GLBMSearch
                  value={searchText}
                  onChange={e => setSearchText(e)}
                  data-testid='searchInput'
                />
              </Box>
            </Box>
          </Box>
          )}
        actions={[
          <Button
            kind='toolbar'
            icon={<Refresh />}
            key={IDUtil.getId('RefreshButton')}
            onClick={refreshEmails}
            a11yTitle='Refresh Email List'
            id={IDUtil.getId('RefreshButton')}
            tip='Reload Results'
            data-testid='refreshBtn'
            label='Refresh'
            margin={{ bottom: 'xsmall' }}
            busy={emailLoading}
          />,
        ]}
      />
      <GLBMDataSummary total={emailData?.length} filtered={searchCount} />
      <EmailGrid
        searchText={searchText}
        setSearchCount={setSearchCount}
        searchCount={searchCount}
        emailLoading={emailLoading}
        data={list}
      />

    </Box>
  );
};

EmailList.propTypes = {};

export default EmailList;
