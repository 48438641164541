// (C) Copyright 2017-2024 Hewlett Packard Enterprise Development LP

import { isEmpty, isNull, isUndefined } from 'lodash';
import isEqual from 'lodash/isEqual';
import React, { useMemo } from 'react';
import {
  Box, Button, CheckBox, Select, Text,
} from 'grommet';
import { Expand } from 'grommet-icons';
import PropTypes from 'prop-types';
import { getSelectValue } from '../../../../shared/util/FormUtil';
import IDUtil from '../../../../shared/util/IDUtil';
import CATextInput from '../../../../shared/form/CATextInput';
import InputContainer from './components/InputContainer';
import BaseContainer from './components/BaseContainer';

const MeterSettings = ({
  rate = undefined,
  onChange,
  onToggleExpansion,
  readOnly,
  expanded,
}) => {
  const shrinkOptions = useMemo(
    () => [
      { label: 'No Shrink', value: 'NO_SHRINK' },
      { label: '10% Shrink', value: 'SHRINK_10' },
      { label: '100% Shrink', value: 'SHRINK_100' },
    ],
    [],
  );

  const tierOptions = useMemo(
    () => [
      { label: 'Absolute', value: 'ABSOLUTE' },
      { label: 'Marginal', value: 'MARGINAL' },
      { label: 'Tiered', value: 'TIERED' },
    ],
    [],
  );

  const _onChange = (event) => {
    const newRate = { ...rate };
    if (event) {
      switch (event.target.name) {
        case 'tierType':
          newRate.tierType = event.value.value;
          break;
        case 'shrinkPolicy':
          newRate.shrinkPolicy = event.value.value;
          break;
        case 'rounding':
          newRate.rounding = event.target.checked;
          break;
        default:
          console.error(`unsupported property: ${event.target.name}`);
      }

      onChange(newRate);
    }
  };

  const isEqualAndNotUndefined = (value1, value2) => {
    if (
      (isNull(value1) || isUndefined(value1) || isEmpty(value1))
      && (isNull(value2) || isUndefined(value2) || isEmpty(value2))) {
      return true;
    }
    return isEqual(value1, value2);
  };

  const _onChangeDescription = (description) => {
    const newRate = { ...rate };
    if (!isEqualAndNotUndefined(description, newRate.description)) {
      newRate.description = description;
      onChange(newRate);
    }
  };

  const _onClick = (state) => {
    onToggleExpansion(state);
  };

  const _getActions = () => {
    const toggle = (expanded !== 'NONE'
      ? (
        <Button
          key='expand'
          icon={<Expand />}
          onClick={() => _onClick('NONE')}
          hoverIndicator={true}
        />
      ) : false);

    return ([
      toggle,
    ]);
  };

  return (
    <BaseContainer
      title='Meter Attributes'
      actions={_getActions()}
      fill={false}
      expanded={true}
    >
      <Box
        gap={readOnly ? 'none' : 'small'}
        flex={false}
        pad={{ vertical: 'small' }}
      >
        <Box
          flex={true}
          direction='row'
        >
          <InputContainer label='Description'>
            {!rate || readOnly
              ? <Text id={IDUtil.getId('BillingInfoEditorDescription')} weight={100} margin='small'>{rate?.description || ''}</Text>
              : (
                <CATextInput
                  name='description'
                  id={IDUtil.getId('BillingInfoEditorDescription')}
                  value={rate?.description || ''}
                  onChange={_onChangeDescription}
                  debounce={true}
                />
              )}
          </InputContainer>
          <InputContainer label='Pricing Model'>
            {!rate || readOnly
              ? <Text id={IDUtil.getId('BillingInfoEditorPricingModel')} weight={100} margin='small'>{getSelectValue(rate ? rate.tierType : 'ABSOLUTE', tierOptions).label}</Text>
              : (
                <Select
                  options={tierOptions}
                  name='tierType'
                  value={getSelectValue(rate ? rate.tierType : 'ABSOLUTE', tierOptions)}
                  labelKey='label'
                  valueKey='value'
                  onChange={_onChange}
                  id={IDUtil.getId('BillingInfoEditorPricingModel')}
                />
              )}
          </InputContainer>
        </Box>
        <Box
          flex={true}
          direction='row'
        >
          <InputContainer label='Max Shrink'>
            {!rate || readOnly
              ? <Text weight={100} id={IDUtil.getId('BillingInfoEditorMaxShrink')} margin='small'>{getSelectValue(rate ? rate.shrinkPolicy : 'SHRINK_100', shrinkOptions).label}</Text>
              : (
                <Select
                  options={shrinkOptions}
                  name='shrinkPolicy'
                  id={IDUtil.getId('BillingInfoEditorMaxShrink')}
                  value={getSelectValue(rate ? rate.shrinkPolicy : 'SHRINK_100', shrinkOptions)}
                  labelKey='label'
                  valueKey='value'
                  onChange={_onChange}
                />
              )}
          </InputContainer>
          <InputContainer label='Round Usage'>
            <CheckBox
              toggle={false}
              id={IDUtil.getId('BillingInfoEditorRoundUsage')}
              name='rounding'
              checked={rate ? rate.rounding : false}
              onChange={_onChange}
              disabled={readOnly}
            />
          </InputContainer>
        </Box>
      </Box>
    </BaseContainer>
  );
};

MeterSettings.propTypes = {
  rate: PropTypes.shape({
    description: PropTypes.string,
    rounding: PropTypes.bool,
    tierType: PropTypes.string,
    shrinkPolicy: PropTypes.string,
  }),
  readOnly: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  onToggleExpansion: PropTypes.func.isRequired,
  expanded: PropTypes.string.isRequired,
};

export default MeterSettings;
