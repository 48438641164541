// (C) Copyright 2017-2024 Hewlett Packard Enterprise Development LP

import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useContext } from 'react';
import { ApiContext } from '../../AppContext';
import { useErrorToast } from '../../components/shared/hooks';
import { executeDelete, executeGet } from '../api';

export const useEquipmentQuery = (customerId, serviceType, options) => {
  const path = useContext(ApiContext)('equipment.list.path', { customerId, serviceType });
  return useErrorToast(useQuery({
    queryKey: ['GLBM:EQUIPMENT-LIST', customerId, serviceType],
    queryFn: () => executeGet(path),
    ...(options || []),
  }), 'Fetch Equipment Error');
};

export const useEquipmentDeleteMutate = (customerId, serviceType, options) => {
  const queryClient = useQueryClient();
  const path = useContext(ApiContext)('equipment.delete.path', { customerId, serviceType });
  return useMutation({
    mutationFn: equipmentIds => executeDelete(path, equipmentIds),
    ...(options || {}),
    onSuccess: () => {
      queryClient.removeQueries({
        queryKey: ['GLBM:EQUIPMENT-LIST', customerId, serviceType]
      });
      options?.onSuccess?.();
    }
  });
};
