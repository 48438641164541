// (C) Copyright 2017-2024 Hewlett Packard Enterprise Development LP

/*
 * action types
 */

import { updateStateAndSession } from 'session/sessionUtil';

export const INVALIDATE_SERVICE_METERS = 'INVALIDATE_SERVICE_METERS';
export const REQUEST_SERVICE_METERS = 'REQUEST_SERVICE_METERS';
export const RECEIVE_SERVICE_METERS = 'RECEIVE_SERVICE_METERS';
export const UPDATE_SERVICE_METERS_FILTERS = 'UPDATE_SERVICE_METERS_FILTERS';

/*
 * action creators
 */

function handleErrors(response) {
  if (!response.ok) {
    throw Error(response.statusText);
  }
  return response;
}

export function invalidateServiceMeters() {
  return { type: INVALIDATE_SERVICE_METERS };
}

export function requestServiceMeters(filters) {
  return {
    type: REQUEST_SERVICE_METERS,
    filters,
  };
}

export function receiveServiceMeters(items, error) {
  return {
    type: RECEIVE_SERVICE_METERS,
    items,
    receivedAt: Date.now(),
    error,
  };
}

export function updateFilters(filters) {
  return {
    type: UPDATE_SERVICE_METERS_FILTERS,
    filters,
  };
}

export const updateFiltersAndSession = updateStateAndSession(updateFilters);
