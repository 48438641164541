// (C) Copyright 2017-2024 Hewlett Packard Enterprise Development LP
import React, { useEffect, useMemo, useState } from 'react';
import {
  Box, Button, FormField, Select, Spinner,
} from 'grommet';
import moment from 'moment';
import {
  usePSADataViewsQuery,
  useQueryExtractBillingPSAData,
} from '../../../core';
import DateTime from '../../shared/component/DateTime';

const ExtractBillingPSAData = () => {
  const [viewType, setViewType] = useState();
  const [date, setDate] = useState(moment().subtract(1, 'months').startOf('month').format('YYYY-MM'));
  const [error, setError] = useState('');

  const onDateChange = (newDate) => {
    const momentDate = moment(newDate, 'YYYY-MM', true);
    let newError = momentDate.isAfter(moment(), 'month') ? 'Select a month prior to or equal to the current month and year' : undefined;
    newError = momentDate.isValid() ? newError : 'Invalid month format (YYYY-MM)';
    setDate(newDate);
    setError(newError);
  };

  const viewValuesQuery = usePSADataViewsQuery();

  const viewValuesOptions = useMemo(() => {
    if (viewValuesQuery.status === 'success' && viewValuesQuery?.data) {
      return Object.entries(viewValuesQuery?.data).map(([key, value]) => ({ label: key, value }));
    }
    return [];
  }, [viewValuesQuery]);

  // once we fetch the first option, and nothing is yet selected, user option 1 as selected value:
  useEffect(() => {
    if (!viewType && viewValuesOptions?.length) {
      setViewType(viewValuesOptions[0].value);
    }
  }, [viewType, viewValuesOptions]);

  const { mutate: extract, isPending: showSpinner } = useQueryExtractBillingPSAData(date, viewType);

  const onExtract = () => {
    if (!error) {
      extract();
    }
  };

  return (
    <Box border='top' direction='row' pad={{ horizontal: 'small' }} align='center' height={{ min: 'xsmall' }} flex={false}>
      <Box margin={{ right: 'auto' }}>
        <strong>Extract All Billing and PSA Data</strong>
        <span>Extract all billing info and PSA data for all billing accounts in CSV format for a given month</span>
      </Box>
      <Box direction='row' align='center' gap='medium'>
        <Box direction='row' align='top' gap='medium' style={{ height: '100px' }}>
          <FormField label='Billing Accounts'>
            <Select
              options={viewValuesOptions}
              value={viewType}
              labelKey='label'
              data-e2e='viewTypeOptions'
              valueKey={{ key: 'value', reduce: true }}
              onChange={({ value: nextValue }) => setViewType(nextValue)}
              placeholder='Select Type'
            />
          </FormField>
          <FormField error={error} label='Invoice Month'>
            <DateTime
              id='extractAllBillingPSADateTime'
              name='billingPSAExtractDate'
              format='YYYY-MM'
              onChange={onDateChange}
              value={date}
            />
          </FormField>
        </Box>
        <Box flex={false} width='xsmall' align='center'>
          {showSpinner && <Spinner size='small' />}
        </Box>
        <Box direction='row' align='center' flex={true} width={{ min: 'xsmall' }} justify='end'>
          <Button
            label='Extract'
            onClick={onExtract}
            secondary={true}
            disabled={showSpinner}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default ExtractBillingPSAData;
