// (C) Copyright 2017-2024 Hewlett Packard Enterprise Development LP
import React from 'react';
import { Box, Button, Text } from 'grommet';
import { Add } from 'grommet-icons';
import PropTypes from 'prop-types';
import IDUtil from '../util/IDUtil';
import FieldItem from './ManyFieldsItem';
import { ListPlaceholder } from './ListPlaceholder';

const ManyFieldsForm = ({
  values = [],
  onChange = () => undefined,
  disabled = false,
  error = false,
  label,
  name,
  fieldsValidation = () => undefined,
}) => {
  const onChangeField = (value, index) => {
    const newList = [...values];
    newList[index] = value;
    onChange(newList);
  };

  return (
    <Box
      style={{ border: `1px solid ${error ? '#F04953' : 'rgba(0, 0, 0, 0.15)'}`, marginTop: '0.5px' }}
      pad='small'
      error={error && error !== 'fields' && Boolean(error)}
    >
      <Box justify='between' direction='row' style={{ height: '48px' }} align='center'>
        <Text>{label}</Text>
        <Box direction='row'>
          {error && error !== 'fields' ? <Box style={{ color: '#F04953' }} align='end'>Required</Box>
            : <Box>&nbsp;</Box>}
          {!disabled && (
            <Button
              icon={<Add />}
              id={IDUtil.getId(`${name}AddButton`)}
              onClick={() => onChange([...values, ''])}
              a11yTitle={`Add${name}`}
            />
          )}
        </Box>
      </Box>

      <Box>
        {values.length === 0 ? (
          <ListPlaceholder
            emptyMessage={`You do not have any ${label} defined at the moment.`}
            unfilteredTotal={0}
            filteredTotal={1}
          />
        ) : (
          <Box gap='small' pad='none' margin='none'>
            {
              values.map((field, index) => (
                <FieldItem
                  key={index}
                  error={error === 'fields' && fieldsValidation(field)}
                  field={field}
                  disabled={disabled}
                  onChange={value => onChangeField(value, index)}
                  index={index}
                  remove={() => onChange(values.filter((el, i) => i !== index))}
                />
              ))
            }
          </Box>
        )}
      </Box>
    </Box>
  );
};

ManyFieldsForm.propTypes = {
  values: PropTypes.array,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  error: PropTypes.bool,
  fieldsValidation: PropTypes.func,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
};

export default ManyFieldsForm;
