// (C) Copyright 2017-2024 Hewlett Packard Enterprise Development LP
import React, { Fragment } from 'react';

import { v4 as uuidv4 } from 'uuid';
import { FormAdd } from 'grommet-icons';
import PropTypes from 'prop-types';
import { DrilldownType } from '@saturn/ca-invoice';
import {
  Box, Button, CheckBox, FormField, Select, TextInput,
} from 'grommet';
import DrilldownFieldsForm from '../shared/component/ManyFieldsForm';
import IDUtil from '../shared/util/IDUtil';
import { MeterType } from '../constants/MeterType';
import { ServiceCategory } from '../services';
import { MeterUnit } from '../constants/MeterUnit';
import { MinChargeInterval } from '../constants/MinChargeInterval';
import { RateInterval } from '../constants/RateInterval';
import { SampleInterval } from '../constants/SampleInterval';
import { AggregationInterval } from '../constants/AggregationInterval';
import ServiceTypeStore from '../stores/ServiceTypeStore';

const ServiceMeterForm = ({
  meter,
  isNew = false,
  readOnly = false,
  errors,
  onChange,
  drilldownFieldsValidation = () => undefined,
}) => {
  function _renderTypeOptions(meter, errors) {
    const options = MeterType.enumValues.map(({ enumKey, label }) => ({
      label,
      value: enumKey,
    })).sort((a, b) => a.label.localeCompare(b.label));

    return (
      <FormField label='Type' error={errors.type} required={true}>
        {readOnly
          ? <TextInput value={meter.type} disabled={true} />
          : (
            <Select
              labelKey='label'
              valueKey={{ key: 'value', reduce: true }}
              value={meter.type}
              options={options}
              onChange={(e) => {
                meter.type = e.value;
                if (meter.type === MeterType.Consumption.label) {
                  delete meter.allocationMinChargeInterval;
                  delete meter.allocationRateInterval;
                  delete meter.allocationSampleMethod;
                  delete meter.allocationAggregationInterval;
                }
                onChange(meter, 'type');
              }}
            />
          )}
      </FormField>
    );
  }
  function _renderIdField(meter, errors) {
    return (
      <FormField
        label='ID'
        error={errors.id}
        required={true}
      >
        {!isNew
          ? <TextInput value={meter.id} disabled={true} />
          : (
            <Box
              direction='row'
              justify='between'
              style={{ height: '36px' }}
            >
              <input
                id={IDUtil.getId('MeterIdInput')}
                name='id'
                value={meter.id || ''}
                onChange={(e) => {
                  meter.id = e.target.value;
                  onChange(meter, 'id');
                }}
                style={{
                  width: '330px',
                  minWidth: '330px',
                  border: 'none',
                  outline: 'none',
                  paddingLeft: '12px',
                }}
              />
              <Box direction='column' justify='center' pad={{ end: 'small' }}>
                <Button
                  label='UUIDv4'
                  plain={true}
                  icon={<FormAdd />}
                  onClick={() => {
                    meter.id = uuidv4();
                    onChange(meter, 'id');
                  }}
                />
              </Box>
            </Box>
          )}
      </FormField>
    );
  }
  function _renderNameField(meter, errors) {
    return (
      <FormField label='Name' error={errors.name} required={true}>
        <TextInput
          id={IDUtil.getId('CatalogServiceEditorNameInput')}
          name='name'
          value={meter.name || ''}
          onChange={(e) => {
            meter.name = e.target.value;
            onChange(meter, 'name');
          }}
          onBlur={() => onChange({ ...meter, name: meter?.name?.trim() })}
          disabled={readOnly}
        />
      </FormField>
    );
  }
  function _renderUnitNameField(meter, errors) {
    return (
      <FormField label='Unit Name' error={errors.unitName} required={true}>
        <TextInput
          id={IDUtil.getId('CatalogServiceEditorUnitNameInput')}
          name='name'
          value={meter.unitName || ''}
          onChange={(e) => {
            meter.unitName = e.target.value;
            onChange(meter, 'unitName');
          }}
          onBlur={() => onChange({ ...meter, unitName: meter?.unitName?.trim() })}
          disabled={readOnly}
        />
      </FormField>
    );
  }
  function _renderNonBillableField(meter, errors) {
    return (
      <FormField label='Non Billable' error={errors.nonBillable} required={true}>
        {readOnly
          ? <TextInput value={meter.nonBillable} disabled={true} />
          : (
            <CheckBox
              checked={meter.nonBillable}
              onChange={(e) => {
                meter.nonBillable = e.target.checked;
                onChange(meter);
              }}
            />
          )}
      </FormField>
    );
  }
  function _renderUnitOptions(meter, errors) {
    const options = MeterUnit.enumValues.map(({ enumKey, label }) => ({
      label,
      value: enumKey,
    })).sort((a, b) => a.label.localeCompare(b.label));

    return (
      <FormField label='Unit of measure' error={errors.unitOfMeasure} required={true}>
        {readOnly
          ? <TextInput value={meter.unitOfMeasure} disabled={true} />
          : (
            <Select
              labelKey='label'
              valueKey={{ key: 'value', reduce: true }}
              value={meter.unitOfMeasure}
              options={options}
              onChange={(e) => {
                meter.unitOfMeasure = e.value;
                onChange(meter, 'unitOfMeasure');
              }}
            />
          )}
      </FormField>
    );
  }

  function _renderServiceCategoryOptions(meter, errors) {
    const options = ServiceCategory.enumValues.map(({ enumKey, label }) => ({
      label: label(),
      value: enumKey,
    })).sort((a, b) => a.label.localeCompare(b.label));

    return (
      <FormField label='Service Category' error={errors.serviceCategory} required={true}>
        {readOnly
          ? <TextInput value={meter.serviceCategory} disabled={true} />
          : (
            <Select
              labelKey='label'
              valueKey={{ key: 'value', reduce: true }}
              value={meter.serviceCategory}
              options={options}
              onChange={(e) => {
                meter.serviceCategory = e.value;
                onChange(meter, 'serviceCategory');
              }}
            />
          )}
      </FormField>
    );
  }
  function _renderServiceTypeField(meter, errors) {
    return (
      <FormField label='Service Type' error={errors.serviceType} required={true}>
        <TextInput
          id={IDUtil.getId('MeterServiceTypeInput')}
          name='name'
          value={(ServiceTypeStore.getService(meter.serviceType) && ServiceTypeStore.getService(meter.serviceType).label) || ''}
          disabled={readOnly}
        />
      </FormField>
    );
  }
  function _renderMinChargeIntervalOptions(meter, errors) {
    const options = MinChargeInterval.enumValues.map(({ enumKey, label, order }) => ({
      label,
      value: enumKey,
      order,
    })).sort((a, b) => a.order - b.order);

    return (
      <FormField
        label='Allocation Min Charge Interval'
        error={errors.allocationMinChargeInterval}
        required={true}
      >
        {readOnly
          ? <TextInput value={meter.allocationMinChargeInterval} disabled={true} />
          : (
            <Select
              labelKey='label'
              valueKey={{ key: 'value', reduce: true }}
              value={meter.allocationMinChargeInterval}
              options={options}
              onChange={(e) => {
                meter.allocationMinChargeInterval = e.value;
                onChange(meter, 'allocationMinChargeInterval');
              }}
            />
          )}
      </FormField>
    );
  }
  function _renderRateIntervalOptions(meter, errors) {
    const options = RateInterval.enumValues.map(({ enumKey, label }) => ({
      label,
      value: enumKey,
    })).sort((a, b) => a.label.localeCompare(b.label));

    return (
      <FormField label='Allocation Rate Interval' error={errors.allocationRateInterval} required={true}>
        {readOnly
          ? <TextInput value={meter.allocationRateInterval} disabled={true} />
          : (
            <Select
              labelKey='label'
              valueKey={{ key: 'value', reduce: true }}
              value={meter.allocationRateInterval}
              options={options}
              onChange={(e) => {
                meter.allocationRateInterval = e.value;
                onChange(meter, 'allocationRateInterval');
              }}
            />
          )}
      </FormField>
    );
  }
  function _renderSampleIntervalOptions(meter, errors) {
    const options = SampleInterval.enumValues.map(({ enumKey, label }) => ({
      label,
      value: enumKey,
    })).sort((a, b) => a.label.localeCompare(b.label));

    return (
      <FormField
        label='Allocation Sample Method'
        error={errors.allocationSampleMethod}
        required={true}
      >
        {readOnly
          ? <TextInput value={meter.allocationSampleMethod} disabled={true} />
          : (
            <Select
              labelKey='label'
              valueKey={{ key: 'value', reduce: true }}
              value={meter.allocationSampleMethod}
              options={options}
              onChange={(e) => {
                meter.allocationSampleMethod = e.value;
                onChange(meter, 'allocationSampleMethod');
              }}
            />
          )}
      </FormField>
    );
  }
  function _renderAggregationIntervalOptions(meter, errors) {
    const options = AggregationInterval.enumValues.map(({ enumKey, label }) => ({
      label,
      value: enumKey,
    })).sort((a, b) => a.label.localeCompare(b.label));

    return (
      <FormField
        label='Aggregation Interval'
        error={errors.allocationAggregationInterval}
        required={true}
      >
        {readOnly
          ? <TextInput value={meter.allocationAggregationInterval} disabled={true} />
          : (
            <Select
              labelKey='label'
              valueKey={{ key: 'value', reduce: true }}
              value={meter.allocationAggregationInterval}
              options={options}
              onChange={(e) => {
                meter.allocationAggregationInterval = e.value;
                onChange(meter, 'allocationAggregationInterval');
              }}
            />
          )}
      </FormField>
    );
  }
  function _renderDrillDownOptions(meter, errors) {
    const options = DrilldownType.enumValues.map(({ enumKey, label }) => ({
      label: enumKey,
      value: enumKey,
    })).sort((a, b) => a.label.localeCompare(b.label));

    return (
      <FormField
        label='Drilldown Type'
        error={errors.drillDownType}
        required={true}
      >
        {readOnly
          ? <TextInput value={meter.drillDownType} disabled={true} />
          : (
            <Select
              labelKey='label'
              valueKey={{ key: 'value', reduce: true }}
              value={meter.drillDownType}
              options={options}
              onChange={(e) => {
                meter.drillDownType = e.value;
                onChange(meter, 'drillDownType');
              }}
            />
          )}
      </FormField>
    );
  }

  return (
    <form>
      {_renderServiceTypeField(meter, errors)}
      {_renderNameField(meter, errors)}
      {_renderIdField(meter, errors)}
      {_renderTypeOptions(meter, errors)}
      {_renderServiceCategoryOptions(meter, errors)}
      {_renderUnitOptions(meter, errors)}
      {meter.unitOfMeasure === 'Each' && _renderUnitNameField(meter, errors)}
      {_renderNonBillableField(meter, errors)}
      {meter.type === 'Allocation'
        && (
        <>
          {_renderMinChargeIntervalOptions(meter, errors)}
          {_renderRateIntervalOptions(meter, errors)}
          {_renderSampleIntervalOptions(meter, errors)}
          {_renderAggregationIntervalOptions(meter, errors)}
        </>
        )}
      {_renderDrillDownOptions(meter, errors)}
      <DrilldownFieldsForm
        label='Drilldown fields'
        name='Drilldown fields'
        values={meter?.drilldownFields || []}
        disabled={readOnly}
        error={errors.drilldownFields}
        fieldsValidation={drilldownFieldsValidation}
        onChange={(value) => {
          onChange({ ...meter, drilldownFields: value });
        }}
      />
    </form>
  );
};

ServiceMeterForm.propTypes = {
  meter: PropTypes.any.isRequired,
  isNew: PropTypes.bool,
  readOnly: PropTypes.bool,
  errors: PropTypes.any,
  customer: PropTypes.any,
  currencies: PropTypes.array,
  canEditCurrency: PropTypes.bool,
  onDOMChange: PropTypes.func,
  onChange: PropTypes.func,
  drilldownFieldsValidation: PropTypes.func,
};

export default ServiceMeterForm;
