// (C) Copyright 2017-2024 Hewlett Packard Enterprise Development LP
import React from 'react';
import { Box } from 'grommet';
import PropTypes from 'prop-types';

const Step = ({ children = undefined }) => {
  /**
   * null transform hack to create a layer for the step. Since we need to support IE11 we cannot use the preferred will-change css property
   * see here for details on how this works: https://classroom.udacity.com/courses/ud860/lessons/4129068601/concepts/41395386460923
   */
  const createLayer = { transform: 'translateZ(0)' };
  return (<Box flex={true} pad={{ horizontal: 'medium' }} overflow='auto' style={createLayer}>{children}</Box>);
};
Step.propTypes = {
  children: PropTypes.node,
};
export default Step;
