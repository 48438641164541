// (C) Copyright 2017-2024 Hewlett Packard Enterprise Development LP

import React, { Component } from 'react';
import { Box } from 'grommet';

class InputContainer extends Component {
  render() {
    return (
      <Box
        direction='row'
        align='center'
        flex={true}
        pad={{ horizontal: 'small' }}
        gap='small'
      >
        <Box size='xsmall'>
          <span>
            {this.props.label}
            :
          </span>
        </Box>
        <Box flex={true}>
          {this.props.children}
        </Box>
      </Box>
    );
  }
}

export default InputContainer;
