// (C) Copyright 2017-2024 Hewlett Packard Enterprise Development LP

import { nice, ticks } from 'd3-array';
import { isFinite } from 'lodash';
import moment from 'moment';

export const computeYTicks = (min, max, tickCnt) => {
  let adjustedMin = min;
  let adjustedMax = max;
  // Corner case where max is less than min - just reverse them
  if (adjustedMax < adjustedMin) {
    [adjustedMin, adjustedMax] = [adjustedMax, adjustedMin];
  }

  // Use d3 to compute nice tick range and values
  [adjustedMin, adjustedMax] = nice(adjustedMin, adjustedMax, tickCnt);
  return ticks(adjustedMin, adjustedMax, tickCnt);
};

export const computeTimePeriodOptions = (contractStartMonth) => {
  const monthOpts = [2, 3, 6, 9, 12];
  let allOpt;
  const yesterday = moment().subtract(1, 'day').format('YYYY-MM-DD');
  const thisMonth = moment.utc(yesterday).startOf('month');
  const lastMonth = thisMonth.clone().subtract(1, 'month');
  const contractStart = moment.utc(contractStartMonth);
  if (lastMonth.clone().subtract(12, 'months').isSameOrAfter(contractStart)) {
    let monthCnt = 24;
    while (lastMonth.clone().subtract(monthCnt, 'months').isSameOrAfter(contractStart)) {
      monthOpts.push(monthCnt);
      monthCnt += 12;
    }
    const allMonthCnt = lastMonth.diff(contractStart, 'months') + 1;
    allOpt = { value: allMonthCnt, label: 'Start of Term' };
  }
  const opts = monthOpts.map(cnt => ({
    value: cnt,
    label: `Last ${cnt} months`,
  }));
  if (allOpt) {
    opts.push(allOpt);
  }
  return opts;
};

export const formatNumber = (value, locale = null) => {
  const numeric = value || 0;
  if (typeof numeric === 'number') {
    try {
      return Math.round(numeric).toLocaleString(locale || '');
    } catch (error) {
      return Math.round(numeric).toLocaleString();
    }
  }
  return null;
};

const isAlmostZero = (number, epsilon = 1e-2) => Math.abs(number) < epsilon;

export const formatNumberWithSigDigits = (number, sigDigits = 3) => {
  if (!isFinite(number)) {
    return '--';
  }
  const capValue = 10 ** sigDigits;
  const options = (sigDigits === 0 || number >= capValue || number <= 0 - capValue || isAlmostZero(number))
    ? {
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    }
    : {
      minimumSignificantDigits: sigDigits,
      maximumSignificantDigits: sigDigits,
    };
  return number.toLocaleString('us-en', options);
};

export const formatShortNumber = (value, locale = null) => {
  const numeric = value || 0;
  if (typeof numeric === 'number') {
    try {
      const formatter = new Intl.NumberFormat(locale || 'en-US', {
        notation: 'compact',
        compactDisplay: 'short',
        minimumFractionDigits: 0,
        minimumIntegerDigits: 1,
      });
      return formatter.format(numeric);
    } catch (error) {
      return Math.round(numeric).toLocaleString();
    }
  }
  return null;
};
