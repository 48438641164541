// (C) Copyright 2017-2024 Hewlett Packard Enterprise Development LP
import React from 'react';
import { Box } from 'grommet';

function Duration({ value }) {
  const duration = value.split('.').join(':').split(':').reverse();

  const rseconds = +duration[1];
  const timeMin = duration[2];

  const hours = (timeMin / 60);
  const rhours = Math.floor(hours);
  const rminutes = Math.round((hours - rhours) * 60);

  return (
    <Box direction='row' justify='end'>
      {(rhours > 0) && (
      <span>
        {rhours}
        <sub className='secondary'>h</sub>
&nbsp;&nbsp;&nbsp;
      </span>
      )}
      {(rminutes > 0 || rhours > 0) && (
      <span>
        {rminutes}
        <sub className='secondary'>m</sub>
&nbsp;&nbsp;&nbsp;
      </span>
      )}
      <span>
        {rseconds}
        <sub className='secondary'>s</sub>
      </span>
    </Box>
  );
}

export default Duration;
