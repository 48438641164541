// (C) Copyright 2017-2024 Hewlett Packard Enterprise Development LP
import React from 'react';
import PropTypes from 'prop-types';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css'; // Core grid CSS, always needed
import 'ag-grid-community/styles/ag-theme-balham.css'; // Optional theme CSS
import { Box } from 'grommet';

const CapacityPlanningGrid = ({ data = undefined }) => {
  const processCellCallback = (params) => {
    if (params && params.value && params.value.value) {
      return params.value.value;
    }
    if (Array.isArray(params.value)) {
      return '-';
    }
    return typeof params.value === 'object' ? params.value.value : params.value;
  };

  const defaultExportParams = {
    processCellCallback,
  };

  return (
    <Box pad={{ horizontal: 'small' }}>
      <div
        className='ag-theme-balham'
        style={{ height: '197px', width: '100%' }}
      >
        <AgGridReact
          toolPanelSuppressSideButtons={true}
          enableColResize={true}
          rowData={data?.rows}
          columnDefs={data?.cols}
          defaultExportParams={defaultExportParams}
          defaultExcelExportParams={defaultExportParams}
          defaultCsvExportParams={defaultExportParams}
        />
      </div>
    </Box>
  );
};

CapacityPlanningGrid.propTypes = {
  data: PropTypes.shape({
    cols: PropTypes.any,
    rows: PropTypes.any,
  }),
};

export default CapacityPlanningGrid;
