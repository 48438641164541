// (C) Copyright 2017-2024 Hewlett Packard Enterprise Development LP

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Box } from 'grommet';
import LocationRates from './LocationRates';
import LocationInfo from './LocationInfo';

const LocationDetails = ({
  readOnly,
  locationId,
  locationRates = [],
  customer = undefined,
  onChange,
  equipment = undefined,
}) => {
  const [expanded, setExpanded] = useState('NONE'); // 'NONE', 'ATTRS', 'RATES'

  return (
    <Box
      direction='column'
      flex={true}
      pad={{ vertical: 'small' }}
    >
      <Box
        direction='column'
        flex={true}
        gap='small'
      >
        <LocationInfo
          expanded={expanded}
          setExpanded={setExpanded}
          equipment={equipment}
          locationId={locationId}
        />
        <LocationRates
          expanded={expanded}
          setExpanded={setExpanded}
          locationRates={locationRates}
          locationId={locationId}
          customer={customer}
          onChange={onChange}
          readOnly={readOnly}
        />
      </Box>
    </Box>
  );
};

LocationDetails.propTypes = {
  readOnly: PropTypes.bool.isRequired,
  locationId: PropTypes.string.isRequired,
  locationRates: PropTypes.array,
  customer: PropTypes.object,
  onChange: PropTypes.func.isRequired,
  equipment: PropTypes.array,
};

export default LocationDetails;
