// (C) Copyright 2017-2024 Hewlett Packard Enterprise Development LP
import { Environment } from './Environment';

function getEnvironment() {
  switch (window.config.appEnv) {
    case 'Prod':
      return Environment.PROD;
    case 'Dev':
    case 'localhost':
    case '127.0.0.1':
    default:
      return Environment.DEV;
  }
}

export function isLocalhost() {
  const host = window.location.hostname;
  return (host === 'localhost');
}

export function isDev() {
  return (getEnvironment() === Environment.DEV);
}

export function isProd() {
  return (getEnvironment() === Environment.PROD);
}

export function getEnvironmentName() {
  if (isDev()) {
    return 'Dev';
  }
  if (isProd()) {
    return 'Prod';
  }

  return 'Unknown';
}
