// (C) Copyright 2017-2024 Hewlett Packard Enterprise Development LP

import { DealType } from '../../shared/constants/DealType';

export function validatePartnerSelection(resellerPartnerId, distributorPartnerId, dealType) {
  let hasErrors = false;
  const dealTypeEnum = DealType.enumValueOf(dealType);
  const errors = {
    resellerPartnerId: [],
    distributorPartnerId: [],
    duplicatePartner: [],
  };

  // If Reseller selected is a Tier 2 Reseller a Distributor must be selected
  if ([DealType.RESELLER.enumKey, DealType.DISTRIBUTOR_RESELLER.enumKey].includes(dealType) && !resellerPartnerId) {
    errors.resellerPartnerId = ['Required'];
    hasErrors = true;
  }

  // If Reseller selected is a Tier 2 Reseller a Distributor must be selected
  if (DealType.DISTRIBUTOR_RESELLER === dealTypeEnum && !distributorPartnerId) {
    errors.distributorPartnerId = ['Required'];
    hasErrors = true;
  }

  // If selected Reseller and Distributor is the same partner:
  if (!hasErrors && DealType.DISTRIBUTOR_RESELLER === dealTypeEnum && distributorPartnerId === resellerPartnerId) {
    errors.resellerPartnerId = [' '];
    errors.distributorPartnerId = [' '];
    errors.duplicatePartner = ['Reseller and Distributor cannot be set to the same partner.'];
    hasErrors = true;
  }

  return [hasErrors, errors];
}
