// (C) Copyright 2017-2024 Hewlett Packard Enterprise Development LP

import React from 'react';
import PropTypes from 'prop-types';
import { useMeasure } from '@nivo/core';
import { useTooltipState } from '@nivo/tooltip';
import ChartTooltip from '../ChartTooltip';
import { formatPercentage, formatTooltipDate, formatValueWithUnit } from './utils';

const computePct = (value, base) => {
  if (value === undefined || !base) {
    return undefined;
  }
  return (value * 100) / base;
};

const Tooltip = ({
  data, unit = undefined, chartBounds, chartMargin,
}) => {
  const { width: chartWidth } = chartBounds;
  const [ref, tooltipBounds] = useMeasure();
  const { width, left } = tooltipBounds;
  const tooltipState = useTooltipState();
  const { position: [xPosition] } = tooltipState;
  let offset = 0;
  if (width / 2 + left + chartMargin.left > xPosition) {
    // on left side of chart
    offset = width / 2 - xPosition + left + chartMargin.left;
  } else if (xPosition + width / 2 + left > chartWidth - chartMargin.right) {
    // on right side of chart
    offset = chartWidth - chartMargin.right - (width / 2 + left + xPosition);
  }
  const orderedValueGroups = [
    [
      { label: 'Requested Capacity', value: data.requested },
      { label: 'Actual Usage', value: data.used, pct: computePct(data.used, data.requested) },
      { label: 'Reserved Capacity', value: data.committed, pct: computePct(data.committed, data.requested) },
    ].filter(x => x.value !== undefined),
    [
      { label: 'Installed Capacity', value: data.usable },
      { label: 'Available Capacity', value: data.available, pct: computePct(data.available, data.usable) },
    ].filter(x => x.value !== undefined),
  ].filter(x => x.length > 0);

  return (
    <div
      ref={ref}
      style={{
        position: 'relative',
        left: offset,
        right: 0,
        bottom: 0,
      }}
    >
      <ChartTooltip
        ref={ref}
        title={formatTooltipDate(data.date) || '-'}
        groups={orderedValueGroups}
        formatValue={formatValueWithUnit}
        unit={unit}
        formatPercent={formatPercentage}
      />
    </div>
  );
};

Tooltip.propTypes = {
  data: PropTypes.shape({
    requested: PropTypes.number,
    used: PropTypes.number,
    committed: PropTypes.number,
    usable: PropTypes.number,
    available: PropTypes.number,
    date: PropTypes.instanceOf(Date),
  }).isRequired,
  unit: PropTypes.string,
  chartBounds: PropTypes.shape({
    width: PropTypes.number.isRequired,
  }).isRequired,
  chartMargin: PropTypes.shape({
    left: PropTypes.number.isRequired,
    right: PropTypes.number.isRequired,
  }).isRequired,
};

export default Tooltip;
