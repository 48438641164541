// (C) Copyright 2017-2024 Hewlett Packard Enterprise Development LP
import React from 'react';
import {
  Box, Text,
} from 'grommet';
import { StatusWarning } from 'grommet-icons';

function WarningBanner(props) {
  return (
    <Box
      align='center'
      direction='row'
      gap='small'
      justify='between'
      elevation='medium'
      pad='small'
      background='status-warning'
    >
      <Box align='center' direction='row' gap='xsmall'>
        <StatusWarning />
        <Text>
          {props.message}
        </Text>
      </Box>
    </Box>
  );
}

export default WarningBanner;
