// (C) Copyright 2017-2024 Hewlett Packard Enterprise Development LP

import fromPairs from 'lodash/fromPairs';
import map from 'lodash/map';
import { UserType } from './UserType';

const permissions = {
  accessAccount: {
    list: 'glbm.access-account.list',
  },
  accessApprover: {
    list: 'glbm.access-approver.list',
    update: 'glbm.access-approver.update'
  },
  accessRefresh: {
    create: 'glbm.access-refresh.create'
  },
  accessRequest: {
    create: 'glbm.access-request.create',
    list: 'glbm.access-request.list',
    update: 'glbm.access-request.update',
  },
  accessServiceDef: {
    list: 'glbm.access-service-def.list'
  },
  accessService: {
    list: 'glbm.access-service.list'
  },
  accessStatus: {
    read: 'glbm.access-status.read'
  },
  accountFeature: {
    list: 'glbm.account-feature.list',
    delete: 'glbm.account-feature.delete',
    update: 'glbm.account-feature.update'
  },
  accountAction: {
    list: 'glbm.account-action.list'
  },
  accountAlert: {
    update: 'glbm.account-alert.update'
  },
  accountAllInvoice: {
    export: 'glbm.account-all-invoice.export',
  },
  accountAsm: {
    delete: 'glbm.account-asm.delete',
    export: 'glbm.account-asm.export',
    update: 'glbm.account-asm.update'
  },
  accountAssignedAction: {
    export: 'glbm.account-assigned-action.export'
  },
  accountConnection: {
    read: 'glbm.account-connection.read'
  },
  accountIsvServiceDataMetric: {
    list: 'glbm.account-isv-service-data-metric.list'
  },
  accountIsvServiceData: {
    export: 'glbm.account-isv-service-data.export'
  },
  accountMeter: {
    list: 'glbm.account-meter.list'
  },
  accountPromotion: {
    read: 'glbm.account-promotion.read',
    update: 'glbm.account-promotion.update'
  },
  accountRpl: {
    export: 'glbm.account-rpl.export'
  },
  accountPurpose: {
    update: 'glbm.account-purpose.update',
  },
  account: {
    backfillMissing: 'glbm.account.backfill-missing',
    checkCapacity: 'glbm.account.check-capacity',
    create: 'glbm.account.create',
    delete: 'glbm.account.delete',
    disconnect: 'glbm.account.disconnect',
    end: 'glbm.account.end',
    eom: 'glbm.account.eom',
    export: 'glbm.account.export',
    fillMissing: 'glbm.account.fill-missing',
    list: 'glbm.account.list',
    reactivate: 'glbm.account.reactivate',
    read: 'glbm.account.read',
    reset: 'glbm.account.reset',
    sync: 'glbm.account.sync',
    syncAll: 'glbm.account.sync-all',
    update: 'glbm.account.update',
    validate: 'glbm.account.validate',
    updatePsaNotRequired: 'glbm.account.update-psa-not-required',
  },
  adminAction: {
    list: 'glbm.admin-action.list'
  },
  alert: {
    ack: 'glbm.alert.ack',
    ackAll: 'glbm.alert.ack-all',
    list: 'glbm.alert.list',
    read: 'glbm.alert.read',
    unack: 'glbm.alert.unack'
  },
  audit: {
    read: 'glbm.audit.read'
  },
  azureCsp: {
    list: 'glbm.azure-csp.list'
  },
  capacityPlanning: {
    read: 'glbm.capacity-planning.read',
    export: 'glbm.all-capacity-planning.export'
  },
  company: {
    create: 'glbm.company.create',
    delete: 'glbm.company.delete',
    list: 'glbm.company.list',
    read: 'glbm.company.read',
    update: 'glbm.company.update'
  },
  country: {
    list: 'glbm.country.list'
  },
  currency: {
    list: 'glbm.currency.list',
    read: 'glbm.currency.read'
  },
  equipmentComponent: {
    list: 'glbm.equipment-component.list',
    update: 'glbm.equipment-component.update'
  },
  equipment: {
    clearAltdeviceid: 'glbm.equipment.clear-altdeviceid',
    delete: 'glbm.equipment.delete',
    list: 'glbm.equipment.list',
    update: 'glbm.equipment.update',
    validate: 'glbm.equipment.validate'
  },
  glcMeter: {
    create: 'glbm.glc-meter.create',
    delete: 'glbm.glc-meter.delete',
    import: 'glbm.glc-meter.import',
    list: 'glbm.glc-meter.list',
    read: 'glbm.glc-meter.read',
    update: 'glbm.glc-meter.update',
    validate: 'glbm.glc-meter.validate'
  },
  invoiceDrilldown: {
    export: 'glbm.invoice-drilldown.export',
    read: 'glbm.invoice-drilldown.read'
  },
  invoiceSummary: {
    read: 'glbm.invoice-summary.read'
  },
  invoice: {
    export: 'glbm.invoice.export',
    read: 'glbm.invoice.read'
  },
  job: {
    read: 'glbm.job.read'
  },
  locale: {
    list: 'glbm.locale.list'
  },
  mockData: {
    create: 'glbm.mock-data.create',
    delete: 'glbm.mock-data.delete',
    list: 'glbm.mock-data.list',
    read: 'glbm.mock-data.read',
    run: 'glbm.mock-data.run',
    update: 'glbm.mock-data.update'
  },
  partnerConnection: {
    list: 'glbm.partner-connection.list'
  },
  partnerInvoiceSummary: {
    read: 'glbm.partner-invoice-summary.read'
  },
  partnerInvoice: {
    export: 'glbm.partner-invoice.export',
    read: 'glbm.partner-invoice.read'
  },
  partner: {
    create: 'glbm.partner.create',
    delete: 'glbm.partner.delete',
    export: 'glbm.partner.export',
    list: 'glbm.partner.list',
    read: 'glbm.partner.read',
    update: 'glbm.partner.update'
  },
  paygQuotes: {
    read: 'glbm.payg.quote.read',
    generate: 'glbm.payg.vmware.action.generate',
    upload: 'glbm.payg.vmware.action.upload',
    list: 'glbm.payg.quote.list',
    create: 'glbm.payg.quote.create',
    delete: 'glbm.payg.quote.delete',
    update: 'glbm.payg.quote.update'
  },
  paygMappings: {
    read: 'glbm.payg.contract.read',
    list: 'glbm.payg.contract.list',
    update: 'glbm.payg.contract.update'
  },
  price: {
    export: 'glbm.price.export'
  },
  psa: {
    read: 'glbm.psa.read',
    update: 'glbm.psa.update'
  },
  puar: {
    list: 'glbm.puar.list',
    update: 'glbm.puar.update'
  },
  serviceDefinition: {
    export: 'glbm.service-definition.export',
    list: 'glbm.service-definition.list'
  },
  serviceFeed: {
    delete: 'glbm.service-feed.delete',
    update: 'glbm.service-feed.update'
  },
  serviceField: {
    list: 'glbm.service-field.list'
  },
  serviceInstance: {
    export: 'glbm.service-instance.export'
  },
  serviceMapping: {
    create: 'glbm.service-mapping.create',
    delete: 'glbm.service-mapping.delete',
    list: 'glbm.service-mapping.list',
    update: 'glbm.service-mapping.update'
  },
  serviceMeter: {
    list: 'glbm.service-meter.list'
  },
  servicePartnerRate: {
    create: 'glbm.service-partner-rate.create',
    read: 'glbm.service-partner-rate.read'
  },
  serviceRate: {
    create: 'glbm.service-rate.create',
    read: 'glbm.service-rate.read'
  },
  serviceSortOrder: {
    update: 'glbm.service-sort-order.update'
  },
  serviceType: {
    list: 'glbm.service-type.list'
  },
  service: {
    create: 'glbm.service.create',
    delete: 'glbm.service.delete',
    list: 'glbm.service.list',
    update: 'glbm.service.update'
  },
  systemFeature: {
    read: 'glbm.system-feature.read'
  },
  tableChanges: {
    read: 'glbm.table-changes.read'
  },
  usageEmail: {
    read: 'glbm.usage-email.read'
  },
  usageFeed: {
    list: 'glbm.usage-feed.list'
  },
  usageFile: {
    list: 'glbm.usage-file.list'
  },
  usagePendingSchedule: {
    read: 'glbm.usage-pending-schedule.read',
    run: 'glbm.usage-pending-schedule.run'
  },
  usagePending: {
    list: 'glbm.usage-pending.list'
  },
  usageProcessed: {
    read: 'glbm.usage-processed.read'
  },
  usage: {
    create: 'glbm.usage.create',
    read: 'glbm.usage.read'
  },
  userEmail: {
    validate: 'glbm.user-email.validate'
  },
  userManager: {
    import: 'glbm.user-manager.import'
  },
  userRole: {
    list: 'glbm.user-role.list'
  },
  user: {
    delete: 'glbm.user.delete',
    list: 'glbm.user.list',
    login: 'glbm.user.login',
    logout: 'glbm.user.logout',
    notify: 'glbm.user.notify',
    read: 'glbm.user.read',
    refresh: 'glbm.user.refresh',
    update: 'glbm.user.update'
  },
  encryptionKey: {
    read: 'glbm.account-key.read',
    create: 'glbm.account-key.create',
    update: 'glbm.account-key.update',
    delete: 'glbm.account-key.delete'
  }
};

export const roles = fromPairs(map(UserType.enumValues, e => [e.value, e.value]));

export const pagePermissions = {
  analytics: {
    page: [permissions.account.list, ['anyOf', permissions.invoice.read, permissions.partnerInvoice.read, permissions.capacityPlanning.read]],
    tabs: {
      invoice: ['anyOf', permissions.invoice.read, permissions.partnerInvoice.read],
      invoice_partner: [permissions.partnerInvoice.read],
      invoice_setOrder: [permissions.accountMeter.list],
      capacity: [permissions.capacityPlanning.read],
    },
  },
  payg: {
    page: [
      'anyOf',
      permissions.paygQuotes.read,
      permissions.paygQuotes.list,
      permissions.paygQuotes.update,
    ],
    tabs: {
      quotes: [permissions.paygQuotes.read, permissions.paygQuotes.list],
      mappings: [permissions.paygMappings.read, permissions.paygMappings.list],
      actions: [permissions.paygQuotes.update]
    },
    quotes: {
      edit: [permissions.paygQuotes.update],
      create: [permissions.paygQuotes.create],
      upload: [permissions.paygQuotes.upload],
      delete: [permissions.paygQuotes.delete],
    },
    mappings: {
      edit: [permissions.paygMappings.update],
    }
  },
  serviceMappings: {
    page: [permissions.account.list, permissions.serviceMapping.list, permissions.serviceMapping.update],
    actions: {
      edit: [permissions.serviceMapping.update],
    }
  },
  alerts: {
    page: [permissions.alert.list, permissions.account.list],
    actions: {
      download: [permissions.alert.read],
      ack: ['anyOf', permissions.alert.ack, permissions.alert.ackAll],
      upload: [permissions.usage.create],
      details: [permissions.alert.read],
      psaUpdate: [permissions.psa.update],
    }
  },
  customers: {
    page: [permissions.account.list],
    actions: {
      add: [permissions.account.create, permissions.account.read, permissions.currency.list, permissions.locale.list],
      delete: [permissions.account.delete],
      reset: [permissions.account.reset],
      end: [permissions.account.end],
      reactivate: [permissions.account.reactivate],
    },
    view: {
      page: [permissions.account.read, permissions.currency.list, permissions.locale.list],
      actions: {
        edit: [permissions.account.read, permissions.currency.list, permissions.locale.list, permissions.account.update, permissions.psa.read],
        viewPartner: [permissions.partner.list],
      },
      admins: {
        page: [permissions.account.read, permissions.user.list],
        actions: {
          edit: [permissions.accountAsm.update],
          remove: [permissions.accountAsm.delete],
        }
      },
      charges: {
        page: [permissions.account.read, ['anyOf', permissions.serviceRate.read, permissions.servicePartnerRate.read]],
        actions: {
          edit: ['anyOf', permissions.serviceRate.create, permissions.servicePartnerRate.create],
          editPartner: [permissions.servicePartnerRate.create],
        }
      },
      features: {
        page: [permissions.account.read, permissions.accountFeature.list],
        actions: {
          edit: [permissions.accountFeature.update],
        }
      },
      capacityalert: {
        page: [permissions.account.read],
        actions: {
          edit: [permissions.accountAlert.update],
        }
      },
      tenant: {
        page: [permissions.account.read, permissions.accountConnection.read, permissions.accountPromotion.update, permissions.account.disconnect],
        actions: {
          connect: [permissions.accountPromotion.read, permissions.accountPromotion.update],
          disconnect: [permissions.account.disconnect],
        }
      },
      psa: {
        page: [permissions.account.read, permissions.psa.read, permissions.account.update],
        page_notRequired: [permissions.account.read, permissions.psa.read, permissions.account.updatePsaNotRequired],
      },
      encryptionKey: {
        page: [permissions.encryptionKey.read],
        actions: {
          add: [permissions.encryptionKey.create],
          edit: [permissions.encryptionKey.update],
          delete: [permissions.encryptionKey.delete],
        }
      },
      purpose: {
        page_update: [permissions.accountPurpose.update],
      },
      services: {
        list: [permissions.account.read, permissions.service.list],
        actions: {
          edit: [permissions.serviceDefinition.list, ['anyOf', permissions.service.create, permissions.equipment.list, permissions.service.list, permissions.serviceRate.read, permissions.serviceMeter.list]],
          promote: [permissions.service.update],
          delete: [permissions.service.delete],
          create: [permissions.serviceDefinition.list, permissions.service.create],
        },
        read: [permissions.account.read, permissions.currency.read, permissions.service.list],
        steps: {
          readRates: [permissions.equipment.list, permissions.serviceRate.read, permissions.serviceMeter.list],
          editRates: [permissions.serviceRate.create],
          readMarkupRates: [permissions.equipment.list, permissions.servicePartnerRate.read, permissions.serviceMeter.list],
          markupRates: [permissions.servicePartnerRate.create],
          readOptions: [permissions.service.list],
          editOptions: [permissions.service.update],
          readMappings: [permissions.equipment.list],
          editMappings: [permissions.equipment.update, permissions.equipmentComponent.update],
        }
      },
      equipments: {
        page: [permissions.equipment.list, permissions.equipment.delete, permissions.account.read, permissions.service.list],
      },
      feeds: {
        page: [permissions.account.read, permissions.service.list],
        actions: {
          edit: [permissions.serviceFeed.update],
          delete: [permissions.serviceFeed.delete],
        }
      }
    }
  },
  usage: {
    page: [permissions.account.list, permissions.usageFile.list, permissions.usage.read, permissions.usage.create],
  },
  jobs: {
    page: [permissions.account.list, permissions.job.read],
  },
  users: {
    page: [permissions.user.list],
    history: [permissions.tableChanges.read],
    view: [permissions.user.read, permissions.userRole.list, permissions.account.list],
    edit: [permissions.user.update],
  },
  accessRequests: {
    page: [permissions.accessRequest.list]
  },
  administration: {
    page: [permissions.account.list, [
      'anyOf',
      permissions.usageProcessed.read,
      permissions.accountAction.list,
      permissions.account.export,
      permissions.price.export,
      permissions.accountRpl.export,
      permissions.serviceDefinition.export,
      permissions.serviceInstance.export,
      permissions.serviceInstance.export,
      permissions.accountAssignedAction.export,
      permissions.accountIsvServiceData.export,
      permissions.accountAsm.export,
      permissions.accessApprover.update,
      permissions.usagePendingSchedule.run,
      permissions.usageEmail.read,
      permissions.adminAction.list,
    ]],
    tabs: {
      processedFiles: [permissions.usageProcessed.read],
      billingAccounts: [permissions.accountAction.list],
      extract: [
        'anyOf',
        permissions.accountAllInvoice.export,
        permissions.price.export,
        permissions.accountRpl.export,
        permissions.serviceDefinition.export,
        permissions.serviceInstance.export,
        permissions.serviceInstance.export,
        permissions.accountAssignedAction.export,
        permissions.accountIsvServiceData.export,
        permissions.accountIsvServiceDataMetric.list,
        permissions.accountAsm.export,
      ],
      approvers: [permissions.accessApprover.list, permissions.accessApprover.update, permissions.userRole.list],
      pendingFiles: [permissions.usagePending.list, permissions.usagePendingSchedule.read, permissions.usagePendingSchedule.run],
      mockData: [permissions.mockData.list, permissions.mockData.read, permissions.mockData.update, permissions.mockData.create, permissions.account.list],
      emails: [permissions.usageEmail.read],
      actions: [permissions.adminAction.list],
    },
    mock: {
      page: [permissions.mockData.read, permissions.mockData.update, permissions.mockData.create, permissions.account.list],
    },
    extract: {
      allCustomerInvoices: [permissions.accountAllInvoice.export],
      assignedUsersList: [permissions.accountAssignedAction.export],
      billingAccountDetails: [permissions.serviceInstance.export],
      customerASMDetails: [permissions.accountAsm.export],
      customerRateCards: [permissions.price.export],
      isvServiceData: [permissions.accountIsvServiceData.export, permissions.accountIsvServiceDataMetric.list],
      restrictedPartiesList: [permissions.accountRpl.export],
      serviceDefinitionList: [permissions.serviceDefinition.export],
      serviceDetails: [permissions.serviceInstance.export],
      allCapacityPlanningData: [permissions.capacityPlanning.export]
    },
  },
  audit: {
    page: [permissions.account.list, permissions.user.list, permissions.audit.read],
    actions: {
      details: [permissions.audit.read],
    }
  },
  requestRole: {
    page: [
      permissions.accessAccount.list,
      permissions.accessServiceDef.list,
      permissions.accessApprover.list,
      permissions.accessRequest.create,
      permissions.accessRequest.update,
    ],
  },
  serviceMeters: {
    page: [permissions.glcMeter.list, permissions.glcMeter.delete, permissions.glcMeter.validate, permissions.glcMeter.import],
    actions: {
      edit: [permissions.glcMeter.create, permissions.glcMeter.update],
    }
  },
  partners: {
    page: [permissions.partner.list, permissions.account.list],
    actions: {
      delete: [permissions.partner.delete]
    },
    add: [permissions.partner.create],
    edit: [permissions.partner.update],
    tenant: [permissions.partnerConnection.list],
  },
  companies: {
    page: [permissions.company.list, permissions.company.create],
    actions: {
      delete: [permissions.company.delete]
    },
    add: [permissions.company.create],
    edit: [permissions.company.update],
  }
};
