// (C) Copyright 2017-2024 Hewlett Packard Enterprise Development LP

import React, { Component } from 'react';
import {
  Box,
  Button,
} from 'grommet';
import PropTypes from 'prop-types';
import GLBMLayer from '../../../shared/component/GLBMLayer';
import GLBMNameValueList from '../../../shared/component/GLBMNameValueList';

class CatalogPlanDialog extends Component {
  constructor(props) {
    super(props);

    this._onSubmit = this._onSubmit.bind(this);

    this.state = {
      heading: this.props.heading,
      plan: this.props.plan,
    };
  }

  _onSubmit() {
    this.props.onChange();
  }

  _formatDate(date) {
    return new Date(date).toLocaleDateString((window.navigator.languages) ? window.navigator.languages[0] : (window.navigator.userLanguage || window.navigator.language), { timeZone: 'UTC' });
  }

  _renderPlanDetails() {
    const { plan } = this.state;
    return (
      <Box margin='medium'>
        <GLBMNameValueList
          title='Selected Plan'
          data={[
            { label: 'Instance Id', value: plan.instanceId },
            { label: 'Id', value: plan.planId },
            { label: 'Name', value: plan.planName },
            { label: 'Deprovisioned Date', value: plan.deprovisioned ? this._formatDate(plan.deprovisioned) : '-' },
          ]}
        />
      </Box>
    );
  }

  render() {
    let closeControl;
    if (this.props.onClose) {
      closeControl = (
        <Button
          label='Cancel'
          onClick={this.props.onClose}
          secondary={true}
        />
      );
    }

    return (
      <GLBMLayer
        position='right'
        full='vertical'
        closer={true}
        onClose={this.props.onClose}
        onEsc={this.props.onClose}
        onClickOutside={this.props.onClose}
        overlayClose={true}
        title={this.props.heading}
      >
        <Box flex={true}>
          {this._renderPlanDetails()}
        </Box>
        <Box flex={false} justify='between' direction='row' pad='medium'>
          {closeControl}
        </Box>
      </GLBMLayer>
    );
  }
}

CatalogPlanDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
};

export default CatalogPlanDialog;
