// (C) Copyright 2017-2024 Hewlett Packard Enterprise Development LP
import React, { useState } from 'react';
import {
  Box, Button, FormField, Spinner,
} from 'grommet';
import moment from 'moment';
import { useQueryExtractAllCustomerInvoices } from '../../../core';
import DateTime from '../../shared/component/DateTime';

const ExtractAllCustomerInvoices = () => {
  const [date, setDate] = useState(moment().subtract(1, 'months').startOf('month').format('YYYY-MM'));
  const [error, setError] = useState('');

  const onDateChange = (newDate) => {
    const momentDate = moment(newDate, 'YYYY-MM', true);
    let newError = momentDate.isSameOrAfter(moment(), 'month') ? 'Select a month prior to current month and year' : undefined;
    newError = momentDate.isValid() ? newError : 'Invalid month format (YYYY-MM)';
    setDate(newDate);
    setError(newError);
  };

  const { mutate: extract, isPending: showSpinner } = useQueryExtractAllCustomerInvoices(date);

  const onExtract = () => {
    if (!error) {
      extract();
    }
  };

  return (
    <Box border='top' direction='row' pad={{ horizontal: 'small' }} align='center' height={{ min: 'xsmall' }} flex={false}>
      <Box margin={{ right: 'auto' }}>
        <strong>Extract All Billing Account Data</strong>
        <span>Extract customer usage account invoices in CSV format for a given month</span>
      </Box>
      <Box direction='row' align='center' gap='medium'>
        <Box direction='row' align='top' gap='medium' style={{ height: '100px' }}>
          <FormField error={error} label='Invoice Month'>
            <DateTime
              id='extractAllCustomerInvoicesDateTime'
              name='customerExtractDate'
              format='YYYY-MM'
              onChange={onDateChange}
              value={date}
            />
          </FormField>
        </Box>
        <Box flex={false} width='xsmall' align='center'>
          {showSpinner && <Spinner size='small' />}
        </Box>
        <Box direction='row' align='center' flex={true} width={{ min: 'xsmall' }} justify='end'>
          <Button
            label='Extract'
            onClick={onExtract}
            secondary={true}
            disabled={showSpinner}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default ExtractAllCustomerInvoices;
