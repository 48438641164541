// (C) Copyright 2017-2024 Hewlett Packard Enterprise Development LP
import PropTypes from 'prop-types';
import React from 'react';
import { Box } from 'grommet';

const ListItem = ({
  id = '',
  label = '',
  selected = false,
  value,
  children = undefined,
  onSelect = undefined
}) => (
  <Box
    style={{ minHeight: 'initial', cursor: 'pointer' }}
    className={`list-item ${selected ? 'selected' : ''}`}
    onClick={onSelect && (() => onSelect(value))}
    hoverIndicator={true}
    id={id}
    data-testid={id}
  >
    {children || <span>{label}</span>}
  </Box>
);

ListItem.propTypes = {
  children: PropTypes.node,
  id: PropTypes.string,
  label: PropTypes.string,
  onSelect: PropTypes.func,
  selected: PropTypes.bool,
  value: PropTypes.any.isRequired,
};

export default ListItem;
