// (C) Copyright 2017-2024 Hewlett Packard Enterprise Development LP

import React, { Component } from 'react';

import {
  Box,
  Button,
  CheckBox,
  Footer,
  FormField, Heading, TextInput,
} from 'grommet';
import moment from 'moment';
import { getError } from '../../../../shared/util/FormUtil';
import CACheckbox from '../../../../shared/form/CACheckbox';
import DateTime from '../../../../shared/component/DateTime';
import GLBMNameValueList from '../../../../shared/component/GLBMNameValueList';
import GLBMLayer from '../../../../shared/component/GLBMLayer';
import IDUtil from '../../../../shared/util/IDUtil';
import { UserContext } from '../../../../../AppContext';
import { roles } from '../../../../shared/constants/Permissions';
import { getRoleCheckerForUser } from '../../../../shared/hooks/permissions';
import { isInstalledCapacityRequired } from './util';

class MeterCapacitiesEditor extends Component {
  constructor(props, context) {
    super(props, context);

    this._onSubmit = this._onSubmit.bind(this);
    this._onChange = this._onChange.bind(this);
    this._onDateChange = this._onDateChange.bind(this);
    this._onBlur = this._onBlur.bind(this);

    const { isOneOfRoles } = getRoleCheckerForUser(this.context);
    this.state = {
      revision: JSON.parse(JSON.stringify(props.revision)),
      isModified: false,
      errors: {},
      requireInstalledCapacity: isInstalledCapacityRequired(props.serviceType),
      allowDates: isOneOfRoles([roles.SUPER, roles.SERVICE_DEV, roles.ASM, roles.SUPPORT, roles.SPECIALIST]),
      useDates: false,
    };

    if (!this.state.revision.hasOwnProperty('committedCapacityOverride')) {
      this.state.revision.committedCapacityOverride = 0;
    }
    if (!this.state.revision.hasOwnProperty('committedCapacityOverrideEnabled')) {
      this.state.revision.committedCapacityOverrideEnabled = false;
    }

    // partial month revision support:
    if (this.state.revision.effectiveDate && this.state.allowDates) {
      if (moment(this.state.revision.effectiveDate).date() !== 1) {
        this.state.useDates = true;
      }
      // on the way into the editor, adjust the date to align with partial months or not, it will be adjusted back to always include -DD
      // on the way back out:
      this.state.revision.effectiveDate = moment(this.state.revision.effectiveDate).format(this.state.useDates ? 'YYYY-MM-DD' : 'YYYY-MM');
    }
  }

  _getTotalErrors(errors) {
    let totalErrors = 0;
    for (const property in errors) {
      totalErrors += (errors[property].length);
    }
    return totalErrors;
  }

  _onSubmit() {
    const { errors, revision } = { ...this.state };
    let validationErrors = this._getTotalErrors(errors) > 0;

    const { effectiveDate } = { ...this.state.revision };

    if (!effectiveDate) {
      errors.effectiveDate = ['Required'];
      validationErrors = true;
    } else if (this.props.startDates.indexOf(effectiveDate) !== -1) {
      errors.effectiveDate = ['Duplicate'];
      validationErrors = true;
    }

    if (validationErrors) {
      this.setState({ errors });
      return;
    }

    // if the user did not actually change anything, lets just close the dialog down even tho they clicked 'OK'. This way we
    // do not end up with a modified revisions object + show warning banner.
    if (!this.state.isModified) {
      this.props.onClose();
      return;
    }

    // flag this revision as dirty:
    revision.dirty = true;
    if (effectiveDate) {
      revision.effectiveDate = moment(effectiveDate).format('YYYY-MM-DD');
    }
    this.props.onChange(revision);
  }

  _onChange(event) {
    const { errors, revision } = { ...this.state };
    const attribute = event.target.getAttribute('name');
    delete errors[attribute];
    errors[attribute] = [];

    if (event) {
      switch (event.target.type) {
        case 'radio':
          revision[event.target.name.split('_')[0]] = event.target.name.split('_')[1];
          break;
        case 'checkbox':
          revision[event.target.name] = event.target.checked;
          break;
        case 'text':
          revision[event.target.name] = (event.target.name === 'tierType' ? event.value.value : event.target.value);
          break;
        case 'number':
          switch (attribute) {
            case 'maximumInvoiceableCapacity':
              if (event.target.value === '') {
                revision[event.target.name] = undefined;
              } else {
                revision[event.target.name] = parseFloat(event.target.value);
              } break;
            default:
              if (!isNaN(parseFloat(event.target.value))) {
                revision[event.target.name] = parseFloat(event.target.value);
              }
          }
          break;
        default:
          revision[event.target.name] = event.target.value;
          break;
      }

      // make sure we keep this in sync even tho we changed other properties:
      revision.committedCapacity = (revision.requestedCapacity * revision.committedPct / 100).toFixed(2);
    }
    this.setState({
      revision,
      isModified: true,
      errors,
    });
  }

  _onDateChange(date) {
    const { revision, errors } = { ...this.state };
    const attribute = 'effectiveDate';

    delete errors[attribute];
    errors[attribute] = [];
    if (moment(date, this.state.useDates ? 'YYYY-MM-DD' : 'YYYY-MM', true).isValid()) {
      revision.effectiveDate = moment(date).format(this.state.useDates ? 'YYYY-MM-DD' : 'YYYY-MM');
    } else {
      revision.effectiveDate = date;
      errors.effectiveDate = this.state.useDates ? ['Invalid Date. Use format: YYYY-MM-DD'] : ['Invalid Date. Use format: YYYY-MM'];
    }
    if (!revision.effectiveDate) {
      errors.effectiveDate = ['Required'];
    } else if (this.props.startDates.indexOf(date) !== -1) {
      errors.effectiveDate = ['Duplicate'];
    }
    this.setState({
      revision,
      errors,
      isModified: true,
    });
  }

  _renderContextDetails(meter) {
    return (
      <Box margin={{ top: 'none' }}>
        <GLBMNameValueList
          title='Selected Meter'
          data={[
            { label: 'Meter Name', value: meter.name },
          ]}
        />
      </Box>
    );
  }

  _getMinContractualCommit(r) {
    if (!r.hasOwnProperty('minimumContractualCapacity') || r.minimumContractualCapacity === 0 || r.resetMinContractualCapacity) {
      return (r.committedCapacityOverrideEnabled ? (r.committedCapacityOverride || 0) : (r.committedCapacity || 0));
    }
    return (r.minimumContractualCapacity || 0);
  }

  _onBlur() {
    const { errors, revision } = { ...this.state };
    if (revision) {
      if (!revision.effectiveDate) {
        errors.effectiveDate = ['Required'];
      } else {
        const m = moment(revision.effectiveDate, 'YYYY-MM-DD');
        if (!m.isValid()) {
          errors.effectiveDate = ['Invalid Date. Use format: YYYY-MM-DD'];
        } else if (this.props.startDates.indexOf(revision.effectiveDate) !== -1) {
          errors.effectiveDate = ['Duplicate'];
        } else {
          errors.effectiveDate = [];
          revision.effectiveDate = m.format('YYYY-MM-DD');
        }
      }
    }
    this.setState({ revision, errors });
  }

  render() {
    const { meter } = this.props;
    const { revision } = this.state;
    const submitFunction = (this.props.canEdit ? this._onSubmit : this.props.onClose);
    const submitLabel = (this.props.canEdit ? 'OK' : 'Close');
    const title = (this.props.canEdit ? 'Edit capacities' : 'Capacities');

    return (
      <GLBMLayer
        position='right'
        full='vertical'
        closer={true}
        onClose={this.props.onClose}
        onEsc={this.props.onClose}
        onClickOutside={this.props.onClose}
        flush={true}
        title={title}
      >
        <Box
          pad='none'
          direction='column'
          fill='vertical'
          style={{ 'maxWidth': '575px', 'minWidth': '575px' }}
        >
          <Box flex={true} overflow='auto'>
            <Box flex={true} pad={{ 'horizontal': 'medium' }}>
              <Box flex={false} pad={{ top: 'small', bottom: 'large' }}>
                {this._renderContextDetails(meter)}
              </Box>
              <Heading level='3'>Capacity Properties:</Heading>
              <Box flex={false}>
                <FormField
                  key='effectiveDate'
                  htmlFor='effectiveDate'
                  label='Start Date'
                  error={getError(this.state.errors.effectiveDate)}
                >
                  <Box
                    direction='column'
                    gap='small'
                  >
                    <DateTime
                      id={IDUtil.getId('CapacitiesPropertiesStartDateInput')}
                      name='effectiveDate'
                      format={this.state.useDates ? 'YYYY-MM-DD' : 'YYYY-MM'}
                      onChange={this._onDateChange}
                      value={revision.effectiveDate}
                      required={true}
                      onBlur={event => this._onBlur(event)}
                    />
                    {this.state.allowDates
                      && (
                        <Box direction='column' justify='center'>
                          <CACheckbox
                            label='Allow custom date for partial month revision'
                            name='useDates'
                            id={IDUtil.getId('CapacitiesPropertiesAllowCustomChk')}
                            onChange={(event) => {
                              this.setState({ useDates: event.target.checked }, () => {
                                if (!this.state.useDates) {
                                  if (moment(revision.effectiveDate, 'YYYY-MM-DD', true).isValid()) {
                                    const adjustedDate = moment(revision.effectiveDate).date(1).format('YYYY-MM');
                                    this._onDateChange(adjustedDate);
                                  } else {
                                    this._onDateChange(revision.effectiveDate);
                                  }
                                } else if (moment(revision.effectiveDate, 'YYYY-MM', true).isValid()) {
                                  const adjustedDate = moment(revision.effectiveDate).date(1).format('YYYY-MM-DD');
                                  this._onDateChange(adjustedDate);
                                } else {
                                  this._onDateChange(revision.effectiveDate);
                                }
                              });
                            }}
                            checked={this.state.useDates}
                            tooltip='Enter the start date if the cost should be calculated based on the number of days the change is in effect.'
                            tooltipPlace='left'
                          />
                        </Box>
                      )}
                  </Box>
                </FormField>
                {this.state.requireInstalledCapacity
                  && (
                    <FormField
                      key='installedCapacity'
                      htmlFor='installedCapacity'
                      label='Installed Capacity'
                    >
                      <TextInput
                        type='number'
                        plain={true}
                        id={IDUtil.getId('MeterInstalledCapacityInput')}
                        name='installedCapacity'
                        value={revision.installedCapacity}
                        min={0}
                        disabled={!this.props.canEdit}
                        onChange={this._onChange}
                      />
                    </FormField>
                  )}
                <FormField
                  key='requestedCapacity'
                  htmlFor='requestedCapacity'
                  label='Requested Capacity'
                >
                  <TextInput
                    type='number'
                    plain={true}
                    id={IDUtil.getId('MeterRequestedCapacityInput')}
                    name='requestedCapacity'
                    value={revision.requestedCapacity}
                    min={0}
                    disabled={!this.props.canEdit}
                    onChange={this._onChange}
                  />
                </FormField>
                <FormField
                  key='committedPct'
                  htmlFor='committedPct'
                  label='Reserved Percent (%)'
                >
                  <TextInput
                    type='number'
                    plain={true}
                    id={IDUtil.getId('MeterCommittedPctInput')}
                    name='committedPct'
                    value={revision.committedPct}
                    min={0}
                    disabled={!this.props.canEdit}
                    onChange={this._onChange}
                  />
                </FormField>
                <FormField
                  key='committedCapacity'
                  htmlFor='committedCapacity'
                  label='Reserved Capacity'
                >
                  <Box
                    direction='column'
                    gap='small'
                  >
                    {revision.committedCapacityOverrideEnabled
                      ? (
                        <TextInput
                          id={IDUtil.getId('MeterCommittedCapacityInput')}
                          type='number'
                          className='hide-number-arrows'
                          style={{ border: 'none' }}
                          name='committedCapacityOverride'
                          value={revision.committedCapacityOverride}
                          min={0}
                          disabled={!this.props.canEdit}
                          onChange={this._onChange}
                        />
                      )
                      : (
                        <TextInput
                          id={IDUtil.getId('MeterCommittedCapacityInput')}
                          style={{ border: 'none' }}
                          name='committedCapacity'
                          value={(revision.requestedCapacity * revision.committedPct / 100).toFixed(2)}
                          min={0}
                          disabled={true}
                        />
                      )}
                    <CheckBox
                      label='Override'
                      name='committedCapacityOverrideEnabled'
                      reverse={false}
                      onChange={this._onChange}
                      disabled={!this.props.canEdit}
                      checked={revision.committedCapacityOverrideEnabled}
                    />
                  </Box>
                </FormField>

                <FormField
                  key='minContractualCapacity'
                  htmlFor='minContractualCapacity'
                  label='Adjusted Reserved Capacity (read only)'
                >
                  <Box
                    direction='column'
                    gap='small'
                  >
                    <TextInput
                      id={IDUtil.getId('MeterMinContractualCapacityInput')}
                      name='minContractualCapacity'
                      value={this._getMinContractualCommit(revision)}
                      min={0}
                      disabled={true}
                      style={{ border: 'none' }}
                    />
                    <CheckBox
                      label='Reset to Reserved Capacity'
                      name='resetMinContractualCapacity'
                      reverse={false}
                      onChange={this._onChange}
                      disabled={!this.props.canEdit}
                      checked={revision.resetMinContractualCapacity || false}
                    />
                  </Box>
                </FormField>

                <FormField
                  key='maxInvoiceableCap'
                  htmlFor='maximumInvoiceableCapacity'
                  label='Maximum Invoiceable Capacity (optional - only set when usage should be capped)'
                >
                  <TextInput
                    type='number'
                    id={IDUtil.getId('maxInvoiceableCapInput')}
                    name='maximumInvoiceableCapacity'
                    value={revision.maximumInvoiceableCapacity || null}
                    disabled={!this.props.canEdit}
                    onChange={this._onChange}
                  />
                </FormField>

              </Box>
            </Box>
          </Box>
          <Box border='top' pad='small' margin={{ top: 'none' }} flex={false}>
            <Footer flex={false} justify='start' gap='small'>
              {this.props.canEdit ? (
                <Button
                  label={submitLabel}
                  type='button'
                  primary={true}
                  onClick={submitFunction}
                />
              ) : ''}
              <Button
                label='Cancel'
                type='button'
                secondary={true}
                onClick={this.props.onClose}
              />
            </Footer>
          </Box>
        </Box>
      </GLBMLayer>
    );
  }
}
MeterCapacitiesEditor.contextType = UserContext;

export default MeterCapacitiesEditor;
