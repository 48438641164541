// (C) Copyright 2017-2024 Hewlett Packard Enterprise Development LP
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import UserStore from '../stores/UserStore';

const LogoutCallbackPage = () => {
  const navigate = useNavigate();

  useEffect(() => {
    async function logout() {
      UserStore.setLogoutUrl('/');
      navigate('/');
    }
    logout();
  }, []);

  return <div />;
};

export default LogoutCallbackPage;
