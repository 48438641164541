// (C) Copyright 2017-2024 Hewlett Packard Enterprise Development LP

import { useQuery } from '@tanstack/react-query';
import { useContext } from 'react';
import { useErrorToast } from '../../components/shared/hooks/useErrorToast';
import {
  executeGet,
} from '../api';
import { ApiContext } from '../../AppContext';

export const useInvoiceSummaryQuery = (customerId, options) => {
  const path = useContext(ApiContext)('monthlyCharges.invoice.master.summary.read.path', { customerId });
  return useErrorToast(useQuery({
    queryKey: ['GLBM:INVOICE-SUMMARY', customerId],
    queryFn: () => executeGet(path),
    ...(options || []),
  }), 'Fetch Invoice Error');
};

export const useInvoiceQuery = (customerId, period, view, options) => {
  const masterPath = useContext(ApiContext)('monthlyCharges.invoice.master.read.path', { customerId, period, view });
  const partnerPath = useContext(ApiContext)('monthlyCharges.invoice.partner.read.path', { customerId, period, view });
  return useErrorToast(useQuery({
    queryKey: ['GLBM:INVOICE', customerId, period, view],
    queryFn: () => executeGet(view === 'MASTER' ? masterPath : partnerPath),
    ...(options || []),
  }), 'Fetch Invoice Error');
};

export const useInvoiceDetailsQuery = (customerId, period, meterId, parameters, options) => {
  const path = useContext(ApiContext)('monthlyCharges.detailsv2.read.path', { customerId, period, meterId }, parameters);
  return useErrorToast(useQuery({
    queryKey: ['GLBM:INVOICE-DETAILS', customerId, period, meterId, parameters],
    queryFn: () => executeGet(path),
    ...(options || []),
  }), 'Fetch Invoice Details Error');
};
