// (C) Copyright 2017-2024 Hewlett Packard Enterprise Development LP
import { Box, Notification } from 'grommet';
import { StatusCritical } from 'grommet-icons';
import * as React from 'react';
import Loader from '../../shared/loader';
import { useFetchActionsQuery } from '../../../core';
import ActionRow from './ActionRow';

const ActionsList = () => {
  const {
    data: actions,
    error,
    isLoading,
    isError,
  } = useFetchActionsQuery();

  if (isLoading) {
    return (
      <Box direction='column' fill={true} align='center' pad='large'>
        <Box direction='row' flex={false} gap='small'>
          <Loader />
        </Box>
      </Box>
    );
  }

  if (isError) {
    console.error(error);
    return (
      <>
        <Box direction='column' fill={true} align='center' pad='large'>
          <Box direction='row' flex={false} gap='small'>
            <StatusCritical size='medium' />
            Error fetching list of actions
          </Box>
        </Box>
        <Notification toast={true} status='critical' message={error.message} />
      </>
    );
  }

  // Create an ActionRow for each data element
  return (
    <>
      {
        actions.map(action => <ActionRow key={action.name} action={action} />)
      }
    </>
  );
};

export default ActionsList;
