// (C) Copyright 2017-2024 Hewlett Packard Enterprise Development LP
import React from 'react';
import PropTypes from 'prop-types';

const ConsumedCellFormatter = ({ value: valueProp = undefined }) => {
  if (!valueProp) {
    return (<span>-</span>);
  }
  const { value, estimated } = valueProp;
  return (value !== undefined
    ? (
      <div className={estimated ? 'consumed-cell-estimated' : undefined}>
        <span>{(estimated ? '*  ' : '') + value.toLocaleString()}</span>
      </div>
    )
    : <div><span>-</span></div>
  );
};

ConsumedCellFormatter.propTypes = {
  value: PropTypes.shape({
    value: PropTypes.string,
    estimated: PropTypes.bool
  }),
};

export default ConsumedCellFormatter;
