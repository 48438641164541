// (C) Copyright 2017-2024 Hewlett Packard Enterprise Development LP
import { Anchor, Menu, Text } from 'grommet';
import { More } from 'grommet-icons';
import React from 'react';
import Highlight from 'react-highlighter';
import { useNavigate } from 'react-router-dom';
import { pagePermissions } from '../shared/constants/Permissions';
import { usePermissionChecker } from '../shared/hooks';
import { insertIf } from '../shared/util/BasicUtil';

const EMPTY = '--';

export const useColumns = (searchText, countryMap, onRemove) => {
  const navigate = useNavigate();
  const { hasPermissions } = usePermissionChecker();
  const canEditCompanies = hasPermissions(pagePermissions.companies.edit);

  const getActions = ({ id }) => [
    ...insertIf(hasPermissions(pagePermissions.companies.edit), [{
      onClick: () => navigate(`/companies/${id}`),
      label: 'Edit Company',
    }]),
    ...insertIf(hasPermissions(pagePermissions.companies.actions.delete), [{
      onClick: () => onRemove(id),
      label: 'Delete Company',
    }]),
  ];

  return [
    {
      property: 'name',
      header: 'Company Name',
      render: ({ id, name }) => (canEditCompanies ? (
        <Anchor weight='bold' onClick={() => navigate(`/companies/${id}`)}>
          <Text weight='bold'>
            <Highlight search={searchText}>{name}</Highlight>
          </Text>
        </Anchor>
      ) : (
        <Text weight='bold'>
          <Highlight search={searchText}>{name}</Highlight>
        </Text>
      )),
      size: 'small',
      primary: true,
    }, {
      property: 'address1',
      header: 'Address 1',
      render: ({ address1 }) => (<Highlight search={searchText}>{address1}</Highlight>),
      size: 'small',
    }, {
      property: 'address2',
      header: 'Address 2',
      render: ({ address2 }) => (
        <Highlight search={searchText}>{address2 || EMPTY}</Highlight>),
      size: 'small',
    },
    {
      property: 'city',
      header: 'City',
      render: ({ city }) => (<Highlight search={searchText}>{city}</Highlight>),
      size: 'small',
    }, {
      property: 'stateOrProvince',
      header: 'State',
      render: ({ stateOrProvince }) => (<Highlight search={searchText}>{stateOrProvince || EMPTY}</Highlight>),
      size: 'small',
    }, {
      property: 'postalCode',
      header: 'Zip',
      render: ({ postalCode }) => (<Highlight search={searchText}>{postalCode}</Highlight>),
      size: 'small',
    }, {
      property: 'country',
      header: 'Country',
      render: ({ country }) => (<Highlight search={searchText}>{countryMap?.[country] || country }</Highlight>),
      size: 'small',
    },
    {
      property: 'actions',
      header: 'Actions',
      size: '96px',
      align: 'start',
      render: datum => (
        <Menu
          icon={<More />}
          items={getActions(datum)}
        />
      ),
      sortable: false,
    },
  ];
};
