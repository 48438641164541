// (C) Copyright 2017-2024 Hewlett Packard Enterprise Development LP

import { useQuery } from '@tanstack/react-query';
import { useContext } from 'react';
import { useErrorToast } from '../../components/shared/hooks/useErrorToast';
import {
  executeGet,
} from '../api';
import { ApiContext } from '../../AppContext';

export const usePSADataQuery = (projectId, options) => {
  const path = useContext(ApiContext)('psa.read.path', { projectId });
  return useErrorToast(useQuery({
    queryKey: ['GLBM:CUSTOMER-PSA-DATA', projectId],
    queryFn: () => executeGet(path),
    ...(options || []),
  }), 'Fetch Customer PSA Error');
};
