// (C) Copyright 2017-2024 Hewlett Packard Enterprise Development LP

import React from 'react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import PropTypes from 'prop-types';
import GLBMLayer from '../../shared/component/GLBMLayer';
import PromoteDialog from './PromoteDialog';
import { queryConfig } from '../../query/config';

function PromoteLayer(props) {
  const customerName = props.customer.name;
  const customerId = props.customer.id;

  // Create a client
  const queryClient = new QueryClient(queryConfig);

  return (
    <GLBMLayer
      position='right'
      closer={true}
      onClose={props.onClose}
      onEsc={props.onClose}
      onClickOutside={props.onClose}
      overlayClose={true}
      flush={true}
      full='vertical'
      title={`Promote ${customerName} (${customerId})`}
    >
      <QueryClientProvider client={queryClient}>
        <PromoteDialog onClose={props.onClose} customer={props.customer} />
      </QueryClientProvider>
    </GLBMLayer>
  );
}

PromoteLayer.propTypes = {
  onClose: PropTypes.func.isRequired,
};

export default PromoteLayer;
