// (C) Copyright 2017-2024 Hewlett Packard Enterprise Development LP

import { Search } from 'grommet-icons';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import {
  Box,
  Button,
  CheckBox,
  Footer,
  Table,
  TableBody, TableCell, TableHeader, TableRow, Text, TextInput,
} from 'grommet';
import GLBMLayer from '../shared/component/GLBMLayer';
import IDUtil from '../shared/util/IDUtil';
import Loader from '../shared/loader';
import { ListPlaceholder } from '../shared/component/ListPlaceholder';
import Toast from '../shared/component/Toast';
import { renderColumnTableCell } from '../shared/util/tableUtils';

class ASMListEditor extends Component {
  constructor(props) {
    super(props);

    this.onSearchChange = this.onSearchChange.bind(this);
    this._onSubmit = this._onSubmit.bind(this);
    this._onAdminSelected = this._onAdminSelected.bind(this);
    this._noRowsElement = this._noRowsElement.bind(this);
    this._onSortList = this._onSortList.bind(this);
    this._onSelectAll = this._onSelectAll.bind(this);
    this._onChange = this._onChange.bind(this);
    this._sortUsers = this._sortUsers.bind(this);

    this.state = {
      adminList: Array.from(props.adminList),
      searchText: props.searchText,
      users: props.users,
      sortProperties: ['selected', 'firstName', 'lastName', 'email'],
      sortIndex: 1,
      sortAscending: false,
      newUser: {
        firstName: '',
        lastName: '',
        email: '',
        role: 'ASM',
      },
      errors: {},
      saveResponse: undefined,
    };
  }

  onSearchChange(event) {
    const search = event.target.value.toLowerCase();
    this.setState({
      searchText: search,
    });
  }

  _onAdminSelected(adminId, event) {
    const { adminList } = this.state;
    let found = false;
    for (let i = adminList.length - 1; i >= 0; i -= 1) {
      if (adminList[i].id === adminId) {
        found = true;
        if (event.target.checked) {
          adminList[i].roles = ['READ'];
        } else {
          adminList.splice(i, 1);
        }
      }
    }
    if (!found) {
      adminList.push({
        id: adminId,
        roles: ['READ'],
      });
    }
    this.setState({
      adminList,
    });
  }

  _noRowsElement(totalAdmins, filteredAdmins) {
    if (this.state.loadAdmins) {
      return (
        <Box direction='row' align='center' gap='small' justify='center' fill={true}>
          <Loader text='Loading Services. Please wait ...' />
        </Box>
      );
    }
    if (totalAdmins === 0) {
      return (
        <ListPlaceholder
          emptyMessage='You have added all the available ASMs to this Billing Account.'
          unfilteredTotal={0}
          filteredTotal={1}
        />
      );
    } if (filteredAdmins === 0) {
      return (
        <ListPlaceholder
          emptyMessage='Your filter returned zero ASMs, adjust to continue.'
          unfilteredTotal={0}
          filteredTotal={1}
        />
      );
    }
    return '';
  }

  _filterUser(searchText) {
    const results = [];
    const { users } = this.state;
    Object.entries(users).forEach((user) => {
      user.forEach((u) => {
        if (u?.role === 'ASM') {
          results.push(u);
        }
      });
    });
    if (searchText && searchText.length) {
      return results.filter(user => (user.firstName.toLowerCase().indexOf(searchText.toLowerCase())
         >= 0 || user.lastName.toLowerCase().indexOf(searchText.toLowerCase()) >= 0
         || user.email.toLowerCase().indexOf(searchText.toLowerCase()) >= 0));
    }
    return results;
  }

  _onSubmit() {
    this.props.onChange(this.state.adminList);
  }

  _onSortList(index, ascending) {
    if (index >= this.state.sortProperties.length) {
      return;
    }

    this.setState({ sortIndex: index, sortAscending: ascending });
  }

  _sortUsers(users) {
    const index = this.state.sortIndex;
    const ascending = this.state.sortAscending;

    if (index >= this.state.sortProperties.length) {
      return undefined;
    }
    const property = this.state.sortProperties[index];
    let updatedUsers = [];
    switch (property) {
      case 'selected':
        updatedUsers = users.sort((a, b) => {
          if (a.selected === b.selected) {
            return 0;
          } if (a.selected) {
            return -1;
          }
          return 1;
        });
        break;
      default:
        updatedUsers = users.sort((a, b) => {
          if (a[property] === b[property]) {
            return 0;
          } if (a[property] > b[property]) {
            return -1;
          }
          return 1;
        });
        break;
    }
    if (!ascending) {
      updatedUsers.reverse();
    }

    return updatedUsers;
  }

  _onSelectAll(adminIDs, event) {
    event.stopPropagation();

    const { adminList } = this.state;
    for (let i = 0; i < adminIDs.length; i += 1) {
      let found = false;
      for (let j = 0; j < adminList.length; j += 1) {
        if (adminList[j].id === adminIDs[i]) {
          found = true;
        }
      }
      if (!found) {
        adminList.push({
          id: adminIDs[i],
          roles: ['READ'],
        });
      }
    }

    this.setState({
      adminList,
    });
  }

  _isAdminSelected(adminId) {
    let selected = false;
    this.state.adminList.forEach((admin) => {
      if (admin.id === adminId && admin.roles.length) {
        selected = true;
      }
    });
    return selected;
  }

  _onChange(event) {
    const { user } = this.state.newUser;
    const attribute = event.target.getAttribute('name');
    user[attribute] = event.target.value;

    const { errors } = this.state;
    delete errors[attribute];
    errors[attribute] = [];

    // validate email to be valid email as the user types:
    switch (attribute) {
      case 'firstName':
      case 'lastName':
      case 'email':
        if (user[attribute].length === 0) {
          errors[attribute].push('Required');
        }
        break;
      case 'password':
        if (!user[attribute]) { // at least 8 character
          errors[attribute].push('Required');
        } else {
          if (user[attribute].length < 8) { // at least 8 character
            errors[attribute].push('Too short');
          }
          if (user[attribute].length > 20) { // max 20 characters
            errors[attribute].push('Too long');
          }
          if (!user[attribute].match(/^(.*[A-Za-z].*)$/)) { // at least one letter
            errors[attribute].push('No letter');
          }
          if (!user[attribute].match(/^(.*[0-9].*)$/)) { // at least one 0-9 number
            errors[attribute].push('No number');
          }
          // eslint-disable-next-line no-useless-escape
          if (!user[attribute].match(/^(.*[@#\$%\^&?!^\*].*)$/)) { // @#$%&?^!*
            errors[attribute].push('No special character');
          }
        }
        break;
      default:
        break;
    }

    this.setState({ newUser: user, errors });
  }

  _renderToast() {
    let message = '';

    if (this.state.saveResponse) {
      message = (
        <Toast open={this.state.saveResponse} status='critical'>
          {this.state.saveResponse}
        </Toast>
      );
    }
    return message;
  }

  render() {
    const rows = [];
    const { searchText, users } = this.state;
    const filteredUsers = this._sortUsers(this._filterUser(searchText));
    const filteredAdminIDs = [];
    filteredUsers.forEach((admin, index) => {
      filteredAdminIDs.push(admin.id);
      rows.push(
        <TableRow>
          <TableCell>
            <CheckBox
              checked={this._isAdminSelected.bind(this, admin.id)()}
              id={IDUtil.getId('ASMEditorListSelectCheckbox', index)}
              onChange={ev => this._onAdminSelected(admin.id, ev)}
            />
          </TableCell>
          <TableCell><Text truncate='tip'>{users[admin.id].firstName}</Text></TableCell>
          <TableCell><Text truncate='tip'>{users[admin.id].lastName}</Text></TableCell>
          <TableCell><Text truncate='tip'>{users[admin.id].email}</Text></TableCell>
        </TableRow>,
      );
    });

    // if no rows:
    const noRows = this._noRowsElement(this.state.users.length, filteredUsers.length);

    return (
      <GLBMLayer
        position='right'
        full='vertical'
        onClose={this.props.onClose}
        onEsc={this.props.onClose}
        onClickOutside={this.props.onClose}
        closer={true}
        flush={true}
        title='Assign ASMs'
      >
        <Box flex={true} style={{ minWidth: '600px', maxWidth: '900px' }}>
          <Box direction='row' pad='small' flex={false}>
            <TextInput
              inline={true}
              fill={true}
              size='medium'
              placeHolder='Search'
              icon={<Search />}
              onChange={this.onSearchChange}
            />
          </Box>
          <Box flex={true} overflow='auto' pad='small'>
            <Table responsive={false} scrollable={true}>
              <TableHeader>
                <TableRow>
                  {renderColumnTableCell(['Select', 'First Name', 'Last Name', 'Email'])}
                </TableRow>
              </TableHeader>
              <TableBody>
                {rows}
              </TableBody>
            </Table>
            {noRows}
          </Box>
        </Box>
        <Box border='top' pad='small' margin={{ top: 'none' }} flex={false}>
          <Footer flex={false} justify='between'>
            <Box justify='start' pad={{ between: 'small' }} direction='row' gap='small'>
              <Button
                label='Assign Selected'
                id={IDUtil.getId('ASMEditorAssignSelectedButton')}
                primary={true}
                onClick={() => this._onSubmit()}
              />
              <Button label='Cancel' type='button' secondary={true} onClick={this.props.onClose} />
            </Box>
          </Footer>
        </Box>
        {this._renderToast()}
      </GLBMLayer>
    );
  }
}

ASMListEditor.propTypes = {
  onChange: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  adminList: PropTypes.array,
  searchText: PropTypes.string,
  users: PropTypes.object
};

ASMListEditor.defaultProps = {
  adminList: [],
  searchText: undefined,
  users: {}
};

const mapStateToProps = store => ({
  user: store.user,
});

const mapDispatchToProps = dispatch => bindActionCreators({}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ASMListEditor);
