// (C) Copyright 2017-2024 Hewlett Packard Enterprise Development LP
export default {
  getId: (id, index) => {
    if (index !== undefined) {
      return (`hpeFC${id}-${index}`);
    }
    return (`hpeFC${id}`);
  },
  getClass: className => (`hpeFC${className}`),
};
