// (C) Copyright 2017-2024 Hewlett Packard Enterprise Development LP
import React from 'react';
import {
  Box, Button, Meter, Text
} from 'grommet';
import PropTypes from 'prop-types';
import GLBMLayer from '../shared/component/GLBMLayer';

const UsageLoadingLayer = ({ total = undefined, loaded = undefined, onCancel = () => undefined }) => (
  <GLBMLayer
    title='Loading usage files...'
    margin='large'
    flush={true}
  >
    <Box margin={{ horizontal: 'small', bottom: 'small' }} data-testid='usage-loading'>
      <Meter size='medium' color='brand' background='light-2' value={loaded} min={0} max={total} />
      <Text data-testid='usage-loading-text'>
        {loaded}
        {' '}
        of
        {' '}
        {total}
        {' '}
        days loaded
      </Text>
      <Box margin={{ top: 'medium' }} direction='row-reverse'>
        <Button secondary={true} data-testid='cancel-usage-loading' label='Cancel' onClick={onCancel} />
      </Box>
    </Box>
  </GLBMLayer>
);

UsageLoadingLayer.propTypes = {
  loaded: PropTypes.number,
  total: PropTypes.number,
  onCancel: PropTypes.func,
};

export default UsageLoadingLayer;
