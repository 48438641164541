// (C) Copyright 2017-2024 Hewlett Packard Enterprise Development LP

/*
 * action types
 */

import { updateStateAndSession } from 'session/sessionUtil';

export const UPDATE_SERVICE_MAPPING_FILTERS = 'UPDATE_SERVICE_MAPPING_FILTERS';

export function updateFilters(filters) {
  return {
    type: UPDATE_SERVICE_MAPPING_FILTERS,
    filters,
  };
}

export const updateFiltersAndSession = updateStateAndSession(updateFilters);
