// (C) Copyright 2017-2024 Hewlett Packard Enterprise Development LP
import React from 'react';
import {
  Box, Heading, Text,
} from 'grommet';
import PropTypes from 'prop-types';

const GLBMTitleBox = ({
  title = undefined,
  message = undefined,
  children = undefined,
}) => (
  <Box>
    {title && <Heading level='3'>{title}</Heading>}
    <Box
      background='light-2'
      pad='small'
      gap='small'
      height={{ min: 'xsmall' }}
    >
      {message && <Text>{message}</Text>}
      {children}
    </Box>
  </Box>
);

GLBMTitleBox.propTypes = {
  title: PropTypes.string,
  message: PropTypes.string,
  children: PropTypes.node,
};

export default GLBMTitleBox;
