// (C) Copyright 2017-2024 Hewlett Packard Enterprise Development LP
import React from 'react';
import Case from 'case';
import PropTypes from 'prop-types';
import { Box, Text } from 'grommet';

const Badge = ({
  label = undefined,
  value,
}) => (
  <Box
    direction='row'
    gap='small'
    flex={false}
    background='#5F7A76'
    pad={{ horizontal: 'small', vertical: 'none' }}
    margin={{ horizontal: 'xsmall', vertical: 'none' }}
    style={{ borderRadius: '12px' }}
  >
    {label
      && (
      <Text
        weight='normal'
        color='white'
      >
        {Case.capital(label)}
        :
      </Text>
      )}
    <Text
      weight='bold'
      color='white'
    >
      {value}
    </Text>
  </Box>
);

Badge.propTypes = {
  label: PropTypes.string,
  value: PropTypes.string.isRequired,
};

export default Badge;
