// (C) Copyright 2017-2024 Hewlett Packard Enterprise Development LP
import React, { Component } from 'react';
import {
  Box, CheckBox, FormField, Select,
} from 'grommet';

class RaidTypeEdit extends Component {
  render() {
    return (
      <Box direction='row' align='center' flex={false}>
        {this.props.isBulk && (
        <Box margin={{ top: 'medium' }}>
          <CheckBox
            name='raidType'
            checked={this.props.update}
            onChange={event => this.props.onBulkChange(event)}
            data-testid='equipment-bulk-edit-raid-type-checkbox'
          />
        </Box>
        )}
        <FormField
          key='serviceMappingRaidConfig'
          label={this.props?.column?.column?.header || 'Raid Config'}
        >
          <Select
            placeHolder='None'
            name='raidType'
            inline={false}
            multiple={true}
            value={this.props.value}
            options={this.props.config.raidTypes}
            onChange={event => this.props.onChange('raidType', event.option)}
          />
        </FormField>
      </Box>
    );
  }
}

export default RaidTypeEdit;
