// (C) Copyright 2017-2024 Hewlett Packard Enterprise Development LP

import React, { useContext } from 'react';
// @ts-ignore
import { DataContext } from 'grommet';
import Highlight from 'react-highlighter';

const DataHighlighter = ({ children }: React.PropsWithChildren) => {
  const dataCtx = useContext<{ view?: { search: string } }>(DataContext);
  const search = dataCtx?.view?.search || '';
  return (
    <Highlight search={search}>{children}</Highlight>
  );
};

export default DataHighlighter;
