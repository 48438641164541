// (C) Copyright 2017-2024 Hewlett Packard Enterprise Development LP

import {
  Box,
  Button,
  Form,
  FormField,
  Heading,
  Notification,
  Paragraph,
  RadioButton,
  Text,
  TextInput,
} from 'grommet';
import { FormEdit } from 'grommet-icons';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import ReadOnlyField from '../../shared/component/ReadOnlyField';
import { DealType } from '../../shared/constants/DealType';
import { getError, getSelectValue } from '../../shared/util/FormUtil';
import IDUtil from '../../shared/util/IDUtil';

import { OptionsBuilder } from '../../shared/util/OptionsBuilder';
import AutocompleteSelect from '../../shared/component/AutocompleteSelect';
import { usePartnersQuery } from '../../../core';
import Loader from '../../shared/loader';
import PartnerRelationshipView from './PartnerRelationshipView';

const StyledFormField = styled(FormField)`
  div > button {
    border: none;
  }
`;

const PartnerRelationshipForm = ({
  customer,
  view = PartnerRelationshipView.EDIT,
  errors = {},
  onDealTypeChange,
  onPartnerChange,
  onEdit = () => { },
}) => {
  const [partnerOptions, setPartnerOptions] = useState([]);
  const [response, setResponse] = useState(undefined);

  const radioButtonChecked = dealType => customer.dealType === dealType;

  const showReseller = () => [DealType.RESELLER, DealType.DISTRIBUTOR_RESELLER].includes(
    DealType.enumValueOf(customer.dealType),
  );

  const showDistributor = () => [DealType.DISTRIBUTOR_RESELLER].includes(
    DealType.enumValueOf(customer.dealType),
  );

  const {
    data: partners,
    isFetching: loading,
  } = usePartnersQuery();

  useEffect(() => {
    if (partners) {
      setPartnerOptions(new OptionsBuilder(partners).labelKey('name')
        .valueKey('id')
        .build());
    }
  }, [partners]);

  const renderReseller = () => {
    const readOnly = view !== PartnerRelationshipView.EDIT;
    if (showReseller()) {
      const value = getSelectValue(customer.resellerPartnerId, partnerOptions || []);
      const input = (
        <TextInput
          name='resellerPartnerId'
          disabled={true}
          value={value ? value.label : ''}
        />
      );

      const select = (
        <AutocompleteSelect
          name='resellerPartnerId'
          options={partnerOptions}
          size='medium'
          value={value ? value.value : undefined}
          onChange={(event) => {
            onPartnerChange('resellerPartnerId', false, event.option.value);
          }}
          labelKey='label'
          valueKey={{
            key: 'value',
            reduce: true,
          }}
        />
      );

      return (
        <StyledFormField
          label='Reseller'
          error={getError(errors.resellerPartnerId)}
        >
          {readOnly ? input : select}
        </StyledFormField>
      );
    }
    return '';
  };

  const renderDistributor = () => {
    const readOnly = view !== PartnerRelationshipView.EDIT;
    if (showDistributor()) {
      const value = getSelectValue(customer.distributorPartnerId, partnerOptions || []);
      const input = (
        <TextInput
          name='distributorPartnerId'
          disabled={true}
          value={value ? value.label : ''}
        />
      );

      const select = (
        <AutocompleteSelect
          options={partnerOptions}
          name='distributorPartnerId'
          value={value ? value.value : undefined}
          onChange={(event) => {
            onPartnerChange('distributorPartnerId', false, event.option.value);
          }}
          labelKey='label'
          valueKey={{
            key: 'value',
            reduce: true,
          }}
        />
      );

      return (
        <StyledFormField
          label='Distributor'
          error={getError(errors.distributorPartnerId)}
        >
          {readOnly ? input : select}
        </StyledFormField>
      );
    }
    return '';
  };

  const renderError = () => {
    const error = getError(errors.duplicatePartner);
    if (error) {
      return (
        <Box>
          <Text
            weight={100}
            style={{
              color: '#F04953',
              fontSize: '1em',
              fontWeight: '400',
              marginTop: '12px',
            }}
          >
            {error}
          </Text>
        </Box>
      );
    }
    return '';
  };

  const renderDealType = () => {
    const readOnly = view !== PartnerRelationshipView.EDIT;
    const dealTypeEdit = (
      <FormField label='Deal Type'>
        <RadioButton
          id='direct-customer'
          name={DealType.DIRECT.enumKey}
          label={DealType.DIRECT.label}
          checked={radioButtonChecked(DealType.DIRECT.enumKey)}
          onChange={() => onDealTypeChange(DealType.DIRECT.enumKey)}
        />
        <RadioButton
          id='reseller'
          name={DealType.RESELLER.enumKey}
          label={DealType.RESELLER.label}
          checked={radioButtonChecked(DealType.RESELLER.enumKey)}
          onChange={() => onDealTypeChange(DealType.RESELLER.enumKey)}
        />
        <RadioButton
          id='distributor-and-reseller'
          name={DealType.DISTRIBUTOR_RESELLER.enumKey}
          label={DealType.DISTRIBUTOR_RESELLER.label}
          checked={radioButtonChecked(DealType.DISTRIBUTOR_RESELLER.enumKey)}
          onChange={() => onDealTypeChange(DealType.DISTRIBUTOR_RESELLER.enumKey)}
        />
      </FormField>
    );

    const dealTypeValue = customer.dealType ? DealType.enumValueOf(customer.dealType).label : '';
    const dealTypeReadOnly = (
      <ReadOnlyField
        label='Deal Type'
        value={dealTypeValue}
      />
    );

    return readOnly ? dealTypeReadOnly : dealTypeEdit;
  };

  const renderEditAction = () => {
    if (view === PartnerRelationshipView.PROTECTED) {
      return (
        <Button
          id={IDUtil.getId('PartnerRelationshipsEdit')}
          icon={<FormEdit />}
          onClick={onEdit}
        />
      );
    }
    return '';
  };

  const onToastClose = () => {
    setResponse(undefined);
  };

  const renderToast = () => {
    let message = '';

    if (response) {
      message = (
        <Notification
          toast={true}
          status={response.status}
          title={response.title}
          message={response.message}
          onClose={onToastClose}
        />
      );
    }
    return message;
  };

  if (view === PartnerRelationshipView.HIDDEN) {
    return null;
  }

  if (loading) {
    return (
      <Box
        direction='row'
        align='center'
        gap='small'
        justify='center'
        fill={true}
      >
        <Loader text='Loading. Please wait ...' />
      </Box>
    );
  }

  return (
    <Box margin={{ top: 'small' }} flex={false}>
      <Box align='center' flex={false} direction='row'>
        <Heading level='2' pad='none' size='small' weight='bold'>
          Partner
          Relationships
        </Heading>
        {renderEditAction()}
      </Box>
      <Paragraph margin='none'>
        Partners specified here receive usage data for this Billing Account and
        can set downstream rates.
      </Paragraph>
      <Form>
        {renderDealType()}
        {renderReseller()}
        {renderDistributor()}
        {renderError()}
        {renderToast()}
      </Form>
    </Box>
  );
};

PartnerRelationshipForm.propTypes = {
  errors: PropTypes.shape({
    resellerPartnerId: PropTypes.arrayOf(PropTypes.string),
    distributorPartnerId: PropTypes.arrayOf(PropTypes.string),
    duplicatePartner: PropTypes.string,
  }),
  view: PropTypes.object, // PartnerRelationshipView
  onPartnerChange: PropTypes.func.isRequired,
  onDealTypeChange: PropTypes.func.isRequired,
  onEdit: PropTypes.func,
  customer: PropTypes.shape({
    dealType: PropTypes.string,
    resellerPartnerId: PropTypes.string,
    distributorPartnerId: PropTypes.string,
  }).isRequired,
};

export default PartnerRelationshipForm;
