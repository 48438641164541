// (C) Copyright 2017-2024 Hewlett Packard Enterprise Development LP
import { Enumify } from 'enumify';

export class MeterType extends Enumify {
  static Allocation = new MeterType(
    'Allocation',
    'The customer is billed for the duration the resource is allocated.  Use this type of meter to bill for a resource that has been allocated even if it was not used.  That start and end times determine the duration the resource was allocated and the quantity describes the amount allocated. The billable quantity takes both into account. Use this type of meter to bill for things like storage, memory, vcpu’s.',
  );

  static Consumption = new MeterType(
    'Consumption',
    'The customer is billed for the quantity consumed without respect to when the consumption occurred.  Use this type of meter to bill for things like read-requests, write-requests, bandwidth, etc.  The start and end times are used to report when the consumption occurred but are not a factor in determining the quantity to charge for.',
  );

  static _ = this.closeEnum(); // TypeScript: Color.closeEnum()

  constructor(label, tooltip) {
    super();
    this.label = label;
    this.tooltip = tooltip;
  }
}
