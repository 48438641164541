// (C) Copyright 2017-2024 Hewlett Packard Enterprise Development LP
import React, { Component } from 'react';

import {
  Box, Button, CheckBox, FormField, Header, Select, Text,
  TextInput,
} from 'grommet';
import { Trash } from 'grommet-icons';
import { v1 as UUID } from 'uuid';

const operatorOptions = [
  { label: 'Is', value: 'equals' },
  { label: 'Is not', value: 'doesNotEqual' },
  { label: 'Is empty', value: 'empty' },
  { label: 'Is not empty', value: 'notEmpty' },
  { label: 'Starts with', value: 'startsWith' },
  { label: 'Ends with', value: 'endsWith' },
  { label: 'Contains', value: 'contains' },
  { label: 'Does not contain', value: 'doesNotContain' },
  { label: 'Wildcard', value: 'wildcard' },
  { label: 'Matches regex', value: 'regex' },
  { label: 'Greater than', value: 'greaterThan' },
  { label: 'Less than', value: 'lessThan' }];

class CustomTiersCriteria extends Component {
  constructor(props) {
    super(props);

    this.state = {
      service: props.service,
      model: props.model,
      fields: this._convertFields(props.fields),
    };

    if (!props.model.id || props.isCopy) {
      this.state.model.id = UUID();
    }

    this._getRules = this._getRules.bind(this);
    this._getGroup = this._getGroup.bind(this);
    this._onDeleteRule = this._onDeleteRule.bind(this);
  }

  componentWillReceiveProps(props) {
    this.setState({ fields: this._convertFields(props.fields) });
  }

  _convertFields(fields) {
    const options = [];
    if (fields && fields.length) {
      for (let i = 0; i < fields.length; i += 1) {
        options.push({ value: fields[i].id, label: fields[i].name });
      }
    }
    return options;
  }

  _onDeleteRule(rule, index) {
    rule.criteria.splice(index, 1);
    this.props.onModelChange(this.state.model);
    this.forceUpdate();
  }

  _onDeleteGroup(parent, index) {
    parent.groups.splice(index, 1);
    this.props.onModelChange(this.state.model);
    this.forceUpdate();
  }

  _onAddRule(group) {
    group.criteria = group.criteria || [];
    group.criteria.push({
      field: this.state.fields[0].value, operator: operatorOptions[0].value, value: '0', caseInsensitive: false,
    });
    this.props.onModelChange(this.state.model);
    this.forceUpdate();
  }

  _onAddGroup(group) {
    group.groups = group.groups || [];
    group.groups.push({
      matchAll: false,
      criteria: [{
        field: this.state.fields[0].value, operator: operatorOptions[0].value, value: '0', caseInsensitive: false,
      }],
    });
    this.props.onModelChange(this.state.model);
    this.forceUpdate();
  }

  _onMatchChange(group) {
    group.matchAll = !group.matchAll;
    this.props.onModelChange(this.state.model);
    this.forceUpdate();
  }

  _onFieldChange(rule, event) {
    rule.field = event.value.value;
    this.props.onModelChange(this.state.model);
    this.forceUpdate();
  }

  _onOperatorChange(rule, event) {
    rule.operator = event.value.value;
    this.props.onModelChange(this.state.model);
    this.forceUpdate();
  }

  _onCaseInsensitiveChange(rule, event) {
    rule.caseInsensitive = !rule.caseInsensitive;
    this.props.onModelChange(this.state.model);
    this.forceUpdate();
  }

  _onValueChange(rule, event) {
    rule.value = event.target.value;
    this.props.onModelChange(this.state.model);
    this.forceUpdate();
  }

  _getSelectValue(rule, options, property) {
    // hack for change of sys_ProviderType_s to sys_InstanceType_s:
    if (rule.field === 'sys_ProviderType_s') {
      console.warn('CustomTiersEditor updating rule.field from sys_ProviderType_s to sys_InstanceType_s');
      rule.field = 'sys_InstanceType_s';
    }

    let result = null;
    options.forEach((option) => {
      if (rule[property] === option.value) {
        result = option;
      }
    });
    return result || options[0];
  }

  _getRules() {
    const results = [];
    const { model } = this.state;

    model.criteria.forEach((criteria) => {
      results.push(this._getGroup(criteria));
    });

    return results;
  }

  _isDisabled(group, index) {
    if (group.criteria.length > 1) {
      return () => { this._onDeleteRule(group, index); };
    }
  }

  _isHidden(rule) {
    if (['empty', 'notEmpty'].indexOf(rule.operator) >= 0) {
      rule.value = '0';
      return true;
    }
    return false;
  }

  _getValueError(rule) {
    if (rule && rule.value.length === 0) {
      return 'a value is required';
    }
  }

  _showValue(rule) {
    if (this._isHidden(rule)) {
      return (<Box flex={true} pad='small' />);
    }
    const results = [];
    results.push(<Box flex={true} pad='small'>
      <FormField error={this._getValueError(rule)}>
        <TextInput name='value' value={rule.value} onChange={this._onValueChange.bind(this, rule)} />
      </FormField>
    </Box>);
    results.push(
      <Box flex={false} pad='small'>
        <CheckBox label='Case insensitive' name='caseInsensitive' checked={rule.caseInsensitive} onChange={this._onCaseInsensitiveChange.bind(this, rule)} />
      </Box>,
    );
    return results;
  }

  _getGroup(group, parent, isGroup, parentIndex) {
    const results = [];
    if (group) {
      results.push(
        <Box direction='row' align='baseline' colorIndex={(isGroup) ? 'light-2' : 'light-1'} pad='small' key='group'>
          <Box flex={false} direction='row' align='center' justify='center' className='group-match-control'>
            <span>Any</span>
            <CheckBox label='All' toggle={true} checked={group.matchAll} onChange={this._onMatchChange.bind(this, group)} />
          </Box>
          <Box flex={true} />
          <Box flex={false} direction='row' gap='small'>
            <Button secondary={true} label='Add Rule' onClick={this._onAddRule.bind(this, group)} />
            {(!isGroup) ? <Button secondary={true} label='Add Group' onClick={this._onAddGroup.bind(this, group)} /> : ''}
            {(isGroup) ? <Button secondary={true} label='Delete' critical={true} icon={<Trash />} onClick={() => { this._onDeleteGroup(parent, parentIndex); }} /> : ''}
          </Box>
        </Box>,
      );
      group.criteria.forEach((rule, index) => {
        results.push((
          <Box direction='row' align='center' fill='horizontal' colorIndex={(isGroup) ? 'light-2' : 'light-1'} pad='small' className='tree-branch' key={`group-${index}`}>
            <Box flex={true} pad='small'>
              <Select
                placeHolder='Field'
                options={this.state.fields}
                value={rule?.field}
                onChange={event => this._onFieldChange(rule, event)}
                labelKey='label'
                valueKey={{ key: 'value', reduce: false }}
              />
            </Box>
            <Box flex={true} pad='small'>
              <Select
                placeHolder='Operator'
                options={operatorOptions}
                value={rule?.operator}
                onChange={event => this._onOperatorChange(rule, event)}
                labelKey='label'
                valueKey={{ key: 'value', reduce: false }}
              />
            </Box>
            {this._showValue.bind(this, rule)()}
            <Box margin='small' flex={false}>
              <Button
                critical={true}
                label='Delete'
                icon={<Trash />}
                disabled={group.criteria.length === 1}
                onClick={this._isDisabled(group, index)}
              />
            </Box>
          </Box>
        ));
      });
      if (group.groups) {
        group.groups.forEach((subGroup, index) => {
          results.push((
            <Box className='custom-tiers-editor-rule tree-branch'>
              {this._getGroup(subGroup, group, true, index)}
            </Box>
          ));
        });
      }
    }
    return results;
  }

  render() {
    return (
      <Box className='custom-tiers-editor' flex={false}>
        <Header>
          <Text size='large' weight='bold'>Tier rules</Text>
        </Header>
        {this._getRules()}
      </Box>
    );
  }
}

export default CustomTiersCriteria;
