// (C) Copyright 2017-2024 Hewlett Packard Enterprise Development LP
import { FormAdd } from 'grommet-icons';
import React, { useEffect, useMemo, useState } from 'react';
import {
  useLocation,
  useNavigate,
  useParams,
} from 'react-router-dom';
import {
  Box, Button, CheckBox, FormField, Main, Select, Spinner, Text, TextInput,
} from 'grommet';
import { v4 as uuidv4 } from 'uuid';
import GLBMHeading from '../../shared/component/GLBMHeading';
import CustomerSelector from '../../shared/component/CustomerSelector';
import Loader from '../../shared/loader';
import ServiceTypeStore from '../../stores/ServiceTypeStore';
import TagsInput from './TagsInput';
import AttributesInput from './AttributesInput';
import MetersInput from './MetersInput';
import {
  useMockDataByIdQuery, useMutationCreateMock, useMutationUpdateMock
} from '../../../core';

const DemoDataGeneration = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const locationId = location?.state?.id;
  const { id: paramsId } = useParams();

  const id = useMemo(() => {
    if (paramsId && paramsId !== 'new') return paramsId;
    if (locationId) return locationId;
    return undefined;
  }, [paramsId, locationId]);

  const [data, setData] = useState({});
  const [errors, setErrors] = useState({});

  const { data: initialData, isFetching: getDataLoading } = useMockDataByIdQuery(id, {
    enabled: !!id,
  });

  const {
    mutate: createMockData, isLoading: createMockDataLoading,
  } = useMutationCreateMock();
  const {
    mutate: updateMockData, isLoading: updateMockDataLoading,
  } = useMutationUpdateMock(paramsId);

  useEffect(() => {
    if (initialData) {
      const newData = {
        ...initialData,
        id: paramsId && paramsId !== 'new' ? initialData.id : undefined,
        name: paramsId === 'new' && locationId ? `Copy of ${initialData.name}` : initialData.name,
        serviceInstanceId: paramsId === 'new' && locationId ? undefined : initialData.serviceInstanceId,
      };
      setData(newData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialData]);

  const validation = () => {
    const newErrors = {};
    if (!data.name) {
      newErrors.name = 'Required';
    }
    if (!data.accountId) {
      newErrors.accountId = 'Required';
    }
    if (!data.serviceInstanceId) {
      newErrors.serviceInstanceId = 'Required';
    }
    if (data.feedId) {
      const v4 = /^[0-9A-Z-]+$/i;
      if (!v4.test(data.feedId)) {
        newErrors.feedId = 'Feed id is invalid, must be only letters, digits or dashes';
      }
    } else {
      newErrors.feedId = 'Required';
    }
    if (!data.fileNamePrefix) {
      newErrors.fileNamePrefix = 'Required';
    }
    if (!data.resourceCountMin) {
      newErrors.resourceCountMin = 'Required';
    }
    if (!data.resourceCountMax) {
      newErrors.resourceCountMax = 'Required';
    }
    if (data.resourceCountMin && data.resourceCountMax && data.resourceCountMin > data.resourceCountMax) {
      newErrors.resourceCountMax = 'Max value cannot be less then Min';
    }
    if (!data.resourceNamePrefix) {
      newErrors.resourceNamePrefix = 'Required';
    }
    if (!data.resourceType) {
      newErrors.resourceType = 'Required';
    }
    if (!data.meters?.length) {
      newErrors.meters = 'Required';
    }
    ['tags', 'attributes', 'meters'].forEach((property) => {
      if (data[property]) {
        const tagsErrors = new Array(data[property].length);
        for (let i = 0; i < data[property].length; i += 1) {
          if (!data[property][i].key) {
            if (!tagsErrors[i]) tagsErrors[i] = {};
            tagsErrors[i].key = 'Key is required';
            newErrors[property] = tagsErrors;
          }
          if (data[property][i].key && !data[property][i].values?.length) {
            if (!tagsErrors[i]) tagsErrors[i] = {};
            tagsErrors[i].key = 'Values are required';
            newErrors[property] = tagsErrors;
          }
          if (data[property][i].key && data[property][i].values) {
            const valuesErrors = new Array(data[property][i].values.length);
            for (let y = 0; y < data[property][i].values.length; y += 1) {
              if (!data[property][i].values[y].value) {
                if (!tagsErrors[i]) tagsErrors[i] = {};
                if (!valuesErrors[y]) valuesErrors[y] = {};
                valuesErrors[y].value = 'Required';
                tagsErrors[i].values = valuesErrors;
                newErrors[property] = tagsErrors;
              }
              if (data[property][i].values[y].value && property === 'tags') {
                const v4 = /^[0-9A-Z-_]{2,48}$/i;
                if (!v4.test(data[property][i].values[y].value)) {
                  if (!tagsErrors[i]) tagsErrors[i] = {};
                  if (!valuesErrors[y]) valuesErrors[y] = {};
                  valuesErrors[y].value = 'Must be 2 to 48 digits, characters, dashes and underscores';
                  tagsErrors[i].values = valuesErrors;
                  newErrors[property] = tagsErrors;
                }
              }
              if (!data[property][i].values[y].weight) {
                if (!tagsErrors[i]) tagsErrors[i] = {};
                if (!valuesErrors[y]) valuesErrors[y] = {};
                valuesErrors[y].weight = 'Required';
                tagsErrors[i].values = valuesErrors;
                newErrors[property] = tagsErrors;
              }
            }
          }
        }
      }
    });
    setErrors(newErrors);
    if (!Object.keys(newErrors).length) {
      setErrors({});
      if (paramsId && paramsId !== 'new') {
        updateMockData(data);
      } else {
        createMockData({ ...data, id: undefined });
      }
    }
  };

  // return all services that have standardUsageFileFormat set to true:
  const serviceTypesOptions = ServiceTypeStore.getServices().filter(el => el.standardUsageFileFormat === true);

  return (
    <Main direction='column' fill='vertical' overflow='hidden'>
      <GLBMHeading
        back={() => navigate('/administration', { state: { tab: 'mockData' } })}
        title='Mock Data Generation'
      />
      <Box flex={true} pad={{ horizontal: 'medium', bottom: 'medium' }} overflow='auto' gap='small'>
        {getDataLoading
          ? (
            <Box direction='row' align='center' gap='small' justify='center' fill={true}>
              <Loader text='Loading. Please wait ...' />
            </Box>
          )
          : (
            <Box width='490px' flex={false}>
              <FormField
                label='Name'
                error={errors.name}
                data-testid='name-container'
                id='name-container'
              >
                <TextInput
                  value={data.name || ''}
                  id='name'
                  onChange={e => setData(prev => ({ ...prev, name: e.target.value }))}
                />
              </FormField>
              <Box margin={{ vertical: 'small' }}>
                <CheckBox
                  label='Active'
                  checked={data.active}
                  onChange={e => setData(prev => ({ ...prev, active: e.target.checked }))}
                />
              </Box>
              <FormField
                label='Billing Account'
                error={errors.accountId}
                data-testid='customer-container'
                id='customer-container'
              >
                <CustomerSelector
                  initialSelection={initialData?.accountId}
                  excludedPurposes={['PRODUCTION', 'UNKNOWN']}
                  style={{ minWidth: '270px' }}
                  onCustomerSelected={value => setData(prev => ({ ...prev, accountId: value }))}
                  allowNoSelection={true}
                  plain={true}
                />
              </FormField>
              <FormField
                label='Service Instance Id'
                error={errors.serviceInstanceId}
                data-testid='serviceInstanceId-container'
                id='serviceInstanceId-container'
              >
                <Box direction='row'>
                  <TextInput
                    style={{ border: 'none' }}
                    value={data.serviceInstanceId || ''}
                    id='serviceInstanceId'
                    onChange={e => setData(prev => ({ ...prev, serviceInstanceId: e.target.value }))}
                    reverse={true}
                  />
                  <Box direction='column' justify='center' pad={{ end: 'small' }}>
                    <Button
                      label='UUIDv4'
                      plain={true}
                      icon={<FormAdd />}
                      onClick={() => {
                        setData(prev => ({
                          ...prev,
                          serviceInstanceId: uuidv4(),
                        }));
                      }}
                    />
                  </Box>
                </Box>

              </FormField>
              <FormField
                label='Feed Id'
                error={errors.feedId}
                data-testid='feedId-container'
                id='feedId-container'
              >
                <TextInput
                  value={data.feedId}
                  id='feedId'
                  data-tesid='feedId'
                  onChange={e => setData(prev => ({ ...prev, feedId: e.target.value }))}
                />
              </FormField>
              <FormField
                label='File Name Prefix'
                error={errors.fileNamePrefix}
                data-testid='fileNamePrefix-container'
                id='fileNamePrefix-container'
              >
                <Select
                  options={serviceTypesOptions}
                  id='fileNamePrefix'
                  value={data.fileNamePrefix}
                  onChange={event => setData(prev => ({ ...prev, fileNamePrefix: event.option.label }))}
                  labelKey='label'
                  valueKey={{ key: 'label', reduce: true }}
                />
              </FormField>
              <FormField
                label='Resource Count Min'
                error={errors.resourceCountMin}
                data-testid='resourceCountMin-container'
                id='resourceCountMin-container'
              >
                <TextInput
                  value={data.resourceCountMin}
                  id='resourceCountMin'
                  data-testid='resourceCountMin'
                  type='number'
                  min={1}
                  max={1000}
                  onChange={e => setData(prev => ({ ...prev, resourceCountMin: +e.target.value }))}
                />
              </FormField>
              <FormField
                label='Resource Count Max'
                error={errors.resourceCountMax}
                data-testid='resourceCountMax-container'
                id='resourceCountMax-container'
              >
                <TextInput
                  value={data.resourceCountMax}
                  type='number'
                  min={1}
                  max={1000}
                  id='resourceCountMax'
                  data-testid='resourceCountMax'
                  onChange={e => setData(prev => ({ ...prev, resourceCountMax: +e.target.value }))}
                />
              </FormField>
              <FormField
                label='Resource Name Prefix'
                error={errors.resourceNamePrefix}
                data-testid='resourceNamePrefix-container'
                id='resourceNamePrefix-container'
              >
                <TextInput
                  value={data.resourceNamePrefix}
                  id='resourceNamePrefix'
                  onChange={e => setData(prev => ({ ...prev, resourceNamePrefix: e.target.value }))}
                />
              </FormField>
              <FormField
                label='Resource Type'
                error={errors.resourceType}
                data-testid='resourceType-container'
                id='resourceType-container'
              >
                <TextInput
                  value={data.resourceType}
                  id='resourceType'
                  onChange={e => setData(prev => ({ ...prev, resourceType: e.target.value }))}
                />
              </FormField>
              <Box
                data-testid='tags-container'
                id='tags-container'
              >
                <TagsInput tags={data.tags} setTags={value => setData(prev => ({ ...prev, tags: value }))} errors={errors.tags} />
              </Box>
              <Box
                data-testid='attributes-container'
                id='attributes-container'
              >
                <AttributesInput attributes={data.attributes} setAttributes={value => setData(prev => ({ ...prev, attributes: value }))} errors={errors.attributes} />
              </Box>
              <Box
                data-testid='meters-container'
                id='meters-container'
              >
                <MetersInput
                  meters={data.meters}
                  setMeters={value => setData(prev => ({ ...prev, meters: value }))}
                  errors={errors.meters}
                  serviceType={data.fileNamePrefix}
                />
              </Box>
            </Box>
          )}
      </Box>
      <Box direction='row' gap='small' pad={{ horizontal: 'small', vertical: 'small' }} border='top' height={{ min: '60px' }}>
        <Button
          label='Save'
          id='DemoDataGenerateButton'
          data-testid='DemoDataGenerateButton'
          type='button'
          primary={true}
          disabled={createMockDataLoading || updateMockDataLoading || getDataLoading}
          onClick={() => validation()}
        />
        <Button
          label='Cancel'
          id='DemoDataCancelButton'
          type='button'
          primary={false}
          secondary={true}
          onClick={() => navigate('/administration', { state: { tab: 'mockData' } })}
        />
        {(createMockDataLoading || updateMockDataLoading)
          && (
            <Box direction='row' align='center' gap='small' justify='start' fill={true} margin='none' pad='none'>
              <Spinner />
              <Text>Generating. Please wait ...</Text>
            </Box>
          )}
      </Box>
    </Main>
  );
};

export default DemoDataGeneration;
