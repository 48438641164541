// (C) Copyright 2017-2024 Hewlett Packard Enterprise Development LP

import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import moment from 'moment';
import { useContext } from 'react';
import URLUtil from '../../components/shared/util/URLUtil';
import { useToast } from '../../components/shared/toast/ToastProvider';
import {
  useErrorToast,
  useOnSuccessDownload,
} from '../../components/shared/hooks';
import {
  executeDelete,
  executeExportCSV,
  executeGet,
  executePost,
} from '../api';
import { ApiContext } from '../../AppContext';

// Extract
export const useQueryExtractAllCustomerInvoices = (invoiceDate) => {
  const formattedDate = moment(invoiceDate, 'YYYY-MM').format('YYYYMM');
  const path = useContext(ApiContext)('customers.export.allInvoices.path', { formattedDate });
  const onSuccess = useOnSuccessDownload();
  return useMutation({
    mutationFn: () => executeExportCSV(path),
    onSuccess,
  });
};

export const usePSADataViewsQuery = () => {
  const path = useContext(ApiContext)('customers.export.billingPSADataViews.path');
  return useQuery({
    queryKey: ['GLBM:SERVICE_DATA_PSA_VIEW_VALUES'],
    queryFn: () => executeGet(path),
  });
};

export const useQueryExtractBillingPSAData = (invoiceDate, viewType) => {
  const formattedDate = moment(invoiceDate, 'YYYY-MM').format('YYYYMM');
  const path = useContext(ApiContext)('customers.export.billingPSAData.v2.path', { formattedDate, viewType });
  const onSuccess = useOnSuccessDownload();
  return useMutation({
    mutationFn: () => executeExportCSV(path),
    onSuccess,
  });
};

export const useQueryExtractAssignedUsersList = () => {
  const path = useContext(ApiContext)('customers.export.assignedUsers.path');
  const onSuccess = useOnSuccessDownload();
  return useMutation({
    mutationFn: () => executeExportCSV(path),
    onSuccess,
  });
};

export const useQueryExtractBillingAccountsDetails = () => {
  const path = useContext(ApiContext)('customers.export.billingAccountsDetails.path');
  const onSuccess = useOnSuccessDownload();
  return useMutation({
    mutationFn: () => executeExportCSV(path),
    onSuccess,
  });
};

export const useQueryExtractCustomerASMDetails = () => {
  const path = useContext(ApiContext)('customers.export.customerASMDetails.path');
  const onSuccess = useOnSuccessDownload();
  return useMutation({
    mutationFn: () => executeExportCSV(path),
    onSuccess,
  });
};

export const useQueryExtractCustomerRateCards = (billingIDs) => {
  const queryParams = URLUtil.objectToQueryParams({
    id: billingIDs,
  });
  const path = useContext(ApiContext)('customers.export.customerRateCards.path', { queryParams });
  const onSuccess = useOnSuccessDownload();
  return useMutation({
    mutationFn: () => executeExportCSV(path),
    onSuccess,
  });
};

export const useQueryExtractISVServiceData = (serviceType, billingIDs, reportType, date) => {
  const formattedDate = moment(date, 'YYYY-MM').format('YYYYMM');
  const queryParams = URLUtil.objectToQueryParams({
    month: formattedDate,
    id: billingIDs,
    serviceMetric: serviceType,
    reportType,
  });
  const path = useContext(ApiContext)('customers.export.ISVServiceData.path', { queryParams });
  const onSuccess = useOnSuccessDownload();
  return useMutation({
    mutationFn: () => executeExportCSV(path),
    onSuccess,
  });
};

export const useMetricValuesQuery = () => {
  const path = useContext(ApiContext)('customers.export.metricValuesQuery.path');
  return useQuery({
    queryKey: ['GLBM:SERVICE_DATA_METRIC_VALUES'],
    queryFn: () => executeGet(path),
  });
};

export const useQueryExtractRestrictedPartiesList = () => {
  const path = useContext(ApiContext)('customers.export.restrictedPartiesList.path');
  const onSuccess = useOnSuccessDownload();
  return useMutation({
    mutationFn: () => executeExportCSV(path),
    onSuccess,
  });
};

export const useExtractDigitalMonthlyBusinessReviewPeriodsQuery = () => {
  const path = useContext(ApiContext)('customers.export.allCapacityPlanningPeriods.path');
  return useQuery({
    queryKey: ['GLBM:DIGITAL_MONTHLY_BUSINESS_REVIEW_PERIOD_VALUES'],
    queryFn: () => executeGet(path),
  });
};

export const useQueryExtractDigitalMonthlyBusinessReviewData = () => {
  const apiContext = useContext(ApiContext);
  const onSuccess = useOnSuccessDownload();
  return useMutation({
    mutationFn: (period) => {
      const path = apiContext('customers.export.allCapacityPlanning.path', { period });
      return executeExportCSV(path);
    },
    onSuccess,
  });
};

export const useQueryExtractServiceDefinitionList = () => {
  const path = useContext(ApiContext)('customers.export.serviceDefinitionList.path');
  const onSuccess = useOnSuccessDownload();
  return useMutation({
    mutationFn: () => executeExportCSV(path),
    onSuccess
  });
};

export const useQueryExtractServiceDetails = () => {
  const path = useContext(ApiContext)('customers.export.serviceDetails.path');
  const onSuccess = useOnSuccessDownload();
  return useMutation({
    mutationFn: () => executeExportCSV(path),
    onSuccess,
  });
};

// Pending Files
export const useGetPendingFilesQuery = (options) => {
  const path = useContext(ApiContext)('administration.pendingFiles.list.path');
  return useErrorToast(useQuery({
    queryKey: ['GLBM:PENDING-FILES'],
    queryFn: () => executeGet(path),
    ...(options || {}),
  }), 'Fetch Pending Files Error');
};

export const useGetSchedulePendingFilesQuery = (options) => {
  const path = useContext(ApiContext)('administration.pendingFiles.schedule.path');
  return useErrorToast(useQuery({
    queryKey: ['GLBM:GET-SCHEDULE'],
    queryFn: () => executeGet(path),
    ...(options || {}),
  }), 'Fetch Schedule Error');
};

export const useSyncScheduleMutation = (options) => {
  const queryClient = useQueryClient();
  const path = useContext(ApiContext)('administration.pendingFiles.sync.path');
  const { addToast } = useToast();
  return useMutation({
    mutationFn: payload => executePost(path, payload),
    ...(options || {}),
    onSuccess: () => {
      queryClient.removeQueries({
        queryKey: ['GLBM:GET-SCHEDULE']
      });
      queryClient.removeQueries({
        queryKey: ['GLBM:PENDING-FILES']
      });
      addToast({
        status: 'normal',
        title: 'Updated',
        message: 'Pending Files List Successfully Updated',
      });
      options?.onSuccess?.();
    },
    onError: (error) => {
      addToast({
        status: 'critical',
        title: 'Pending Files were not updated',
        message: error.response.data.message,
      });
    },
  });
};

export const useDeletePendingFileMutation = (options) => {
  const queryClient = useQueryClient();
  const apiContext = useContext(ApiContext);
  const { addToast } = useToast();
  return useMutation({
    mutationFn: fileName => executeDelete(apiContext('administration.pendingFiles.delete.path', { filename: fileName })),
    ...(options || {}),
    onSuccess: () => {
      queryClient.removeQueries({
        queryKey: ['GLBM:GET-SCHEDULE']
      });
      queryClient.removeQueries({
        queryKey: ['GLBM:PENDING-FILES']
      });
      addToast({
        status: 'normal',
        title: 'Deleted',
        message: 'Pending File Successfully Deleted',
      });
      options?.onSuccess?.();
    },
    onError: (error) => {
      addToast({
        status: 'critical',
        title: 'Delete Pending File Error',
        message: error?.response?.data?.message,
      });
    },
  });
};

// Processed Files Data
export const useProcessedFilesDataQuery = (customer, date, stage, serviceProps, context, options) => {
  let service = serviceProps;
  if (service && service === 'THREEPAR') {
    service = '3PAR';
  }
  const queryParams = URLUtil.objectToQueryParams({
    date, stage, service, context,
  });
  const path = useContext(ApiContext)('administration.processedFiles.path', { customer, queryParams });
  return useErrorToast(useQuery({
    queryKey: ['GLBM:PROCESSED-FILES', customer, queryParams],
    queryFn: () => executeGet(path),
    enabled: !!(customer && date && stage),
    ...(options || {}),
  }), 'Fetch Processed Files Error');
};
