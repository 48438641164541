// (C) Copyright 2017-2024 Hewlett Packard Enterprise Development LP
import { Box } from 'grommet';
import React from 'react';
import GLBMDataTable from '../shared/component/GLBMDataTable';
import { AccountKey } from './types';
import { useColumns } from './utils';

interface EncryptionKeyTableProps {
  keys?: AccountKey[];
  loading?: boolean;
  onEditAccountKey: (AccountKey) => any;
  onDeleteAccountKey: (AccountKey) => any;
  onActivateAccountKey: (AccountKey) => any;
}

const EncryptionKeyTable: React.FC<EncryptionKeyTableProps> = (
  {
    keys = [],
    loading = false,
    onEditAccountKey,
    onDeleteAccountKey,
    onActivateAccountKey,
  },
) => {
  const columns = useColumns(onEditAccountKey, onDeleteAccountKey, onActivateAccountKey);
  return (
    <Box pad={{ horizontal: 'small' }}>
      <GLBMDataTable
        data={keys}
        columns={columns}
        primaryKey='keyId'
        loading={loading}
      />
    </Box>
  );
};

export default EncryptionKeyTable;
