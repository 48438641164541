// (C) Copyright 2017-2024 Hewlett Packard Enterprise Development LP

import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useContext } from 'react';
import { useToast } from '../../components/shared/toast/ToastProvider';
import { useErrorToast } from '../../components/shared/hooks/useErrorToast';
import {
  executeGet,
  executePost,
} from '../api';
import { ApiContext } from '../../AppContext';

export const useFixedChargesQuery = (customerId, tenant, options) => {
  const masterPath = useContext(ApiContext)('services.rates.path', { customerId, serviceType: 'NONMETERED' });
  const partnerPath = useContext(ApiContext)('services.partnerRates.path', { customerId, serviceType: 'NONMETERED', tenant });
  return useErrorToast(useQuery({
    queryKey: ['GLBM:SERVICE-TYPE-RATES', customerId, 'NONMETERED', tenant],
    queryFn: () => executeGet(tenant === 'MASTER' ? masterPath : partnerPath),
    ...(options || []),
  }), 'Fetch Fixed Charges Error');
};

export const useFixedChargesUpdateMutate = (customerId, tenant, options) => {
  const masterPath = useContext(ApiContext)('services.fixed-charges.rates.update.path', { customerId, serviceType: 'NONMETERED' });
  const partnerPath = useContext(ApiContext)('services.partnerRates.path', { customerId, serviceType: 'NONMETERED', tenant });
  const queryClient = useQueryClient();
  const { addToast } = useToast();
  return useMutation({
    mutationFn: payload => executePost(tenant === 'MASTER' ? masterPath : partnerPath, payload),
    ...(options || {}),
    onSuccess: () => {
      queryClient.removeQueries({
        queryKey: ['GLBM:CUSTOMERS-LIST']
      });
      queryClient.removeQueries({
        queryKey: ['GLBM:CUSTOMER', customerId]
      });
      queryClient.removeQueries({
        queryKey: ['GLBM:SERVICE-TYPE-RATES', customerId, 'NONMETERED', tenant]
      });
      options?.onSuccess?.();
    },
    onError: ((err) => {
      addToast({
        status: 'critical',
        title: 'Fixed Charges not updated',
        message: err?.response?.data?.message || err?.message,
      });
      options?.onError?.();
    }),
  });
};
