// (C) Copyright 2017-2024 Hewlett Packard Enterprise Development LP

import moment from 'moment';
import {
  UPDATE_ADMINISTRATION_PAGE_FILTERS,
  UPDATE_PROCESSED_FILE_PAGE_FILTERS,
} from './AdministrationActions';

export function customerAdmin(state = {
  isFetching: false,
  didInvalidate: true,
  filters: { customerId: '' },
  items: [],
}, action = {}) {
  switch (action.type) {
    case UPDATE_ADMINISTRATION_PAGE_FILTERS:
      return { ...state, filters: action.filters };
    default:
      return state;
  }
}

export function migrationAdmin(state = {
  isFetching: false,
  didInvalidate: true,
  items: [],
}, action = {}) {
  switch (action.type) {
    default:
      return state;
  }
}

export function processedFileAdmin(state = {
  filters: {
    customerId: undefined,
    date: moment.utc().startOf('day').subtract(1, 'days').format('M/D/YYYY'),
    stage: 'preconfig',
    service: undefined,
    searchText: undefined,
  },
}, action = {}) {
  if (action.type === UPDATE_PROCESSED_FILE_PAGE_FILTERS) {
    return { ...state, filters: action.filters };
  }
  return state;
}
