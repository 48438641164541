// (C) Copyright 2017-2024 Hewlett Packard Enterprise Development LP

import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import {
  TextInput,
} from 'grommet';
import noop from 'lodash/noop';
import CustomInput from './CustomInput';

const TagsInput = ({
  tags = [], setTags = noop, errors = [], setErrors = noop,
}) => {
  const KeyInput = useCallback(({ id, i, ...rest }) => (
    <TextInput
      {...rest}
      id={id}
      data-testid={id}
      onChange={(e) => {
        const newProperty = JSON.parse(JSON.stringify(tags));
        newProperty[i].key = e.target.value;
        setTags(newProperty);
      }}
    />
  ), [setTags, tags]);
  const ValueInput = useCallback(({
    id, i, y, ...rest
  }) => (
    <TextInput
      {...rest}
      id={id}
      data-testid={id}
      onChange={(e) => {
        const newProperty = JSON.parse(JSON.stringify(tags));
        newProperty[i].values[y].value = e.target.value;
        setTags(newProperty);
      }}
    />
  ), [setTags, tags]);

  return (
    <CustomInput
      property={tags}
      keyInput={KeyInput}
      valueInput={ValueInput}
      setProperty={setTags}
      errors={errors}
      setErrors={setErrors}
      propertyName='Tag'
      title='Tags'
    />
  );
};

TagsInput.propTypes = {
  tags: PropTypes.array,
  setTags: PropTypes.func,
  errors: PropTypes.array,
  setErrors: PropTypes.func,
};

export default TagsInput;
