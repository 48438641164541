// (C) Copyright 2017-2024 Hewlett Packard Enterprise Development LP
export const getItemStyle = (isDragging, draggableStyle) => ({ // some basic styles to make the items look a bit nicer
  userSelect: 'none',
  border: isDragging ? '2px solid #767676' : '2px solid #ccc',
  marginTop: -2,
  background: isDragging ? '#f5f5f5' : 'white',
  ...draggableStyle
});

export const getListStyle = isDraggingOver => ({
  border: isDraggingOver ? '4px solid #01a982' : '4px solid transparent',
  background: isDraggingOver ? '#ccc' : 'transparent',
  padding: '10px 8px 8px 8px',
});

export const serviceCategoryLabels = {
  'STORAGE': 'Storage',
  'COMPUTE': 'Compute',
  'NETWORK': 'Network',
  'NONMETERED': 'Non-Metered',
};

export const sortData = (data, options) => {
  data.sort((a, b) => {
    const aSort = a.services[0].meters[0].sort.split(':')[0];
    const bSort = b.services[0].meters[0].sort.split(':')[0];

    if (aSort < bSort && options.locations !== 'AUTOMATIC_ASCENDING') {
      return -1;
    }
    if (aSort > bSort && options.locations !== 'AUTOMATIC_ASCENDING') {
      return 1;
    }
    if (a.name === '(no Location)') {
      return 1;
    }
    if (b.name === '(no Location)') {
      return -1;
    }
    if (a.name < b.name) {
      return -1;
    }
    if (a.name > b.name) {
      return 1;
    }

    return 0;
  });

  data.forEach((location) => {
    location.services.sort((a, b) => {
      const aSort = a.meters[0].sort.split(':')[1];
      const bSort = b.meters[0].sort.split(':')[1];
      if (aSort < bSort && options.services !== 'AUTOMATIC_ASCENDING') { return -1; }
      if (aSort > bSort && options.services !== 'AUTOMATIC_ASCENDING') { return 1; }
      if (a.name.toLowerCase() < b.name.toLowerCase()) { return -1; }
      if (a.name.toLowerCase() > b.name.toLowerCase()) { return 1; }
      return 0;
    });
  });

  data.forEach((location) => {
    location.services.forEach((service) => {
      service.meters.sort((a, b) => {
        const aSort = a.sort.split(':')[2];
        const bSort = b.sort.split(':')[2];
        if (aSort < bSort && options.meters !== 'AUTOMATIC_ASCENDING') { return -1; }
        if (aSort > bSort && options.meters !== 'AUTOMATIC_ASCENDING') { return 1; }
        if (a.name.toLowerCase() < b.name.toLowerCase()) { return -1; }
        if (a.name.toLowerCase() > b.name.toLowerCase()) { return 1; }
        return 0;
      });
    });
  });

  return data;
};
