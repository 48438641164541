// (C) Copyright 2017-2024 Hewlett Packard Enterprise Development LP

import {
  UPDATE_SERVICE_MAPPING_FILTERS,
} from './ServiceMappingsActions';

// eslint-disable-next-line @typescript-eslint/default-param-last
export function serviceMappingList(state = {
  isFetching: false,
  didInvalidate: true,
  items: [],
  total: 0,
  filters: {
    list: { searchText: '', sort: { property: 'serviceName', direction: 'asc', external: true } },
    panel: {},
  },
  error: undefined,
  policies: {},
}, action) {
  switch (action.type) {
    case UPDATE_SERVICE_MAPPING_FILTERS:
      return { ...state, filters: action.filters };
    default:
      return state;
  }
}
