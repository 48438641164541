// (C) Copyright 2017-2024 Hewlett Packard Enterprise Development LP

import InvoiceAPI, { DrilldownType } from '@saturn/ca-invoice';
import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css'; // Core grid CSS, always needed
import 'ag-grid-community/styles/ag-theme-balham.css'; // Optional theme CSS

import ConsumedCellFormatter from './ConsumedCellFormatter';
import ConsumedSparklineFormatter from './ConsumedSparklineFormatter';
import PinnedRowCellRenderer from './PinnedRowCellRenderer';
import StateCellFormatter from './StateCellFormatter';
import StateSparklineFormatter from './StateSparklineFormatter';

const MeterDetailsGrid = ({
  meter = undefined,
  data = undefined,
  showOnlyEstimated = false
}) => {
  const gridOptions = {
    getRowStyle: (params) => {
      if (params.node.rowPinned) {
        return {
          'fontWeight': 'bold',
          'backgroundColor': '#eee !important',
          'borderBottom': '2px solid #ddd !important',
        };
      }
      return '';
    },
  };

  const defaultColDef = useMemo(() => ({
    resizable: true,
  }), []);

  const getUiOptions = () => ({
    [DrilldownType.ON_OFF]: {
      chart: StateSparklineFormatter,
      value: StateCellFormatter,
      pinned: PinnedRowCellRenderer,
    },
    [DrilldownType.VALUE]: {
      chart: ConsumedSparklineFormatter,
      value: ConsumedCellFormatter,
      pinned: PinnedRowCellRenderer,
    },
  });

  const {
    columns,
    rows,
    context,
  } = useMemo(() => {
    if (meter && data) {
      const {
        context,
        columns,
        rows: initialRows,
      } = InvoiceAPI.details(data, meter, getUiOptions());
      let rows = initialRows;
      if (showOnlyEstimated) {
        columns[1].children = columns[1].children.filter(column => column?.headerName?.includes('*'));
        const newRows = [];
        rows.forEach((row) => {
          let estimated = false;
          const newRow = JSON.parse(JSON.stringify(row));
          Object.keys(newRow).forEach((key) => {
            if (newRow[key]?.estimated === false) {
              delete newRow[key];
            }
            if (newRow[key]?.estimated) {
              estimated = true;
            }
          });
          if (estimated) newRows.push(newRow);
        });
        rows = newRows;
      }
      return {
        columns,
        rows,
        context,
      };
    }
    return {
      columns: [],
      rows: [],
      context: {},
    };
  }, [meter, data, showOnlyEstimated]);

  const getRowStyle = (params) => {
    if (params.node.rowPinned) {
      return {
        'fontWeight': 'bold',
        'background': '#eee',
        'borderBottom': '2px solid #ddd !important',
      };
    }
    return '';
  };

  return (
    <div
      style={{
        width: '100%',
        height: '100%',
      }}
      className='ag-theme-balham'
    >
      <AgGridReact
        toolPanelSuppressSideButtons={true}
        enableColResize={true}
        suppressCsvExport={true}
        suppressExcelExport={true}
        gridOptions={gridOptions}
        getRowStyle={getRowStyle}
        defaultColDef={defaultColDef}
        rowData={rows}
        columnDefs={columns}
        pinnedBottomRowData={[context]}
      />
    </div>
  );
};

MeterDetailsGrid.propTypes = {
  meter: PropTypes.any,
  data:
  PropTypes.any,
  showOnlyEstimated:
  PropTypes.bool,
};

export default MeterDetailsGrid;
