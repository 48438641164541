// (C) Copyright 2017-2024 Hewlett Packard Enterprise Development LP
import { Enumify } from 'enumify';

export class Severity extends Enumify {
  static critical = new Severity();

  static warning = new Severity();

  static _ = this.closeEnum(); // TypeScript: Color.closeEnum()
}

export class ValidationError {
  constructor({ id = '-', issue = undefined }) {
    this.issues = {};
    if (id && issue) {
      this.issues[id] = [issue];
    }
  }

  addIssue({ id = '', issue = {} }) {
    if (!this.issues.hasOwnProperty(id)) {
      this.issues[id] = [];
    }
    this.issues[id].push(issue);
  }

  getIssue(id) {
    if (this.issues) {
      return this.issues[id];
    }
    return [];
  }

  getIssues() {
    if (this.issues) {
      return Object.keys(this.issues).map((id, index) => this.issues[id]).reduce((acc, item) => {
        acc = acc.concat(item);
        return acc;
      }, []);
    }
    return [];
  }

  hasIssues(severity) {
    if (this.issues) {
      return Object.keys(this.issues).map((id, index) => this.issues[id].filter(issue => (severity ? issue.severity === severity : true)).length > 0).reduce((acc, item) => {
        acc = acc || item;
        return acc;
      }, false);
    }
    return false;
  }
}
