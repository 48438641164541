// (C) Copyright 2017-2024 Hewlett Packard Enterprise Development LP
import React, {
  useContext, useMemo, useState,
} from 'react';
import Highlight from 'react-highlighter';
import { useNavigate } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { produce } from 'immer';
import styled from 'styled-components';
import PropTypes, { shape } from 'prop-types';

import {
  Anchor,
  Box, Button,
  Main,
  Select, Text,
} from 'grommet';
import { Download, Refresh } from 'grommet-icons';
import moment from 'moment';
import ExportUtil from '../shared/util/ExportUtil';
import { AnchorStyledMenu } from '../shared/component/AnchorStyledMenu';
import IDUtil from '../shared/util/IDUtil';
import { ApiContext } from '../../AppContext';
import {
  useAlertCountQuery,
  useAlertsQuery,
  useCustomersQuery,
} from '../../core';
import GLBMDataSummary from '../shared/component/GLBMDataSummary';
import GLBMSearch from '../shared/component/GLBMSearch';
import OptionalAnchor from '../shared/component/OptionalAnchor';
import {
  pagePermissions,
  roles,
} from '../shared/constants/Permissions';
import {
  usePermissionChecker,
  useRoleChecker,
} from '../shared/hooks';
import UserStore from '../stores/UserStore';
import AlertsPSARetryDialog from './AlertsPSARetryDialog';
import {
  updateFiltersAndSession,
} from './redux/AlertActions';
import AlertsFilter from './AlertsFilter';
import { AlertType } from './AlertType';
import AlertDetails from './AlertDetails';
import ServiceTypeStore from '../stores/ServiceTypeStore';

import FilterControl from '../shared/component/FilterControl';
import GLBMHeading from '../shared/component/GLBMHeading';
import GLBMDataTable from '../shared/component/GLBMDataTable';
import UsageUpload from '../usage/UsageUpload';
import AlertsAckDialog from './AlertsAckDialog';

const PaddedAnchor = styled(Anchor)`
  padding: 12px 0;
`;

const _prepareAlerts = (alerts) => {
  if (alerts?.length) {
    const formatter = new Intl.ListFormat('en', {
      style: 'long',
      type: 'conjunction',
    });
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < alerts.length; i += 1) {
      const alert = alerts[i];

      // description:
      const serviceTypeEnum = ServiceTypeStore.getService(alert.serviceType);
      if (serviceTypeEnum) {
        alert.serviceTypeDescription = serviceTypeEnum.label;
      } else if (alert.serviceType === 'NONMETERED') {
        alert.serviceTypeDescription = 'Non-Metered Services';
      } else {
        alert.serviceTypeDescription = alert.serviceType;
      }
      const alertTypeEnum = AlertType.enumValueOf(alert.type);
      alert.typeDescription = (alertTypeEnum ? alertTypeEnum.label : alert.type);

      // type:
      switch (alert.type) {
        case 'MISSING_RESOURCE':
          if (alert.properties.feed) {
            alert.message = (`Feed ${alert.properties.feed} is missing ${alert.days} day${alert.days === 1 ? '' : 's'} of usage data`);
          } else {
            alert.message = (`Device ID ${alert.properties.deviceId} is missing ${alert.days} day${alert.days === 1 ? '' : 's'} of usage data`);
          }
          break;
        case 'MISSING_DATA':
          if (alert.properties.feed) {
            alert.message = (`Feed ${alert.properties.feed} is missing ${alert.days} day${alert.days === 1 ? '' : 's'} of usage data (no file received)`);
          } else {
            alert.message = (`Device ID ${alert.properties.deviceId} is missing ${alert.days} day${alert.days === 1 ? '' : 's'} of usage data (no file received)`);
          }
          break;
        case 'INVALID_USAGE_FILE':
          alert.message = (`Device ID ${alert.properties.deviceId} is missing ${alert.days} day${alert.days === 1 ? '' : 's'} of usage data (invalid file received) [Reason: ${alert.properties.reason}]`);
          break;
        case 'CAPACITY_PERCENT_FREE_V2':
          switch (serviceTypeEnum) {
            case 'COHESITY_LICENSES':
            case 'SWLIC':
              alert.message = (`Location ${alert.properties.location}, Tier ${alert.properties.tier}, License Type ${alert.properties.licenseType} is ${alert.properties.percentFree}% free, threshold is ${alert.properties.threshold}%`);
              break;
            default:
              alert.message = (`Location ${alert.properties.location}, Tier ${alert.properties.tier} is ${alert.properties.percentFree}% free, threshold is ${alert.properties.threshold}%`);
              break;
          }
          break;
        case 'CAPACITY_PERCENT_CHANGED_V2':
          switch (serviceTypeEnum) {
            case 'COHESITY_LICENSES':
            case 'SWLIC':
              alert.message = (`Location ${alert.properties.location}, Tier ${alert.properties.tier}, License Type ${alert.properties.licenseType} has changed ${alert.properties.percentChange}%, threshold is ${alert.properties.threshold}%`);
              break;
            default:
              alert.message = (`Location ${alert.properties.location}, Tier ${alert.properties.tier} has changed ${alert.properties.percentChange}%, threshold is ${alert.properties.threshold}%`);
              break;
          }
          break;
        case 'NEW_EQUIPMENT':
          switch (alert.serviceType) {
            case 'THREEPAR':
              // HACK ALERT: 10/04/2018: In a few months, we should be able to remove this hack since all alerts of this type should have resourceName.
              // currently we have some alerts in QA + PROD that will not have this property, so we use deviceId instead.
              alert.message = (`New 3PAR array ${alert.properties.resourceName || alert.properties.deviceId} has billable resources`);
              break;
            case 'BLADES':
              alert.message = (`New blade with serial number ${alert.properties.deviceId} is billable`);
              break;
            default:
              alert.message = (`Resource with Device Id ${alert.properties.deviceId} was discovered`);
          }
          break;
        case 'INVALID_USAGE_RECORDS':
          alert.message = `Usage file with Feed ${alert.properties.feed} has records with invalid or missing data`;
          break;
        case 'UNCONFIGURED_SERVICE':
          // eslint-disable-next-line radix
          alert.message = `Service has ${parseInt(alert.properties.missingMeters)
            // eslint-disable-next-line radix
            .toLocaleString()} usage records without a meter id assigned and ${parseInt(alert.properties.invalidMeters)
            .toLocaleString()} invalid meter IDs being generated`;
          break;
        case 'DUPLICATE_RESOURCE':
          alert.message = `Two or more records for resource ${alert.properties.resourceId} were found`;
          break;
        case 'UNCONFIGURED_RATES':
          alert.message = `${alert.properties.partnerName} (${alert.properties.partnerId}) has not entered pricing for ${formatter.format(alert.properties.meterNames)}`;
          break;
        case 'PSA_UPDATE_FAILURE':
          alert.message = `The API to update the Activation Date in PSA failed for the following reason: ${alert.properties.failureMessage}`;
          break;
        default:
          console.warn(alert);
          alert.message = '-';
      }
    }
  }
  return alerts;
};

const AlertsListPage = (props) => {
  const navigate = useNavigate();

  const [filters, setFilters] = useState(props.alerts.filters);
  const [filterActive, setFilterActive] = useState(false);
  const [ackConfirmationActive, setAckConfirmationActive] = useState(false);
  const [retryPSAActive, setRetryPSAActive] = useState(false);
  const [selectedAlert, setSelectedAlert] = useState(undefined);
  const [showUpload, setShowUpload] = useState(false);
  const [viewDetails, setViewDetails] = useState(false);

  const me = useMemo(() => UserStore.getUser(), []);
  const { isOneOfRoles } = useRoleChecker();
  const { hasPermissions } = usePermissionChecker();

  const {
    data: alertList,
    isFetching: loading,
    refetch: _refreshAlerts,
  } = useAlertsQuery(filters?.list?.period, filters?.panel, {
    select: _data => _prepareAlerts(_data),
  });

  const {
    data: customerList,
    isFetching: isFetchingCustomers,
  } = useCustomersQuery({
    select: (_data) => {
      const {
        id: myId,
        assignedCustomers,
      } = me;
      if (!_data) {
        return [];
      }
      return _data.reduce(
        (customers, c) => {
          const myRole = me.role === 'ASM'
            ? c.asmConfig.asms.filter(asm => asm.id === myId)[0]?.roles[0]
            : assignedCustomers.filter(customer => customer.customerId === c.id)[0]?.asmRole;

          // eslint-disable-next-line no-param-reassign
          customers[c.id] = {
            id: c.id,
            name: c.name,
            asmRole: (myRole || undefined),
          };
          return customers;
        },
        {},
      );
    },
  });

  const contextFn = useContext(ApiContext);

  const downloadUsageFiles = ({ id: alertId, customerId }, rawForm) => {
    const path = contextFn('alerts.download.path', {
      alertId,
      customerId,
      rawForm,
    });
    window.open(path, '_blank');
  };

  const onSearchChange = (event) => {
    const search = event;
    const newList = { ...filters.list };
    newList.searchText = search;
    const newFilters = {
      ...filters,
      list: newList,
    };
    setFilters(newFilters);
    props.updateFiltersAndSession(newFilters);
  };

  const _onShowAckConfirmation = (alert) => {
    setSelectedAlert(alert);
    setAckConfirmationActive(true);
  };

  const _onViewDetails = (alert) => {
    setViewDetails(!!alert);
    setSelectedAlert(alert);
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const _getAction = (alert, userType) => {
    let canConfigure = true;
    let canUpload = true;
    let canAck = true;
    let canViewDetails = true;
    let canDownload = true;
    let canRetryPSA = false;

    const StyledDownloadMenu = (
      <AnchorStyledMenu
        key='download'
        className='download-menu'
        responsive={true}
        id={IDUtil.getId('ActionsDownload', alert.index)}
        label='Download'
        size='small'
        items={[
          {
            label: 'Original',
            onClick: () => downloadUsageFiles(alert, true),
            id: IDUtil.getId('ActionsDownloadOriginal', alert.index),
          },
          {
            label: 'Readable',
            onClick: () => downloadUsageFiles(alert, false),
            id: IDUtil.getId('ActionsDownloadReadable', alert.index),
          },
        ]}
      />
    );

    if (isOneOfRoles([roles.ASM, roles.SERVICE_DEV])) {
      // eslint-disable-next-line no-prototype-builtins
      if (customerList.hasOwnProperty(alert.customerId)) {
        // eslint-disable-next-line no-multi-assign
        canUpload = canAck = canConfigure = canViewDetails = canDownload = (customerList[alert.customerId].asmRole === 'SUPER_EDIT' || customerList[alert.customerId].asmRole === 'EDIT');
      } else {
        console.error('alert customer id is not found in customer list');
        console.error(alert);
        console.error(customerList);
      }
    } else {
      canConfigure = hasPermissions(pagePermissions.customers.view.services.actions.edit);
      canUpload = hasPermissions(pagePermissions.alerts.actions.upload);
      canAck = hasPermissions(pagePermissions.alerts.actions.ack);
      canViewDetails = hasPermissions(pagePermissions.alerts.actions.details);
      canDownload = hasPermissions(pagePermissions.alerts.actions.download);
      canRetryPSA = hasPermissions(pagePermissions.alerts.actions.psaUpdate);
    }

    const actions = [];
    switch (alert.type) {
      case 'MISSING_DATA':
        if (canAck) {
          actions.push(
            <PaddedAnchor
              key='ack'
              id={IDUtil.getId('ActionsAcknowledge', alert.index)}
              onClick={() => _onShowAckConfirmation(alert)}
            >
              Acknowledge
            </PaddedAnchor>,
          );
        }
        if (canUpload) {
          actions.push(
            <PaddedAnchor
              key='upload'
              id={IDUtil.getId('ActionsUpload', alert.index)}
              onClick={() => {
                setShowUpload(true);
                setSelectedAlert(alert);
              }}
            >
              Upload
            </PaddedAnchor>,
          );
        }
        break;
      case 'INVALID_USAGE_FILE':
        if (canAck) {
          actions.push(
            <PaddedAnchor
              key='ack'
              id={IDUtil.getId('ActionsAcknowledge', alert.index)}
              onClick={() => _onShowAckConfirmation(alert)}
            >
              Acknowledge
            </PaddedAnchor>,
          );
        }
        if (canUpload) {
          actions.push(
            <PaddedAnchor
              key='upload'
              id={IDUtil.getId('ActionsUpload', alert.index)}
              onClick={() => {
                setShowUpload(true);
                setSelectedAlert(alert);
              }}
            >
              Upload
            </PaddedAnchor>,
          );
        }
        if (canDownload) {
          actions.push(StyledDownloadMenu);
        }
        break;
      case 'CAPACITY_PERCENT_FREE_V2':
      case 'CAPACITY_PERCENT_CHANGED_V2':
        actions.push(
          <PaddedAnchor
            key='view'
            id={IDUtil.getId('ActionsTrend', alert.index)}
            onClick={() => navigate(`/analytics/${alert.customerId}/${alert.serviceType}/capacity`, {
              customerId: alert.customerId,
              serviceType: alert.serviceType,
              alert,
            })}
          >
            Trend
          </PaddedAnchor>,
        );
        break;
      case 'UNCONFIGURED_EQUIPMENT':
      case 'UNCONFIGURED_SERVICE':
        if (canConfigure) {
          actions.push(
            <PaddedAnchor
              key='conf'
              id={IDUtil.getId('ActionsConfigure', alert.index)}
              onClick={() => navigate(`/customers/${alert.customerId}/services/${alert.serviceType}`, {
                customerId: alert.customerId,
                serviceType: alert.serviceType,
                alert,
              })}
            >
              Configure
            </PaddedAnchor>,
          );
        }
        if (canViewDetails) {
          actions.push(
            <PaddedAnchor
              key='details'
              id={IDUtil.getId('ActionsDetails', alert.index)}
              onClick={() => _onViewDetails(alert)}
            >
              Details
            </PaddedAnchor>,
          );
        }
        break;
      case 'NEW_EQUIPMENT':
        if (canAck) {
          actions.push(
            <PaddedAnchor
              key='ack'
              id={IDUtil.getId('ActionsAcknowledge', alert.index)}
              onClick={() => _onShowAckConfirmation(alert)}
            >
              Acknowledge
            </PaddedAnchor>,
          );
        }
        if (canConfigure) {
          actions.push(
            <PaddedAnchor
              key='conf'
              id={IDUtil.getId('ActionsConfigure', alert.index)}
              onClick={() => {
                navigate(`/customers/${alert.customerId}/services/${alert.serviceType}/equipment`, {
                  customerId: alert.customerId,
                  serviceType: alert.serviceType,
                  alert,
                });
              }}
            >
              Configure
            </PaddedAnchor>,
          );
        }
        if (canViewDetails) {
          actions.push(
            <PaddedAnchor
              id={IDUtil.getId('ActionsDetails', alert.index)}
              key='details'
              onClick={() => _onViewDetails(alert)}
            >
              Details
            </PaddedAnchor>,
          );
        }
        break;
      case 'INVALID_USAGE_RECORDS':
        if (canAck) {
          actions.push(
            <PaddedAnchor
              key='ack'
              id={IDUtil.getId('ActionsAcknowledge', alert.index)}
              onClick={() => _onShowAckConfirmation(alert)}
            >
              Acknowledge
            </PaddedAnchor>,
          );
        }
        actions.push(
          <PaddedAnchor
            key='details'
            id={IDUtil.getId('ActionsDetails', alert.index)}
            onClick={() => _onViewDetails(alert)}
          >
            Details
          </PaddedAnchor>,
        );
        if (canDownload) {
          actions.push(StyledDownloadMenu);
        }
        break;
      case 'MISSING_RESOURCE':
        if (canAck) {
          actions.push(
            <PaddedAnchor
              key='ack'
              id={IDUtil.getId('ActionsAcknowledge', alert.index)}
              onClick={() => _onShowAckConfirmation(alert)}
            >
              Acknowledge
            </PaddedAnchor>,
          );
        }
        if (canConfigure) {
          actions.push(
            <PaddedAnchor
              key='conf'
              id={IDUtil.getId('ActionsConfigure', alert.index)}
              onClick={() => navigate(`/customers/${alert.customerId}/services`, {
                customerId: alert.customerId,
                serviceType: alert.serviceType,
                alert,
              })}
            >
              Configure
            </PaddedAnchor>,
          );
        }
        break;
      case 'DUPLICATE_RESOURCE':
        if (canAck) {
          actions.push(
            <PaddedAnchor
              key='ack'
              id={IDUtil.getId('ActionsAcknowledge', alert.index)}
              onClick={() => _onShowAckConfirmation(alert)}
            >
              Acknowledge
            </PaddedAnchor>,
          );
        }
        if (canConfigure) {
          actions.push(
            <PaddedAnchor
              key='conf'
              id={IDUtil.getId('ActionsConfigure', alert.index)}
              onClick={() => navigate(`/customers/${alert.customerId}/services`, {
                customerId: alert.customerId,
                serviceType: alert.serviceType,
                alert,
              })}
            >
              Configure
            </PaddedAnchor>,
          );
        }
        if (canViewDetails) {
          actions.push(
            <PaddedAnchor
              id={IDUtil.getId('ActionsDetails', alert.index)}
              key='details'
              onClick={() => _onViewDetails(alert)}
            >
              Details
            </PaddedAnchor>,
          );
        }
        break;
      case 'UNCONFIGURED_RATES':
        if (canAck) {
          actions.push(
            <PaddedAnchor
              key='ack'
              id={IDUtil.getId('ActionsAcknowledge', alert.index)}
              onClick={() => _onShowAckConfirmation(alert)}
            >
              Acknowledge
            </PaddedAnchor>,
          );
        }
        break;
      case 'PSA_UPDATE_FAILURE':
        if (canAck) {
          actions.push(
            <PaddedAnchor
              key='ack'
              id={IDUtil.getId('ActionsAcknowledge', alert.index)}
              onClick={() => _onShowAckConfirmation(alert)}
            >
              Acknowledge
            </PaddedAnchor>,
          );
        }
        if (canRetryPSA) {
          actions.push(
            <PaddedAnchor
              key='retry'
              id={IDUtil.getId('ActionsRetryPSAUpdate', alert.index)}
              onClick={() => {
                setRetryPSAActive(true);
                setSelectedAlert(alert);
              }}
            >
              Retry PSA Update
            </PaddedAnchor>,
          );
        }
        break;
      default:
        console.warn('no actions', alert.type);
        break;
    }

    return (
      <Box direction='row' gap='small'>
        {actions}
      </Box>
    );
  };

  const _getAlertCustomer = (customerId, id, searchText) => (
    <OptionalAnchor
      onClick={() => navigate(`/customers/${customerId}`)}
      permissions={pagePermissions.customers.view.page}
      highlight={searchText}
      id={id}
    >
      {customerId}
    </OptionalAnchor>
  );

  const _getAlertServiceType = (customerId, serviceType, id) => {
    const serviceTypeEnum = ServiceTypeStore.getService(serviceType);
    if (serviceTypeEnum) {
      return (
        <Anchor
          id={id}
          onClick={() => navigate({
            pathname: `/customers/${customerId}/services/${serviceType}`,
            state: {
              origin: 'alerts',
            },
          })}
        >
          {serviceTypeEnum.label}
        </Anchor>
      );
    }
    if (serviceType === 'NONMETERED') {
      return (
        <Anchor
          id={id}
          onClick={() => navigate({
            pathname: `/customers/${customerId}/charges`,
            state: {
              origin: 'alerts',
            },
          })}
        >
          Non-Metered Services
        </Anchor>
      );
    }
    console.warn('Unsupported Service Type: ', serviceType);
    return (<span id={id}>{serviceType}</span>);
  };

  const _filterAlerts = (searchText) => {
    const results = [];
    const properties = ['customerId', 'serviceType', 'message', 'typeDescription'];
    if (alertList?.length) {
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < alertList.length; i += 1) {
        // eslint-disable-next-line no-plusplus
        for (let k = 0; k < properties.length; k += 1) {
          if (alertList[i][properties[k]].toLowerCase()
            .indexOf(searchText.toLowerCase()) !== -1) {
            results.push(alertList[i]);
            break;
          }
        }
      }
    }
    return results;
  };

  const _exportToCSV = (filteredAlerts) => {
    const headers = ['Date', 'Alert', 'Billing Account', 'Service', 'Message'];
    const fields = ['start', 'typeDescription', 'customerId', 'serviceTypeDescription', 'message'];
    const filename = `GLBM_Alerts_${moment.utc()
      .format('YYYY-MM-DD')}.csv`;
    ExportUtil.csv(filteredAlerts, fields, filename, headers);
  };

  const _onHideConfirmation = () => {
    setAckConfirmationActive(false);
    setRetryPSAActive(false);
    setSelectedAlert(undefined);
  };

  const _onConfirmationConfirmed = (alertIDs) => {
    // find and remove ack'ed alert:
    if (alertIDs && alertIDs.length > 0) {
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < alertIDs.length; i += 1) {
        const alertId = alertIDs[i];
        const idx = alertList.findIndex(a => (a.id === alertId));
        if (idx !== -1) {
          alertList.splice(idx, 1);
        }
      }
    }

    setAckConfirmationActive(false);
    setRetryPSAActive(false);
    setSelectedAlert(undefined);
    setTimeout(() => {
      _refreshAlerts();
    });
  };

  const _onFilterChange = (filterPanel) => {
    const updatedFilters = produce(filters, (draft) => {
      // eslint-disable-next-line no-param-reassign
      draft.panel = filterPanel;
      return draft;
    });

    setFilterActive(false);
    setFilters(updatedFilters);
    props.updateFiltersAndSession(updatedFilters);
    setTimeout(() => {
      _refreshAlerts();
    });
  };

  const _renderUploadLayer = () => {
    if (showUpload) {
      const message = `Select the files that contain the missing ${ServiceTypeStore.getService(selectedAlert.serviceType).label} metering data for ${selectedAlert.customerId}. Data for dates prior to the six most recent calendar months is ignored.`;
      return (
        <UsageUpload
          message={message}
          navigate={navigate}
          onClose={() => {
            setShowUpload(false);
          }}
        />
      );
    }
    return '';
  };

  const _renderLayer = () => {
    let layer;
    if (filterActive) {
      layer = (
        <AlertsFilter
          onClose={() => setFilterActive(false)}
          onChange={_onFilterChange}
          filter={filters.panel}
          customers={customerList}
        />
      );
    } else if (ackConfirmationActive && selectedAlert !== undefined) {
      layer = (
        <AlertsAckDialog
          alert={selectedAlert}
          onChange={_onConfirmationConfirmed}
          onClose={_onHideConfirmation}
        />
      );
    } else if (retryPSAActive && selectedAlert !== undefined) {
      layer = (
        <AlertsPSARetryDialog
          alert={selectedAlert}
          onChange={_onConfirmationConfirmed}
          onClose={_onHideConfirmation}
        />
      );
    } else if (viewDetails && selectedAlert) {
      const alert = selectedAlert;
      const serviceTypeEnum = ServiceTypeStore.getService(alert.serviceType);
      let canConfigure = alert.serviceType ? hasPermissions(pagePermissions.customers.view.services.actions.edit) : hasPermissions(pagePermissions.customers.view.services.list);
      if (canConfigure) {
        if (isOneOfRoles([roles.ASM, roles.SERVICE_DEV])) {
          if (Object.hasOwn(customerList, alert.customerId)) {
            canConfigure = (customerList[alert.customerId].asmRole === 'SUPER_EDIT' || customerList[alert.customerId].asmRole === 'EDIT');
          }
        }
      }

      layer = (
        <AlertDetails
          alert={alert}
          onConfigure={canConfigure ? () => navigate({
            pathname: `/customers/${alert.customerId}/services/${serviceTypeEnum ? alert.serviceType : ''}`,
            state: { tenant: 'MASTER' },
          }) : undefined}
          onClose={() => _onViewDetails()}
        />
      );
    }
    return layer;
  };

  const _onChangePeriod = (option) => {
    const newFilters = {
      ...filters,
      list: {
        ...filters.list,
        period: option.value,
      },
    };
    props.updateFiltersAndSession(newFilters);
    setFilters(newFilters);
    setTimeout(() => {
      _refreshAlerts();
    });
  };

  const _renderPeriodOptions = () => {
    const options = [
      {
        value: 30,
        label: 'Previous 30 Days',
      },
      {
        value: 60,
        label: 'Previous 60 Days',
      },
      {
        value: 90,
        label: 'Previous 90 Days',
      },
    ];

    const selectedPeriod = (filters.list.period || 30);
    let value;

    switch (selectedPeriod) {
      case 30:
        // eslint-disable-next-line prefer-destructuring
        value = options[0];
        break;
      case 60:
        // eslint-disable-next-line prefer-destructuring
        value = options[1];
        break;
      case 90:
        // eslint-disable-next-line prefer-destructuring
        value = options[2];
        break;
      default:
        break;
    }

    return (
      <Box width='250px' flex={false} key='periodOptions'>
        <Select
          labelKey='label'
          valueKey={{
            key: 'value',
            reduce: true,
          }}
          value={value.value}
          options={options}
          id={IDUtil.getId('AlertsPeriodSelect')}
          onChange={(option => _onChangePeriod(option))}
        />
      </Box>
    );
  };

  const _getColumns = (searchText, _loading, _count, userType) => [
    {
      property: 'startFormat',
      header: 'Date',
      size: '100px',
      dataCallback: ({ start }) => moment(start)
        .format('x'),
      render: ({
        start,
        index,
      }) => (
        <Text id={IDUtil.getId('Date', index)}>
          {moment(start)
            .format('ll')}
        </Text>
      ),
    },
    {
      property: 'typeDescription',
      header: 'Alert',
      size: '150px',
      render: ({
        typeDescription,
        index,
      }) => (
        <Text id={IDUtil.getId('Alert', index)}>
          <Highlight search={searchText}>
            {typeDescription}
          </Highlight>
        </Text>
      ),
    },
    {
      property: 'customerId',
      header: 'Billing Account',
      render: ({
        customerId,
        index,
      }) => _getAlertCustomer(customerId, IDUtil.getId('BillingAccount', index), searchText),
      size: '250px',
    },
    {
      property: 'serviceType',
      header: 'Service',
      dataCallback: ({ serviceType }) => (ServiceTypeStore.getService(serviceType) ? ServiceTypeStore.getService(serviceType).label : serviceType),
      render: ({
        customerId,
        serviceType,
        index,
      }) => _getAlertServiceType(customerId, serviceType, IDUtil.getId('ServiceType', index)),
      size: 'small',
    },
    {
      property: 'message',
      header: 'Message',
      size: 'medium',
      render: ({
        message,
        index,
      }) => (
        <Text id={IDUtil.getId('Message', index)}>
          <Highlight search={searchText}>
            {message}
          </Highlight>
        </Text>
      ),
    },
    {
      property: 'actions',
      header: 'Actions',
      render: datum => _getAction(datum, userType),
      sortable: false,
      size: '250px',
    },
  ];

  const _onSortColumn = (sort) => {
    const updatedFilters = produce(filters, (draft) => {
      // eslint-disable-next-line no-param-reassign
      draft.list.sort = sort;
      return draft;
    });

    setFilters(updatedFilters);
    props.updateFiltersAndSession(updatedFilters);
  };

  // todo update count (in redux/flux store) when we acknowledge
  const {
    data: alertCountDetails,
  } = useAlertCountQuery();
  const unfilteredTotal = useMemo(() => alertCountDetails?.unacked, [alertCountDetails]);
  const { role: userType } = me;
  const {
    list: {
      searchText,
      sort,
    },
  } = filters;

  const filteredAlerts = _filterAlerts(searchText);

  return (
    <Main direction='column' fill='vertical' overflow='hidden'>
      <GLBMHeading
        title='Alerts'
        search={[
          _renderPeriodOptions(),
          <GLBMSearch
            key='searchText'
            value={searchText}
            onChange={onSearchChange}
          />,
          <FilterControl
            key='filterControl'
            filters={filters?.panel}
            onFilter={isFetchingCustomers ? undefined : () => setFilterActive(true)}
            onClear={() => _onFilterChange({ })}
          />,
        ]}
        actions={[
          <Button
            kind='toolbar'
            icon={<Refresh />}
            onClick={() => {
              _refreshAlerts();
            }}
            a11yTitle='Refresh Alert List'
            id={IDUtil.getId('ListViewToolbarRefreshButton')}
            key='refreshBtn'
            label='Refresh'
            busy={loading || isFetchingCustomers}
          />,
          <Button
            kind='toolbar'
            icon={<Download />}
            onClick={() => _exportToCSV(filteredAlerts)}
            a11yTitle='Export to CSV'
            id={IDUtil.getId('ExportToCSVButton')}
            key='downloadBtn'
            label='Download'
          />,
        ]}
      />
      <GLBMDataSummary total={unfilteredTotal} filtered={loading ? undefined : filteredAlerts?.length} />
      <GLBMDataTable
        columns={_getColumns(searchText, loading, filteredAlerts.length, userType)}
        data={filteredAlerts.map((el, i) => ({
          ...el,
          index: i,
        }))}
        loading={loading}
        total={filteredAlerts.length}
        sort={sort}
        onSort={_onSortColumn}
      />
      {_renderLayer()}
      {_renderUploadLayer()}
    </Main>
  );
};

AlertsListPage.contextTypes = {
  router: PropTypes.object,
};

AlertsListPage.propTypes = {
  updateFiltersAndSession: PropTypes.func.isRequired,
  alerts: shape({
    list: PropTypes.array,
  }).isRequired,
};

const mapStateToProps = store => ({
  alerts: store.alert.list,
});

const mapDispatchToProps = dispatch => bindActionCreators({
  updateFiltersAndSession,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(AlertsListPage);
