// (C) Copyright 2017-2024 Hewlett Packard Enterprise Development LP

import { useCallback, useState } from 'react';
import { useUser } from '../../stores/UserStore';

const useRememberedState = (keyPrefix, defaultValue) => {
  const currentUser = useUser();
  const key = `glbm-${keyPrefix}-${currentUser.id}`;

  const [value, setValue] = useState(() => {
    const storedValue = localStorage.getItem(key);
    if (storedValue === null) {
      return defaultValue;
    }
    return JSON.parse(storedValue);
  });
  const set = useCallback((val) => {
    if (val) {
      localStorage.setItem(key, JSON.stringify(val));
    }
    setValue(val);
  }, [key]);

  return [value, set];
};

export default useRememberedState;
