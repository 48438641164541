// (C) Copyright 2017-2024 Hewlett Packard Enterprise Development LP
import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { setDirtyOptions, setOptions } from 'services/redux/ServiceActions';
import { FormField } from 'grommet';
import PropTypes from 'prop-types';
import CARadioButton from '../../../../../shared/form/CARadioButton';
import ReadOnlyField from '../../../../../shared/component/ReadOnlyField';

class XPBillingMethodField extends Component {
  constructor(props) {
    super(props);
    this._onChangeBillingMethod = this._onChangeBillingMethod.bind(this);
  }

  _onChangeBillingMethod(billingUnitsMeasure) {
    const { options } = this.props;
    options.config.billingUnitsMeasure = billingUnitsMeasure;
    this.props.setOptions(options);
    this.props.setDirtyOptions();
    this.props.setDirty();
  }

  _getEditableLayout() {
    return (
      <FormField label='Set billing unit of measure to'>
        <CARadioButton
          label='Uncompressed'
          id='radio_billing_options_uncompressed'
          name='radio_billing_options_uncompressed'
          onChange={this._onChangeBillingMethod.bind(this, 'UNCOMPRESSED')}
          checked={this.props.options.config.billingUnitsMeasure === 'UNCOMPRESSED'}
        />
        <CARadioButton
          label='Compressed'
          id='radio_billing_options_compressed'
          name='radio_billing_options_compressed'
          onChange={this._onChangeBillingMethod.bind(this, 'COMPRESSED')}
          checked={this.props.options.config.billingUnitsMeasure === 'COMPRESSED'}
        />
      </FormField>
    );
  }

  _getReadOnlyLayout() {
    const labelFromBillingMethodMap = new Map()
      .set('UNCOMPRESSED', 'Uncompressed')
      .set('COMPRESSED', 'Compressed');

    const { billingUnitsMeasure } = this.props.options.config;

    return (
      <ReadOnlyField label='Billing units of measure' value={labelFromBillingMethodMap.get(billingUnitsMeasure)} />
    );
  }

  render() {
    return this.props.readOnly ? this._getReadOnlyLayout() : this._getEditableLayout();
  }
}

XPBillingMethodField.propTypes = {
  setOptions: PropTypes.func.isRequired,
  setDirtyOptions: PropTypes.func.isRequired,
  setDirty: PropTypes.func.isRequired,
  options: PropTypes.shape({
    config: PropTypes.shape({
      billingUnitsMeasure: PropTypes.oneOfType([PropTypes.string]),
    }),
  }),
};

const mapStateToProps = ({ service: { details: { options } } }) => ({ options });

const mapDispatchToProps = dispatch => bindActionCreators({
  setOptions,
  setDirtyOptions,
}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(XPBillingMethodField);
