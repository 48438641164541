// (C) Copyright 2017-2024 Hewlett Packard Enterprise Development LP
import React, { Component } from 'react';

import pluralize from 'pluralize';
import { getColumnLabel, getEquipmentId } from 'services/Util';
import {
  Anchor, Box, Header, Heading, Table, TableRow, Text,
} from 'grommet';
import { FormAdd, FormSubtract } from 'grommet-icons';
import PropTypes from 'prop-types';
import { ConfigurableComponent } from '../../../model';

class ConfigureResourcesContext extends Component {
  constructor(props) {
    super(props);
    this.state = {
      context: {
        count: (this.props.equipment.length === 1 ? 1 : 6),
        total: undefined,
      },
    };

    this._toggleShowAllContext = this._toggleShowAllContext.bind(this);
  }

  _toggleShowAllContext() {
    const { context } = this.state;
    context.count = (context.count === Number.MAX_VALUE ? (this.props.equipment.length === 1 ? 1 : 6) : Number.MAX_VALUE);
    this.setState({ context });
  }

  _renderContextDebug(equipment) {
    if (equipment.length === 1) {
      const resource = equipment[0];
      return (
        <TableRow key={'resourceCol' + 'altDeviceIds'}>
          <td style={{ verticalAlign: 'baseline' }} className='secondary'>Alternate Device Ids:</td>
          <td>
            <div style={{ maxWidth: '250px' }}>
              <strong>{resource.data.altDeviceIds.join(', ')}</strong>
            </div>
          </td>
        </TableRow>
      );
    }
  }

  _renderContextDetails(equipment) {
    const distinctColumns = [...this.props.serviceType.configureResources.distinctColumns];
    const { serviceType } = this.props;
    const { context } = this.state;

    let details;
    if (equipment.length === 1) {
      const resource = equipment[0];
      details = distinctColumns.map((column, idx) => {
        if (column instanceof ConfigurableComponent) {
          return;
        }
        return (
          <TableRow key={`resourceCol${idx}`}>
            <td className='secondary'>{column.header(serviceType)}</td>
            <td>
              <div style={{ maxWidth: '250px' }} className='ellipsis'>
                <strong>{getColumnLabel(resource.data, column, serviceType)}</strong>
              </div>
            </td>
          </TableRow>
        );
      }).slice(0, context.count);
    } else {
      details = [];
      let i; let j; let tempArray; const chunk = 3;
      for (i = 0, j = equipment.length; i < j && i < context.count; i += chunk) {
        tempArray = equipment.slice(i, i + chunk);
        if (tempArray && tempArray.length) {
          details.push(
            <TableRow key={`resourceRow${i}`}>
              {
                tempArray.map((resource, idx) => (
                  <td key={`resourceCol${idx}`}>
                    <div style={{ maxWidth: '130px' }} className='ellipsis'>
                      <strong>{getEquipmentId(resource.data, serviceType)}</strong>
                    </div>
                  </td>
                ))
              }
            </TableRow>,
          );
        }
      }
    }

    return details;
  }

  render() {
    const { context } = this.state;
    const equipment = this.props.equipment || [];
    const [deviceOrComponent] = this.props.serviceType.inventoryManagement;
    const equipmentLabel = this.props.serviceType[deviceOrComponent];
    const formattedLabel = pluralize(equipmentLabel, equipment.length);

    return (
      <Box margin={{ top: 'medium', bottom: 'medium' }} flex={false}>
        <Header size='small' justify='between'>
          {equipment.length === 1 && <Heading level='3'>Selected Resource:</Heading>}
          {equipment.length !== 1 && (
          <Text size='large'>
            Selected
            {' '}
            {formattedLabel}
            {': '}
            {equipment.length}
          </Text>
          )}
          {((context.count < equipment.length) || (context.count === 1 && equipment.length === 1))
            && (
            <Box align='center' direction='row' onClick={this._toggleShowAllContext}>
              <FormAdd />
              <Anchor>Show All</Anchor>
            </Box>
            )}
          {context.count === Number.MAX_VALUE
          && (
          <Box direction='row' onClick={this._toggleShowAllContext}>
            <FormSubtract />
            <Anchor>Show Less</Anchor>
          </Box>
          )}
        </Header>
        <Box background='light-2' flex={false} pad='small'>
          <Table margin={{ bottom: 'none' }}>
            <tbody>
              {this._renderContextDetails(equipment)}
              {context.count === Number.MAX_VALUE
              && this._renderContextDebug(equipment)}
            </tbody>
          </Table>
        </Box>
      </Box>
    );
  }
}

ConfigureResourcesContext.propTypes = {
  serviceType: PropTypes.object.isRequired,
  equipment: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default ConfigureResourcesContext;
