// (C) Copyright 2017-2024 Hewlett Packard Enterprise Development LP
import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
} from 'grommet';
import GLBMNameValueList from '../../shared/component/GLBMNameValueList';
import GLPBindingSelector from '../../shared/component/GLPBindingSelector';
import StatusItem from './StatusItem';

const getStatusDetails = (workspace) => {
  if (workspace) {
    return (<StatusItem severity='ok' status='Account is ready to be promoted' />);
  }
  return '-';
};

const GLPAccountDetails = ({ onChange }) => {
  const [selectedBinding, setSelectedBinding] = useState(undefined);

  useEffect(() => {
    onChange(selectedBinding);
  }, [selectedBinding]);

  const payload = useMemo(() => [
    { label: 'GLP Workspace', value: <GLPBindingSelector onBindingSelected={setSelectedBinding} /> },
    { label: 'Active', value: selectedBinding ? (selectedBinding?.active ? 'Yes' : 'No') : '-' },
    { label: 'CA Tenant ID', value: selectedBinding?.caTenantId || '-' },
    { label: 'GLP Workspace ID', value: selectedBinding?.platformCustomerId || '-' },
    { label: 'Status', value: getStatusDetails(selectedBinding) },
  ], [selectedBinding]);

  return (
    <Box
      pad={{ 'horizontal': 'small' }}
      align='start'
      margin='small'
      flex={false}
    >
      <GLBMNameValueList
        title='GLP Account Details'
        data={payload}
      />
    </Box>
  );
};

GLPAccountDetails.propTypes = {
  onChange: PropTypes.func.isRequired,
};

export default GLPAccountDetails;
