// (C) Copyright 2017-2024 Hewlett Packard Enterprise Development LP
import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { Box, Button } from 'grommet';
import { Refresh } from 'grommet-icons';
import PropTypes from 'prop-types';
import { useCustomerQuery, useQueryAccountConnections } from '../../core';
import GLBMHeading from '../shared/component/GLBMHeading';
import TenantDisconnectDialog from './TenantDisconnectDialog';
import PromoteLayer from './promote/PromoteLayer';
import PartnerConnectionInfoPanel from './PartnerConnectionInfoPanel';
import CustomerConnectionInfoPanel from './CustomerConnectionInfoPanel';
import BillingAccountInfoPanel from './BillingAccountInfoPanel';
import IDUtil from '../shared/util/IDUtil';
import Loader from '../shared/loader';
import Toast from '../shared/component/Toast';

const CustomerTenantPage = () => {
  const params = useParams();
  const navigate = useNavigate();

  const [layer, setLayer] = useState();
  const [response, setResponse] = useState();

  const {
    data: customer,
  } = useCustomerQuery(params.customerId);

  const {
    isFetching: loading,
    data: connections,
    refetch,
  } = useQueryAccountConnections(params.customerId, {
    onError: (error) => {
      console.error('error', error);
      setResponse({
        status: 'critical',
        text: `Error loading account details: ${error.message}`,
      });
    }
  });

  const fetchAccountDetails = () => {
    refetch();
  };

  const onClickCancel = () => {
    navigate('/customers/');
  };

  const onConnectClicked = () => {
    setLayer('connect');
  };

  const onDisconnectClicked = () => {
    setLayer('disconnect');
  };

  const layerClosed = () => {
    setLayer(undefined);
    refetch();
  };

  const renderLayer = () => {
    let result;
    if (layer) {
      if (layer === 'connect') {
        result = (
          <PromoteLayer
            onClose={layerClosed}
            customer={customer}
          />
        );
      } else if (layer === 'disconnect') {
        result = (
          <TenantDisconnectDialog
            onClose={layerClosed}
            customer={customer}
            tenant={connections?.customerConnection}
          />
        );
      }
    }
    return result;
  };

  const isEmptyObj = obj => (obj // 👈 null and undefined check
      && Object.keys(obj).length === 0
      && Object.getPrototypeOf(obj) === Object.prototype);

  const renderNavButtons = (customerConnection) => {
    if (!customerConnection) {
      return (<Box />);
    }

    let action;

    if (isEmptyObj(customerConnection)) {
      action = (
        <Button
          label='Connect'
          type='button'
          primary={true}
          id={IDUtil.getId('EditorViewToolbarSaveButton')}
          onClick={onConnectClicked}
        />
      );
    } else {
      action = (
        <Button
          label='Disconnect'
          type='button'
          primary={true}
          id={IDUtil.getId('EditorViewToolbarSaveButton')}
          onClick={onDisconnectClicked}
        />
      );
    }

    return (
      <Box direction='row' gap='small' pad={{ horizontal: 'small', vertical: 'small' }}>
        {action}
        <Button
          label='Cancel'
          type='button'
          secondary={true}
          id={IDUtil.getId('EditorViewToolbarCancelButton')}
          onClick={onClickCancel}
        />
      </Box>
    );
  };

  const onToastClose = () => {
    setResponse(undefined);
  };

  const renderToast = () => {
    let message = '';

    if (response) {
      message = (
        <Toast
          open={response}
          status={(response.status ? response.status : 'critical')}
          onClose={onToastClose}
        >
          {response.text}
        </Toast>
      );
    }
    return message;
  };

  let loadingComponent;
  if (loading) {
    loadingComponent = (
      <Box direction='row' align='center' gap='small' justify='center'>
        <Loader text='Loading. Please wait ...' />
      </Box>
    );
  }

  return (
    <Box direction='column' fill='vertical'>
      <GLBMHeading
        back='/customers'
        title={`Manage Connections: ${customer ? `${customer.name} (${customer.id})` : ''}`}
        actions={[
          <Button kind='toolbar' icon={<Refresh />} onClick={fetchAccountDetails} a11yTitle='Refresh Account Details List' id={IDUtil.getId('ListViewToolbarRefreshButton')} />
        ]}
      />
      <Box flex={true} pad={{ horizontal: 'medium' }} gap='medium' overflow='scroll'>
        <Box margin={{ top: 'small' }} flex={false}>
          <BillingAccountInfoPanel data={customer} />
        </Box>
        {connections?.customerConnection
          && (
          <Box margin={{ top: 'small' }} flex={false}>
            <CustomerConnectionInfoPanel data={connections.customerConnection} />
          </Box>
          )}
        {connections?.resellerConnection
          && (
          <Box margin={{ top: 'small' }} flex={false}>
            <PartnerConnectionInfoPanel
              title='Reseller Information'
              data={connections.resellerConnection}
            />
          </Box>
          )}
        {connections?.distributorConnection
          && (
          <Box margin={{ top: 'small' }} flex={false}>
            <PartnerConnectionInfoPanel
              title='Distributor Information'
              data={connections.distributorConnection}
            />
          </Box>
          )}
      </Box>
      {loadingComponent}
      <Box border='top'>
        { renderNavButtons(connections?.customerConnection) }
      </Box>
      {renderLayer()}
      {renderToast()}
    </Box>
  );
};

CustomerTenantPage.contextTypes = {
  router: PropTypes.object,
};

export default CustomerTenantPage;
