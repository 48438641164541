// (C) Copyright 2017-2024 Hewlett Packard Enterprise Development LP
import { createSelector } from 'reselect';

/**
 * Session Data Selector
 * Any session data we want to persist must be selected here. This selector will select from the store to be persisted to session storage
 */

const getServiceMappingsData = ({ serviceMappings: { list: { filters } } }) => filters && { serviceMappings: { list: { filters } } };
const getAlertsSessionData = ({ alert: { list: { filters } } }) => filters && { alert: { list: { filters } } };
const getUsageSessionData = ({ usage: { list: { filters } } }) => filters && { usage: { list: { filters } } };
const getJobsSessionData = ({ job: { list: { filters } } }) => filters && { job: { list: { filters } } };
const getCustomerSessionData = ({ customer: { list: { filters } } }) => filters && { customer: { list: { filters } } };
const getAnalyticSessionData = ({ analytics: { list: { filters, modes } } }) => filters && { analytics: { list: { filters, modes } } };
const getAdministrationSessionData = ({ administration: { filters } }) => filters && { administration: { filters } };
const getPartnerSessionData = ({ partner: { list: { filters } } }) => filters && { partner: { list: { filters } } };
const getServiceMetersData = ({ serviceMeters: { list: { filters } } }) => filters && { serviceMeters: { list: { filters } } };

export const sessionDataSelector = createSelector(
  [
    getServiceMappingsData,
    getAlertsSessionData,
    getUsageSessionData,
    getJobsSessionData,
    getCustomerSessionData,
    getAnalyticSessionData,
    getAdministrationSessionData,
    getPartnerSessionData,
    getServiceMetersData,
  ],
  (...sessionData) => sessionData
    .filter(data => data)
    // eslint-disable-next-line no-shadow
    .reduce((sessionData, sessionDataSelection) => Object.assign(sessionData, sessionDataSelection), {}),
);
