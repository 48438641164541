// (C) Copyright 2017-2024 Hewlett Packard Enterprise Development LP
import * as PropType from 'prop-types';
import React from 'react';
import {
  Nav,
} from 'grommet';

const SidebarNavigation = ({ items }) => (
  <Nav overflow='auto' gap='none' flex={true}>
    {items}
  </Nav>
);

SidebarNavigation.propTypes = {
  items: PropType.array.isRequired,
};

export default SidebarNavigation;
