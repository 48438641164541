// (C) Copyright 2017-2024 Hewlett Packard Enterprise Development LP
import React, { FC } from 'react';
import GLBMNameValueList from '../../shared/component/GLBMNameValueList';
import { AccountKey } from '../types';

const EMPTY_CELL = '--';

interface EncryptionKeyContextProps {
  accountKey?: AccountKey;
}

const EncryptionKeyContext: FC<EncryptionKeyContextProps> = ({ accountKey = {} }) => (
  <GLBMNameValueList
    title='Selected Key'
    data={[
      { label: 'Status', value: accountKey.active ? 'Active' : 'Inactive' },
      { label: 'Name', value: accountKey.name || EMPTY_CELL },
      { label: 'Created By', value: accountKey.createdBy || EMPTY_CELL },
      { label: 'Created On', value: accountKey.created || EMPTY_CELL },
      { label: 'Last Used On', value: accountKey.lastUsed || EMPTY_CELL },
      { label: 'Fips', value: accountKey.fips ? 'Yes' : 'No' },
    ]}
  />
);

export default EncryptionKeyContext;
