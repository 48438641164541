// (C) Copyright 2017-2024 Hewlett Packard Enterprise Development LP

import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import {
  AccordionPanel, Box, Keyboard, Text
} from 'grommet';
import { getLocationRateStatus } from './utils';

const LocationGroupItem = ({
  children,
  selected,
  location = undefined,
  locationId,
  onNextGroup = undefined,
  onPrevGroup = undefined,
  locationRate = undefined,
  expectLocationRate,
  requireLocationRate,
}) => {
  const ref = useRef();
  useEffect(() => {
    if (selected && ref.current) {
      ref.current.getElementsByTagName('button')?.[0]?.focus();
    }
  }, [selected]);

  return (
    <Keyboard
      onUp={(event) => {
        event.preventDefault();
        onPrevGroup?.();
      }}
      onDown={(event) => {
        event.preventDefault();
        onNextGroup?.();
      }}
    >
      <Box flex={false}>
        <AccordionPanel
          ref={ref}
          label={(
            <Box
              direction='row'
              align='center'
            >
              <Box pad={{ left: 'small' }}>
                {expectLocationRate ? getLocationRateStatus(locationRate, requireLocationRate, selected) : null}
              </Box>
              <Text
                size='large'
                margin={{ horizontal: 'small', vertical: 'xsmall' }}
                color={selected ? 'selected-text' : undefined}
              >
                {location?.name || locationId}
              </Text>
            </Box>
          )}
          background={selected ? 'selected-background' : undefined}
          key={locationId}
          role='tab'
        >
          {children}
        </AccordionPanel>
      </Box>
    </Keyboard>
  );
};

LocationGroupItem.propTypes = {
  children: PropTypes.node.isRequired,
  onPrevGroup: PropTypes.func,
  onNextGroup: PropTypes.func,
  expectLocationRate: PropTypes.bool.isRequired,
  requireLocationRate: PropTypes.bool.isRequired,
  locationRate: PropTypes.object,
  selected: PropTypes.bool.isRequired,
  locationId: PropTypes.string.isRequired,
  location: PropTypes.shape({
    name: PropTypes.string,
  }),
};

export default LocationGroupItem;
