// (C) Copyright 2017-2024 Hewlett Packard Enterprise Development LP
import PropTypes from 'prop-types';
import React, { useCallback, useState } from 'react';
import {
  Box,
  Button,
  CheckBox,
  Footer,
  FormField,
  Text,
  TextInput,
} from 'grommet';
import moment from 'moment';
import GLBMLayer from '../../shared/component/GLBMLayer';
import DateTime from '../../shared/component/DateTime';
import GLBMNameValueList from '../../shared/component/GLBMNameValueList';
import type { Feed } from '../../../core/types';

const formatDate = (date: string) => {
  if (date) {
    return moment(date).format('ll');
  }
  return '';
};

const DATE_FORMAT = 'YYYY-MM-DD';

interface Props {
  feed: Feed,
  onSubmit: (feed: Feed) => void,
  onClose: () => void,
}

const FeedEditor = ({ feed, onSubmit: onSubmitProp, onClose }: Props) => {
  const [exclude, setExclude] = useState(feed.exclude);
  const [excludeBefore, setExcludeBefore] = useState(feed.excludeBefore);
  const [excludeAfter, setExcludeAfter] = useState(feed.excludeAfter);
  const [notes, setNotes] = useState(feed.notes);
  const [notesError, setNotesError] = useState<string>();

  const onSubmit = useCallback(() => {
    if ((!!excludeBefore || !!excludeAfter) && !notes) {
      setNotesError('Notes are required when exclude date is set');
      return;
    }
    setNotesError('');

    const newFeed = {
      ...feed,
      exclude,
      excludeBefore,
      excludeAfter,
      notes,
    };

    onSubmitProp(newFeed);
  }, [exclude, excludeAfter, excludeBefore, feed, notes, onSubmitProp]);

  const updateNotes = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setNotes(e.target.value);
  }, []);

  const updateExclude = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setExclude(!e.target.checked);
  }, []);

  const updateExcludeBefore = useCallback((newExcludeBefore: string) => {
    if (moment(newExcludeBefore, DATE_FORMAT, true).isValid() || !newExcludeBefore) {
      // ensure that the exclude after date is later than exclude before
      if (newExcludeBefore && excludeAfter && moment(newExcludeBefore).isAfter(moment(excludeAfter))) {
        setExcludeAfter(newExcludeBefore);
      }

      setExcludeBefore(newExcludeBefore);
    }
  }, [excludeAfter]);

  const updateExcludeAfter = useCallback((newExcludeAfter: string) => {
    if (moment(newExcludeAfter, DATE_FORMAT, true).isValid() || !newExcludeAfter) {
      // ensure that the exclude before date is earlier than exclude after
      if (newExcludeAfter && excludeBefore && moment(excludeBefore).isAfter(moment(newExcludeAfter))) {
        setExcludeBefore(newExcludeAfter);
      }

      setExcludeAfter(newExcludeAfter);
    }
  }, [excludeBefore]);

  const renderContextDetails = ({ feed: feedName, from, to }) => (
    <GLBMNameValueList
      title='Selected Feed'
      data={[
        { label: 'Feed', value: feedName },
        {
          label: 'First Seen Date',
          value: (
            <Box direction='row' gap='medium'>
              <Text>{formatDate(from)}</Text>
              <Text weight={100} size='small'>{moment(from).fromNow()}</Text>
            </Box>
          ),
        },
        {
          label: 'Last Seen Date',
          value: (
            <Box direction='row' gap='medium'>
              <Text>{formatDate(to)}</Text>
              <Text weight={100} size='small'>{moment(to).fromNow()}</Text>
            </Box>
          ),
        },
      ]}
      pairProps={{ direction: 'column' }}
      valueProps={{ width: 'medium' }}
    />
  );

  return (
    <GLBMLayer
      position='right'
      onClose={onClose}
      onClickOutside={onClose}
      onEsc={onClose}
      closer={true}
      flush={true}
      overlayClose={true}
      full='vertical'
      title='Edit Feed'
    >
      <Box
        pad='none'
        direction='column'
        flex={true}
        fill='vertical'
        overflow='auto'
      >
        <Box margin={{ horizontal: 'medium' }} flex={false} width='medium'>
          {renderContextDetails(feed)}

          <Box pad={{ vertical: 'medium' }}>
            <FormField label='Include' htmlFor='input-exclude'>
              <CheckBox id='input-exclude' name='exclude' toggle={true} checked={!exclude} onChange={updateExclude} />
            </FormField>

            <FormField label='Exclude Before' help='(Will drop all usage prior to this date)' htmlFor='input-excludeBefore'>
              <DateTime
                id='input-excludeBefore'
                name='excludeBefore'
                format={DATE_FORMAT}
                value={excludeBefore}
                onChange={updateExcludeBefore}
              />
            </FormField>

            <FormField label='Exclude After' help='(Will drop all usage following this date)' htmlFor='input-excludeAfter'>
              <DateTime
                id='input-excludeAfter'
                name='excludeAfter'
                format={DATE_FORMAT}
                value={excludeAfter}
                onChange={updateExcludeAfter}
              />
            </FormField>

            <FormField label='Notes' help='(limit of 255 characters)' error={notesError} htmlFor='input-notes'>
              <TextInput
                id='input-notes'
                name='notes'
                maxLength={254}
                value={notes}
                onChange={updateNotes}
              />
            </FormField>
          </Box>
        </Box>
      </Box>
      <Box border='top' pad='small' margin={{ top: 'none' }} flex={false}>
        <Footer flex={false} justify='start' gap='small'>
          <Button label='OK' primary={true} onClick={onSubmit} />
          <Button label='Cancel' secondary={true} onClick={onClose} />
        </Footer>
      </Box>
    </GLBMLayer>
  );
};

FeedEditor.propTypes = {
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default FeedEditor;
