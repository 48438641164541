// (C) Copyright 2017-2024 Hewlett Packard Enterprise Development LP
import { Enumify } from 'enumify';

export class SampleInterval extends Enumify {
  static Average = new SampleInterval('Average');

  static Last = new SampleInterval('Last');

  static Maximum = new SampleInterval('Maximum');

  static Sum = new SampleInterval('Sum');

  static Count = new SampleInterval('Count');

  static _ = this.closeEnum(); // TypeScript: Color.closeEnum()

  constructor(label, tooltip) {
    super();
    this.label = label;
    this.tooltip = tooltip;
  }
}
