// (C) Copyright 2017-2024 Hewlett Packard Enterprise Development LP
import { Enumify } from 'enumify';

export class ServiceStatusType extends Enumify {
  static COMPLETE = new ServiceStatusType('COMPLETE', 'In Production', 'ok');

  static INCOMPLETE = new ServiceStatusType('INCOMPLETE', 'In Preview', 'warning');

  static NEW = new ServiceStatusType('NEW', 'New', 'unknown');

  static _ = this.closeEnum(); // TypeScript: Color.closeEnum()

  constructor(value, label, severity) {
    super();
    this.value = value;
    this.label = label;
    this.severity = severity;
  }
}
