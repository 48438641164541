// (C) Copyright 2017-2024 Hewlett Packard Enterprise Development LP
import React, { useMemo } from 'react';
import {
  Box,
  Button,
  FormField,
  Select,
  Table,
  TableBody,
  TableCell,
  TableHeader,
  TableRow,
  Text,
} from 'grommet';
import { FormTrash } from 'grommet-icons';

import moment from 'moment/moment';
import { cloneDeep } from 'lodash';
import DateTime from '../../../../../../shared/component/DateTime';
import { getDuplicateRevisions } from './Util';

const TableData = ({ style, children }) => <TableCell className='veryThinTableRow' style={{ ...style }} verticalAlign='top'>{children}</TableCell>;
const optionSelectRenderer = (attribute, options, revision, index, isSubmitted, onChange) => (
  <FormField error={isSubmitted && !revision.id ? 'Required' : undefined} required={true}>
    <Select
      labelKey='label'
      valueKey={{
        key: 'value',
        reduce: true
      }}
      placeHolder='None'
      name={attribute}
      value={revision.id}
      options={options}
      onChange={(event) => {
        onChange(index, 'id', event.option.value);
      }}
    />
  </FormField>
);

const getDateError = (isDuplicate, effectiveDate) => {
  if (isDuplicate) {
    return 'Duplicate';
  }
  if (!effectiveDate || !moment(effectiveDate, 'YYYY-MM', true).isValid()) {
    return 'Required (YYYY-MM)';
  }
  return undefined;
};

const dateRenderer = (revision, attribute, index, isSubmitted, isDuplicate, onChange) => (
  <Box flex={true} style={{ whiteSpace: 'nowrap' }}>
    {index === 0
      ? <Text margin='small' weight={500}>{revision.effectiveDate}</Text>
      : (
        <FormField
          error={isSubmitted ? getDateError(isDuplicate, revision.effectiveDate) : undefined}
          name='date-field'
          required={true}
        >
          <DateTime
            id={`multi-tier-datetime-${attribute}-${index}`}
            value={revision.effectiveDate && moment(revision.effectiveDate, 'YYYY-MM', true).isValid()
              ? moment(revision.effectiveDate).format('YYYY-MM')
              : undefined}
            onChange={(date) => {
              onChange(index, 'effectiveDate', date);
            }}
            format='YYYY-MM'
          />
        </FormField>
      )}
  </Box>
);
const removeBand = (index, onRemove) => (index !== 0 ? (
  <Button
    icon={<FormTrash />}
    onClick={() => onRemove(index)}
    a11yTitle='Delete Step'
  />
) : null);

const MultiResourceRevisionEdit = ({
  name,
  attribute,
  revisions,
  onChange,
  contractStartMonth,
  isSubmitted,
  options,
}) => {
  const onRevisionChanged = (index, property, newValue) => {
    const updatedRevisions = cloneDeep(revisions);
    updatedRevisions[index][property] = newValue;
    onChange(attribute, updatedRevisions);
  };

  const onRevisionRemoved = (index) => {
    const updatedRevisions = cloneDeep(revisions);
    updatedRevisions.splice(index, 1);
    onChange(attribute, updatedRevisions);
  };

  const headers = useMemo(() => {
    const TableHeading = ({ children }) => (
      <TableCell scope='col' border='bottom' className='veryThinTableHeaders'>
        {children}
      </TableCell>
    );
    return [name, 'Start Date', ''].map(label => <TableHeading key={`table-heading-${label}`}>{label}</TableHeading>);
  }, []);

  const dateDuplications = useMemo(() => getDuplicateRevisions(revisions), [revisions]);

  const rows = useMemo(() => (revisions).map((revision, index) => (
    <TableRow key={`${attribute}-${index}`} style={{ verticalAlign: 'baseline' }}>
      <TableData>{optionSelectRenderer(attribute, options, revision, index, isSubmitted, onRevisionChanged)}</TableData>
      <TableData>{dateRenderer(revision, attribute, index, isSubmitted, dateDuplications.findIndex(e => e === index) !== -1, onRevisionChanged)}</TableData>
      <TableData style={{ maxWidth: 50, paddingTop: '4px' }}>{removeBand(index, onRevisionRemoved)}</TableData>
    </TableRow>
  )), [options, revisions, isSubmitted]);

  return (
    <Box direction='row' align='center' flex={false}>
      <Box direction='column'>
        <Table responsive={false} scrollable={false} data-e2e='tiers-and-dates'>
          <TableHeader>
            <TableRow>
              {headers}
            </TableRow>
          </TableHeader>
          <TableBody>
            {rows}
          </TableBody>
        </Table>
      </Box>
    </Box>
  );
};

export default MultiResourceRevisionEdit;
