// (C) Copyright 2017-2024 Hewlett Packard Enterprise Development LP

import React from 'react';
import {
  Box, NameValueList, NameValuePair, Text
} from 'grommet';
import PropTypes from 'prop-types';
import IDUtil from '../shared/util/IDUtil';
import BaseContainer from './components/BaseContainer';

const ServiceAttributes = ({ service = undefined, customer = undefined }) => {
  const locationMap = {};
  if (customer) {
    customer.locations.forEach((l) => {
      locationMap[l.id] = l.name;
    });
  }

  const showLocation = service && service.type === 'LOCATION';
  const missingLocation = (service && !locationMap[service.locationId]);

  return (
    <BaseContainer title='Service Attributes' expanded={true} id={IDUtil.getId('NonMeterServiceAttributes')}>
      <Box pad='small' direction='row'>
        <NameValueList layout='column'>
          <NameValuePair name='Description'>
            <Text id={IDUtil.getId('NonMeterServiceAttributesDescription')}>{service?.description || ''}</Text>
          </NameValuePair>
          <NameValuePair name='Apply the charge to'>
            <Text id={IDUtil.getId('NonMeterServiceAttributesApplyCharges')}>
              {service?.type === 'CUSTOMER' ? 'Billing Account' : 'Location'}
            </Text>
          </NameValuePair>
          {showLocation && (
          <NameValuePair name='Location'>
            <Text style={{ color: missingLocation ? 'red' : '' }} id={IDUtil.getId('NonMeterServiceAttributesLocation')}>
              {(service && locationMap[service.locationId]) ? locationMap[service.locationId] : '(not found)'}
            </Text>
          </NameValuePair>
          )}
        </NameValueList>
      </Box>
    </BaseContainer>
  );
};

ServiceAttributes.propTypes = {
  service: PropTypes.object,
  customer: PropTypes.object,
};

export default ServiceAttributes;
