// (C) Copyright 2017-2024 Hewlett Packard Enterprise Development LP

import React from 'react';
import { Box } from 'grommet';
import GLBMHeading from '../../shared/component/GLBMHeading';

interface Props {
  fill?: React.ComponentProps<typeof Box>['fill']
  expanded?: boolean
  id?: string
  title?: React.ReactNode
  actions?: React.ReactNode[]
  children?: React.ReactNode
}

const BaseContainer = ({
  fill = false,
  expanded = true,
  id = undefined,
  title = undefined,
  actions = undefined,
  children = null,
}: Props) => (
  <Box
    direction='column'
    fill={fill && expanded ? fill : false}
    flex={fill && expanded}
    border={true}
    id={id}
  >
    <Box flex={false} border='bottom'>
      <GLBMHeading
        title={title}
        actions={actions}
        height='42px'
        level='3'
        inline={true}
      />
    </Box>
    {expanded && (
      <Box flex={true} pad='xsmall'>
        {children}
      </Box>
    )}
  </Box>
);

export default BaseContainer;
