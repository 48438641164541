// (C) Copyright 2017-2024 Hewlett Packard Enterprise Development LP

import React, { useEffect, useMemo, useState } from 'react';
import {
  Box, Button, Footer, FormField, TextInput,
} from 'grommet';
import moment from 'moment';
import CurrencyUtils from '../i18n/CurrencyUtil';
import GLBMLayer from '../shared/component/GLBMLayer';
import CARadioButton from '../shared/form/CARadioButton';
import CACheckbox from '../shared/form/CACheckbox';
import IDUtil from '../shared/util/IDUtil';
import {
  calculateDownstreamRate,
  calculateMarkup,
  isLessThanFiveDecimalPlaces,
  isLessThanNineDecimalPlaces,
} from '../services/service-edit/steps/rates/util';
import DateTime from '../shared/component/DateTime';
import { roles } from '../shared/constants/Permissions';
import { useRoleChecker } from '../shared/hooks';
import { Revision } from './types';

const updateMaster = ({
  quantity, rate, markup, downstreamRate,
}) => {
  const obj = {};
  obj[parseFloat(quantity)] = {
    rate: (rate !== undefined ? parseFloat(rate) : undefined),
    markup: (markup !== undefined ? parseFloat(markup) : undefined),
    downstreamRate: (downstreamRate !== undefined ? parseFloat(downstreamRate) : undefined),
  };
  return obj;
};

// eslint-disable-next-line no-restricted-globals
const isNumber = n => !isNaN(parseFloat(n)) && isFinite(n);

const getMarkupValue = (revision: Revision, { markup }) => {
  let value = 'Not Available';
  if (!revision.explicitDownstreamRate) {
    value = (markup !== undefined ? markup : '');
  } else if (revision.explicitDownstreamRate && markup !== undefined) {
    value = CurrencyUtils.getNumberString(parseFloat(markup), 4, null);
  }
  return value;
};

const getCustomerRateValue = (revision: Revision, { downstreamRate }) => {
  let value = 'Not Available';
  if (!revision.explicitDownstreamRate && downstreamRate !== undefined) {
    value = CurrencyUtils.getNumberString(parseFloat(downstreamRate), 8, null);
  } else if (revision.explicitDownstreamRate) {
    value = (downstreamRate !== undefined ? downstreamRate : '');
  }
  return value;
};

const getTotalCustomerPrice = (revision: Revision, {
  rate, quantity, markup, downstreamRate,
}) => {
  let value: number;
  if (!revision.explicitDownstreamRate && rate !== undefined && quantity !== undefined && markup !== undefined) {
    value = (((rate * markup) / 100) + rate) * quantity;
  } else if (revision.explicitDownstreamRate && quantity && downstreamRate) {
    value = quantity * downstreamRate;
  }
  return value;
};

interface Props {
  revision: Revision
  partner: boolean,
  onSubmit: (revision: Revision) => void,
  onClose: () => void,
  revisions: Revision[],
}

const RevisionEditor = ({
  revision: revisionProp, revisions, partner, onSubmit: onSubmitCb, onClose
}: Props) => {
  const { isOneOfRoles } = useRoleChecker();
  const allowDates = useMemo(() => isOneOfRoles([roles.ASM, roles.SUPER, roles.SERVICE_DEV, roles.SUPPORT, roles.SPECIALIST]), [isOneOfRoles]);

  const [useDates, setUseDates] = useState(() => {
    if (revisionProp?.effectiveDate && allowDates) {
      if (moment(revisionProp.effectiveDate).date() !== 1) {
        return true;
      }
    }
    return false;
  });
  const dateFormat = useDates ? 'YYYY-MM-DD' : 'YYYY-MM';
  const [revision, setRevision] = useState(() => {
    const value = (
      revisionProp ? JSON.parse(JSON.stringify(revisionProp)) : {
        effectiveDate: null,
        explicitDownstreamRate: false,
        tiers: {
          0: {
            rate: 0,
          },
        },
      }
    );
    // default to explicitDownstreamRate = false:
    if (partner && revisionProp && revisionProp.explicitDownstreamRate === undefined) {
      value.explicitDownstreamRate = false;
    }
    if (value?.effectiveDate && allowDates) {
      value.effectiveDate = moment(value.effectiveDate).format(dateFormat);
    }
    return value;
  });
  const [tier, setTier] = useState((revisionProp ? Object.entries(revisionProp.tiers)
    .reduce((arr, [quantity, { rate, markup, downstreamRate }]) => (
      [...arr, {
        quantity, rate, markup, downstreamRate,
      }]
    ), [])[0] : { quantity: 0, rate: 0 }));

  const startDates = useMemo(() => (revisions || []).reduce((map, r) => {
    map.push(r.effectiveDate);
    return map;
  }, []), [revisions]);
  const [initialDate] = useState(revision.effectiveDate);

  const onSubmit = () => {
    const revToSubmit = { ...revision };
    revToSubmit.tiers = updateMaster(tier);
    if (partner) {
      if ((!revision.explicitDownstreamRate && tier.markup === undefined) || (revision.explicitDownstreamRate && tier.downstreamRate === undefined)) {
        revToSubmit.explicitDownstreamRate = undefined;
      }
    }
    if (revision.effectiveDate) {
      revToSubmit.effectiveDate = moment(revision.effectiveDate).format('YYYY-MM-DD');
    }
    onSubmitCb(revToSubmit);
  };

  const updateValueForMinus = (origValue: string) => {
    let value = origValue;
    // if user presses minus and not at the beginning of the string
    const minusCount = (value.match(/-/g) || []).length;
    if (minusCount === 1) {
      const minusIndex = value.indexOf('-');
      if (minusIndex !== -1 && minusIndex > 0) {
        value = `-${value.split('-').join('')}`;
      }
    } else if (minusCount === 2) {
      // if user presses minus a second time:
      value = value.split('-').join('');
    }
    return value;
  };

  const onChange = ({ target: { value, name } } = { target: { value: undefined, name: null } }) => {
    const newTier = { ...tier };
    if (name) {
      const newValue = updateValueForMinus(value);
      switch (name) {
        case 'markup':
        case 'downstreamRate':
          if (newValue === '-') {
            newTier[name] = newValue;
          } else if (newValue === '') {
            newTier[name] = undefined;
          } else if (isNumber(newValue)) {
            if ((name === 'markup' && isLessThanFiveDecimalPlaces(newValue))
              || (name === 'downstreamRate' && isLessThanNineDecimalPlaces(newValue))) {
              newTier[name] = newValue;
            }
          }

          // calculate and set the 'other' value:
          if (name === 'markup') {
            newTier.downstreamRate = calculateDownstreamRate(newTier.markup, newTier.rate);
          } else if (name === 'downstreamRate') {
            newTier.markup = calculateMarkup(newTier.downstreamRate, newTier.rate);
          }

          break;
        default:
          if (!value || isNumber(value)) {
            newTier[name] = value;
          }
          break;
      }
    }
    setTier(newTier);
  };

  const onMarkupTypeChange = (explicitDownstreamRate: boolean) => {
    setRevision(prevState => ({ ...prevState, explicitDownstreamRate }));
    setTier((prevState) => {
      const newTier = { ...prevState };
      // clear the 'other' value:
      if (!explicitDownstreamRate && newTier.markup === undefined) {
        newTier.downstreamRate = undefined;
      } else if (explicitDownstreamRate && newTier.downstreamRate === undefined) {
        newTier.markup = undefined;
      }
      return newTier;
    });
  };

  const onBlur = () => {
    setRevision((prevState) => {
      if (prevState) {
        if (!isNumber(prevState.markup) || isLessThanFiveDecimalPlaces(prevState.markup)) {
          return { ...prevState, markup: undefined };
        }
      }
      return prevState;
    });
  };

  const onDateBlur = () => {
    setRevision((prevState) => {
      if (prevState) {
        if (prevState.effectiveDate) {
          const m = moment(prevState.effectiveDate, dateFormat);
          if (m.isValid()) {
            return { ...prevState, effectiveDate: m.format(dateFormat) };
          }
        }
      }
      return prevState;
    });
  };

  useEffect(() => {
    setRevision((prevState) => {
      if (!useDates) {
        if (moment(prevState.effectiveDate, 'YYYY-MM-DD', true).isValid()) {
          return { ...prevState, effectiveDate: moment(prevState.effectiveDate).date(1).format('YYYY-MM') };
        }
      } else if (moment(prevState.effectiveDate, 'YYYY-MM', true).isValid()) {
        return { ...prevState, effectiveDate: moment(prevState.effectiveDate).date(1).format('YYYY-MM-DD') };
      }
      return prevState;
    });
  }, [useDates]);

  const errorMessage = useMemo(() => {
    const invalidDate = startDates.includes(revision.effectiveDate) && revision.effectiveDate !== initialDate;
    let result = '';
    if (invalidDate) {
      result = 'Duplicate';
    } else if (!revision.effectiveDate) {
      result = 'Required';
    } else if (!moment(revision.effectiveDate, dateFormat, true).isValid()) {
      result = `Invalid Date. Use format: ${dateFormat}`;
    }
    return result;
  }, [dateFormat, initialDate, revision.effectiveDate, startDates]);

  const customerPrice = getTotalCustomerPrice(revision, tier);

  return (
    <GLBMLayer
      position='right'
      full='vertical'
      closer={true}
      onClose={onClose}
      onEsc={onClose}
      onClickOutside={onClose}
      title='Add Revision'
      flush={true}
    >
      <Box pad='none' direction='column' fill='vertical' style={{ 'maxWidth': '575px', 'minWidth': '575px' }}>
        <Box flex={true}>
          <Box flex={false} pad={{ horizontal: 'medium' }}>
            <FormField label='Start Date' error={errorMessage} htmlFor={IDUtil.getId('RevisionEditStartDate')}>
              {partner
                ? (
                  <TextInput
                    id={IDUtil.getId('RevisionEditStartDate')}
                    name='startDate'
                    value={revision.effectiveDate}
                    disabled={true}
                  />
                )
                : (
                  <Box
                    direction='column'
                    margin={{ vertical: 'small' }}
                    gap='medium'
                  >
                    <DateTime
                      name='RevisionEditStartDate'
                      id={IDUtil.getId('RevisionEditStartDate')}
                      disabled={partner}
                      required={true}
                      format={dateFormat}
                      value={revision.effectiveDate || ''}
                      onChange={(date) => {
                        let newDate = date;
                        if (moment(date, dateFormat, true).isValid()) {
                          newDate = moment(date).format(dateFormat);
                        }
                        setRevision((prevState) => {
                          if (newDate !== prevState.effectiveDate) {
                            return { ...prevState, effectiveDate: newDate };
                          }
                          return prevState;
                        });
                      }}
                      onBlur={onDateBlur}
                    />
                    {allowDates && (
                      <Box direction='column' justify='center'>
                        <CACheckbox
                          label='Allow custom date for partial month revision'
                          id={IDUtil.getId('RevisionEditAllowCustomDate')}
                          name='RevisionEditAllowCustomDate'
                          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                            setUseDates(event.target.checked);
                          }}
                          checked={useDates}
                          tooltip='Enter the start date if the cost should be calculated based on the number of days the change is in effect.'
                        />
                      </Box>
                    )}
                  </Box>
                )}
            </FormField>
            <FormField label='Quantity' htmlFor={IDUtil.getId('RevisionEditQuantity')}>
              <TextInput
                type='number'
                id={IDUtil.getId('RevisionEditQuantity')}
                name='quantity'
                value={tier.quantity}
                disabled={partner}
                onChange={(p) => {
                  setTier(prevState => ({
                    ...prevState,
                    quantity: Math.round(+p.target.value),
                  }));
                }}
              />
            </FormField>
            <FormField label='Rate Per Unit' htmlFor={IDUtil.getId('RevisionEditRate')}>
              {!partner
                ? (
                  <TextInput
                    id={IDUtil.getId('RevisionEditRate')}
                    name='rate'
                    value={tier.rate}
                    disabled={partner}
                    onChange={(p) => {
                      setTier((prevState) => {
                        if (isLessThanNineDecimalPlaces(+p.target.value)) {
                          return { ...prevState, rate: p.target.value };
                        }
                        return prevState;
                      });
                    }}
                  />
                )
                : (
                  <TextInput
                    id={IDUtil.getId('RevisionEditRate')}
                    name='rate'
                    value={tier.rate === undefined ? 'Not Available' : tier.rate}
                    disabled={true}
                  />
                )}
            </FormField>
            {!partner
              && (
                <FormField label='Total Cost' help='(calculated)' htmlFor={IDUtil.getId('RevisionEditTotalCost')}>
                  <TextInput
                    plain={true}
                    id={IDUtil.getId('RevisionEditTotalCost')}
                    value={CurrencyUtils.getNumberString(tier.quantity * tier.rate, 8)}
                    focusIndicator={false}
                  />
                </FormField>
              )}

            {partner && (
              <>
                <FormField label='Pricing Method'>
                  <CARadioButton
                    id='pricingMethodMarkupPercent'
                    data-testid='pricingMethodMarkupPercent'
                    name='pricingMethodMarkupPercent'
                    label='Markup Percentage'
                    onChange={() => onMarkupTypeChange(false)}
                    checked={!revision.explicitDownstreamRate || false}
                    tooltip='Enter a markup percentage to the rate you pay to calculate the price for your customer.'
                    place='left'
                  />
                  <CARadioButton
                    id='pricingMethodDirectRate'
                    data-testid='pricingMethodDirectRate'
                    name='pricingMethodDirectRate'
                    label='Direct Rate'
                    checked={revision.explicitDownstreamRate || false}
                    onChange={() => onMarkupTypeChange(true)}
                    tooltip='Enter an explicit rate independent of the rate you pay to set the price for your customer.'
                    place='left'
                  />
                </FormField>
                <FormField label='Markup %' htmlFor='markup' style={{ backgroundColor: 'rgba(248, 248, 248, 0.95)' }}>
                  <TextInput
                    id='markup'
                    name='markup'
                    value={getMarkupValue(revision, tier)}
                    onChange={onChange}
                    disabled={revision.explicitDownstreamRate}
                    onBlur={onBlur}
                  />
                </FormField>
                <FormField label='Customer Rate' htmlFor='downstreamRate' style={{ backgroundColor: 'rgba(248, 248, 248, 0.95)' }}>
                  <TextInput
                    id='downstreamRate'
                    name='downstreamRate'
                    value={getCustomerRateValue(revision, tier)}
                    onChange={onChange}
                    disabled={!revision.explicitDownstreamRate}
                    onBlur={onBlur}
                  />
                </FormField>
                <FormField label='Total Customer Price' htmlFor='customerPrice' style={{ backgroundColor: 'rgba(248, 248, 248, 0.95)' }}>
                  <TextInput
                    id='customerPrice'
                    name='customerPrice'
                    value={customerPrice !== undefined ? CurrencyUtils.getNumberString(customerPrice, 8, null) : 'Not Available'}
                    disabled={true}
                  />
                </FormField>
              </>
            )}
          </Box>
          <Box flex={false} pad={{ horizontal: 'medium' }} margin={{ top: 'small' }} />
        </Box>
        <Box border='top' pad='small' margin={{ top: 'none' }} flex={false}>
          <Footer flex={false} justify='start' gap='small'>
            <Button
              label='OK'
              type='button'
              primary={true}
              onClick={errorMessage === '' ? onSubmit : null}
            />
            <Button
              label='Cancel'
              type='button'
              secondary={true}
              onClick={onClose}
            />
          </Footer>
        </Box>
      </Box>
    </GLBMLayer>
  );
};

export default RevisionEditor;
