// (C) Copyright 2017-2024 Hewlett Packard Enterprise Development LP

import {
  CLEAR_UPLOAD,
  FILE_UPLOAD_FAILURE,
  FILE_UPLOAD_SUCCESSFUL,
  RECEIVE_ALERT_COUNT,
  RECEIVE_FILE_VALIDATION,
  REQUEST_FILE_VALIDATION,
  UPDATE_ALERT_FILTERS,
} from './AlertActions';

// eslint-disable-next-line default-param-last
export function alertList(state = {
  totalAlerts: 0,
  filters: {
    list: {
      searchText: '', sort: { property: 'startFormat', direction: 'desc', external: true }, period: 30,
    },
    panel: { accountStatus: ['BILLABLE'] },
  },
  error: undefined,
}, action) {
  switch (action.type) {
    case UPDATE_ALERT_FILTERS:
      return { ...state, filters: action.filters };
    case RECEIVE_ALERT_COUNT:
      return { ...state, totalAlerts: action.count.totalAlerts };
    default:
      return state;
  }
}
// eslint-disable-next-line default-param-last
export function alertUpload(state = {
  policies: {},
}, action) {
  switch (action.type) {
    case REQUEST_FILE_VALIDATION:
      return {
        ...state,
        policies: {
          ...state.policies,
          [action.filename]: undefined
        }
      };
    case RECEIVE_FILE_VALIDATION:
      return {
        ...state,
        policies: {
          ...state.policies,
          [action.filename]: action.policy
        }
      };
    case FILE_UPLOAD_SUCCESSFUL:
      return {
        ...state,
        policies: {
          ...state.policies,
          [action.filename]: {
            ...state.policies[action.filename],
            uploaded: true,
          }
        }
      };
    case FILE_UPLOAD_FAILURE:
      return {
        ...state,
        policies: {
          ...state.policies,
          [action.filename]: {
            ...state.policies[action.filename],
            uploaded: true,
            failed: true,
          }
        }
      };
    case CLEAR_UPLOAD:
      return { ...state, policies: {} };
    default:
      return state;
  }
}
