// (C) Copyright 2017-2024 Hewlett Packard Enterprise Development LP
import React, { useCallback } from 'react';
import ReactDiffViewer from 'react-diff-viewer';
import Prism from 'prismjs';
import {
  Box,
  Button,
  Footer,
  Text,
} from 'grommet';
import PropTypes from 'prop-types';
import GLBMLayer from './GLBMLayer';
import GLBMHeading from './GLBMHeading';

const newStyles = {
  line: {
    fontSize: '12px',
  },
};

const CompareJSONFlow = ({
  onClose = () => { },
  title = '',
  subTitle = '',
  onSave = () => { },
  oldFile = {},
  newFile = { },
}) => {
  const highlightSyntax = useCallback(str => (
    <pre
      style={{ display: 'inline' }}
      dangerouslySetInnerHTML={{ __html: Prism.highlight(str || '', Prism.languages.json) }}
    />
  ), []);

  return (
    <GLBMLayer>

      <Box pad='small'>
        <GLBMHeading title={title} />
        {subTitle
          && (
          <Box pad={{ horizontal: 'small' }}>
            <Text pad='small'>
              {subTitle}
            </Text>
          </Box>
          )}
      </Box>
      <Box
        full={true}
        height='70vh'
        separator='top'
        direction='column'
        overflow='auto'
        width='80vw'
      >
        <ReactDiffViewer
          styles={newStyles}
          oldValue={JSON.stringify(oldFile, null, 2)}
          newValue={JSON.stringify(newFile, null, 2)}
          splitView={true}
          renderContent={highlightSyntax}
        />
      </Box>
      <Footer justify='start' pad={{ between: 'small', horizontal: 'small', vertical: 'small' }} flex={false}>
        <Button
          primary={true}
          label='Save'
          secondary={true}
          onClick={onSave}
        />
        <Button
          label='Close'
          secondary={true}
          onClick={onClose}
        />
      </Footer>
    </GLBMLayer>
  );
};

CompareJSONFlow.propTypes = {
  onClose: PropTypes.func,
  title: PropTypes.string,
  subTitle: PropTypes.string,
  onSave: PropTypes.func,
  oldFile: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.array,
  ]),
  newFile: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.array,
  ]),
};

export default CompareJSONFlow;
