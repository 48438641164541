// (C) Copyright 2017-2024 Hewlett Packard Enterprise Development LP

import { FormField } from 'grommet';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import { useCompaniesQuery } from '../../../core';
import AutocompleteSelect from '../../shared/component/AutocompleteSelect';
import ReadOnlyField from '../../shared/component/ReadOnlyField';
import { getSelectValue } from '../../shared/util/FormUtil';
import IDUtil from '../../shared/util/IDUtil';
import { OptionsBuilder } from '../../shared/util/OptionsBuilder';

const StyledFormField = styled(FormField)`
  div > button {
    border: none;
  }
`;

const CompanySelector = ({
  companyId = '',
  onCompanySelected,
  readOnly = false,
  ...rest
}) => {
  const [companyOptions, setCompanyOptions] = useState([]);

  const { data: companiesData } = useCompaniesQuery();
  useEffect(() => {
    if (companiesData) {
      setCompanyOptions(new OptionsBuilder(companiesData).labelKey('name')
        .valueKey('id')
        .build());
    }
  }, [companiesData]);

  const value = getSelectValue(companyId, companyOptions || []);

  if (readOnly) {
    return (
      <ReadOnlyField
        label='Company'
        value={value ? value.label : undefined}
        id={IDUtil.getId('BillingInfoEditorCompanyId')}
      />
    );
  }

  return (
    <StyledFormField label='Company'>
      <AutocompleteSelect
        name='companyId'
        options={companyOptions}
        size='medium'
        value={value ? value.value : undefined}
        onChange={(event) => {
          onCompanySelected('companyId', false, event.option?.value);
        }}
        labelKey='label'
        valueKey={{ key: 'value', reduce: true }}
        sortFn={(a, b) => a.label.localeCompare(b.label)}
        clear={{
          position: 'bottom',
          label: 'Clear'
        }}
        {...rest}
      />
    </StyledFormField>
  );
};

CompanySelector.propTypes = {
  companyId: PropTypes.string,
  onCompanySelected: PropTypes.func.isRequired,
  readOnly: PropTypes.bool,
};

export default CompanySelector;
