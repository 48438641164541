// (C) Copyright 2017-2024 Hewlett Packard Enterprise Development LP
import { useQueryClient } from '@tanstack/react-query';
import React, {
  useEffect, useMemo, useState,
} from 'react';
import { connect } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import isEqual from 'lodash/isEqual';

import {
  Anchor, Box, Main, Notification,
} from 'grommet';
import { FormPreviousLink } from 'grommet-icons';
import {
  useCurrencyQuery,
  useCustomerQuery,
  useGetServiceQuery,
} from '../../../core';
import {
  usePermissionChecker,
  useRoleChecker,
} from '../../shared/hooks';
import UserStore from '../../stores/UserStore';
import ServiceTypeStore from '../../stores/ServiceTypeStore';
import {
  initializeServiceEditor,
  initializeServiceOptions,
  setContext,
  setCustomerForServiceEditor,
  setOptions,
  setPermissions,
} from '../redux/ServiceActions';
import ServiceEdit from './ServiceEdit';
import Loader from '../../shared/loader';

const ServiceEditContainer = (props) => {
  const navigate = useNavigate();
  const params = useParams();
  const queryClient = useQueryClient();
  const { hasPermissions } = usePermissionChecker();
  const { isOneOfRoles } = useRoleChecker();
  const { customerId, serviceId } = params;
  const location = useLocation();
  const { state } = location;
  const tenant = state?.tenant || 'MASTER';
  const [loading, setLoading] = useState(true);
  const [response, setResponse] = useState(undefined);
  const me = useMemo(() => UserStore.getUser(), []);

  const {
    isFetching: isLoadingCustomer,
    data: customer
  } = useCustomerQuery(customerId, {
    onError: (error) => {
      setResponse({
        status: 'critical',
        message: `Unable to load customer: ${error || ''}`,
        stacktraceRef: undefined,
      });
      setLoading(false);
    },
  });

  useEffect(() => {
    // clear any cached data:
    queryClient.removeQueries({ queryKey: ['GLBM:EQUIPMENT-LIST', customerId, serviceId] });

    props.initializeServiceEditor();
    props.setContext({ tenant });
  }, []);

  useEffect(() => {
    props.setPermissions({
      user: me,
      isOneOfRoles,
      customer,
      hasPermissions,
      serviceId,
      tenant,
    });
  }, [props.setPermissions, customer, hasPermissions, isOneOfRoles, me, serviceId, tenant]);

  const {
    data: currencyData,
    error: currencyError,
  } = useCurrencyQuery(customer ? (customer.contractCurrency || 'USD') : undefined);

  const {
    isError: isServiceError,
    error: serviceError,
    isSuccess: isServiceSuccess,
    data: serviceData,
    refetch: _getOptions
  } = useGetServiceQuery(customer?.id, serviceId, {
    enabled: false,
  });

  useEffect(() => {
    if (isServiceSuccess && serviceData) {
      const options = JSON.parse(JSON.stringify(serviceData));
      if (!options.config.hasOwnProperty('serviceType')) {
        options.config.serviceType = options.serviceType;
      }
      props.initializeServiceOptions(options);
      if (options.status === 'NEW') {
        options.status = 'INCOMPLETE';
      }
      props.setOptions(options);
      setLoading(false);
    }
  }, [isServiceSuccess, serviceData]);

  useEffect(() => {
    if (isServiceError && serviceError) {
      setLoading(false);
      setResponse({
        status: 'critical',
        message: `Unable to load service: ${serviceError?.response?.data?.message || serviceError?.message}`,
        stacktraceRef: undefined
      });
    }
  }, [isServiceError, serviceError]);

  useEffect(() => {
    if (currencyData) {
      const newCustomer = { ...customer, preferences: currencyData };
      if (!isEqual(customer, newCustomer)) {
        props.setCustomer(newCustomer);
        _getOptions();
      }
    }
    if (currencyError) {
      console.error(currencyError);
      setResponse({
        status: 'critical',
        message: `Unable to load customer currency: ${currencyError.message}`,
        stacktraceRef: undefined,
      });
      setLoading(false);
    }
  }, [currencyData, currencyError, customer]);

  // If they click Back on the first step
  const _cancel = () => {
    navigate(-1);
  };

  const _getLoadingContent = () => (
    <Main
      direction='column'
      appCentered={true}
      size='full'
      justify='center'
    >
      <Box
        direction='row'
        gap='small'
        justify='center'
      >
        <Loader text='Loading. Please wait ...' />
      </Box>
    </Main>
  );

  const _getResponseContent = () => {
    const serviceTypeEnum = ServiceTypeStore.getService(serviceId);
    return (
      <Box
        fill={false}
        direction='column'
        size='full'
        justify='center'
      >
        <Notification
          toast={true}
          state={response.stacktraceRef}
          title={response.message}
          message={`Unable to show Service Configuration Wizard for ${serviceTypeEnum.displayName} service`}
          status={response.status}
        />
        <Box
          direction='row'
          pad='medium'
          justify='start'
        >
          <Anchor animateIcon={true} primary={true} onClick={_cancel} icon={<FormPreviousLink size='xxlarge' color='brand' />}>Back to services</Anchor>
        </Box>
      </Box>
    );
  };
  if (loading) {
    return _getLoadingContent();
  }
  if (response) {
    return _getResponseContent();
  }
  return (<ServiceEdit location={location} navigate={navigate} params={params} />);
};
const mapStateToProps = store => ({
  serviceEditor: store.service.details,
});
const mapDispatchToProps = dispatch => bindActionCreators({
  setCustomer: setCustomerForServiceEditor,
  setPermissions,
  setOptions,
  setContext,
  initializeServiceOptions,
  initializeServiceEditor,
}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ServiceEditContainer);
