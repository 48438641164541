// (C) Copyright 2017-2024 Hewlett Packard Enterprise Development LP

import {
  useMutation,
  useQueries,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';
import { useContext } from 'react';
import moment from 'moment';
import { ApiContext } from '../../AppContext';
import { useErrorToast } from '../../components/shared/hooks/useErrorToast';
import { useToast } from '../../components/shared/toast/ToastProvider';
import {
  executeGet, executeUpload
} from '../api';

export const useUsageFilesQuery = (customerId, queryParams, options) => {
  const path = useContext(ApiContext)('usageFiles.list.path', { customerId }, queryParams);
  return useErrorToast(useQuery({
    queryKey: ['GLBM:USAGE-FILE-LIST', customerId, queryParams],
    queryFn: () => executeGet(path),
    ...(options || []),
  }), 'Fetch Metering Files Error');
};

export const useUsageFilesQueries = (customerId, queryParams, options) => {
  const contextFn = useContext(ApiContext);
  const queryClient = useQueryClient();
  const arrayOfDates = [];
  if (queryParams?.from) arrayOfDates.push(queryParams?.from);
  if (arrayOfDates?.length > 0 && queryParams?.to) {
    while (moment(arrayOfDates[arrayOfDates.length - 1]).isBefore(queryParams?.to)) {
      const newDate = moment(arrayOfDates[arrayOfDates.length - 1]).clone().add(1, 'day').format('YYYY-MM-DD');
      arrayOfDates.push(newDate);
    }
  }
  const arrayOfParams = arrayOfDates.map(date => ({ ...queryParams, from: date, to: date }));
  return useErrorToast(useQueries(
    {
      queries: arrayOfParams.map(param => ({
        queryKey: ['GLBM:USAGE-FILE-LIST', customerId, param],
        queryFn: ({ signal }) => {
          const path = contextFn('usageFiles.list.path', { customerId }, param);
          return executeGet(path, { signal });
        },
        ...(options || {}),
      })),
      combine: results => ({
        data: results.reduce((acc, res) => (res?.data ? [...acc, ...res.data] : acc), []),
        refetch: () => {
          arrayOfParams.forEach((param) => {
            queryClient.removeQueries({ queryKey: ['GLBM:USAGE-FILE-LIST', customerId, param] });
          });
          results.forEach((res) => {
            res.refetch();
          });
        },
        isFetching: results.some(res => res.isFetching),
        isError: results.some(res => res.isError),
        error: results.find(res => res.isError)?.error,
        total: arrayOfParams.length,
        loaded: results.filter(res => res.isSuccess && !res.isFetching).length,
        cancel: () => arrayOfParams.forEach((param) => {
          queryClient.cancelQueries({ queryKey: ['GLBM:USAGE-FILE-LIST', customerId, param] });
        })
      })
    }
  ), 'Fetch Metering Files Error');
};

export const useUsageFilenameValidationQuery = (files, options) => {
  const contextFn = useContext(ApiContext);
  return useQueries(
    {
      queries: files.map(({ name: filename }) => ({
        queryKey: ['GLBM:USAGE-FILE-VALIDATION', filename],
        queryFn: () => {
          const path = contextFn('usageFiles.validate.path', { filename });
          return executeGet(path);
        },
      })),
      ...(options || {}),
    }
  );
};

export const useUsageFileUploadMutate = (options) => {
  const queryClient = useQueryClient();
  const path = useContext(ApiContext)('usageFiles.upload.path');
  const { addToast } = useToast();
  return useMutation({
    mutationFn: (file) => {
      const payload = new FormData();
      payload.append('file', file);
      return executeUpload(path, payload);
    },
    ...(options || {}),
    onSuccess: (response, payload) => {
      queryClient.removeQueries({
        queryKey: ['GLBM:USAGE-FILE-LIST']
      });
      addToast({
        status: 'normal',
        title: 'Uploaded',
        message: 'Usage Files Successfully Uploaded',
      });
      options?.onSuccess?.(response, payload);
    },
    onError: (error) => {
      addToast({
        status: 'critical',
        title: 'Usage File Upload Error',
        message: error?.response?.data?.message,
      });
    }
  });
};
