// (C) Copyright 2017-2024 Hewlett Packard Enterprise Development LP

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Footer,
  Form,
  FormField,
  Select,
  Text, TextInput,
} from 'grommet';
import EmailFieldsForm from '../../shared/component/ManyFieldsForm';
import { isAssignedRole } from '../../shared/constants/UserType';
import GLBMLayer from '../../shared/component/GLBMLayer';
import { useUserRoleQuery } from '../../../core';
import IDUtil from '../../shared/util/IDUtil';

const ApproverEditor = ({
  approver: initialApprover = {}, onClose = () => undefined, isNew = true, isLoading = false, onChange = () => undefined
}) => {
  const [approver, setApprover] = useState(initialApprover);
  const [errors, setErrors] = useState({});
  const {
    data: userRoles,
  } = useUserRoleQuery();

  const roleOptions = userRoles?.map(el => ({ value: el.id, label: el.displayName })).filter(el => isAssignedRole(el.value)) || [];
  const getRoleName = roleId => roleOptions?.find(el => el.value === roleId)?.label;
  const emailValidation = (email) => {
    if (!email) {
      return 'Required';
    } if (!(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]+$/i).test(email)) {
      return 'Invalid Email';
    }
    return undefined;
  };

  const onFieldChange = (value, fieldName) => {
    const newApprover = { ...approver };
    const newErrors = { ...errors };
    newApprover[fieldName] = value;
    if (!value) {
      newErrors[fieldName] = 'Required';
    } else if (fieldName === 'emails') {
      if (value.length === 0) {
        newErrors[fieldName] = 'Required';
      }
    } else {
      newErrors[fieldName] = undefined;
    }
    setApprover(newApprover);
    setErrors(newErrors);
  };

  const handleSave = () => {
    let noErrors = true;
    const newErrors = { ...errors };
    if (!approver.role) {
      noErrors = false;
      newErrors.role = 'Required';
    }
    if (!approver.location?.trim()) {
      noErrors = false;
      newErrors.location = 'Required';
    }
    if (!approver.name?.trim()) {
      noErrors = false;
      newErrors.name = 'Required';
    }
    if (!approver.emails || approver.emails.length === 0) {
      noErrors = false;
      newErrors.emails = 'Required';
    } else if (approver.emails.some(email => !email || emailValidation(email))) {
      newErrors.emails = 'fields';
      noErrors = false;
    }
    setErrors(newErrors);
    if (noErrors) {
      onChange(approver);
    }
  };

  return (
    <GLBMLayer
      position='right'
      full='vertical'
      id='edit-layer'
      onClickOutside={onClose}
      onClose={onClose}
      onEsc={onClose}
      closer={true}
      flush={true}
      overlayClose={true}
      title={isNew ? 'Add new approvers' : 'Edit approvers'}
    >
      <Box
        pad='none'
        direction='column'
        flex={true}
        fill='vertical'
        style={{ width: '400px' }}
      >
        <Box flex={true} pad={{ 'horizontal': 'small' }} align='start'>
          <Box flex={true} direction='column' justify='between' fill='horizontal'>
            <Form>
              <FormField label='Role' error={errors.role} required={true}>
                <Select
                  labelKey='label'
                  valueKey='value'
                  id={IDUtil.getId('ApproverEditorRoleSelect')}
                  value={approver.role}
                  options={roleOptions}
                  valueLabel={(
                    <Box height='34px' justify='center' pad={{ start: 'small' }}>
                      <Text>{approver.role && getRoleName(approver.role) ? getRoleName(approver.role) : undefined}</Text>
                    </Box>
                  )}
                  onChange={e => onFieldChange(e.value.value, 'role')}
                />
              </FormField>
              <FormField label='Location' error={errors.location} required={true}>
                <TextInput
                  id={IDUtil.getId('ApproverEditorLocationInput')}
                  maxlength='20'
                  name='location'
                  value={approver.location}
                  onChange={e => onFieldChange(e.target.value, 'location')}
                />
              </FormField>
              <FormField label='Name' error={errors.name} required={true}>
                <TextInput
                  id={IDUtil.getId('ApproverEditorNameInput')}
                  name='name'
                  maxlength='50'
                  value={approver.name}
                  onChange={e => onFieldChange(e.target.value, 'name')}
                />
              </FormField>
              <EmailFieldsForm
                label='Emails*'
                name='Emails'
                values={approver.emails}
                error={errors.emails}
                onChange={value => onFieldChange(value, 'emails')}
                fieldsValidation={emailValidation}
              />
            </Form>
          </Box>
        </Box>
      </Box>
      <Box border='top' pad='small' margin={{ top: 'none' }} flex={false}>
        <Footer flex={false} justify='start' gap='small'>
          <Button
            label='Save'
            primary={true}
            disabled={isLoading}
            onClick={!isLoading ? () => handleSave() : () => undefined}
          />
          <Button label='Cancel' secondary={true} onClick={onClose} />
        </Footer>
      </Box>
    </GLBMLayer>
  );
};

ApproverEditor.propTypes = {
  approver: PropTypes.object,
  onChange: PropTypes.func,
  onClose: PropTypes.func,
  isNew: PropTypes.bool,
  isLoading: PropTypes.bool,
};

export default ApproverEditor;
