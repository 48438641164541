// (C) Copyright 2017-2024 Hewlett Packard Enterprise Development LP
import { Box } from 'grommet';
import PropTypes from 'prop-types';
import React from 'react';

const TypeLabel = ({ label = '' }) => (
  <Box direction='row' align='center' gap='small'>
    <span>{label}</span>
  </Box>
);

TypeLabel.propTypes = {
  label: PropTypes.string,
};

export default TypeLabel;
