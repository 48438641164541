// (C) Copyright 2017-2024 Hewlett Packard Enterprise Development LP
import React, { useContext } from 'react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { Checkmark } from 'grommet-icons';
import { useToast } from '../../components/shared/toast/ToastProvider';
import { ApiContext } from '../../AppContext';
import { executeDelete, executePut } from '../api';
import type { Feed } from '../types';

export const useServiceFeedUpdateMutate = (customerId, serviceType, options) => {
  const queryClient = useQueryClient();
  const path = useContext(ApiContext)('feeds.update.path', { customerId, serviceType });
  const { addToast } = useToast();
  return useMutation<unknown, Error, Feed[]>({
    mutationFn: (payload: Feed[]) => executePut(path, payload),
    ...(options || {}),
    onSuccess: () => {
      queryClient.removeQueries({
        queryKey: ['GLBM:SERVICE-LIST', customerId]
      });
      addToast({
        status: 'normal',
        title: 'Saved.',
        icon: <Checkmark color='brand' />,
      });
      options?.onSuccess?.();
    }
  });
};

export const useServiceFeedDeleteMutate = (customerId, serviceType, options) => {
  const queryClient = useQueryClient();
  const contextFn = useContext(ApiContext);
  return useMutation({
    mutationFn: (feedId) => {
      const path = contextFn('feeds.delete.path', { customerId, serviceType, feedId });
      return executeDelete(path);
    },
    ...(options || {}),
    onSuccess: () => {
      queryClient.removeQueries({
        queryKey: ['GLBM:SERVICE-LIST', customerId]
      });
      queryClient.removeQueries({
        queryKey: ['GLBM:SERVICE-TYPE', customerId, serviceType]
      });
      options?.onSuccess?.();
    }
  });
};
