// (C) Copyright 2017-2024 Hewlett Packard Enterprise Development LP

import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { formatNumberWithSigDigits } from './utils';

export const CellFormatter = ({
  value = undefined,
  colDef = undefined,
  data = undefined,
}) => {
  const change = value?.change;

  let changeElement;
  if (change !== undefined) {
    if (change === 0 || Math.abs(change) < 0.0001) {
      changeElement = '';
    } else if (change > 0) {
      changeElement = (
        <span className='ag-value-change-delta-up'>
          +&nbsp;
          {Math.abs(change).toLocaleString()}
        </span>
      );
    } else {
      changeElement = (
        <span className='ag-value-change-delta-down'>
          (-&nbsp;
          {Math.abs(change).toLocaleString()}
          )
        </span>
      );
    }
  }

  let cellId = '';
  if (colDef?.tableId) cellId = `${cellId + colDef.tableId}-`;
  if (colDef?.field) cellId = `${cellId + colDef.field}-`;
  if (data?.type) cellId += data.type.replace(/ /gi, '-');
  return (
    <div id={cellId} data-testid='cell'>
      {changeElement}
      {value?.unit === '$' ? <span>{value.unit}</span> : ''}
      <span>{(value && value?.value !== undefined) ? formatNumberWithSigDigits(value?.value, 2) : ''}</span>
      {value?.unit !== '$' ? (
        <span>
          &nbsp;
          {value?.unit}
        </span>
      ) : ''}
    </div>
  );
};
CellFormatter.propTypes = {
  value: PropTypes.shape({
    change: PropTypes.number,
    unit: PropTypes.string,
    value: PropTypes.any,
  }),
  colDef: PropTypes.shape({
    tableId: PropTypes.string,
    field: PropTypes.string,
  }),
  data: PropTypes.shape({
    type: PropTypes.string,
  })
};

const CapacityPlanningUtil = {

  transformMeterDetails: (data, missing) => data
    .map(({
      used,
      usable,
      requested,
      committed,
      date,
      ...rest
    }) => {
      const highStart = requested <= usable ? requested : usable;
      const optimalStart = committed <= requested && requested <= usable ? committed : requested;
      const lowStart = committed <= requested && requested <= usable ? 0 : committed;

      return {
        ...rest,
        ...(!missing ? {
          high: [highStart, usable],
          requested,
          committed,
          optimal: [optimalStart, requested],
          low: [lowStart, committed],
        } : {}),
        usable,
        used,
        date: moment(date).format('M/D/YY')
      };
    }),

  processData: (data, forecastDays) => {
    const actuals = data.values.filter(v => !v.forecast).map(v => v.used);
    const forecast = data.values.filter(v => v.forecast).map(v => v.used);
    const series = [].concat(data.values);
    const banded = (typeof data.values[0].committed !== 'undefined' && typeof data.values[0].requested !== 'undefined');

    return {
      actuals: actuals.concat(new Array(forecastDays)),
      forecast: new Array(actuals.length - 1).concat(forecast),
      series,
      earliestDate: data.earliestDate,
      lastDate: data.lastDate,
      banded,
      unit: data.unit,
    };
  },

  getGridData(data, group, missingContractData, tableId) {
    return {
      group,
      data,
      cols: this.createCols(data.lastDate, data.earliestDate, tableId),
      rows: this.createRows(data.series, data.unit, missingContractData),
      gridOptions: {
        columnDefs: [],
        rowData: [],
      },
    };
  },

  createCols: (lastDate, earliestDate, tableId) => {
    const columns = [
      {
        field: 'type',
        headerName: 'Type',
        width: 150,
        pinned: 'left',
        suppressMenu: true,
      },
    ];
    const today = moment();

    for (let i = 0; i <= moment(lastDate).startOf('month').diff(moment(earliestDate), 'months'); i += 1) {
      const date = moment(earliestDate).add(i, 'months');
      const current = (today.format('YYYY-MM') === moment(date).format('YYYY-MM'));
      const forecast = (current || date.isAfter(today));
      columns.push({
        field: date.format('YYYY-MM'),
        headerName: date.format('MMM YYYY') + (forecast ? ' (forecast)' : ''),
        width: 200,
        current,
        tableId: tableId || '',
        cellRenderer: CellFormatter,
        cellClass(params) {
          return (params.value && params.value.current ? 'data-grid-cell-container data-grid-cell-current' : 'data-grid-cell-container');
        },
        suppressMenu: true,
      });
    }

    return columns;
  },

  createRows: (series, unit, missingContractData) => {
    const rows = {};

    const types = {
      'usable': { name: 'Installed Capacity', order: 0 },
      'requested': { name: 'Requested Capacity', order: 1 },
      'committed': { name: 'Reserved Capacity', order: 2 },
      'used': { name: 'Usage', order: 3 },
      'available': { name: 'Available Capacity', order: 4 },
    };

    if (missingContractData) {
      delete types.requested;
      delete types.committed;
    }

    const data = series;
    series.sort((a, b) => {
      if (a.date < b.date) { return -1; }
      if (a.date > b.date) { return 1; }
      return 0;
    });

    Object.keys(data[0]).forEach((key) => {
      if (types[key]) {
        rows[key] = { type: types[key].name, order: types[key].order };
      }
    });

    const s1 = moment().format('YYYY-MM');
    data.forEach((d) => {
      const s = moment(d.date).format('YYYY-MM');
      Object.keys(d).forEach((key) => {
        switch (key) {
          case 'usable':
          case 'requested':
          case 'committed':
          case 'available':
            if (Object.hasOwn(rows, key)) {
              rows[key][s] = rows[key][s] || {
                total: 0,
                count: 0,
                unit,
                current: (s1 === s),
              };
              rows[key][s].total = d[key]; // usable --> Installed Capacity - uses the last day of the month...
              rows[key][s].count += 1;
            }
            break;
          case 'used':
            rows[key][s] = rows[key][s] || {
              total: 0,
              count: 0,
              unit,
              current: (s1 === s),
            };
            rows[key][s].total += d[key]; // the rest - adds them all up... and then divides by number of days...
            rows[key][s].count += 1;
            break;
          default:
            break;
        }
      });
    });

    Object.keys(rows).forEach((key) => {
      const keys = Object.keys(rows[key]).sort((a, b) => {
        if (a < b) { return -1; }
        if (a === b) { return 0; }
        return 1;
      });
      keys.forEach((k, i) => {
        if (k !== 'type' && k !== 'order') {
          if (key === 'usable' || key === 'requested' || key === 'committed' || key === 'available') {
            rows[key][k] = {
              value: rows[key][k].total || 0,
              change: (i > 0) ? (rows[key][k].total) - rows[key][keys[i - 1]].value : 0,
              unit: rows[key][k].unit,
              current: rows[key][k]?.current,
              total: rows[key][k]?.total,
              count: rows[key][k]?.count,
            };
          } else {
            rows[key][k] = {
              value: (rows[key][k].total / rows[key][k].count) || 0,
              change: (i > 0) ? (rows[key][k].total / rows[key][k].count) - rows[key][keys[i - 1]].value : 0,
              unit: rows[key][k].unit,
              current: rows[key][k]?.current,
              total: rows[key][k]?.total,
              count: rows[key][k]?.count,
            };
          }
        }
      });
    });

    return Object.keys(rows).map(x => rows[x]).sort((a, b) => a.order - b.order);
  },
};
export default CapacityPlanningUtil;
