// (C) Copyright 2017-2024 Hewlett Packard Enterprise Development LP

import { useEffect } from 'react';
import type { UseQueryResult } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { useToast } from '../toast/ToastProvider';

export const useErrorToast = <T extends UseQueryResult>(result: T, title: string): T => {
  const { addToast } = useToast();
  useEffect(() => {
    if (result.isError) {
      addToast({
        status: 'critical',
        title,
        message: (result.error instanceof AxiosError ? result?.error?.response?.data?.message : undefined) || result?.error?.message,
      });
    }
    // show fn changes on every render, so don't include in the effect deps
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [result.isError]);
  return result;
};
