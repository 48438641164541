// (C) Copyright 2017-2024 Hewlett Packard Enterprise Development LP

import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { PurposeType } from '../shared/constants/PurposeType';
import GLBMNameValueList from '../shared/component/GLBMNameValueList';

const isEmptyObj = obj => (obj // 👈 null and undefined check
  && Object.keys(obj).length === 0
  && Object.getPrototypeOf(obj) === Object.prototype);

const CustomerConnectionInfoPanel = ({ data = {} }) => {
  const hasData = useMemo(() => data && !isEmptyObj(data), [data]);
  const payload = useMemo(() => [
    { label: 'CA Tenant', value: data?.caTenantId ? `${data.caTenantName} (${data.caTenantId})` : '-' },
    { label: 'CA Tenant Company', value: data?.caTenantCompany ? `${data.caTenantCompany}` : '-' },
    { label: 'GLC Tenant', value: data?.glcTenantId ? `${data.glcTenantName} (${data.glcTenantId})` : '-' },
    // eslint-disable-next-line no-nested-ternary
    { label: 'GLC Purpose', value: data?.glcPurpose ? (PurposeType.enumValueOf(data.glcPurpose) ? PurposeType.enumValueOf(data.glcPurpose).label : data.glcPurpose) : '-' },
    { label: 'GLP Workspace ID', value: data?.glpWorkspaceId || '-' },
  ], [data]);

  return (
    <GLBMNameValueList
      title='Customer Information'
      message={!hasData ? 'This billing account is currently not connected to a CA or GLC tenant.' : undefined}
      data={hasData ? payload : []}
    />
  );
};

CustomerConnectionInfoPanel.propTypes = {
  data: PropTypes.object,
};

export default CustomerConnectionInfoPanel;
