// (C) Copyright 2017-2024 Hewlett Packard Enterprise Development LP
import { Box, Heading } from 'grommet';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import ReactRouterPrompt from 'react-router-prompt';
import { bindActionCreators } from 'redux';
import PartnerBadge from '../../shared/badges/PartnerBadge';

import SaveChangesDialog from '../../shared/dialogs/SaveChangesDialog';
import ServiceTypeStore from '../../stores/ServiceTypeStore';
import Wizard from '../../wizard/Wizard';
import Step from '../../wizard/WizardStep';
import { ServiceStep } from '../model';
import ServiceSaveDialog from './ServiceSaveDialog';
import ConfigureResources from './steps/configure-resources/ConfigureResources';
import ManageRates from './steps/rates/ManageRates';
import ServiceOptions from './steps/service-options/ServiceOptions';

const ServiceEdit = (props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams();

  // provide a default location.state object if needed:
  useEffect(() => {
    if (!location.state) {
      location.state = {
        origin: 'services',
        tenant: 'MASTER',
      };
    }
  }, [location.state]);

  const { permissions } = props;
  const { customer } = props.serviceEditor;
  const { customerId, serviceId } = params;
  const { origin } = location.state ? location.state : { origin: 'services' };

  const [layer, setLayer] = useState(undefined);
  const [isDirty, setIsDirty] = useState(false);

  const tenant = useMemo(() => {
    if (location && location.state) {
      return (location.state.tenant);
    }
    return 'MASTER';
  }, [location]);

  const _setDirty = () => {
    if (!permissions.asmRole || (permissions.canEditMappings || permissions.canEditOptions || permissions.canEditRates)) {
      setIsDirty(true);
    }
  };

  const steps = useMemo(() => {
    const p = (permissions);
    const steps = [];
    if (p.canReadOptions) {
      steps.push(
        // eslint-disable-next-line no-use-before-define
        <Step key={ServiceStep.OPTIONS.enumKey} type={ServiceStep.OPTIONS.enumKey} label='Service options' validation={() => true} onNext={_onOptionsNext}>
          <ServiceOptions setDirty={_setDirty} readOnly={!permissions.canEditOptions} />
        </Step>,
      );
    }
    if (p.canReadMappings) {
      steps.push(
        // eslint-disable-next-line no-use-before-define
        <Step key={ServiceStep.CONFIGURE_RESOURCES.enumKey} type={ServiceStep.CONFIGURE_RESOURCES.enumKey} label='Configure resources' validation={() => true} onNext={_onMappingsNext} onPrev={_onMappingsPrev}>
          <ConfigureResources setDirty={_setDirty} customer={customer} readOnly={!permissions.canEditMappings} />
        </Step>,
      );
    }
    if (p.canReadRates) {
      steps.push(
        <Step
          key={ServiceStep.RATES.enumKey}
          type={ServiceStep.RATES.enumKey}
          label={p.canEditRates || p.canMarkupRates ? 'Manage rates' : 'View rates'}
          validation={() => true}
        >
          <ManageRates
            setDirty={_setDirty}
            customer={customer}
            serviceType={props.serviceType}
            tenant={tenant}
            readOnly={!permissions.canEditRates}
            permissions={permissions}
          />
        </Step>,
      );
    }

    return steps;
  }, [props.serviceEditor]);

  const _onOptionsNext = () => {

  };

  const _onMappingsNext = () => {

  };

  const _onMappingsPrev = () => {

  };

  const _onFinish = () => {
    setLayer('saveService');
  };

  const _onSaveServiceDialogClosed = (success) => {
    setLayer(undefined);
    if (success) {
      setIsDirty(false);
      setTimeout(() => {
        navigate(`/customers/${customerId}/services`);
      }, 100);
    }
  };

  const canSave = useMemo(() => {
    const {
      canEditOptions, canEditMappings, canEditRates, canMarkupRates,
    } = permissions || {};
    return canEditOptions || canEditMappings || canEditRates || canMarkupRates;
  }, [permissions]);

  const _isDirty = useMemo(
    () => layer !== 'saveService' && !!(isDirty),
    [layer, isDirty]
  );

  const _isDirtyWarning = () => (customer && customer.status === 'BILLABLE' && props.serviceEditor.options && props.serviceEditor.options.status === 'COMPLETE');

  const customerTitle = (customer ? `${customer.name} (${customer.id})` : '');
  const forcePage = (props.params.page) ? 1 : 0;
  const { canEditOptions, canEditMappings, canEditRates } = permissions;
  const editableServiceWizard = canEditOptions || canEditMappings || canEditRates;

  if (!customer) {
    return (<Box />);
  }

  // if the user editing can save changes:
  const callback = (canSave ? _onFinish : undefined);
  return (
    <Box direction='column' fill='vertical'>
      <Wizard
        title={(
          <Box direction='row' gap='xxsmall' align='center'>
            <Heading level='2'>Set up</Heading>
            {tenant && tenant !== 'MASTER' ? <PartnerBadge tenant={tenant} /> : ''}
            <Heading level='2'>{props.serviceType && props.serviceType.label}</Heading>
            <Heading level='2'>service:</Heading>
            <Heading level='2'>{customerTitle}</Heading>
          </Box>
)}
        forcePage={forcePage}
        callback={callback}
        origin={origin}
        history={props.history}
        dirtyWarning={_isDirtyWarning()}
        unsavedChanges={() => true}
      >
        {steps}
      </Wizard>
      <ReactRouterPrompt when={_isDirty && editableServiceWizard}>
        {({ isActive, onConfirm, onCancel }) => (
          <SaveChangesDialog
            onConfirm={onConfirm}
            onCancel={onCancel}
          />
        )}
      </ReactRouterPrompt>
      {(layer === 'saveService') && (
        <ServiceSaveDialog
          onClose={_onSaveServiceDialogClosed}
          customerId={customerId}
          serviceId={serviceId}
        />
      )}
    </Box>
  );
};

/**
 * This function is called to let us know about the state changes in the store. The goal here is to return from the overall store, what
 * THIS component cares about from the uber-store. In this case, this panel wants to for now only see the 'service' options (ie. first step in Blades wizard):
 */
const mapStateToProps = ({ service }) => ({
  serviceType: ServiceTypeStore.getService(service.details.options.config.serviceType),
  serviceEditor: service.details,
  permissions: service.details.permissions,
  validation: service.details.validation,
});
const mapDispatchToProps = dispatch => bindActionCreators({
}, dispatch);

ServiceEdit.contextTypes = {
  router: PropTypes.object,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ServiceEdit);
