// (C) Copyright 2017-2024 Hewlett Packard Enterprise Development LP
import {
  analyticsList,
  capacityPlanning,
} from './AnalyticsReducers';

export {
  analyticsList,
  capacityPlanning,
};
