// (C) Copyright 2017-2024 Hewlett Packard Enterprise Development LP
import React from 'react';
import PropTypes from 'prop-types';
import {
  Box, Button, Footer, Spinner,
} from 'grommet';
import { Checkmark, Save } from 'grommet-icons';
import IDUtil from '../../shared/util/IDUtil';

const NavButtons = ({
  submit = undefined, close, saving, saved
}) => {
  let submitControl;

  if (submit) {
    if (!saving && !saved) {
      submitControl = (
        <Button
          label='Promote'
          key={IDUtil.getId('ServicePromoteDialogOKButton')}
          id={IDUtil.getId('ServicePromoteDialogOKButton')}
          primary={true}
          icon={<Save />}
          onClick={submit}
        />
      );
    } else if (saving && !saved) {
      submitControl = (
        <Button
          label='Promoting...'
          key={IDUtil.getId('ServicePromoteDialogOKButton')}
          id={IDUtil.getId('ServicePromoteDialogOKButton')}
          plain={true}
          primary={false}
          icon={<Spinner className='spinning-small' />}
        />
      );
    } else if (saved) {
      submitControl = (
        <Button
          label='Promoted'
          key={IDUtil.getId('ServicePromoteDialogOKButton')}
          id={IDUtil.getId('ServicePromoteDialogOKButton')}
          primary={true}
          icon={<Checkmark />}
          onClick={close}
        />
      );
    }
  }

  let closeControl;
  if (close && !saved) {
    closeControl = (
      <Button
        label='Cancel'
        onClick={close}
        key={IDUtil.getId('ServicePromoteDialogCancelButton')}
        id={IDUtil.getId('ServicePromoteDialogCancelButton')}
        secondary={true}
      />
    );
  }

  return (
    <Box border='top' pad='small' margin={{ top: 'none' }} flex={false}>
      <Footer flex={false} justify='start' gap='small'>
        {[submitControl, closeControl]}
      </Footer>
    </Box>
  );
};

NavButtons.propTypes = {
  submit: PropTypes.func,
  close: PropTypes.func.isRequired,
  saving: PropTypes.bool.isRequired,
  saved: PropTypes.bool.isRequired,
};

export default NavButtons;
