// (C) Copyright 2017-2024 Hewlett Packard Enterprise Development LP

import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { Box, Keyboard } from 'grommet';
import MeterItem from './MeterItem';

// flat list of meter items, with keyboard nav enabled
const MeterItems = ({
  meters = [],
  onSelectMeter,
  selectedMeterId = undefined,
  rateProps = {},
  leftPad = undefined,
  onNextGroup = undefined,
  onPrevGroup = undefined,
  idPrefix = undefined,
  ...rest
}) => {
  const onSelectMeterByKey = useCallback((isPrev) => {
    const meterIndex = meters.map(m => m.id).indexOf(selectedMeterId);
    let meterToSelect;
    if (isPrev && meterIndex > 0) {
      meterToSelect = meters[meterIndex - 1];
    } else if (!isPrev && meterIndex < (meters.length - 1)) {
      meterToSelect = meters[meterIndex + 1];
    }

    if (meterToSelect) {
      onSelectMeter(meterToSelect);
    } else if (isPrev && onPrevGroup) {
      onPrevGroup();
    } else if (!isPrev && onNextGroup) {
      onNextGroup();
    }
  }, [meters, selectedMeterId, onSelectMeter, onNextGroup, onPrevGroup]);

  return (
    <Keyboard
      onUp={(event) => {
        event.preventDefault();
        event.stopPropagation();
        onSelectMeterByKey(true);
      }}
      onDown={(event) => {
        event.preventDefault();
        event.stopPropagation();
        onSelectMeterByKey(false);
      }}
    >
      <Box {...rest}>
        {meters.map((m, index) => (
          <MeterItem
            key={m.id}
            meter={m}
            onClick={onSelectMeter}
            selected={selectedMeterId === m.id}
            rate={rateProps[m.id]}
            leftPad={leftPad}
            index={idPrefix !== undefined ? `${idPrefix}-${index}` : index}
          />
        ))}
      </Box>
    </Keyboard>
  );
};

MeterItems.propTypes = {
  meters: PropTypes.arrayOf(PropTypes.object),
  onSelectMeter: PropTypes.func.isRequired,
  selectedMeterId: PropTypes.string,
  rateProps: PropTypes.object,
  leftPad: PropTypes.string,
  onNextGroup: PropTypes.func,
  onPrevGroup: PropTypes.func,
  idPrefix: PropTypes.number,
};

export default MeterItems;
