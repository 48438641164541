// (C) Copyright 2017-2024 Hewlett Packard Enterprise Development LP
import { Enumify } from 'enumify';

export class EquipmentFilter extends Enumify {
  static ALL = new EquipmentFilter('All');

  static ACTIVE = new EquipmentFilter('Active');

  static END_OF_SYSTEM_TERM = new EquipmentFilter('BILLABLE');

  static _ = this.closeEnum(); // TypeScript: Color.closeEnum()

  constructor(label) {
    super();
    this.label = label;
  }
}
