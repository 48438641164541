// (C) Copyright 2017-2024 Hewlett Packard Enterprise Development LP

import React, { useCallback, useContext, useEffect } from 'react';
import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js';
import { Security } from '@okta/okta-react';
import { Box } from 'grommet';
import { useNavigate } from 'react-router-dom';
import GlbmRoutes from './GlbmRoutes';
import { OktaContext } from './AppContext';

const getParamValue = (param: string) => {
  const searchParamValue = new URLSearchParams(window.location.search).get(param);
  if (searchParamValue) {
    sessionStorage.setItem(param, searchParamValue);
    return searchParamValue;
  }
  return sessionStorage.getItem(param);
};

const GlbmApp: React.FC = () => {
  const navigate = useNavigate();
  const oktaAuthClient = useContext<OktaAuth>(OktaContext);

  useEffect(() => {
    getParamValue('previews');
  }, []);

  const restoreOriginalUri = useCallback((_oktaAuth: OktaAuth, originalUri: string) => {
    navigate(toRelativeUrl(originalUri, window.location.origin), { replace: true });
  }, [navigate]);

  const onAuthRequired = useCallback(() => {
    navigate('/');
  }, [navigate]);

  return (
    <Box fill={true} overflow='hidden' height='100vh'>
      <Security oktaAuth={oktaAuthClient} restoreOriginalUri={restoreOriginalUri} onAuthRequired={onAuthRequired}>
        <GlbmRoutes />
      </Security>
    </Box>
  );
};

export default GlbmApp;
