// (C) Copyright 2017-2024 Hewlett Packard Enterprise Development LP
import React, { useEffect, useMemo, useState } from 'react';
import ReactRouterPrompt from 'react-router-prompt';
import { useNavigate, useParams } from 'react-router-dom';

import {
  Box,
  Button,
  Main,
} from 'grommet';
import {
  Add, Search,
} from 'grommet-icons';
import PropTypes from 'prop-types';
import GLBMSaving from '../shared/component/GLBMSaving';
import SaveChangesDialog from '../shared/dialogs/SaveChangesDialog';
import IDUtil from '../shared/util/IDUtil';
import ConfirmationDialog from '../shared/dialogs/ConfirmationDialog';
import GLBMSearch from '../shared/component/GLBMSearch';
import { pagePermissions } from '../shared/constants/Permissions';
import { usePermissionChecker } from '../shared/hooks';
import ASMListEditor from './ASMListEditor';
import GLBMHeading from '../shared/component/GLBMHeading';
import { insertIf } from '../shared/util/BasicUtil';
import GLBMNameValueList from '../shared/component/GLBMNameValueList';
import {
  useASMUsersQuery,
  useCustomerQuery,
  useRemoveASMMutate,
  useSaveASMMutate,
} from '../../core';
import UserStore from '../stores/UserStore';
import ASMListTable from './ASMListTable';

/**
 * For all the users in adminList, if not found in users list, then remove from adminList, user must be deleted
 * @param users
 * @param adminList
 */
const pruneMissingUsers = (adminList, users) => {
  for (let i = adminList.length - 1; i >= 0; i -= 1) {
    const admin = adminList[i];
    if (!Object.hasOwn(users, admin.id)) {
      console.warn(`Removing admin user ${admin.id}${+', user not found in global user list'}`);
      adminList.splice(i, 1);
    }
  }
  return adminList;
};

const ASMListPage = () => {
  const navigate = useNavigate();
  const { customerId } = useParams();
  const { hasPermissions } = usePermissionChecker();

  const {
    data: users,
    isFetching: loadUsers,
  } = useASMUsersQuery({
    select: res => res.reduce((map, user) => {
      // eslint-disable-next-line no-param-reassign
      map[user.id] = user;
      return map;
    }, {}),
  });

  const {
    data: customer,
    isFetching: isLoadingCustomer,
  } = useCustomerQuery(customerId);
  const asmList = useMemo(() => customer?.asmConfig?.asms, [customer]);

  const [primaryASMId, setPrimaryASMId] = useState(undefined);
  useEffect(() => {
    if (customer && customer?.asmConfig) {
      setPrimaryASMId(customer?.asmConfig?.primaryId);
    }
  }, [customer]);

  const [adminList, setAdminList] = useState();
  const [originalAdminList, setOriginalAdminList] = useState();
  const [removedList, setRemovedList] = useState([]);
  const [sort, setSort] = useState();
  const [selectedIndex, setSelectedIndex] = useState();
  const [layer, setLayer] = useState(undefined);
  const [searchText, setSearchText] = useState(undefined);
  const [submitted, setSubmitted] = useState(false);

  const me = useMemo(() => UserStore.getUser(), []);

  const {
    mutate: saveASMs,
    isPending: isSavingASMs,
    error: asmSaveError,
  } = useSaveASMMutate(customerId);
  const {
    mutate: removeASMs,
    isPending: isSavingRemove,
    error: removeSaveError,
  } = useRemoveASMMutate(customerId);

  useEffect(() => {
    if (asmList && users) {
      const fetchedAdminList = pruneMissingUsers([...asmList], users);
      setAdminList(fetchedAdminList);
      setOriginalAdminList(JSON.parse(JSON.stringify(fetchedAdminList)));
    }
  }, [asmList, users]);

  const filteredAdmins = useMemo(() => {
    if (!searchText || !adminList || !users) {
      return adminList;
    }
    return adminList?.filter((admin) => {
      const user = users[admin.id];
      return user?.email.toLowerCase().includes(searchText)
      || user?.firstName.toLowerCase().includes(searchText)
      || user?.lastName.toLowerCase().includes(searchText);
    });
  }, [searchText, adminList, users]);

  const onSubmitASMEdit = () => {
    setSubmitted(true);

    // validate:
    if (adminList.length > 0 && !primaryASMId) {
      return;
    }

    const updatedCustomer = { ...customer };
    updatedCustomer.asmConfig.asms = adminList;
    updatedCustomer.asmConfig.primaryId = primaryASMId;
    saveASMs(updatedCustomer.asmConfig, {
      onSuccess: () => {
        setOriginalAdminList(adminList);
        setTimeout(() => {
          navigate('/customers');
        }, 100);
      },
    });
  };

  const onSubmitASMRemove = () => {
    removeASMs(removedList, {
      onSuccess: () => {
        setOriginalAdminList(adminList);
        setTimeout(() => {
          navigate('/customers');
        }, 0);
      },
    });
  };

  const isDirty = useMemo(
    () => JSON.stringify(adminList) !== JSON.stringify(originalAdminList),
    [adminList, originalAdminList],
  );

  const onRemoveClick = (selectedInd) => {
    setLayer('removeConfirm');
    setSelectedIndex(selectedInd);
  };

  const onASMListEditorApplied = (administrationList) => {
    setLayer(undefined);
    setAdminList(administrationList);
  };

  const onAdminListRemoveConfirmed = () => {
    const updatedAdminList = [...adminList];
    const removingIndex = updatedAdminList.findIndex(el => el.id === selectedIndex);
    if (removingIndex !== -1) {
      const removedAsm = updatedAdminList.splice(removingIndex, 1)[0];
      setLayer(undefined);
      setAdminList(updatedAdminList);
      if (removedAsm?.id === primaryASMId) {
        setPrimaryASMId(undefined);
      }
    }
  };

  const onChangeRole = (admin, value) => {
    const updatedAdminList = [...adminList];
    updatedAdminList.forEach((a) => {
      if (a.id === admin.id) {
        // eslint-disable-next-line no-param-reassign
        a.roles[0] = value;
      }
    });
    setAdminList(updatedAdminList);
  };

  const getRoleValue = (role) => {
    const roles = [
      { value: 'READ', label: 'Read' },
      { value: 'EDIT', label: 'Edit' },
      { value: 'SUPER_EDIT', label: 'Super Edit' },
    ];
    return roles.filter(r => r.value === role)[0] || { value: 'READ', label: 'Read' };
  };

  const renderConfirmationDetails = (admin) => {
    const user = users[admin.id];
    return (
      <GLBMNameValueList
        title='Selected ASM'
        data={[
          { label: 'Name', value: `${user.firstName} ${user.lastName}` },
          { label: 'Email', value: user.email },
          { label: 'Role', value: getRoleValue(admin.roles[0]).label },
        ]}
      />
    );
  };

  const renderLayer = () => {
    let result;
    if (layer) {
      if (layer === 'removeConfirm') {
        result = (
          <ConfirmationDialog
            data={{ index: selectedIndex }}
            title='Remove ASM?'
            submitLabel='Yes, remove the ASM'
            cancelLabel='Cancel'
            onClose={() => setLayer(undefined)}
            onChange={onAdminListRemoveConfirmed}
            details={renderConfirmationDetails(adminList.filter(el => el.id === selectedIndex)[0])}
          />
        );
      } else if (layer === 'addAdmin') {
        const currentASMIds = [];
        adminList.forEach((admin) => {
          currentASMIds.push(admin.id);
        });

        result = (
          <ASMListEditor
            onClose={() => setLayer(undefined)}
            onChange={onASMListEditorApplied}
            adminList={adminList}
            users={users}
            currentASMIds={currentASMIds}
          />
        );
      }
    }
    return result;
  };

  const onToggleRemovedSelected = (event, id) => {
    const updatedRemovedList = [...removedList];
    if (event.target.checked) {
      updatedRemovedList.push(id);
    } else {
      const index = updatedRemovedList.indexOf(id);
      if (index !== -1) {
        updatedRemovedList.splice(index, 1);
      }
    }
    setRemovedList(updatedRemovedList);
  };

  const myAsmRoles = customer && customer.asmConfig && customer.asmConfig.asms.find(el => el.id === me.id) ? customer.asmConfig.asms.find(el => el.id === me.id).roles : [];
  const canEdit = hasPermissions(pagePermissions.customers.view.admins.actions.edit);
  const canDelete = hasPermissions(pagePermissions.customers.view.admins.actions.remove) && ['EDIT', 'SUPER_EDIT'].includes(myAsmRoles[0]);

  return (
    <Main direction='column' fill='vertical' overflow='hidden'>
      <GLBMHeading
        back='/customers'
        title={`ASMs for ${customer ? `${customer.name} (${customer.id})` : ''}`}
        search={(
          <GLBMSearch
            placeholder='Search'
            icon={<Search />}
            onChange={(props) => {
              // eslint-disable-next-line react/prop-types
              setSearchText(props?.toLowerCase());
            }}
            id={IDUtil.getId('ASMListViewToolbarSearchInput')}
          />
)}
        actions={[
          ...insertIf(canEdit, [
            <Button
              kind='toolbar'
              id={IDUtil.getId('ASMListViewToolbarAddButton')}
              icon={<Add />}
              disabled={!users}
              onClick={() => setLayer('addAdmin')}
              key='addButton'
              label='Add'
            />]),
        ]}
      />
      <Box direction='column' fill='vertical'>
        <Box flex={true} pad={{ horizontal: 'medium' }}>
          <Box flex={false}>
            <ASMListTable
              searchText={searchText}
              asms={filteredAdmins}
              users={users}
              sort={sort}
              onSort={setSort}
              onRemoveClick={onRemoveClick}
              onChangeRole={onChangeRole}
              canEdit={canEdit}
              canDelete={canDelete}
              removedList={removedList}
              onToggleRemovedSelected={onToggleRemovedSelected}
              primaryASMId={primaryASMId}
              onPrimaryASMSelected={setPrimaryASMId}
              loading={isLoadingCustomer || loadUsers}
            />
          </Box>
        </Box>
        <Box
          direction='row'
          pad={{ horizontal: 'small', vertical: 'small' }}
          gap='small'
          flex={false}
          border='top'
        >
          {canEdit && (
            <Button
              label='Save'
              type='button'
              primary={true}
              id={IDUtil.getId('EditorViewToolbarSaveButton')}
              onClick={onSubmitASMEdit}
            />
          )}
          {canDelete && (
            <Button
              label={`Remove ${removedList.length} ASM${removedList.length === 1 ? '' : 's'}`}
              type='button'
              primary={true}
              id={IDUtil.getId('EditorViewToolbarDeleteButton')}
              onClick={removedList.length !== 0 ? onSubmitASMRemove : undefined}
            />
          )}
          <Button
            label={canEdit || canDelete ? 'Cancel' : 'Close'}
            type='button'
            secondary={true}
            id={IDUtil.getId('EditorViewToolbarCancelButton')}
            onClick={() => navigate('/customers')}
          />
          <GLBMSaving
            saving={isSavingASMs || isSavingRemove}
            error={asmSaveError || removeSaveError}
            warning={submitted && adminList?.length > 0 && !primaryASMId ? 'A Primary ASM must be selected.' : undefined}
          />
        </Box>
        {renderLayer()}
        <ReactRouterPrompt when={isDirty}>
          {({ onConfirm, onCancel }) => (
            <SaveChangesDialog
              onConfirm={onConfirm}
              onCancel={onCancel}
            />
          )}
        </ReactRouterPrompt>
      </Box>
    </Main>
  );
};

ASMListPage.contextTypes = {
  router: PropTypes.object,
};

ASMListPage.propTypes = {};

export default ASMListPage;
