// (C) Copyright 2017-2024 Hewlett Packard Enterprise Development LP

import { useQuery } from '@tanstack/react-query';
import { useContext } from 'react';
import { ApiContext } from '../../AppContext';
import { useErrorToast } from '../../components/shared/hooks';
import { executeGet } from '../api';

export const useGlpBindingsQuery = (options) => {
  const path = useContext(ApiContext)('glp.bindings.path');
  return useErrorToast(useQuery({
    queryKey: ['GLBM: GLP-BINDINGS-LIST'],
    queryFn: () => executeGet(path),
    ...(options || []),
  }), 'Fetch GLP Bindings Error');
};
