// (C) Copyright 2017-2024 Hewlett Packard Enterprise Development LP

import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import { Box } from 'grommet';
import { useMeasure } from '@nivo/core';
import { ResponsiveBar } from '@nivo/bar';
import { useNormalizeColor } from '@saturn/charts';
import {
  computeBarData,
  computeYTicks,
  formatterX,
  formatterY,
} from './utils';
import LineLayer, { lineStyle } from './LineLayer';
import Tooltip from './Tooltip';
import Legend from '../ChartLegend';
import { insertIf } from '../../../shared/util/BasicUtil';

export const parseShortMonthString = (month) => {
  const { groups: monthMatches } = month.match(/0*(?<month>[0-9]*)\/(?<year>[0-9]*)/);
  return new Date(Date.UTC(parseInt(monthMatches.year, 10), parseInt(monthMatches.month, 10) - 1, 1));
};

const getLegendOffset = (tickValues) => {
  if (tickValues && tickValues[tickValues.length - 1]) {
    return -15 - (10 * formatterY(tickValues[tickValues.length - 1]).replace(/,/gi, '').length);
  }
  return undefined;
};

function SingleMonthChart({
  unit,
  values,
  month,
}) {
  const normalizeColor = useNormalizeColor();
  const { current: graphColors } = useRef([normalizeColor('graph-1'), normalizeColor('graph-4')]);
  const { current: lineColor } = useRef(normalizeColor('graph-0'));
  const [nivoData, setNivoData] = useState([]);
  const [yTicks, setYTicks] = useState([]);
  const [hasCommitted, setHasCommitted] = useState(false);

  const monthDate = useMemo(() => parseShortMonthString(month), [month]);

  useEffect(() => {
    const filteredValues = computeBarData(values, monthDate);
    setNivoData(filteredValues);
    const ticks = computeYTicks(filteredValues);
    setYTicks(ticks);
    setHasCommitted(filteredValues.filter(x => x.committed !== undefined).length > 0);
  }, [values, month, monthDate]);

  const { current: margin } = useRef({
    bottom: 60,
    top: 30,
    left: 100,
    right: 50,
  });
  const legendOffset = getLegendOffset(yTicks);
  const layers = ['grid', 'axes', 'bars', ...insertIf(hasCommitted, [LineLayer(lineColor)]), 'markers', 'legends', 'annotations'];

  const [barRef, bounds] = useMeasure();

  const tooltip = useCallback(props => (
    <Tooltip {...props} unit={unit} chartBounds={bounds} chartMargin={margin} />
  ), [bounds, margin, unit]);

  return (
    <Box height='350px' ref={barRef}>
      <ResponsiveBar
        theme={{
          tooltip: {
            container: {
              boxShadow: '0', borderRadius: '0', padding: 'none', background: 'none',
            },
          },
        }}
        key={month}
        data={nivoData}
        indexBy='date'
        keys={['used', 'available']}
        valueScale={{ type: 'linear' }}
        minValue={yTicks[0]}
        maxValue={yTicks[yTicks.length - 1]}
        xScale={{ type: 'time' }}
        enableLabel={false}
        axisLeft={{
          legend: <tspan style={{ fontSize: '18px' }}>{unit}</tspan>,
          legendPosition: 'middle',
          legendOffset,
          tickValues: yTicks,
          format: formatterY,
        }}
        gridYValues={yTicks}
        axisBottom={{
          format: formatterX,
        }}
        colors={graphColors}
        height={300}
        margin={margin}
        padding={0.4}
        tooltip={tooltip}
        isInteractive={true}
        animate={false}
        layers={layers}
      />
      <Legend
        items={[
          { key: 'Actual Usage', squareIcon: true, color: graphColors[0] },
          { key: 'Available Capacity', squareIcon: true, color: graphColors[1] },
          ...insertIf(hasCommitted, [
            {
              key: 'Reserved Capacity',
              lineStyle: 'solid',
              color: lineColor,
              lineWidth: lineStyle.strokeWidth,
            },
          ]),
        ]}
      />
    </Box>
  );
}

SingleMonthChart.propTypes = {
  unit: PropTypes.string.isRequired,
  values: PropTypes.array.isRequired,
  month: PropTypes.string.isRequired,
};

export default SingleMonthChart;
