// (C) Copyright 2017-2024 Hewlett Packard Enterprise Development LP

import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import ReactJson from 'react-json-view';
import ReactDiffViewer from 'react-diff-viewer';
import Prism from 'prismjs';
import 'prismjs/components/prism-json.min';
import {
  Box,
  Button,
  Footer,
  Select,
  Tab,
  Tabs,
} from 'grommet';
import ListItem from '../shared/list/ListItem';
import GLBMLayer from '../shared/component/GLBMLayer';
import { useAuditDetailsQuery, useAuditVersionsQuery } from '../../core';
import Loader from '../shared/loader';

const AuditDetails = ({
  audit: initialAudit = undefined,
  onClose = undefined,
}) => {
  const [audit, setAudit] = useState(initialAudit);
  const [auditVersions, setAuditVersions] = useState(undefined);
  const [selectedOldAuditVersion, setSelectedOldAuditVersion] = useState(undefined);
  const [selectedNewAuditVersion, setSelectedNewAuditVersion] = useState(undefined);
  const [activeTabIndex, setActiveTabIndex] = useState(0);

  // audit for the details tab:
  const {
    data: selectedAuditDetails,
    isFetching: isFetchingAuditDetails,
  } = useAuditDetailsQuery(audit?.detailsKey, {
    enabled: audit !== undefined,
  });

  // audit details for the compare tabs:
  const {
    data: oldAuditVersion,
  } = useAuditDetailsQuery(selectedOldAuditVersion?.detailsKey, {
    enabled: selectedOldAuditVersion !== undefined,
  });

  const {
    data: newAuditVersion,
  } = useAuditDetailsQuery(selectedNewAuditVersion?.detailsKey, {
    enabled: selectedNewAuditVersion !== undefined,
  });

  const auditOptions = useMemo(() => {
    if (auditVersions?.length) {
      return auditVersions
        .sort((a, b) => {
          const aDate = new Date(a.time);
          const bDate = new Date(b.time);
          if (aDate > bDate) return -1;
          if (aDate < bDate) return 1;
          return 0;
        }).map(version => ({
          value: version.id,
          label: moment(version.time)
            .format('lll'),
          time: version.time,
        }));
    }
    return [];
  }, [auditVersions]);

  const {
    data,
    isSuccess,
    isFetching: isFetchingAuditVersions,
  } = useAuditVersionsQuery(audit?.versionKey);

  useEffect(() => {
    if (isSuccess) {
      setAuditVersions(data);
    }
  }, [isSuccess, data]);

  useEffect(() => {
    if (auditVersions && auditOptions) {
      if (auditOptions.length > 1) {
        const elementPos = auditOptions?.map(x => x.value).indexOf(audit.id);
        const prevAuditId = (auditOptions.length === (elementPos + 1) ? auditOptions[elementPos]?.value : auditOptions[elementPos + 1]?.value);
        const prevAudit = auditVersions?.filter(v => v.id === prevAuditId)[0];
        setSelectedOldAuditVersion(prevAudit);
        setSelectedNewAuditVersion(audit);
      } else {
        setSelectedOldAuditVersion(audit);
        setSelectedNewAuditVersion(audit);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auditVersions, auditOptions]);

  const _onDetailsVersionSelected = (version) => {
    if (version) {
      const selectedAudit = auditVersions?.filter(v => v.id === version.value)[0];
      if (selectedAudit) {
        setAudit(selectedAudit);
      }
    } else {
      console.warn('no audit trail version selected');
    }
  };

  const _renderDetails = () => {
    const elementPos = auditOptions?.map(x => x.value).indexOf(audit.id);
    return (
      <Box direction='row' fill={true} size='full'>
        <Box flex={true} overflow='scroll'>
          {selectedAuditDetails && <ReactJson src={selectedAuditDetails} />}
        </Box>
        <Box direction='column' fill='vertical' separator='left' className='list' overflow='scroll'>
          {auditOptions?.map((version, index) => (
            <ListItem
              key={version.value}
              label={version.label}
              value={version}
              selected={elementPos === index}
              onSelect={item => _onDetailsVersionSelected(item)}
            />
          ))}
        </Box>
      </Box>
    );
  };

  const _onSelectAuditVersion = (event, option) => {
    const selectedVersion = auditVersions?.filter(v => v.id === option.value)[0];
    if (event === 'selectedOldAuditVersion') {
      setSelectedOldAuditVersion(selectedVersion);
    } else {
      setSelectedNewAuditVersion(selectedVersion);
    }
  };

  const _renderHistory = (splitView) => {
    const newStyles = {
      line: {
        fontSize: '12px',
      },
    };

    return (
      <Box direction='column' fill='vertical' overflow='scroll'>
        <Box direction='row' style={{ minHeight: '48px' }}>
          <Box flex={true} direction='row' className='select-wide-box'>
            <Select
              options={auditOptions}
              style={{ width: '100%' }}
              value={selectedOldAuditVersion?.id}
              onChange={({ option }) => _onSelectAuditVersion('selectedOldAuditVersion', option)}
              labelKey='label'
              valueKey={{ key: 'value', reduce: false }}
              dropHeight='medium'
            />
          </Box>
          <Box flex={true} direction='row' className='select-wide-box'>
            <Select
              options={auditOptions}
              style={{ width: '100%' }}
              value={selectedNewAuditVersion?.id}
              onChange={({ option }) => _onSelectAuditVersion('selectedNewAuditVersion', option)}
              labelKey='label'
              valueKey={{ key: 'value', reduce: false }}
              dropHeight='medium'
            />
          </Box>
        </Box>
        <Box direction='column' fill='vertical' border='top'>
          {(oldAuditVersion !== undefined && newAuditVersion !== undefined) && (
          <ReactDiffViewer
            styles={newStyles}
            oldValue={JSON.stringify(oldAuditVersion, null, 2)}
            newValue={JSON.stringify(newAuditVersion, null, 2)}
            splitView={splitView}
            renderContent={str => (
              <pre
                style={{ display: 'inline' }}
                dangerouslySetInnerHTML={{ __html: Prism.highlight(str || '', Prism.languages.json) }}
              />
            )}
          />
          )}
        </Box>
      </Box>
    );
  };

  return (
    <GLBMLayer
      flush={true}
      full={true}
      margin='large'
      title='Audit Details:'
    >
      <Box
        fill={true}
        size='full'
        border='top'
      >
        <Box style={{ height: '100%', width: '100%' }} className='tabWrapper'>
          {(isFetchingAuditDetails || isFetchingAuditVersions) ? (
            <Box direction='row' align='center' gap='small' justify='center' fill={true}>
              <Loader text='Loading Audit Log Details. Please wait ...' />
            </Box>
          ) : (
            <Tabs
              activeIndex={activeTabIndex}
              onActive={idx => setActiveTabIndex(idx)}
            >
              <Tab title='Single Log'>
                {_renderDetails()}
              </Tab>
              <Tab title='Compare - Inline'>
                {_renderHistory(false)}
              </Tab>
              <Tab title='Compare - Side by Side'>
                {_renderHistory(true)}
              </Tab>
            </Tabs>
          )}
        </Box>
      </Box>
      <Box border='top' pad='small' margin={{ top: 'none' }} flex={false}>
        <Footer flex={false}>
          <Button
            label='Close'
            secondary={true}
            onClick={onClose}
          />
        </Footer>
      </Box>
    </GLBMLayer>
  );
};

AuditDetails.propTypes = {
  audit: PropTypes.object,
  onClose: PropTypes.func,
};

export default AuditDetails;
