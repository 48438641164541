// (C) Copyright 2017-2024 Hewlett Packard Enterprise Development LP

import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import ChartTooltip from '../ChartTooltip';

const installedCapacityId = 'Installed Capacity';
const requestedCapacityId = 'Requested Capacity';
const pctBases = {
  'Actual Usage': requestedCapacityId,
  'Reserved Capacity': requestedCapacityId,
  'Available Capacity': installedCapacityId,
};
const pctGroups = [requestedCapacityId, installedCapacityId];

const ForecastTooltip = ({
  slice: { points = [] },
  values = [],
  formatNumber = () => { },
  unit = undefined,
  formatNumberWithSigDigits = () => { }
}) => {
  const date = points[0] && points[0].data ? moment(points[0].data.x).format('M/D/YYYY') : '';
  const value = values.map(element => ({
    id: element.id,
    data: element.data
      .find(d => moment(d.x).format('M/D/YYYY') === date),
    pctOf: pctBases[element.id],
  }))
    .filter(v => v.data !== undefined && v.data.y0 === undefined)
    .sort((a, b) => (b.data.y - a.data.y));

  const pctOfValues = [...new Set(value.map(v => v.pctOf))].filter(id => !!id)
    .reduce((obj, id) => {
      const found = value.find(v => v.id === id);
      return { ...obj, [id]: found && found.data ? found.data.y : undefined };
    }, {});

  const orderedValueGroups = [];

  pctGroups.forEach((pctId) => {
    if (pctOfValues[pctId] !== undefined) {
      orderedValueGroups.push([
        value.find(v => v.id === pctId),
        ...value.filter(v => v.pctOf === pctId),
      ]);
    } else {
      const filtered = value.filter(v => v.pctOf === pctId);
      if (filtered.length > 0) {
        orderedValueGroups.push(filtered);
      }
    }
  });
  const extraValues = value.filter(v => !pctGroups.includes(v.id) && !pctGroups.includes(v.pctOf));
  if (extraValues.length > 0) {
    orderedValueGroups.push(extraValues);
  }

  const groups = orderedValueGroups.map(g => g.map(el => ({
    label: el.id,
    value: el.data.y,
    pct: el.pctOf && pctOfValues[el.pctOf] !== undefined ? (el.data.y * 100) / pctOfValues[el.pctOf] : undefined,
  })));

  return (
    <ChartTooltip
      title={date}
      groups={groups}
      unit={unit}
      formatValue={(v, u) => `${v >= 1 ? formatNumber(v) : formatNumberWithSigDigits(v, 2)} ${u || ''}`}
      formatPercent={x => (x !== undefined ? `${formatNumber(x)}%` : '')}
    />
  );
};

ForecastTooltip.propTypes = {
  slice: PropTypes.object,
  values: PropTypes.array,
  formatNumber: PropTypes.func,
  formatNumberWithSigDigits: PropTypes.func,
  unit: PropTypes.string,
};

export default ForecastTooltip;
