// (C) Copyright 2017-2024 Hewlett Packard Enterprise Development LP

import { reduce } from 'lodash/collection';

export const getDefaultValue = ({
  type,
  defaultValue,
  values,
}) => {
  switch (type) {
    case 'BOOLEAN':
      if (typeof defaultValue === 'string') {
        return defaultValue === 'true';
      }
      return Boolean(defaultValue);
    case 'INT':
      return parseInt(defaultValue, 10);
    case 'FLOAT':
      return parseFloat(defaultValue);
    case 'STRING':
      if (values) {
        return values.find(item => item.value === defaultValue);
      }
      return defaultValue;
    default:
      return defaultValue;
  }
};

export const setFormValuesInitialState = inputs => reduce(
  inputs,
  (result, currentObject) => {
    if (currentObject.defaultValue) {
      // eslint-disable-next-line no-param-reassign
      result[currentObject.field] = getDefaultValue(currentObject);
    } else if (currentObject.type === 'BOOLEAN') {
      // eslint-disable-next-line no-param-reassign
      result[currentObject.field] = false;
    } else if (currentObject.type === 'LIST') {
      // eslint-disable-next-line no-param-reassign
      result[currentObject.field] = [];
    }
    return result;
  },
  {},
);

export const hydratePayload = (inputs) => {
  const result = {};
  Object.entries(inputs)
    .forEach(([key, value]) => {
      if (Array.isArray(value)) {
        result[key] = value.map(item => item.value);
      } else if (value && typeof value === 'object') {
        result[key] = value?.value;
      } else {
        result[key] = value;
      }
    });
  return result;
};

export const areRequiredInputsAvailable = (inputs, formValues) => inputs.filter(input => input.required)
  .every(input => !!formValues[input.field]);
