// (C) Copyright 2017-2024 Hewlett Packard Enterprise Development LP

import React, { useEffect } from 'react';

function MarketingHeader() {
  useEffect(() => {
    if (window.HPEHFWSLOADER) {
      window.HPEHFWSLOADER.insertHF(window.hfwsHeader, '');
    }
    if (window.HPEHF) {
      window.HPEHF.forceReInit();
    }
  }, []);
  return (
    <div id='header' className='header' style={{ zIndex: 10 }} />
  );
}

export default MarketingHeader;
