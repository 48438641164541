// (C) Copyright 2017-2024 Hewlett Packard Enterprise Development LP
import React, { useMemo } from 'react';

import {
  Box,
  Button,
  Table,
  TableBody,
  TableCell,
  TableHeader,
  TableRow,
} from 'grommet';
import {
  Add, Copy, Edit, Revert, Trash
} from 'grommet-icons';
import moment from 'moment';
import IDUtil from '../shared/util/IDUtil';
import CurrencyUtils from '../i18n/CurrencyUtil';
import { renderColumnTableCell } from '../shared/util/tableUtils';
import BaseContainer from './components/BaseContainer';
import type { Customer, Revision, Service } from './types';

// @ts-ignore
const formatDate = (date: string) => new Date(date).toLocaleDateString((window.navigator.languages) ? window.navigator.languages[0] : (window.navigator.userLanguage || window.navigator.language), { timeZone: 'UTC' });

const getMarkupValue = ({ rate, markup, downstreamRate }) => {
  let value = '-';
  if (markup !== undefined) {
    value = `${CurrencyUtils.getNumberString(markup, 4, null)}%`;
  } else if (rate === undefined && downstreamRate !== undefined) {
    value = 'Not Available';
  }
  return value;
};

const getCustomerRateValue = ({ rate, markup, downstreamRate }) => {
  let value = '-';
  if (downstreamRate !== undefined) {
    value = `${CurrencyUtils.getNumberString(downstreamRate, 8, null)}`;
  } else if (rate === undefined && markup !== undefined) {
    value = 'Not Available';
  }
  return value;
};

const getCustomerTotalValue = ({ explicitDownstreamRate }: Revision, {
  rate, markup, downstreamRate, quantity,
}) => {
  let value = '-';
  if (explicitDownstreamRate && downstreamRate !== undefined) {
    value = `${CurrencyUtils.getNumberString(quantity * downstreamRate, 8, null)}`;
  } else if (!explicitDownstreamRate && rate !== undefined && quantity !== undefined && markup !== undefined) {
    value = `${CurrencyUtils.getNumberString((((rate * markup) / 100) + rate) * quantity, 8, null)}`;
  } else if (rate === undefined && markup !== undefined) {
    value = 'Not Available';
  }
  return value;
};

const getExplicitDownstreamRate = (explicitDownstreamRate: boolean) => {
  if (explicitDownstreamRate === undefined) {
    return '-';
  }
  if (explicitDownstreamRate) {
    return 'Direct Rate';
  }
  return 'Markup Percentage';
};

interface Props {
  service?: Service
  customer?: Customer
  partner: boolean
  readOnly: boolean
  onResetMarkups: () => void
  onEditRevision: (revision: Revision) => void
  onDeleteRevision: (revision: Revision) => void
}

const ServiceRevisions = ({
  readOnly, service = undefined, partner, onResetMarkups, onEditRevision, onDeleteRevision, customer = undefined,
}: Props) => {
  const actions = useMemo(() => {
    const addRevision = (!partner ? (
      <Button
        key='addRevision'
        icon={<Add />}
        onClick={service ? () => onEditRevision(null) : null}
        label='Add Revision'
        id={IDUtil.getId('BillingInfoEditorAddRevisionService')}
        hoverIndicator={true}
      />
    ) : undefined);

    const resetMarkups = (partner ? (
      <Button
        key='edit'
        icon={<Revert />}
        label='Reset Pricing'
        onClick={() => onResetMarkups()}
        hoverIndicator={true}
      />
    ) : undefined);

    return ([
      !readOnly && !(service?.readOnly) ? addRevision : null,
      service && !service.readOnly ? resetMarkups : null,
    ]);
  }, [onEditRevision, onResetMarkups, partner, readOnly, service]);

  const onCloneRevision = (selectedRevision: Revision) => {
    const newRevision = JSON.parse(JSON.stringify(selectedRevision));
    newRevision.effectiveDate = null;
    onEditRevision(newRevision);
  };

  const revisions = service ? service.rateRevisions : [];
  const currencySymbol = useMemo(() => {
    if (customer) {
      return customer?.preferences?.currencySymbol ? customer.preferences.currencySymbol : CurrencyUtils.getCurrencyString(undefined, undefined, customer.locale, customer.contractCurrency).replace(/[0,.]+/gi, '').trim();
    }
    return '$';
  }, [customer]);
  const backgroundColor = { backgroundColor: 'rgba(248, 248, 248, 0.95)' };
  const labels = ['Start Date', 'Quantity', `Rate Per Unit (${currencySymbol})`, `Total Cost (${currencySymbol})`];

  if (partner) {
    labels.push('Pricing Method');
    labels.push('Markup');
    labels.push(`Customer Rate (${currencySymbol})`);
    labels.push(`Total Customer Price (${currencySymbol})`);
  }

  if (!readOnly && !(service?.readOnly)) {
    labels.push('Actions');
  }

  // build the table rows
  const rows = [];
  revisions
    .sort((a, b) => moment(a.effectiveDate).diff(moment(b.effectiveDate)))
    .forEach((revision, index) => {
      const tier = Object.entries(revision.tiers)
        .reduce((arr, [quantity, { rate, markup, downstreamRate }]) => (
          [...arr, {
            quantity: parseInt(quantity, 10), rate, markup, downstreamRate,
          }]
        ), [])[0];

      rows.push(
        <TableRow key={`fixedChargeRevision${revision.effectiveDate}`} id={IDUtil.getId('Capacity', index)}>
          <TableCell id={IDUtil.getId('CapacityEffectiveDate', index)}>{formatDate(revision.effectiveDate)}</TableCell>
          <TableCell id={IDUtil.getId('CapacityQuantity', index)}>{tier.quantity}</TableCell>
          <TableCell id={IDUtil.getId('CapacityEffectiveRatePerUnit', index)}>{tier.rate !== undefined ? CurrencyUtils.getNumberString(parseFloat(tier.rate), 2, null) : 'Not Available'}</TableCell>
          <TableCell id={IDUtil.getId('CapacityEffectiveTotalCost', index)}>{tier.rate !== undefined ? CurrencyUtils.getNumberString((tier.quantity * tier.rate), 2, null) : 'Not Available'}</TableCell>

          {partner && (
            <>
              <TableCell style={backgroundColor}>
                {getExplicitDownstreamRate(revision.explicitDownstreamRate)}
              </TableCell>
              <TableCell style={backgroundColor} id={IDUtil.getId('CapacityEffectiveMarkup', index)}>{getMarkupValue(tier)}</TableCell>
              <TableCell style={backgroundColor} id={IDUtil.getId('CapacityEffectiveCustomerRate', index)}>{getCustomerRateValue(tier)}</TableCell>
              <TableCell style={backgroundColor} id={IDUtil.getId('CapacityEffectiveCustomerTotal', index)}>{getCustomerTotalValue(revision, tier)}</TableCell>
            </>
          )}

          {!readOnly && !(service?.readOnly) && (
            <TableCell style={{ width: '200px' }}>
              <Box direction='row' gap='xsmall'>
                <Button size='large' id={IDUtil.getId('CapacityEffectiveEdit', index)} icon={<Edit style={{ height: '18px', width: '18px' }} />} onClick={() => onEditRevision(revision)} />
                {!partner && (
                <Button size='large' id={IDUtil.getId('CapacityEffectiveCopy', index)} icon={<Copy style={{ height: '18px', width: '18px' }} />} onClick={() => onCloneRevision(revision)} />
                )}
                {!partner && (
                <Button size='large' id={IDUtil.getId('CapacityEffectiveDelete', index)} icon={<Trash style={{ height: '18px', width: '18px' }} />} onClick={() => onDeleteRevision(revision)} />
                )}
              </Box>
            </TableCell>
          )}
        </TableRow>
      );
    });

  return (
    <BaseContainer
      title='Capacities'
      actions={actions}
      fill='vertical'
      expanded={true}
    >
      <Box
        direction='column'
        fill='vertical'
        data-e2e='capacities'
        overflow='auto'
      >
        <Table
          className='narrow-table'
        >
          <TableHeader>
            {renderColumnTableCell(labels)}
          </TableHeader>
          <TableBody>
            {rows}
          </TableBody>
        </Table>
      </Box>
    </BaseContainer>
  );
};

export default ServiceRevisions;
