// (C) Copyright 2017-2024 Hewlett Packard Enterprise Development LP

import includes from 'lodash/includes';

import { PartnerType } from '../../shared/constants/PartnerType';
import { pagePermissions, roles } from '../../shared/constants/Permissions';
import { getASMCustomerRole } from '../../shared/util/asmCustomerRole';
import {
  INITIALIZE_SERVICE_EDITOR,
  INITIALIZE_SERVICE_OPTIONS,
  INVALIDATE_EQUIPMENT,
  INVALIDATE_VALIDATION,
  RECEIVE_VALIDATION,
  SET_CONTEXT,
  SET_DIRTY_COMPONENT,
  SET_DIRTY_EQUIPMENT,
  SET_DIRTY_OPTIONS,
  SET_DIRTY_RATES,
  SET_EQUIPMENT,
  SET_EQUIPMENT_FILTER,
  SET_LOCATION_RATES,
  SET_METERS,
  SET_OPTIONS,
  SET_PERMISSIONS, SET_RATES,
} from './ServiceActions';

// eslint-disable-next-line default-param-last
export function options(state = null, action) {
  switch (action.type) {
    case INITIALIZE_SERVICE_EDITOR:
      return null;
    case SET_OPTIONS:
      return { ...state, ...action.options };
    default:
      return state;
  }
}

// eslint-disable-next-line default-param-last
export function originalOptions(state = null, action) {
  switch (action.type) {
    case INITIALIZE_SERVICE_EDITOR:
      return null;
    case INITIALIZE_SERVICE_OPTIONS:
      return { ...state, ...action.options };
    default:
      return state;
  }
}

/**
 * Reducer function that handles all equipment mapping actions.
 * @param state
 * @param action
 * @returns {*}
 */
// eslint-disable-next-line default-param-last
export function equipment(state = {
  isFetched: false,
  didInvalidate: true,
  items: [],
}, action) {
  switch (action.type) {
    case INITIALIZE_SERVICE_EDITOR:
      return {
        ...state,
        isFetched: false,
        didInvalidate: true,
        items: []
      };
    case INVALIDATE_EQUIPMENT:
      return {
        ...state,
        didInvalidate: true
      };
    case SET_EQUIPMENT:
      return {
        ...state,
        isFetched: true,
        didInvalidate: false,
        items: action.equipment,
      };
    case SET_EQUIPMENT_FILTER:
      return { ...state, filter: action.filter };
    default:
      return state;
  }
}

// eslint-disable-next-line default-param-last
export function meters(state = [], action) {
  switch (action.type) {
    case INITIALIZE_SERVICE_EDITOR:
      return null;
    case SET_METERS:
      return action.meters;
    default:
      return state;
  }
}

// eslint-disable-next-line default-param-last
export function rates(state = [], action) {
  switch (action.type) {
    case INITIALIZE_SERVICE_EDITOR:
      return null;
    case SET_RATES:
      return action.rates;
    default:
      return state;
  }
}

// eslint-disable-next-line default-param-last
export function locationRates(state = [], action) {
  switch (action.type) {
    case INITIALIZE_SERVICE_EDITOR:
      return null;
    case SET_LOCATION_RATES:
      return action.rates;
    default:
      return state;
  }
}

// eslint-disable-next-line default-param-last
export function context(state = {
  tenant: undefined,
}, action) {
  switch (action.type) {
    case INITIALIZE_SERVICE_EDITOR:
      return null;
    case SET_CONTEXT:
      return action.context;
    default:
      return state;
  }
}

/**
 * Reducer function that handles/contains list of all equipment that the user has changed, ie. its 'dirty'.
 * Once an equipment mapping is dirty, then it can not become 'clean' again. The mapping will be passed to the
 * backend on save no matter what the user did.
 * @param state
 * @param action
 * @returns {*}
 */
// eslint-disable-next-line default-param-last
export function dirtyEquipment(state = [], action) {
  switch (action.type) {
    case INITIALIZE_SERVICE_EDITOR:
      // eslint-disable-next-line no-case-declarations
      const newState = state.slice(0);
      newState.length = 0;
      return newState;
    case SET_DIRTY_EQUIPMENT:
      return Object.assign([], state, action.equipment);
    default:
      return state;
  }
}

/**
 * Reducer function that handles/contains list of all equipment components (drives) that the user has
 * changed.
 * @param state
 * @param action
 * @returns {*}
 */
// eslint-disable-next-line default-param-last
export function dirtyComponents(state = [], action) {
  switch (action.type) {
    case INITIALIZE_SERVICE_EDITOR:
      // eslint-disable-next-line no-case-declarations
      const newState = state.slice(0);
      newState.length = 0;
      return newState;
    case SET_DIRTY_COMPONENT:
      return Object.assign([], state, action.component);
    default:
      return state;
  }
}

const getNewPermissions = (state, action) => {
  const {
    user, isOneOfRoles, customer, hasPermissions, serviceId, tenant,
  } = action;

  const asmRole = getASMCustomerRole(user, customer);

  const checkAsmRole = (...neededRoles) => {
    if (isOneOfRoles([roles.SERVICE_DEV, roles.ASM])) {
      return includes(neededRoles, asmRole);
    }
    return true;
  };

  const isResellerTenant = includes([PartnerType.RESELLER.enumKey, PartnerType.DISTRIBUTOR.enumKey], tenant);
  const isSuperAndResellerTenant = isOneOfRoles([roles.SUPER]) && isResellerTenant;

  const result = {
    ...state.permissions,
    userType: user.role,
    asmRole,

    canReadRates: hasPermissions(isResellerTenant ? pagePermissions.customers.view.services.steps.readMarkupRates : pagePermissions.customers.view.services.steps.readRates)
      && checkAsmRole('READ', 'EDIT', 'SUPER_EDIT'),
    canEditRates: hasPermissions(pagePermissions.customers.view.services.steps.editRates)
      && checkAsmRole('EDIT', 'SUPER_EDIT')
      && !isSuperAndResellerTenant,
    canMarkupRates: hasPermissions(pagePermissions.customers.view.services.steps.markupRates)
      && isSuperAndResellerTenant,
    canReadOptions: hasPermissions(pagePermissions.customers.view.services.steps.readOptions)
      && checkAsmRole('SUPER_EDIT')
      && !isSuperAndResellerTenant,
    canEditOptions: hasPermissions(pagePermissions.customers.view.services.steps.editOptions)
      && checkAsmRole('SUPER_EDIT')
      && !isSuperAndResellerTenant,
    canReadMappings: hasPermissions(pagePermissions.customers.view.services.steps.readMappings)
      && checkAsmRole('SUPER_EDIT')
      && !isSuperAndResellerTenant,
    canEditMappings: hasPermissions(pagePermissions.customers.view.services.steps.editMappings)
      && checkAsmRole('SUPER_EDIT')
      && !isSuperAndResellerTenant,
  };

  // certain services have extra options:
  if (serviceId === 'AZURE_STACK') {
    result.canEditRates = false;
  }
  return result;
};

// eslint-disable-next-line default-param-last
export function permissions(state = {
  asmRole: undefined,
  userType: undefined,
  canReadOptions: false,
  canEditOptions: false,
  canReadMappings: false,
  canEditMappings: false,
  canReadRates: false,
  canEditRates: false,
  canMarkupRates: false,
}, action) {
  switch (action.type) {
    case INITIALIZE_SERVICE_EDITOR:
      return {
        ...state,
        asmRole: undefined,
        userType: undefined,
        canReadOptions: false,
        canEditOptions: false,
        canReadMappings: false,
        canEditMappings: false,
        canReadRates: false,
        canEditRates: false,
        canMarkupRates: false
      };
    case SET_PERMISSIONS:
      return getNewPermissions(state, action);
    default:
      return state;
  }
}

// eslint-disable-next-line default-param-last
export function dirtyState(state = {
  options: false,
  resources: false,
  rates: false,
}, action) {
  switch (action.type) {
    case INITIALIZE_SERVICE_EDITOR:
      return {
        ...state,
        options: false,
        resources: false,
        rates: false
      };
    case SET_DIRTY_OPTIONS:
      return { ...state, options: true };
    case SET_DIRTY_EQUIPMENT:
      return { ...state, resources: (action.equipment && action.equipment.length > 0) };
    case SET_DIRTY_COMPONENT:
      return { ...state, resources: (action.component && action.component.length > 0) };
    case SET_DIRTY_RATES:
      return { ...state, rates: true };
    default:
      return state;
  }
}

const defaultValidation = {
  isFetching: false,
  didInvalidate: true,
  results: undefined,
  errors: undefined,
};

// eslint-disable-next-line default-param-last
export function validation(state = defaultValidation, action) {
  switch (action.type) {
    case INITIALIZE_SERVICE_EDITOR:
      return {
        ...state, isFetching: false, didInvalidate: true, results: undefined, errors: undefined
      };
    case INVALIDATE_VALIDATION:
      return { ...defaultValidation, didInvalidate: true };
    case RECEIVE_VALIDATION:
      return {
        ...state,
        isFetching: false,
        didInvalidate: false,
        results: action.results,
        errors: action.errors,
        properties: action.properties
      };
    default:
      return state;
  }
}
