// (C) Copyright 2017-2024 Hewlett Packard Enterprise Development LP
import { AdvancedSwitch, instanceOf } from '../utils/AdvancedEnum';

const encodeEntry = (key, value) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`;

const arrayResolver = (key, value, shouldUseParam) => (shouldUseParam(key, value) ? value.map(primitiveValue => encodeEntry(key, primitiveValue)) : []);
const defaultResolver = (key, value, shouldUseParam) => (shouldUseParam(key, value) ? [encodeEntry(key, value)] : []);
const objectResolver = (key, value, shouldUseParam) => Object.entries(value)
  .flatMap(([_key, _value]) => {
    if (_value instanceof Array) {
      return arrayResolver(_key, _value, shouldUseParam);
    } if (_value instanceof Object) {
      return objectResolver(_key, _value, shouldUseParam);
    }
    return defaultResolver(_key, _value, shouldUseParam);
  });

const shouldUseParamPredicate = (whiteList, blackList, key, value) => typeof value !== 'undefined' && value !== '' && (!whiteList || whiteList.includes(key)) && (!blackList || !blackList.includes(key));

function _resolveQueryParams(key, value, shouldUseParam) {
  return AdvancedSwitch(value)
    .case(instanceOf(Array), arrayResolver)
    .case(instanceOf(Object), objectResolver)
    .default(defaultResolver)
    .resolveAndInvoke(key, value, shouldUseParam);
}

// { panel: {service: ['3PAR', 'Blades']} }

/**
 * @param whiteList: optional parameter, use to specify which params from the object should be added as query params
 * @param blackList: optional parameter, use to specify which params from the object should NOT be added as query params
 */
export default {
  objectToQueryParams: (object, whiteList, blackList) => {
    const queryParamList = Object.entries(object).reduce((_queryParamList, [key, value]) => {
      const shouldUseParam = shouldUseParamPredicate.bind(null, whiteList, blackList);
      const queryParams = _resolveQueryParams(key, value, shouldUseParam);
      return [..._queryParamList, ...queryParams];
    }, []);

    return `?${queryParamList.join('&')}`;
  },
};
