// (C) Copyright 2017-2024 Hewlett Packard Enterprise Development LP

import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useContext } from 'react';
import { ApiContext } from '../../AppContext';
import { useErrorToast } from '../../components/shared/hooks';
import { useToast } from '../../components/shared/toast/ToastProvider';
import { executeGet, executePut } from '../api';

export const useUsersQuery = (options) => {
  const path = useContext(ApiContext)('users.list.path');
  return useErrorToast(useQuery({
    queryKey: ['GLBM:USERS-LIST'],
    queryFn: () => executeGet(path),
    ...(options || []),
  }), 'Fetch Users Error');
};

export const useUsersQueryMap = options => useUsersQuery({
  ...options,
  select: data => data?.reduce((_map, c) => {
    // eslint-disable-next-line no-param-reassign
    _map[c.id] = {
      id: c.id,
      name: `${c.firstName} ${c.lastName}`,
      email: c.email,
    };
    return _map;
  }, {
    system: { name: 'System' },
    unknown: { name: 'Unknown User' },
  }),
});

export const useUserHistoryQuery = (userId, options) => {
  const path = useContext(ApiContext)('users.history.path', { userId });
  return useErrorToast(useQuery({
    queryKey: ['GLBM:USERS-LIST:HISTORY', userId],
    queryFn: () => executeGet(path),
    ...(options || []),
  }), 'Fetch User History Error');
};

const getSuccessfulUserUpdateMessage = (user) => {
  if (user) {
    const roleWithEmailSent = user.role === 'ASM' || user.role === 'SERVICE_DEV';
    return `${user.firstName} ${user.lastName} is successfully updated.${roleWithEmailSent ? ' An email will be sent to the user about the change(s).' : ''} `;
  }
  return undefined;
};

export const useUserUpdateMutate = (userId, options) => {
  const queryClient = useQueryClient();
  const { addToast } = useToast();
  const path = useContext(ApiContext)('users.update.path', { userId });
  return useMutation({
    mutationFn: payload => executePut(path, payload),
    ...(options || {}),
    onSuccess: (payload) => {
      queryClient.removeQueries({
        queryKey: ['GLBM:USERS-LIST'],
      });
      queryClient.removeQueries({
        queryKey: ['GLBM:USERS', userId],
      });

      addToast({
        status: 'normal',
        title: 'Updated',
        message: getSuccessfulUserUpdateMessage(payload),
      });
      options?.onSuccess?.();
    },
    onError: (error) => {
      addToast({
        status: 'critical',
        title: 'Update User Error',
        message: error?.response?.data?.message,
      });
    },
  });
};
export const useUserApprovalUpdateMutate = (userId, options) => {
  const queryClient = useQueryClient();
  const { addToast } = useToast();
  const path = useContext(ApiContext)('users.approval.update.path', { userId });
  return useMutation({
    mutationFn: payload => executePut(path, payload),
    ...(options || {}),
    onSuccess: () => {
      queryClient.removeQueries({
        queryKey: ['GLBM:USERS-LIST'],
      });
      queryClient.removeQueries({
        queryKey: ['GLBM:USERS', userId],
      });
      addToast({
        status: 'normal',
        title: 'Updated',
        message: 'User Approval Successfully Updated',
      });
      options?.onSuccess?.();
    },
    onError: (error) => {
      addToast({
        status: 'critical',
        title: 'Update User Approval Error',
        message: error?.response?.data?.message,
      });
    },
  });
};

export const useUserRoleQuery = (options) => {
  const path = useContext(ApiContext)('users.roles.path');
  return useErrorToast(useQuery({
    queryKey: ['GLBM:USERS:ROLES'],
    queryFn: () => executeGet(path),
    ...(options || []),
  }), 'Fetch User Roles Error');
};

export const useUserQuery = (userId, options) => {
  const path = useContext(ApiContext)('users.read.path', { userId });
  return useErrorToast(useQuery({
    queryKey: ['GLBM:USERS', userId],
    queryFn: () => executeGet(path),
    ...(options || []),
  }), 'Fetch User Error');
};
