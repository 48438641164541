// (C) Copyright 2017-2024 Hewlett Packard Enterprise Development LP

import moment from 'moment';
import {
  FAILURE_JOBS,
  INVALIDATE_JOBS,
  REQUEST_JOBS,
  SUCCESS_JOBS,
  UPDATE_JOBS_FILTERS,
} from './JobActions';

// eslint-disable-next-line @typescript-eslint/default-param-last
export const jobList = (state = {
  loading: true,
  items: [],
  filters: {
    customerId: '',
    list: { searchText: '', sort: { property: 'created', direction: 'desc', external: true } },
    panel: {
      from: moment.utc().startOf('day').format('YYYY-MM-DD'),
      to: moment.utc().endOf('day').format('YYYY-MM-DD'),
    },
  },
  error: false,
}, action) => {
  switch (action.type) {
    case INVALIDATE_JOBS:
      return {
        ...state,
        didInvalidate: true,
        items: []
      };
    case REQUEST_JOBS:
      return {
        ...state,
        items: action.items,
        filters: action.filters,
        error: action.error,
        loading: action.loading
      };
    case SUCCESS_JOBS:
    case FAILURE_JOBS:
      return {
        ...state,
        items: action.items,
        error: action.error,
        loading: action.loading
      };
    case UPDATE_JOBS_FILTERS:
      return { ...state, filters: action.filters };
    default:
      return state;
  }
};
