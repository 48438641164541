// (C) Copyright 2017-2024 Hewlett Packard Enterprise Development LP
import React, { useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import pluralize from 'pluralize';
import * as Case from 'case';
import PropTypes from 'prop-types';
import {
  Anchor,
  Box,
  Button,
  Main,
  Menu, Text,
} from 'grommet';
import {
  Add, More, Refresh,
} from 'grommet-icons';
import moment from 'moment';
import IDUtil from '../shared/util/IDUtil';
import ConfirmationDialog from '../shared/dialogs/ConfirmationDialog';
import {
  useCustomerQuery, useCustomerServicesQuery, useDeleteServiceMutation
} from '../../core';
import { pagePermissions, roles } from '../shared/constants/Permissions';
import {
  usePermissionChecker,
  useRoleChecker,
} from '../shared/hooks';
import UserStore from '../stores/UserStore';
import ServicePromoteDialog from './ServicePromoteDialog';
import ServiceExtentionsDialog from './extensions/ServiceExtentionsDialog';
import { DealType } from '../shared/constants/DealType';
import { isProd } from '../shared/environment/EnvironmentUtil';
import ServiceTypeStore from '../stores/ServiceTypeStore';
import { StatusIcon } from '../shared/component/StatusIcon';
import { insertIf } from '../shared/util/BasicUtil';
import GLBMHeading from '../shared/component/GLBMHeading';
import GLBMDataTable from '../shared/component/GLBMDataTable';
import Toast from '../shared/component/Toast';
import GLBMNameValueList from '../shared/component/GLBMNameValueList';

const ServicesListPage = () => {
  const { customerId } = useParams();
  const navigate = useNavigate();
  const { hasPermissions } = usePermissionChecker();
  const { isRole } = useRoleChecker();

  const [sort, setSort] = useState({
    property: 'serviceType',
    direction: 'asc',
  });
  const [layer, setLayer] = useState(undefined);
  const [selectedService, setSelectedService] = useState(undefined);
  const [response, setResponse] = useState(undefined);

  const me = useMemo(() => UserStore.getUser(), []);

  const {
    data: customer,
  } = useCustomerQuery(customerId);

  const {
    data: serviceList,
    isFetching: loading,
    refetch: refreshServiceList,
  } = useCustomerServicesQuery(customerId);

  const { mutate: deleteService } = useDeleteServiceMutation({
    onSuccess: () => {
      setLayer(undefined);
      setSelectedService(undefined);
      setTimeout(() => refreshServiceList(), 0);
    }
  });

  const onServiceEdit = (serviceType, tenant) => {
    navigate(`/customers/${customerId}/services/${serviceType}`, {
      state: {
        origin: 'services',
        tenant,
      }
    });
  };

  const onManageFeeds = (serviceType) => {
    navigate(`/customers/${customerId}/feeds/${serviceType}`, {
      state: {
        origin: 'services',
      }
    });
  };

  const getCanEdit = (status, serviceType) => {
    if (status === 'NEW' && !hasPermissions(pagePermissions.customers.view.services.actions.create)) {
      return false;
    }
    return ServiceTypeStore.getService(serviceType) !== undefined;
  };

  const canEditDistributorRates = (service) => {
    if (isProd() || service.status === 'NEW' || !hasPermissions(pagePermissions.customers.view.services.steps.markupRates)) {
      return false;
    }
    return (customer && [DealType.DISTRIBUTOR_RESELLER].includes(DealType.enumValueOf(customer.dealType)));
  };

  const canEditResellerRates = (service) => {
    if (isProd() || service.status === 'NEW' || !hasPermissions(pagePermissions.customers.view.services.steps.markupRates)) {
      return false;
    }
    return (customer && [DealType.RESELLER, DealType.DISTRIBUTOR_RESELLER].includes(DealType.enumValueOf(customer.dealType)));
  };

  const onSortList = (newSort) => {
    setSort(newSort);
  };

  const canPromoteService = () => {
    if (!hasPermissions(pagePermissions.customers.view.services.actions.promote)) {
      return false;
    }
    if (isRole(roles.ASM) && customer) {
      const { asmConfig } = customer;
      if (asmConfig) {
        const myId = me.id;
        const myAsmRoles = (asmConfig.asms ? asmConfig.asms.filter(asm => asm.id === myId) : undefined);
        return (myAsmRoles ? myAsmRoles[0].roles[0] !== 'READ' : false);
      }
      return false;
    }
    return true;
  };

  const onPromoteClick = (service) => {
    setLayer('promote');
    setSelectedService(service);
  };

  const getStatus = (service, asContext) => {
    const canPromote = canPromoteService();
    switch (service.status) {
      case 'NEW':
        return (
          <Box direction='row' gap='small' align='center'>
            <StatusIcon size='small' value='unknown' />
            <Text>New</Text>
          </Box>
        );
      case 'INCOMPLETE':
        if (canPromote) {
          return (
            <Box direction='row' gap='small' align='center'>
              <StatusIcon size='small' value='warning' />
              {!asContext
                && (
                  <Anchor onClick={() => onPromoteClick(service)}>In Preview</Anchor>
                )}
              {asContext
                && <Text>In Preview</Text>}
            </Box>
          );
        }
        return (
          <Box direction='row' gap='small' align='center'>
            <StatusIcon size='small' value='warning' />
            <Text>In Preview</Text>
          </Box>
        );
      case 'COMPLETE':
      case 'BILLABLE':
        return (
          <Box direction='row' gap='small' align='center'>
            <StatusIcon size='small' value='ok' />
            <Text>In Production</Text>
          </Box>
        );
      default:
        return '';
    }
  };

  const renderConfirmationDetails = service => (
    <Box margin={{ top: 'small' }}>
      <GLBMNameValueList
        title='Selected Service'
        data={[
          { label: 'Service', value: ServiceTypeStore.getService(service.serviceType).displayName },
          { label: 'Status', value: getStatus(service, true) },
        ]}
      />
    </Box>
  );

  const onPromoteApplied = () => {
    setLayer(undefined);
    setSelectedService(undefined);
    setTimeout(() => refreshServiceList(), 0);
  };

  const onLayerClose = () => {
    setLayer(undefined);
    setSelectedService(undefined);
  };

  const onDeleteConfirmed = ({ service }) => {
    deleteService({ customerId: customer.id, serviceId: service.serviceType });
  };

  const deleteServiceConfirm = (service) => {
    setLayer('removeConfirm');
    setSelectedService(service);
  };

  const renderLayer = () => {
    let result;
    if (layer) {
      if (layer === 'promote') {
        result = (
          <ServicePromoteDialog
            onClose={onPromoteApplied}
            customer={customer}
            service={selectedService}
          />
        );
      } else if (layer === 'editService') {
        const existingServices = serviceList?.map(service => service.serviceType);

        result = (
          <ServiceExtentionsDialog
            onClose={onLayerClose}
            onChange={onPromoteApplied}
            customer={customer}
            existingServices={existingServices}
          />
        );
      } else if (layer === 'removeConfirm') {
        result = (
          <ConfirmationDialog
            data={{ customer, service: selectedService }}
            title='Are You Sure?'
            submitLabel='Yes, delete the Service'
            cancelLabel='Cancel'
            text="Deleting a service will permanently erase it from this billing account, including any usage files and configuration settings for that service. This action should only be done with approval from the product or engineering teams. If this service needs to be added back after deletion, all usage files would need to be uploaded to this billing account and it would need to be configured again. Please be absolutely sure you want to completely remove this service from this billing account before choosing 'Yes' below."
            onClose={onLayerClose}
            onChange={onDeleteConfirmed}
            details={renderConfirmationDetails(selectedService)}
          />
        );
      }
    }
    return result;
  };

  const addService = () => {
    setLayer('editService');
  };

  const deleteEquipment = (serviceType) => {
    navigate(`/customers/${customerId}/equipments/${serviceType}`, {
      state: {
        origin: 'services',
      }
    });
  };

  const onToastClose = () => {
    setResponse(undefined);
  };

  const renderToast = () => {
    let message = '';

    if (response) {
      message = (
        <Toast
          open={response}
          status={(response.status ? response.status : 'critical')}
          onClose={onToastClose}
        >
          {response.text}
        </Toast>
      );
    }
    return message;
  };

  const getActions = datum => [
    ...insertIf(getCanEdit(datum.status, datum.serviceType), [{
      onClick: () => onServiceEdit(datum.serviceType, 'MASTER'),
      label: 'Edit',
      id: IDUtil.getId('Edit', datum.index),
    }]),
    ...insertIf(hasPermissions(pagePermissions.customers.view.feeds.actions.edit), [{
      onClick: () => onManageFeeds(datum.serviceType),
      label: 'Manage Feeds',
      id: IDUtil.getId('ManageFeeds', datum.index),
    }]),
    ...insertIf(hasPermissions(pagePermissions.customers.view.services.actions.delete), [{
      onClick: () => deleteServiceConfirm(datum),
      label: 'Delete',
      id: IDUtil.getId('Delete', datum.index),
    }]),
    ...insertIf(hasPermissions(pagePermissions.customers.view.equipments.page), [{
      onClick: () => deleteEquipment(datum.serviceType),
      label: 'Delete Equipment',
      id: IDUtil.getId('DeleteEquipment', datum.index),
    }]),
    ...insertIf(canEditDistributorRates(datum), [{
      onClick: () => onServiceEdit(datum.serviceType, 'DISTRIBUTOR'),
      label: 'Edit Distributor Rates',
      id: IDUtil.getId('EditDistributorRates', datum.index),
    }]),
    ...insertIf(canEditResellerRates(datum), [{
      onClick: () => onServiceEdit(datum.serviceType, 'RESELLER'),
      label: 'Edit Reseller Rates',
      id: IDUtil.getId('EditResellerRates', datum.index),
    }]),
    ...insertIf(canPromoteService() && datum.status === 'INCOMPLETE', [{
      onClick: () => onPromoteClick(datum),
      label: 'Promote',
      id: IDUtil.getId('Promote', datum.index),
    }]),
  ];

  const getColumns = () => [
    {
      property: 'status',
      header: 'Status',
      render: datum => (
        <div id={IDUtil.getId('Status', datum.index)}>{getStatus(datum, false)}</div>
      ),
    },
    {
      property: 'serviceType',
      header: 'Service',
      primary: true,
      dataCallback: ({ serviceType }) => (ServiceTypeStore.getService(serviceType) ? ServiceTypeStore.getService(serviceType).label : serviceType),
      render: ({ status, serviceType, ...datum }) => {
        const canEdit = getCanEdit(status, serviceType);
        const serviceTypeEnum = ServiceTypeStore.getService(serviceType);
        return (
          <Anchor
            disabled={!canEdit}
            onClick={serviceTypeEnum ? () => onServiceEdit(serviceType, 'MASTER') : undefined}
            id={IDUtil.getId('Service', datum.index)}
          >
            {serviceTypeEnum ? serviceTypeEnum.label : serviceType}
          </Anchor>
        );
      },
    },
    {
      property: 'equipment',
      header: 'Resources found',
      render: ({ serviceType, equipment, ...datum }) => {
        const serviceTypeEnum = ServiceTypeStore.getService(serviceType);
        if (hasPermissions(pagePermissions.customers.view.equipments.page)) {
          return (
            <Anchor
              onClick={() => deleteEquipment(serviceType)}
              id={IDUtil.getId('ResourcesFound', datum.index)}
            >
              {`${equipment || 0} ${serviceTypeEnum ? pluralize(Case.capital(serviceTypeEnum.device), equipment) : ''}`}
            </Anchor>
          );
        }

        return <div id={IDUtil.getId('ResourcesFound', datum.index)}>{`${equipment || 0} ${serviceTypeEnum ? pluralize(Case.capital(serviceTypeEnum.device), equipment) : ''}`}</div>;
      },
    },
    {
      property: 'firstSeen',
      header: 'First date collected',
      render: ({ firstSeen, ...datum }) => (<Text id={IDUtil.getId('FirstDateCollected', datum.index)}>{moment(firstSeen).format('ll')}</Text>),
    },
    {
      property: 'rates',
      header: 'Defined rates',
      render: ({ rates, possibleRates, ...datum }) => (
        <Text id={IDUtil.getId('DefinedRates', datum.index)}>
          {rates || 0}
          {' '}
          of
          {' '}
          {possibleRates || 0}
        </Text>
      ),
    },
    {
      property: 'feeds',
      header: 'Included Feeds',
      dataCallback: ({ feeds }) => feeds.filter(feed => !feed.exclude).length,
      render: ({
        feeds, status, serviceType, ...datum
      }) => {
        const canEdit = getCanEdit(status, serviceType);
        return (
          <Text id={IDUtil.getId('IncludedFeeds', datum.index)}>
            <Anchor disabled={!canEdit} onClick={() => onManageFeeds(serviceType)}>
              {feeds.filter(feed => !feed.exclude).length}
              {' '}
              of
              {' '}
              {feeds.length}
            </Anchor>
          </Text>
        );
      },
    },
    {
      property: 'actions',
      header: 'Actions',
      size: '96px',
      align: 'start',
      sortable: false,
      render: datum => (
        <Menu
          icon={<More />}
          id={IDUtil.getId('Actions', datum.index)}
          items={getActions(datum)}
        />
      ),
    },
  ];

  return (
    <Main direction='column' fill='vertical' overflow='hidden'>
      <GLBMHeading
        back='/customers'
        title={`Services: ${customer ? `${customer.name} (${customer.id})` : ''}`}
        actions={[
          <Button
            kind='toolbar'
            icon={<Refresh />}
            onClick={refreshServiceList}
            a11yTitle='Refresh Customer List'
            id={IDUtil.getId('ListViewToolbarRefreshButton')}
            key='refreshButton'
            label='Refresh'
            busy={loading}
          />,
          ...insertIf(hasPermissions(pagePermissions.customers.view.services.actions.create), [
            <Button
              kind='toolbar'
              icon={<Add />}
              label='Add'
              onClick={addService}
              a11yTitle='New Service'
              id={IDUtil.getId('ListViewToolbarAddButton')}
              key='newServiceBtn'
            />]),
        ]}
        inline={true}
      />
      <GLBMDataTable
        columns={getColumns()}
        data={serviceList?.map((el, i) => ({ ...el, index: i })) || []}
        loading={loading}
        total={serviceList?.length}
        onSort={onSortList}
        sort={sort}
      />
      <Box direction='row' border='top' gap='small' pad={{ horizontal: 'small', vertical: 'small' }} flex={false}>
        <Button
          label='Close'
          type='button'
          secondary={true}
          id={IDUtil.getId('EditorViewToolbarCancelButton')}
          onClick={() => navigate('/customers')}
        />
      </Box>
      {renderToast()}
      {renderLayer()}
    </Main>
  );
};

ServicesListPage.contextTypes = {
  router: PropTypes.object,
};

export default ServicesListPage;
