// (C) Copyright 2017-2024 Hewlett Packard Enterprise Development LP

import React from 'react';
import {
  Box, Button, Text,
} from 'grommet';
import PropTypes from 'prop-types';
import GLBMLayer from '../component/GLBMLayer';

const SaveChangesDialog = ({
  onCancel,
  onConfirm,
  additionalText = undefined,
  title = 'Unsaved Changes',
}) => (
  <GLBMLayer
    position='right'
    full='vertical'
    overlayClose={true}
    flush={true}
    onClose={onCancel}
    onEsc={onCancel}
    onClickOutside={onCancel}
    title={title}
  >
    <Box margin='medium' flex='grow' style={{ 'maxWidth': '475px' }}>
      <Text size='large' weight={100}>You have unsaved changes. Do you want to continue editing or discard your changes and leave?</Text>
      {additionalText && (
      <Text weight={100}>
        {additionalText}
      </Text>
      )}
    </Box>
    <Box margin='medium' direction='row' gap='small'>
      <Button label='Continue Editing' primary={true} onClick={onCancel} />
      <Button label='Discard Changes' secondary={true} onClick={onConfirm} />
    </Box>
  </GLBMLayer>
);

SaveChangesDialog.propTypes = {
  title: PropTypes.string,
  onConfirm: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  additionalText: PropTypes.string,
};

export default SaveChangesDialog;
