// (C) Copyright 2017-2024 Hewlett Packard Enterprise Development LP
const reParts = /\d+|\D+/g;

// Regular expression to test if the string has a digit.
const reDigit = /\d/;

export function cmpStringsWithNumbers(a, b) {
  // Get rid of casing issues.
  // eslint-disable-next-line no-param-reassign
  a = (a ? a.toUpperCase() : '');
  // eslint-disable-next-line no-param-reassign
  b = (b ? b.toUpperCase() : '');

  // Separates the strings into substrings that have only digits and those
  // that have no digits.
  const aParts = a.match(reParts);
  const bParts = b.match(reParts);

  // Used to determine if aPart and bPart are digits.
  let isDigitPart;

  // If `a` and `b` are strings with substring parts that match...
  if (aParts && bParts
    && (isDigitPart = reDigit.test(aParts[0])) === reDigit.test(bParts[0])) {
    // Loop through each substring part to compare the overall strings.
    const len = Math.min(aParts.length, bParts.length);
    for (let i = 0; i < len; i += 1) {
      let aPart = aParts[i];
      let bPart = bParts[i];

      // If comparing digits, convert them to numbers (assuming base 10).
      if (isDigitPart) {
        aPart = parseInt(aPart, 10);
        bPart = parseInt(bPart, 10);
      }

      // If the substrings aren't equal, return either -1 or 1.
      if (aPart != bPart) {
        return aPart < bPart ? -1 : 1;
      }

      // Toggle the value of isDigitPart since the parts will alternate.
      isDigitPart = !isDigitPart;
    }
  }

  // Use normal comparison.
  return (a >= b) - (a <= b);
}
