// (C) Copyright 2017-2024 Hewlett Packard Enterprise Development LP

// eslint-disable-next-line default-param-last
export function auth(state = {
  isFetching: false,
  info: [],
  error: undefined,
}, action) {
  // For now, don't handle any actions
  // and just return the state given to us.
  return state;
}
