// (C) Copyright 2017-2024 Hewlett Packard Enterprise Development LP

import React, { useMemo } from 'react';
import {
  Anchor,
  Box,
} from 'grommet';
import moment from 'moment';
import VersionPanel from '../authentication/VersionPanel';

function MarketingFooter() {
  const currentYear = useMemo(() => moment().year(), []);
  return (
    <Box direction='row' pad='small' border='top'>
      <Box>
        <span className='cc-copyright'>
          {`Copyright© 2015 - ${currentYear} Hewlett Packard Enterprise`}
        </span>
      </Box>
      <VersionPanel />
      <Box flex={false} direction='row' gap='small' justify='end'>
        <span>
          <Anchor
            target='_blank'
            className='hpehf-link'
            href='https://www.hpe.com/us/en/legal/privacy.html'
            data-analytics-action='footer'
            data-analytics-value='footer|utility and legal|Privacy'
          >
            Privacy
          </Anchor>
        </span>
        <span className='separator'>|</span>
        <span>
          <Anchor
            target='_blank'
            className='hpehf-link'
            href='https://www.hpe.com/us/en/about/legal/terms-of-use.html'
            data-analytics-value='footer|utility and legal|Terms of Use'
          >
            Terms of Use
          </Anchor>
        </span>
        <span className='separator'>|</span>
        <span>
          <Anchor
            target='_blank'
            id='hpe-footer-privacy-cookie-link'
            className='hpehf-link'
            href='https://www.hpe.com/us/en/legal/privacy.html#accordion-accordioncontainerco-4'
            data-analytics-action='footer'
            data-analytics-value='footer|utility and legal| Ad Choices &amp; Cookies'
          >
            Ad Choices &amp; Cookies
          </Anchor>
        </span>
        <span className='separator'>|</span>
        <span>
          <Anchor
            target='_blank'
            className='hpehf-link'
            href='https://www.hpe.com/us/en/privacy/personal-information.html'
            data-analytics-action='footer'
            data-analytics-value='footer|utility and legal|Do Not Sell My Personal Information'
          >
            Do Not Sell My Personal Information
          </Anchor>
        </span>
        <span className='separator'>|</span>
        <span>
          <Anchor
            target='_blank'
            className='hpehf-link'
            href='https://www.hpe.com/us/en/sitemap.html'
            data-analytics-action='footer'
            data-analytics-value='footer|utility and legal|Sitemap'
          >
            Sitemap
          </Anchor>
        </span>
      </Box>
    </Box>
  );
}

export default MarketingFooter;
