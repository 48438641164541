// (C) Copyright 2017-2024 Hewlett Packard Enterprise Development LP
import {
  Box, Button, Form, FormField, Heading, TextInput
} from 'grommet';
import { FormEdit } from 'grommet-icons';
import PropTypes from 'prop-types';
import React from 'react';
import ReadOnlyField from '../../shared/component/ReadOnlyField';
import IDUtil from '../../shared/util/IDUtil';
import DateTime from '../../shared/component/DateTime';
import { StatusIcon } from '../../shared/component/StatusIcon';
import { pagePermissions } from '../../shared/constants/Permissions';
import { getPermissionCheckersForUser } from '../../shared/hooks';
import ServiceTypeStore from '../../stores/ServiceTypeStore';
import { PSAConstants } from './PSAConstants';

const PSACustomerForm = ({
  errors = {},
  onEdit = () => {},
  customer = {},
  user = {},
  readOnly = true,
}) => {
  const {
    psaProject,
    psaProjectServices,
    psaPortalActivationDate,
    psaNotRequired,
  } = customer;

  const renderBaseProjectId = () => {
    const psaProjectId = (psaProject ? psaProject.projectId : '');
    const psaProjectIdError = errors.psaProjectId;
    return (
      <ReadOnlyField
        label='Base Project ID'
        id={IDUtil.getId('PSAProjectId')}
        key='PSAProjectId'
        value={psaNotRequired ? PSAConstants.NA : psaProjectId}
        error={!psaNotRequired && psaProjectIdError ? psaProjectIdError[0] : undefined}
      />
    );
  };

  const getStatus = (service) => {
    const status = (service ? service.status : 'NEW');
    switch (status) {
      case 'NEW':
        return (
          <Box key='new' margin={{ right: 'small' }}>
            <StatusIcon
              size='small'
              value='unknown'
            />
          </Box>
        );
      case 'INCOMPLETE':
        return (
          <Box key='incomplete' margin={{ right: 'small' }}>
            <StatusIcon
              size='small'
              value='warning'
            />
          </Box>
        );
      case 'COMPLETE':
      case 'BILLABLE':
        return (
          <Box key='billable' margin={{ right: 'small' }}>
            <StatusIcon
              size='small'
              value='ok'
            />
          </Box>
        );
      default:
        return '';
    }
  };

  const renderServices = () => {
    const { services: customerServices } = { ...customer };

    const sortedServiceNodes = (
      (psaProjectServices || [])
        .map(service => ServiceTypeStore.getService(service))
        .sort((a, b) => (`${a.label}`).localeCompare(b.label))
        .map(serviceEnum => (
          <Box
            direction='row'
            justify='start'
            align='center'
            wrap={true}
            pad='none'
            margin={{ right: 'medium' }}
            key={`psa-services-${serviceEnum?.type}`}
          >
            {getStatus((customerServices || []).find(({ type }) => type === serviceEnum?.type))}
            {serviceEnum?.label}
          </Box>
        ))
    );

    return (
      <ReadOnlyField
        label='Project Services'
        value={`${(psaProjectServices || []).length} selected service${(psaProjectServices || []).length === 1 ? '' : 's'}`}
        id={IDUtil.getId('PSAProjectServices')}
      >
        <Box
          direction='row'
          justify='start'
          align='center'
          wrap={true}
          pad='none'
          margin={{ left: 'medium' }}
        >
          {sortedServiceNodes}
        </Box>
      </ReadOnlyField>
    );
  };

  const renderActivationDate = () => (
    <FormField
      label='Activation Date'
      help='Will be set when all project services are in production.'
    >
      {
        psaPortalActivationDate ? (
          <DateTime
            value={psaPortalActivationDate || ''}
            format='YYYY-MM'
            id={IDUtil.getId('PSAActivationDate')}
          />
        ) : (
          <TextInput
            disabled={true}
            plain={true}
            id={IDUtil.getId('PSAActivationDate')}
          />
        )
      }
    </FormField>
  );

  const renderEditAction = () => {
    const { hasPermissions } = getPermissionCheckersForUser(user);
    const isPSAPageNotRequired = hasPermissions(pagePermissions.customers.view.psa.page_notRequired);

    // if flag is set and user is not admin, hide button:
    if (psaNotRequired && !isPSAPageNotRequired) {
      return '';
    }

    if (!readOnly) {
      return (
        <Button
          id={IDUtil.getId('PSAInfoEdit')}
          icon={<FormEdit />}
          onClick={onEdit}
          key='PSAInfoEdit'
        />
      );
    }
    return '';
  };

  return (
    <Box margin={{ top: 'small' }} flex={false}>
      <Box align='center' flex={false} direction='row'>
        <Heading level='2' pad='none' size='small' weight='bold'>
          PSA Project Info
        </Heading>
        {renderEditAction()}
      </Box>
      <Form>
        {renderBaseProjectId()}
        {renderServices()}
        {renderActivationDate()}
      </Form>
    </Box>
  );
};

PSACustomerForm.propTypes = {
  errors: PropTypes.shape({
    psaProjectId: PropTypes.arrayOf(PropTypes.string),
    psaPortalActivationDate: PropTypes.arrayOf(PropTypes.string),
    psaProjectServices: PropTypes.arrayOf(PropTypes.string),
    psaNotRequired: PropTypes.arrayOf(PropTypes.string),
  }),
  onEdit: PropTypes.func,
  customer: PropTypes.shape({
    psaProjectId: PropTypes.string,
    psaPortalActivationDate: PropTypes.string,
    psaProjectServices: PropTypes.arrayOf(PropTypes.string),
    psaProject: PropTypes.shape({
      projectId: PropTypes.string,
    }),
    psaNotRequired: PropTypes.bool,
  }),
  user: PropTypes.shape({
    id: PropTypes.string,
  }),
  readOnly: PropTypes.bool,
};

export default PSACustomerForm;
