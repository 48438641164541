// (C) Copyright 2017-2024 Hewlett Packard Enterprise Development LP
import React from 'react';
import { Box, Footer, Text } from 'grommet';

const FooterApprovers = () => (
  <Footer>
    <Box margin={{ left: 'medium', top: 'small' }}>
      <Text style={{ fontWeight: 'bold' }}>This page is read-only.  Approver management is done on the User Management page.</Text>
    </Box>
  </Footer>
);

export default FooterApprovers;
