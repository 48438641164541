// (C) Copyright 2017-2024 Hewlett Packard Enterprise Development LP
import moment from 'moment';
import React, { useEffect, useMemo, useState } from 'react';
import {
  Box, Button, FormField, Select, Spinner, Text,
} from 'grommet';
import {
  useExtractDigitalMonthlyBusinessReviewPeriodsQuery,
  useQueryExtractDigitalMonthlyBusinessReviewData,
} from '../../../core';

const ExtractDigitalMonthlyBusinessReviewData = () => {
  const [period, setPeriod] = useState();
  const [submitted, setSubmitted] = useState(false);

  const { mutate: extract, isPending: showSpinner } = useQueryExtractDigitalMonthlyBusinessReviewData();

  const periodValuesQuery = useExtractDigitalMonthlyBusinessReviewPeriodsQuery();

  useEffect(() => {
    if (periodValuesQuery.status === 'success' && periodValuesQuery.data && !period) {
      setPeriod(periodValuesQuery.data[0].id);
    }
  }, [period, periodValuesQuery]);

  const periodOptions = useMemo(() => (
    periodValuesQuery.status === 'success' && periodValuesQuery.data
      ? periodValuesQuery.data.sort(({ id: aDate }, { id: bDate }) => {
        const aMoment = moment(aDate, 'YYYY-MM');
        const bMoment = moment(bDate, 'YYYY-MM');
        return bMoment - aMoment; // Newest first
      }).map(el => ({
        label: moment(el.id, 'YYYY-MM').format('MMM YYYY'),
        value: el.id,
        lastModified: moment(el.lastModified).format('ll'),
      }))
      : []
  ), [periodValuesQuery]);

  const errors = useMemo(() => ({
    period: period ? undefined : 'Required'
  }), [period]);

  const hasErrors = useMemo(() => Object.values(errors).some(error => error !== undefined), [errors]);

  const lastModified = useMemo(() => {
    if (period) {
      const selectedOption = periodOptions.find(el => (el.value === period));
      if (selectedOption) {
        return selectedOption.lastModified;
      }
    }
    return '-';
  }, [period, periodOptions]);

  return (
    <Box border='top' direction='row' pad={{ horizontal: 'small' }} align='center' height={{ min: 'xsmall' }} flex={false}>
      <Box margin={{ right: 'auto' }} width={{ max: 'large' }} basis='1/2'>
        <strong>Extract for Digital Monthly Business Review</strong>
        <span>Extract and aggregate usage data for production accounts and generate capacity planning forecasts to ensure optimal resource allocation and scalability</span>
      </Box>
      <Box direction='row' align='center' justify='end' gap='medium' style={{ height: '100px' }} basis='1/2'>
        <Box
          direction='row'
          align='center'
          justify='end'
          gap='medium'
          style={{ height: '100px' }}
          width={{ min: 'small' }}
        >
          <Box direction='column'>
            <Text size='xsmall' weight='500'>Period</Text>
            <FormField
              required={true}
              error={submitted && errors.period}
              info={`Last modified: ${lastModified}`}
            >
              <Select
                options={periodOptions}
                value={period}
                labelKey='label'
                valueKey={{
                  key: 'value',
                  reduce: true,
                }}
                onChange={({ value: nextValue }) => setPeriod(nextValue)}
                placeholder='Select Period'
              />
            </FormField>
          </Box>
        </Box>
        <Box flex={false} width='xsmall' align='center'>
          {showSpinner && <Spinner size='small' />}
        </Box>
        <Box direction='row' align='center' flex={false} width={{ min: 'xsmall' }} justify='end' alignContent='end'>
          <Button
            label='Extract'
            onClick={() => {
              setSubmitted(true);
              if (!hasErrors && period) {
                extract(period);
              }
            }}
            disabled={showSpinner}
            secondary={true}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default ExtractDigitalMonthlyBusinessReviewData;
