// (C) Copyright 2017-2024 Hewlett Packard Enterprise Development LP

import { useQuery } from '@tanstack/react-query';
import { useContext } from 'react';
import { ApiContext } from '../../AppContext';
import { executeGet } from '../api';

export const useCurrenciesQuery = (options) => {
  const path = useContext(ApiContext)('currencies.path');
  return useQuery({
    queryKey: ['currencies'],
    queryFn: () => executeGet(path),
    ...options,
  });
};

export const useCountriesQuery = (options) => {
  const path = useContext(ApiContext)('countries.path');
  return useQuery({
    queryKey: ['countries'],
    queryFn: () => executeGet(path),
    ...options,
  });
};

export const useLocalesQuery = (options) => {
  const path = useContext(ApiContext)('locales.path');
  return useQuery({
    queryKey: ['locales'],
    queryFn: () => executeGet(path),
    ...options,
  });
};

export const useCurrencyQuery = (currency, options) => {
  const path = useContext(ApiContext)('currency.path', { currency });
  return useQuery({
    queryKey: ['currency', currency],
    queryFn: () => executeGet(path),
    enabled: !!currency,
    ...options,
  });
};
