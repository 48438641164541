// (C) Copyright 2017-2024 Hewlett Packard Enterprise Development LP
import { toRelativeUrl } from '@okta/okta-auth-js';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useOktaAuth } from '@okta/okta-react';
import {
  Anchor, Box, Heading, Image, Meter, Text,
} from 'grommet';
import { FormPreviousLink } from 'grommet-icons';
import IDUtil from '../shared/util/IDUtil';
import AuthActions from '../actions/AuthActions';
import AppDispatcher from '../dispatcher/AppDispatcher';
import UserConstants from '../constants/UserConstants';
import UserStore from '../stores/UserStore';
import { getOktaAuthClient } from './config';
import { StatusIcon } from '../shared/component/StatusIcon';

const getParamValue = param => sessionStorage.getItem(param);

const LoginCallbackPage = () => {
  const navigate = useNavigate();
  const { authState } = useOktaAuth();
  const [error, setError] = useState('');

  useEffect(() => {
    if (authState && authState.isAuthenticated) {
      // retrieve access + id tokens:
      const { accessToken, idToken } = { ...authState };

      // store them in the auth client:
      try {
        const { appEnv } = { ...window.config };
        const authClient = getOktaAuthClient(appEnv);
        if (accessToken && idToken) {
          authClient.tokenManager.add('idToken', idToken);
          authClient.tokenManager.add('accessToken', accessToken);
        }
      } catch (err) {
        console.error(err);
      }

      sessionStorage.setItem('token', accessToken.accessToken);
      AuthActions.loginOkta(accessToken.accessToken).then((res) => {
        if (typeof Storage !== 'undefined') {
          sessionStorage.setItem('lastTokenRefresh', new Date().toISOString());
          const userCopy = JSON.parse(JSON.stringify(res));
          sessionStorage.setItem('user', JSON.stringify(userCopy));
        }
        AppDispatcher.dispatch({
          actionType: UserConstants.LOGIN_SUCCESS,
          user: res,
          source: 'sso',
        });

        // after okta login, check if we should reload into preview link:
        const previewUrl = getParamValue('previews');
        if (previewUrl) {
          navigate(toRelativeUrl(`/?previews=${previewUrl}`, window.location.origin));
          window.location.reload();
        } else {
          navigate('/', { replace: true });
        }
      })
        .catch((err) => {
          setError(err?.body?.message || `There was an error. Please try again. ${JSON.stringify(err)}`);
        });
    }
  }, [authState]);

  const logout = () => {
    UserStore.setLogoutUrl('/');
    AuthActions.logout();
  };

  const renderLoggingInComponent = () => (
    <Box pad={{ vertical: 'large' }} flex={false}>
      <Box
        direction='row'
        justify='between'
        align='center'
        gap='small'
        announce={true}
        responsive={false}
      >
        <Text weight='bold' color='brand'>Logging in to GreenLake Billing Manager Portal ... </Text>
      </Box>
      {authState && authState.accessToken && (
      <Meter
        size='large'
        color='brand'
        value={100}
        min={0}
        max={100}
      />
      )}
    </Box>
  );

  const renderErrorComponent = () => (
    <Box pad={{ vertical: 'medium' }}>
      <Box
        gap='small'
        pad={{
          vertical: 'small',
          horizontal: 'small',
        }}
        separator='all'
        direction='row'
      >
        <StatusIcon value='critical' />
        <Text weight={600} size='24px'>
          {error}
        </Text>
      </Box>
      <Box pad={{ vertical: 'medium' }}>
        <Text weight={600} size='24px'>
          <Anchor
            animateIcon={true}
            primary={true}
            onClick={logout}
            icon={<FormPreviousLink size='xxlarge' color='brand' />}
          >
            Return to Login
          </Anchor>
        </Text>
      </Box>
    </Box>
  );

  return (
    <Box flex={false} fill={true} direction='row' justify='center' className='Okta' background='#425563'>
      <Box direction='row' align='center' justify='center'>
        <Box
          justify='center'
          pad='large'
          align='center'
          style={{ width: '650px' }}
          className={IDUtil.getClass('LoginFormClass')}
          id={IDUtil.getId('LoginFormContainer')}
          gap='small'
          background='white'
          elevation='xlarge'
          round={true}
        >
          <Image src='/img/hpe_pri_grn_pos_rgb.svg' height='177' alt='HPE Logo' />
          <Heading
            strong={true}
            uppercase={false}
            truncate={false}
          >
            HPE GreenLake Billing Manager
          </Heading>
          {renderLoggingInComponent()}
          {error && renderErrorComponent()}
        </Box>
      </Box>
    </Box>
  );
};

export default LoginCallbackPage;
