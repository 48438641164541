// (C) Copyright 2017-2024 Hewlett Packard Enterprise Development LP
import { Enumify } from 'enumify';

export class ServiceStep extends Enumify {
  static OPTIONS = new ServiceStep();

  static CONFIGURE_RESOURCES = new ServiceStep();

  static RATES = new ServiceStep();

  static _ = this.closeEnum();
}
