// (C) Copyright 2017-2024 Hewlett Packard Enterprise Development LP

import React, { useMemo, useRef, useState } from 'react';
import {
  Box, Button, FormField, Select, Spinner, Text,
} from 'grommet';
import moment from 'moment';
import CustomerSelector from '../../shared/component/CustomerSelector';
import DateTime from '../../shared/component/DateTime';
import { useMetricValuesQuery, useQueryExtractISVServiceData } from '../../../core';

const ExtractISVServiceData = () => {
  const [serviceType, setServiceType] = useState();
  const [customers, setCustomers] = useState([]);
  const [reportType, setReportType] = useState('Summary');
  const [date, setDate] = useState(moment().subtract(1, 'months').startOf('month').format('YYYY-MM'));
  const [submitted, setSubmitted] = useState(false);

  const { mutate: extract, isPending: showSpinner } = useQueryExtractISVServiceData(serviceType, customers || [], reportType, date);

  // keep updating an errors object when serviceType and date changes:
  const errors = useMemo(() => {
    const newErrors = {
      serviceType: undefined,
      date: undefined,
    };

    // validate service type:
    if (!serviceType) {
      newErrors.serviceType = 'Required';
    }

    // validate date:
    if (!date) {
      newErrors.date = 'Required';
    } else {
      const momentDate = moment(date, 'YYYY-MM', true);
      const dateError = momentDate.isSameOrAfter(moment(), 'month') ? 'Select a month prior to current month and year' : undefined;
      newErrors.date = (momentDate.isValid() ? dateError : 'Invalid date format (YYYY-MM)');
    }
    return newErrors;
  }, [serviceType, date]);

  // if errors object is not all undefined --> we have errors:
  const hasErrors = useMemo(() => {
    const fieldErrors = Object.entries(errors).map(([key, value]) => (value !== undefined ? key : undefined)).filter(e => e !== undefined);
    return fieldErrors.length > 0;
  }, [errors]);

  const { current: reportTypeOptions } = useRef([
    {
      label: 'Summary',
      value: 'Summary',
    },
    {
      label: 'Full',
      value: 'Full',
    },
  ]);

  const onChange = (value) => {
    if (!value || value === 'All') {
      setCustomers([]);
    }
    setCustomers(value);
  };

  const metricValuesQuery = useMetricValuesQuery();

  const metricValuesOptions = useMemo(() => {
    if (metricValuesQuery.status === 'success' && metricValuesQuery?.data) {
      return Object.entries(metricValuesQuery?.data).map(([key, value]) => ({ label: key, value }));
    }
    return [];
  }, [metricValuesQuery]);

  const renderCustomerOptions = (filter) => {
    let values = [];
    if (filter && filter.length > 0) {
      values = filter;
    }

    return (
      <CustomerSelector
        multiple={true}
        initialSelection={values}
        onCustomerSelected={selected => onChange(selected)}
        plain={true}
      />
    );
  };

  const renderDateOptions = () => (
    <DateTime
      id='extractIsvServiceDataDateTime'
      name='customerExtractDate'
      format='YYYY-MM'
      onChange={setDate}
      value={date}
    />
  );

  const renderReportTypeOptions = options => (
    <Select
      options={options}
      value={reportType}
      labelKey='label'
      data-e2e='reportTypeOptions'
      valueKey={{ key: 'value', reduce: true }}
      onChange={({ value: nextValue }) => setReportType(nextValue)}
    />
  );

  const renderServiceMetricOptions = options => (
    <Select
      options={options}
      value={serviceType}
      labelKey='label'
      valueKey={{ key: 'value', reduce: true }}
      onChange={({ value: nextValue }) => setServiceType(nextValue)}
      placeholder='Select Service Metric'
    />
  );

  return (
    <Box border='top' direction='row' pad={{ horizontal: 'small' }} align='center' height={{ min: 'xsmall' }} flex={false}>
      <Box margin={{ right: 'auto' }}>
        <strong>Extract ISV Service Data</strong>
        <span>Extract ISV Service Data in CSV format</span>
      </Box>
      <Box direction='row' align='center' gap='medium' style={{ height: '100px' }}>
        <Box direction='row' align='center' gap='medium' style={{ height: '100px' }}>
          <Box direction='column'>
            <Text size='xsmall' weight='500'>Service Metric</Text>
            <FormField required={true} error={submitted && errors.serviceType}>
              {renderServiceMetricOptions(metricValuesOptions)}
            </FormField>
          </Box>
          <Box direction='column'>
            <Text size='xsmall' weight='500'>Billing Accounts</Text>
            <FormField style={{ minWidth: '160px' }}>
              {renderCustomerOptions(customers)}
            </FormField>
          </Box>
          <Box direction='column'>
            <Text size='xsmall' weight='500'>Report Type</Text>
            <FormField>
              {renderReportTypeOptions(reportTypeOptions)}
            </FormField>
          </Box>
          <Box direction='column'>
            <Text size='xsmall' weight='500'>Month</Text>
            <FormField error={submitted && errors.date}>
              {renderDateOptions()}
            </FormField>
          </Box>
        </Box>
        <Box flex={false} width='xsmall' align='center'>
          {showSpinner && <Spinner size='small' />}
        </Box>
        <Box direction='row' align='center' flex={true} width={{ min: 'xsmall' }} justify='end'>
          <Button
            label='Extract'
            data-e2e='extractIsvServiceDataButton'
            onClick={() => {
              setSubmitted(true);
              if (!hasErrors) {
                extract();
              }
            }}
            secondary={true}
            disabled={showSpinner}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default ExtractISVServiceData;
