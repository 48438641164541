// (C) Copyright 2017-2024 Hewlett Packard Enterprise Development LP

import moment from 'moment';
import {
  UPDATE_USAGE_FILTERS,
} from './UsageActions';

export const usageList = (state = {
  filters: {
    customerId: '',
    list: { searchText: '', sort: { property: 'fileName', direction: 'asc', external: true } },
    panel: {
      from: moment.utc().subtract(1, 'day').format('YYYY-MM-DD'),
      to: moment.utc().subtract(1, 'day').format('YYYY-MM-DD'),
      stage: ['raw'],
    },
  },
  error: false,
}, action) => {
  switch (action.type) {
    case UPDATE_USAGE_FILTERS:
      return { ...state, filters: action.filters };
    default:
      return state;
  }
};
