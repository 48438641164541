// (C) Copyright 2017-2024 Hewlett Packard Enterprise Development LP

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Notification, Text } from 'grommet';
import { Alert, CircleInformation } from 'grommet-icons';
import moment from 'moment';
import { useQueryCapacityHistogram } from '../../../core';
import CapacityPlanningUtil from './CapacityPlanningUtil';
import CapacityPlanningChart from './TrendChart/CapacityPlanningChart';
import CapacityPlanningGrid from './CapacityPlanningGrid';
import Loader from '../../shared/loader';
import { VIEWS } from './consts';
import SingleMonthChart from './SingleMonthChart';

const CapacityPlanningGroup = ({
  accountId = undefined,
  locationId = undefined,
  group = undefined,
  groupBy = undefined,
  outputUnit = undefined,
  timePeriodOption: monthsBack = undefined,
  priorMonths: monthsBackForForecast = undefined,
  forecastPeriod: forecastMonths = undefined,
  view = undefined,
  viewMonth = undefined,
}) => {
  const [capacityData, setCapacityData] = useState(undefined);
  const {
    missingContactData, unit,
  } = { ...group };
  const displayUnit = (outputUnit !== 'None') ? outputUnit : unit;
  const query = useQueryCapacityHistogram(
    accountId,
    locationId,
    groupBy,
    group,
    monthsBack,
    monthsBackForForecast,
    forecastMonths,
    displayUnit,
  );
  const isStandartService = (groupName) => {
    if (groupName) {
      return groupName === 'MLOPS'
        || groupName === 'VMAAS'
        || groupName === 'SAP_HANA2_0'
        || groupName === 'CAAS'
        || groupName === 'HPCAAS'
        || groupName === 'CATALOG_LIFECYCLE_MGR'
        || groupName === 'SAPHECCE';
    }
    return false;
  };

  const insertIf = (condition, elements) => (condition ? elements : []);

  useEffect(() => {
    if (query.isSuccess && query.data) {
      const results = query.data;

      if (results && typeof results === 'object') {
        const earliestDate = moment.utc()
          .subtract(monthsBack, 'months')
          .startOf('month');
        const lastDate = forecastMonths > 0
          ? moment.utc().add(forecastMonths, 'months').endOf('month')
          : moment.utc().endOf('month');
        const forecastDays = forecastMonths > 0 ? lastDate.diff(moment.utc(), 'days') : 0;

        results.earliestDate = earliestDate;
        results.lastDate = lastDate;

        const missing = {
          requested: (Object.hasOwn(results, 'missingContractData') ? results.missingContractData : true),
          committed: (Object.hasOwn(results, 'missingContractData') ? results.missingContractData : true),
        };
        const transformedValues = CapacityPlanningUtil.transformMeterDetails(results.values, results.missingContractData);
        const chartValues = CapacityPlanningUtil.processData(results, forecastDays);
        const gridData = CapacityPlanningUtil.getGridData(chartValues, '', results.missingContractData, query.data.groupName.replace(/ /gi, '-'));

        let invalidCommittedCapacity;
        let invalidRequestedCapacity;
        let errorMessage;

        if (missingContactData) {
          errorMessage = 'Requested and Reserved Capacity values are not applicable for this group.';
        } else if (gridData && gridData.data && gridData.data.series && gridData.data.series.length > 0) {
          const lastRealDay = transformedValues.findLast(({ forecast }) => !forecast)?.date;
          const lastDayMoment = lastRealDay && moment(lastRealDay);
          const currentDay = lastDayMoment && moment().isBefore(lastDayMoment, 'day') ? lastDayMoment : moment();
          invalidCommittedCapacity = gridData.data.series.some(({
            usable,
            committed,
            date
          }) => date && moment(date).isSameOrAfter(currentDay, 'day') && usable < committed && (isStandartService(group.serviceType) || usable !== 0));
          invalidRequestedCapacity = gridData.data.series.some(({
            usable,
            requested,
            date
          }) => date && moment(date).isSameOrAfter(currentDay, 'day') && usable < requested && (isStandartService(group.serviceType) || usable !== 0));
          const errorMessagePrefix = [
            ...insertIf(invalidRequestedCapacity, ['Requested Capacity']),
            ...insertIf(invalidCommittedCapacity, ['Reserved Capacity']),
          ].join(' and ');
          errorMessage = `${errorMessagePrefix} ${invalidRequestedCapacity && invalidCommittedCapacity ? 'are (or are projected to be)' : 'is (or is projected to be)'} above Installed Capacity. Please contact HPE support to fix this issue.`;
        }

        setCapacityData({
          missing,
          chartValues,
          transformedValues,
          gridData,
          errorMessage,
          hasErrors: missingContactData || invalidCommittedCapacity || invalidRequestedCapacity,
          rawData: results,
        });
      } else {
        console.error('Histogram Results was not an object. results:', results);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query.status, locationId, monthsBack, forecastMonths, monthsBackForForecast, displayUnit, view]);

  return (
    <Box>
      {!query.isLoading && query.isSuccess && capacityData
        && (
        <Box>
          <Box gap='xsmall' margin={{ vertical: 'xsmall' }}>
            {capacityData && capacityData.hasErrors && (
            <Box margin={{ horizontal: 'medium' }}>
              <Notification
                status='info'
                icon={<Alert />}
                message={<Text>{capacityData.errorMessage}</Text>}
              />
            </Box>
            )}
          </Box>
          { view === VIEWS.trend && (
            <CapacityPlanningChart
              unit={capacityData.rawData.unit}
              data={capacityData.chartValues}
              values={capacityData.transformedValues}
              serviceCategory={group.serviceCategory}
              missing={capacityData.missing}
            />
          )}
          { view === VIEWS.singleMonth && (
            <SingleMonthChart
              month={viewMonth}
              unit={capacityData.rawData.unit}
              values={capacityData.transformedValues}
            />
          )}
          <CapacityPlanningGrid data={capacityData.gridData} />
          {group.dataConvertedFromMeterUsage && (
            <Box direction='row' margin={{ vertical: 'xsmall', horizontal: 'medium' }} gap='small'>
              <CircleInformation />
              <Text size='small'>
                The data in the chart and table above represents the physical
                resources that are installed and used. The values shown will be different
                from the values on the Monthly Charges report since the Monthly Charges
                report is showing billing values which are based on the sum of the hourly
                usage over the entire month.
              </Text>
            </Box>
          )}
        </Box>
        )}
      {query.isLoading
      && (
      <Box direction='row' align='center' gap='small' justify='center' fill={true}>
        <Loader text='Loading. Please wait ...' />
      </Box>
      )}
    </Box>
  );
};

CapacityPlanningGroup.propTypes = {
  accountId: PropTypes.any,
  locationId: PropTypes.any,
  group: PropTypes.shape({
    serviceType: PropTypes.string,
    serviceCategory: PropTypes.any,
    dataConvertedFromMeterUsage: PropTypes.any,
  }),
  groupBy: PropTypes.any,
  outputUnit: PropTypes.string,
  timePeriodOption: PropTypes.number,
  priorMonths: PropTypes.any,
  forecastPeriod: PropTypes.number,
  view: PropTypes.string,
  viewMonth: PropTypes.any,
};

export default CapacityPlanningGroup;
