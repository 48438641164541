// (C) Copyright 2017-2024 Hewlett Packard Enterprise Development LP
import { Box } from 'grommet';
import React from 'react';
import { useAlertCountQuery } from '../../core';
import SidebarButton from '../main/SidebarButton';

export const AlertSidebarButton = () => {
  const {
    data: alertCountDetails,
  } = useAlertCountQuery();

  return (
    <SidebarButton
      to='alerts'
      key='alerts'
      label='Alerts'
      id='Alerts'
      badge={alertCountDetails?.unacked || 0}
      badgeTooltip={(
        <Box>
          <Box justify='between' direction='row' gap='small' data-e2e='tooltip-in-production'>
            <Box>In Production:</Box>
            <Box>{alertCountDetails?.unackedByStatus?.BILLABLE?.toLocaleString() ?? 0}</Box>
          </Box>
          <Box justify='between' direction='row' gap='small' data-e2e='tooltip-not-configured'>
            <Box>Not Configured:</Box>
            <Box>{alertCountDetails?.unackedByStatus?.INCOMPLETE?.toLocaleString() ?? 0}</Box>
          </Box>
          <Box justify='between' direction='row' gap='small' data-e2e='tooltip-onboarding'>
            <Box>Onboarding:</Box>
            <Box>{alertCountDetails?.unackedByStatus?.REQUEST?.toLocaleString() ?? 0}</Box>
          </Box>
        </Box>
        )}
    />
  );
};
