// (C) Copyright 2017-2024 Hewlett Packard Enterprise Development LP
import keyMirror from 'keymirror';

/**
 * Separated list of
 * @type {{Customers, Analytics, Alerts, Users, Administration}}
 */
const i18n = {
  Defaults: keyMirror({
    'locale': 'en',
    'currency': 'USD',
    'currencySymbol': '$',
  }),
};
export { i18n };

/**
 * A utility for determining whether or not the current user has specific claims
 */
class CurrencyUtil {
  constructor() {
    this.accountLocale = 'en';
    this.accountCurrency = 'USD';
  }

  /**
   * Returns a locale formatted currency string from a number
   * @param numberArg The value to be formatted
   * @param decimalsArg Number of decimal places on returned string
   * @param localeArg The locale used for formatting the number - default {accountLocale}
   * @param currencyArg The currency code used for formatting - default {accountCurrency}
   * @returns {string}
   */
  getCurrencyString(numberArg, decimalsArg, localeArg, currencyArg) {
    const number = (typeof (numberArg) === 'number' ? numberArg : 0);
    const locale = localeArg || this.accountLocale;
    const currency = currencyArg || this.accountCurrency;
    const decimals = (typeof (decimalsArg) === 'number' ? decimalsArg : 2);
    const options = {
      style: 'currency',
      currency,
      maximumFractionDigits: decimals,
    };
    return number.toLocaleString(locale, options);
  }

  /**
   * Returns a locale formatted string from a number
   * @param numberArg The value to be formatted
   * @param decimals Number of decimal places on returned string
   * @param localeArg The locale used for formatting the number - default {accountLocale}
   * @returns {string}
   */
  getNumberString(numberArg, decimals, localeArg = undefined) {
    const number = (typeof (numberArg) === 'number' ? numberArg : 0);
    const locale = localeArg || this.accountLocale;
    const options = {
      maximumFractionDigits: (typeof (decimals) === 'number' ? decimals : 3),
    };
    return number.toLocaleString(locale, options);
  }
}

const instance = new CurrencyUtil();
export default instance;
