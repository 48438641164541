// (C) Copyright 2017-2024 Hewlett Packard Enterprise Development LP

import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { Box, Button, Text } from 'grommet';
import { _getStatus } from './utils';
import IDUtil from '../../../../../shared/util/IDUtil';

const selectedTextProps = {
  weight: 'bold',
  color: 'selected-text',
};

const MeterItem = ({
  meter,
  onClick,
  selected,
  rate = undefined,
  leftPad = undefined,
  index,
}) => {
  const ref = useRef();
  useEffect(() => {
    if (selected && ref.current) {
      ref.current.focus();
    }
  }, [selected]);

  return (
    <Button
      ref={ref}
      a11yTitle={`Select ${meter.name}`}
      onClick={() => onClick(meter)}
      id={IDUtil.getId('Meter', index)}
    >
      <Box
        direction='row'
        align='center'
        pad={{ left: leftPad, right: 'small', vertical: 'xsmall' }}
        background={selected ? 'selected-background' : undefined}
      >
        <Box pad={{ left: 'small' }}>
          {_getStatus(rate, selected)}
        </Box>
        <Box direction='column' margin={{ left: 'small' }}>
          <Text wordBreak='break-word' {...(selected ? selectedTextProps : {})}>{meter.name}</Text>
          {rate
            && <Text size='small' {...(selected ? selectedTextProps : {})} wordBreak='break-word'>{rate.description}</Text>}
        </Box>
      </Box>
    </Button>
  );
};

MeterItem.propTypes = {
  meter: PropTypes.shape({
    name: PropTypes.string,
  }).isRequired,
  onClick: PropTypes.func.isRequired,
  selected: PropTypes.bool.isRequired,
  rate: PropTypes.object,
  leftPad: PropTypes.string,
  index: PropTypes.string.isRequired,
};

export default MeterItem;
