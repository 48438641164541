// (C) Copyright 2017-2024 Hewlett Packard Enterprise Development LP
import React, { Component } from 'react';
import {
  Box, CheckBox, FormField, TextInput,
} from 'grommet';
import { isLessThanFiveDecimalPlaces, isNumber } from '../../rates/util';

const MIN_VALUE = 1.0;
const MAX_VALUE = 4.0;

class ReplicationRatioEdit extends Component {
  constructor(props) {
    super(props);
    if (!props.isBulk && !props.value) {
      this.props.onChange('replicationRatio', 1.0);
    }
  }

  _onChange({ target: { value, name } } = { target: { name: null } }) {
    if (!!value && value > MAX_VALUE) return;
    if (!!value && value < MIN_VALUE) {
      this.props.onChange('replicationRatio', MIN_VALUE);
      return;
    }
    if (!value || (isNumber(value) && isLessThanFiveDecimalPlaces(value))) {
      this.props.onChange('replicationRatio', value);
    }
  }

  _onBlur({ target: { value, name } } = { target: { name: null } }) {
    if (!isNumber(value)) {
      this.props.onChange('replicationRatio', 0);
    }
  }

  render() {
    return (
      <Box direction='row' align='center' flex={false}>
        {this.props.isBulk && (
          <Box margin={{ top: 'medium' }}>
            <CheckBox
              name='replicationRatio'
              checked={this.props.update}
              onChange={event => this.props.onBulkChange(event)}
              data-testid='equipment-bulk-edit-replicationRatio-checkbox'
            />
          </Box>
        )}
        <FormField
          key='replicationRatio'
          label={this.props?.column?.column?.header || 'Replication Ratio'}
          help='(must be between 1.0 and 4.0)'
        >
          <TextInput
            name='replicationRatio'
            value={this.props.value}
            onChange={event => this._onChange(event)}
            onBlur={event => this._onBlur(event)}
          />
        </FormField>
      </Box>
    );
  }
}

export default ReplicationRatioEdit;
