// (C) Copyright 2017-2024 Hewlett Packard Enterprise Development LP
import React from 'react';
import PropTypes from 'prop-types';
import {
  Box, Button, FormField, Text, TextInput,
} from 'grommet';
import { CircleInformation } from 'grommet-icons';
import styled from 'styled-components';
import noop from 'lodash/noop';

const StyledFormField = styled(FormField)`
      div > button {
        border: none;
      }
    `;

const CustomInput = ({
  property = [], setProperty = noop, errors = [], propertyName = undefined, title = undefined, keyInput = noop, valueInput = noop,
}) => (
  (
    <Box direction='column' flex={false} margin={{ bottom: 'small' }}>
      <Text size='xsmall' margin={{ bottom: '10px' }}>{title}</Text>
      <Box margin={{ bottom: '10px' }}>
        {property?.map((tag, i) => (
          // eslint-disable-next-line react/no-array-index-key
          (
            <Box key={`${propertyName}${i}`} direction='column'>
              <Box direction='row' justify='between'>
                <StyledFormField
                  label='Key'
                  error={errors[i]?.key}
                  width={{ max: '196px' }}
                >
                  {keyInput({
                      style: { border: 'none' },
                      i,
                      value: tag.key,
                      id: (`${propertyName}-key${i}`),
                    })}
                </StyledFormField>
                <Box direction='row' gap='xsmall' alignSelf='start' pad={{ vertical: '32px' }}>
                  <Button
                      label='Add Value'
                      id={`add-value${i}`}
                      type='button'
                      secondary={true}
                      onClick={() => {
                        const newProperty = JSON.parse(JSON.stringify(property));
                        newProperty[i].values = newProperty[i].values ? [...newProperty[i].values, { value: undefined, weight: undefined }] : [{ value: undefined, weight: undefined }];
                        setProperty(newProperty);
                      }}
                    />
                  <Button
                      label={`Delete ${propertyName}`}
                      id={`delete-${propertyName}${i}`}
                      type='button'
                      secondary={true}
                      onClick={() => setProperty(property.filter((el, filterIndex) => filterIndex !== i))}
                    />
                </Box>
              </Box>
              <Box direction='column' margin={{ left: 'medium' }}>
                {tag?.values?.map((value, indexValue) => (
                  // eslint-disable-next-line react/no-array-index-key
                  (
                      <Box direction='row' justify='between' key={`${propertyName}-values-${i}-${indexValue}`} margin={{ vertical: 'xsmall' }} pad='xsmall' style={{ border: '1px solid black', borderRadius: '5px' }}>
                        <Box direction='column'>
                          <FormField
                            label='Value'
                            width={{ max: '196px' }}
                            data-testid={`${propertyName}-value-${i}-${indexValue}-container`}
                            error={errors[i]?.values && errors[i]?.values[indexValue]?.value}
                          >
                            {valueInput({
                              style: { border: 'none' },
                              i,
                              y: indexValue,
                              value: value.value,
                              id: `${propertyName}-value-${i}-${indexValue}`,
                            })}
                          </FormField>
                          <FormField
                            label='Weight'
                            width={{ max: '196px' }}
                            error={errors[i]?.values && errors[i]?.values[indexValue]?.weight}
                            data-testid={`${propertyName}-weight-${i}-${indexValue}-container`}
                          >
                            <TextInput
                              value={value.weight}
                              id={`${propertyName}-weight-${i}-${indexValue}`}
                              test-id={`${propertyName}-weight-${i}-${indexValue}`}
                              type='number'
                              min={1}
                              onChange={(e) => {
                                const newProperty = JSON.parse(JSON.stringify(property));
                                newProperty[i].values[indexValue].weight = e.target.value;
                                setProperty(newProperty);
                              }}
                            />
                          </FormField>
                        </Box>
                        <Box direction='row' alignSelf='start' pad={{ vertical: '31px' }}>
                          <Button
                            label='Delete Value'
                            id={`delete-${propertyName}-value-${i}-${indexValue}`}
                            type='button'
                            secondary={true}
                            onClick={() => {
                              const newProperty = JSON.parse(JSON.stringify(property));
                              newProperty[i].values = newProperty[i].values.filter((el, filterIndex) => filterIndex !== indexValue);
                              setProperty(newProperty);
                            }}
                          />
                        </Box>
                      </Box>
                  )
                ))}
              </Box>
            </Box>
          )
        ))}
      </Box>
      <Box direction='row' justify='between'>
        <Button
          label={`Add ${propertyName}`}
          id={`add-${propertyName}`}
          type='button'
          secondary={true}
          onClick={() => setProperty([...property, { key: undefined, values: [{ value: undefined, weight: undefined }] }])}
        />
        {typeof errors === 'string' && (
          <Box direction='row' pad='xsmall' margin={{ horizontal: 'medium' }} gap='small' background='rgba(252,97,97,0.23921568627450981)'>
            <CircleInformation />
            <Text size='small'>
              {errors}
            </Text>
          </Box>
        )}
      </Box>
    </Box>
  )
);

CustomInput.propTypes = {
  property: PropTypes.array,
  setProperty: PropTypes.func,
  errors: PropTypes.array,
  title: PropTypes.string,
  propertyName: PropTypes.string,
  keyInput: PropTypes.func,
  valueInput: PropTypes.func,
};

export default CustomInput;
