// (C) Copyright 2017-2024 Hewlett Packard Enterprise Development LP

import { useContext } from 'react';
import {
  UseMutationOptions,
  useMutation,
  useQuery,
  useQueryClient
} from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { executeGet, executePost, executePut } from '../api';
import { ApiContext } from '../../AppContext';
import type {
  AllCategoriesExceptionDef,
  CapacityAlertSettingsV2
} from '../types';

export const useQueryCapacityAlertConfig = (customerId: string) => {
  const path = useContext(ApiContext)('capacity.alert.v2.read.path', { customerId });
  return useQuery<CapacityAlertSettingsV2, Error | AxiosError>({
    queryKey: ['GLBM:CUSTOMER:ALERTS:V2', customerId],
    queryFn: () => executeGet(path),
  });
};

export const useMutationCapacityAlertConfig = (customerId: string, options: Partial<UseMutationOptions<any, any, CapacityAlertSettingsV2>> = {}) => {
  const queryClient = useQueryClient();
  const path = useContext(ApiContext)('capacity.alert.v2.update.path', { customerId });
  return useMutation<any, Error | AxiosError, CapacityAlertSettingsV2>({
    mutationFn: (payload: CapacityAlertSettingsV2) => executePut(path, payload),
    ...options,
    onSuccess: (...args) => {
      queryClient.removeQueries({
        queryKey: ['GLBM:CUSTOMER:ALERTS:V2', customerId]
      });
      options?.onSuccess?.(...args);
    },
  });
};

export const useQueryCapacityAlertExceptionDefinitions = (customerId: string) => {
  const apiCtx = useContext(ApiContext);
  const path = apiCtx('capacity.alert.v2.overrideTiers.path', { customerId });
  return useQuery<AllCategoriesExceptionDef, Error | AxiosError>({
    queryKey: ['GLBM:CUSTOMERS-CAPACITY-ALERT-EXCEPTION', customerId],
    queryFn: () => executeGet<AllCategoriesExceptionDef>(path),
  });
};

interface ValidationError {
  validationErrors?: {
    propertyName: string
    message: string
  }[]
}

export const useCapacityAlertEmailValidation = (options = {}) => {
  const path = useContext(ApiContext)('capacity.alert.emailVerify.path');
  return useMutation<any, Error | AxiosError<ValidationError>, string>({
    mutationFn: email => executePost(path, { email }),
    ...options,
  });
};
