// (C) Copyright 2017-2024 Hewlett Packard Enterprise Development LP

import React, { Component } from 'react';
import {
  Box,
  Button, CheckBoxGroup,
  Footer,
} from 'grommet';
import PropTypes from 'prop-types';
import IDUtil from '../shared/util/IDUtil';
import { BillingType } from '../shared/constants/BillingType';
import CustomerSelector from '../shared/component/CustomerSelector';
import GLBMFilterPropertyBox from '../shared/component/GLBMFilterPropertyBox';
import { AlertType } from './AlertType';
import ServiceTypeStore from '../stores/ServiceTypeStore';
import GLBMLayer from '../shared/component/GLBMLayer';
import TypeLabel from '../shared/component/TypeLabel';
import StatusLabel from '../shared/component/StatusLabel';

class AlertsFilter extends Component {
  constructor(props) {
    super(props);

    this._onSubmit = this._onSubmit.bind(this);
    this._onClear = this._onClear.bind(this);

    this.state = {
      filter: JSON.parse(JSON.stringify(props.filter)),
      customers: props.customers || [],
    };
  }

  _onChangeAlert(name, value, option) {
    const options = value.map(v => (typeof v === 'object' ? v.value : v)).filter(item => item !== '');
    const { filter } = { ...this.state };
    if (option.label === 'All' || option.value === '') {
      delete filter[name];
    } else {
      filter[name] = options;
      if (filter[name].length === 0) {
        delete filter[name];
      }
    }
    this.setState({ filter });
  }

  _onSubmit() {
    const { filter } = this.state;
    this.props.onChange(filter);
  }

  _onClear() {
    this.setState({ filter: {} });
  }

  _renderCustomerOptions(filter) {
    return (
      <CustomerSelector
        multiple={true}
        initialSelection={filter?.customerId}
        onCustomerSelected={(selected, last) => this._onChangeAlert('customerId', selected, { label: last })}
        plain={true}
      />
    );
  }

  _renderServiceOptions(filter) {
    const options = ServiceTypeStore.getServices().map(({ label, type }) => ({
      label: <TypeLabel value={type} label={label || type} />,
      value: type,
      id: IDUtil.getId(`Service${type}`),
    })).sort((a, b) => a.label.props.label.localeCompare(b.label.props.label));

    return (
      <CheckBoxGroup
        value={filter.serviceType || ['']}
        id={IDUtil.getId('ServicesChkGroup')}
        options={[{ label: 'All', value: '', id: IDUtil.getId('ServicesAll') }].concat(options)}
        onChange={({ value, option }) => this._onChangeAlert('serviceType', value, option)}
      />
    );
  }

  _renderAccountStatusOptions(filter) {
    const options = BillingType.enumValues.map(({
      enumKey, label, severity, order
    }) => ({
      'label': <StatusLabel value={severity} label={label} />,
      'value': enumKey,
      'id': IDUtil.getId(`AccountStatus${enumKey}`),
      'data-testid': IDUtil.getId(`AccountStatus${enumKey}`),
      order,
    })).sort((a, b) => a.order - b.order);

    return (
      <CheckBoxGroup
        value={filter.accountStatus || ['']}
        id={IDUtil.getId('AccountStatusChkGroup')}
        options={[{
          'label': 'All',
          'value': '',
          'id': IDUtil.getId('AccountStatusAll'),
          'data-testid': IDUtil.getId('AccountStatusAll')
        }].concat(options)}
        onChange={({ value, option }) => this._onChangeAlert('accountStatus', value, option)}
      />
    );
  }

  _renderAlertOptions(filter) {
    const options = AlertType.enumValues.map(({ enumKey, description }) => ({
      label: <TypeLabel value={enumKey} label={description} />,
      value: enumKey,
      id: IDUtil.getId(`Alerts${enumKey}`),
    })).sort((a, b) => a.label.props.label.localeCompare(b.label.props.label));

    return (
      <CheckBoxGroup
        value={filter.alertType || ['']}
        id={IDUtil.getId('AlertsChkGroup')}
        options={[{ label: 'All', value: '', id: IDUtil.getId('AlertsAll') }].concat(options)}
        onChange={({ value, option }) => this._onChangeAlert('alertType', value, option)}
      />
    );
  }

  render() {
    const { filter } = this.state;
    const hasFilter = Object.keys(filter).length > 0;
    return (
      <GLBMLayer
        position='right'
        flush={true}
        closer={false}
        onEsc={this.props.onClose}
        onClickOutside={this.props.onClose}
        onClose={this.props.onClose}
        a11yTitle='Customers Filter'
        full='vertical'
        title='Filter Alerts'
      >
        <Box
          pad='none'
          direction='column'
          fill='vertical'
        >
          <Box flex={true} overflow='auto'>
            <GLBMFilterPropertyBox label='Billing Account'>
              {this._renderCustomerOptions(filter)}
            </GLBMFilterPropertyBox>
            <GLBMFilterPropertyBox label='Billing Account Status'>
              {this._renderAccountStatusOptions(filter)}
            </GLBMFilterPropertyBox>
            <GLBMFilterPropertyBox label='Service'>
              {this._renderServiceOptions(filter)}
            </GLBMFilterPropertyBox>
            <GLBMFilterPropertyBox label='Alert'>
              {this._renderAlertOptions(filter)}
            </GLBMFilterPropertyBox>
          </Box>
        </Box>
        <Box border='top' pad='small' margin={{ top: 'none' }} flex={false}>
          <Footer flex={false} justify='between'>
            <Box justify='start' gap='small' direction='row'>
              <Button
                label='Apply'
                type='button'
                id={IDUtil.getId('ApplyBtn')}
                {...{ 'data-testid': IDUtil.getId('ApplyBtn') }}
                primary={true}
                onClick={this._onSubmit}
              />
              <Button
                label='Cancel'
                type='button'
                id={IDUtil.getId('CancelBtn')}
                {...{ 'data-testid': IDUtil.getId('CancelBtn') }}
                secondary={true}
                onClick={this.props.onClose}
              />
            </Box>
            <Button
              label='Clear Filters'
              id={IDUtil.getId('ResetBtn')}
              {...{ 'data-testid': IDUtil.getId('ResetBtn') }}
              onClick={hasFilter ? this._onClear : undefined}
            />
          </Footer>
        </Box>
      </GLBMLayer>
    );
  }
}

AlertsFilter.propTypes = {
  onClose: PropTypes.func,
  onChange: PropTypes.func,
  filter: PropTypes.object,
  customers: PropTypes.array,
};

AlertsFilter.defaultProps = {
  onClose: null,
  onChange: null,
  filter: {},
  customers: null,
};

export default AlertsFilter;
