// (C) Copyright 2017-2024 Hewlett Packard Enterprise Development LP

import React, { useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import find from 'lodash/find';
import findIndex from 'lodash/findIndex';
import {
  setDirtyRates,
  setMeters,
  setRates,
} from 'services/redux/ServiceActions';
import { ServiceStep } from 'services/model/ServiceStep';
import { Box, Notification } from 'grommet';
import {
  useEquipmentQuery,
  useServiceMeterAndRatesQuery,
  useValidationMutation,
} from '../../../../../core';
import MeterList from './MeterList';
import MeterDetails from './MeterDetails';
import UserStore from '../../../../stores/UserStore';
import LocationDetails from './LocationDetails';
import {
  setLocationRates,
} from '../../../redux/ServiceActions';
import { isInstalledCapacityRequired } from './util';

const ManageRates = (props) => {
  const [meters, setMeters] = useState([]);
  const [rates, setRates] = useState([]);
  const [locationRates, setLocationRates] = useState([]);
  const [selectedMeter, setSelectedMeter] = useState(undefined);
  const [selectedGroupId, setSelectedGroupId] = useState(undefined);
  const [selectedRate, setSelectedRate] = useState();
  const [response, setResponse] = useState();

  const requireInstalledCapacity = useMemo(() => isInstalledCapacityRequired(props.serviceType), []);
  const groupByLocation = useMemo(() => props.options?.config?.reportBy === 'LOCATION', []);

  const { mutate: fetchValidationIfNeeded } = useValidationMutation(ServiceStep.RATES);

  useEffect(() => {
    fetchValidationIfNeeded();
  }, [meters, rates, locationRates]);

  const _getDefaultRate = (meter) => {
    const rate = {
      shrinkPolicy: 'SHRINK_100',
      meterId: meter.id,
      meterName: meter.name,
      rounding: true,
      tierType: 'ABSOLUTE',
      capacityRevisions: [{
        committedCapacity: 0,
        committedCapacityOverrideEnabled: false,
        committedPct: 0,
        effectiveDate: '2010-01-01',
        minimumContractualCapacity: 0,
        requestedCapacity: 0,
      }],
      rateRevisions: [{
        effectiveDate: '2010-01-01',
        tiers: {
          '0.0': {
            rate: 0,
            name: 'Band 1',
          },
        },
      }],
    };

    // if this service requires installed capacity to be entered (CAAS, VMAAS, MLOps):
    if (requireInstalledCapacity) {
      rate.capacityRevisions[0].installedCapacity = 0;
    }

    return rate;
  };

  const _onMeterSelect = (meterId) => {
    if (meterId) {
      const meter = meters.filter(m => m.id === meterId)[0];
      const rate = rates.filter(r => r.meterId === meterId)[0];
      setSelectedMeter(meter);
      setSelectedRate(rate);
      setSelectedGroupId(undefined);
    } else {
      setSelectedMeter(undefined);
      setSelectedRate(undefined);
    }
  };

  const _onGroupSelect = (groupId) => {
    if (groupId) {
      setSelectedMeter(undefined);
      setSelectedRate(undefined);
      setSelectedGroupId(groupId);
    } else {
      setSelectedGroupId(undefined);
    }
  };

  const _prepareMeterAndRates = (currentMeters, currentRates, currentLocationRates) => {
    if (props.permissions.canEditRates) {
      for (let i = 0; i < currentMeters.length; i += 1) {
        const meter = currentMeters[i];
        const rate = (currentRates || []).filter(m => m.meterId === meter.id)[0];
        if (!rate) {
          currentRates.push(_getDefaultRate(meter));
        } else if (meter.name !== rate.meterName) {
          // we might need to update the rate.meterName based on user changes:
          rate.dirty = true;
          rate.meterName = meter.name;
        }
      }
    }

    setMeters(currentMeters);
    setRates(currentRates);
    setLocationRates(currentLocationRates);

    props.setMeters(currentMeters);
    if (!props.isReadOnlyOverride) {
      props.setRates(currentRates);
      props.setLocationRates(currentLocationRates || []);
    }

    // if we already modified rates based on meter name changing:
    const dirtyRates = (rates || []).filter(r => r.dirty).length;
    if (dirtyRates > 0) {
      props.setDirtyRates();
      props.setDirty();
    }
  };

  useEffect(() => {
    if (!selectedMeter && !selectedGroupId && meters && meters.length) {
      _onMeterSelect(meters[0].id);
    }
  }, [selectedMeter, selectedGroupId, meters]);

  const {
    data: equipment,
  } = useEquipmentQuery(props.options.customerId, props.options.serviceType);

  const queryResults = useServiceMeterAndRatesQuery(props.options.customerId, props.options.serviceType, props.options, props.tenant);
  const allQueriesCompleted = useMemo(() => queryResults.every(query => query.isSuccess), [queryResults]);
  const someQueryFailed = useMemo(() => queryResults.some(query => query.isError), [queryResults]);

  useEffect(() => {
    if (someQueryFailed) {
      setResponse({
        status: 'critical',
        title: 'Unable to retrieve Meters and/or Rates for this service.',
      });
    }
  }, [someQueryFailed, setResponse]);

  useEffect(() => {
    if (allQueriesCompleted) {
      if (props.useInteralRates || !props.rates || props.rates.length === 0) {
        if (props.tenant === 'MASTER') {
          const [{ data: fetchedMeters }, { data: fetchedRates }] = queryResults;
          _prepareMeterAndRates(fetchedMeters, fetchedRates.meterRates, fetchedRates.locationRates);
        } else {
          const [{ data: fetchedMeters }, { data: fetchedRates }] = queryResults;
          _prepareMeterAndRates(fetchedMeters, fetchedRates, []);
        }
      } else {
        const [{ data: fetchedMeters }] = queryResults;
        _prepareMeterAndRates(fetchedMeters, props.rates, props.locationRates);
      }
    }
  }, [allQueriesCompleted]);

  const _onMeterChanged = (newRate) => {
    const updatedRates = [...rates];
    updatedRates.forEach((meter, index) => {
      if (meter.meterId === newRate.meterId) {
        updatedRates[index] = newRate;
        updatedRates[index].dirty = true;
      }
    });

    props.setRates(updatedRates);
    props.setDirtyRates();
    props.setDirty();

    setRates(updatedRates);
    setSelectedRate(newRate);
  };

  const _onLocationRateChanged = (locationId, newRates) => {
    let updatedLocationRates = [...(locationRates || [])];
    const found = findIndex(updatedLocationRates, ['locationId', locationId]);
    if (found > -1) {
      updatedLocationRates[found].commitments = newRates;
      if (updatedLocationRates[found].commitments?.length === 0) {
        // if empty - remove THIS locationRate at found index...
        updatedLocationRates = updatedLocationRates.filter(r => r.locationId !== locationId);
      }
    } else {
      updatedLocationRates.push({
        locationId,
        commitments: newRates,
      });
    }

    props.setLocationRates(updatedLocationRates);
    props.setDirtyRates();
    props.setDirty();

    setLocationRates(updatedLocationRates);
  };

  return (
    <Box direction='column' fill='vertical'>
      {response
        && (
          <Notification
            status={response?.status || 'critical'}
            title={response?.title}
            message={response?.message}
          />
        )}
      <Box direction='column' flex={true}>
        <Box flex={true} direction='row'>
          <MeterList
            meters={meters}
            rates={rates}
            locationRates={locationRates}
            onClick={_onMeterSelect}
            onGroupSelect={_onGroupSelect}
            selectedMeter={selectedMeter}
            selectedGroupId={selectedGroupId}
            customer={props.customer}
            serviceType={props.serviceType}
            readOnly={props.isReadOnlyOverride || props.readOnly}
            options={props.options}
            validation={props.validation}
            tenant={props.tenant}
          />
          {selectedMeter && (
          <MeterDetails
            meter={selectedMeter}
            rate={selectedRate}
            onChange={_onMeterChanged}
            customer={props.customer}
            serviceType={props.serviceType}
            readOnly={props.isReadOnlyOverride || props.readOnly}
            options={props.options}
            validation={props.validation}
            userType={UserStore.getUser().role}
            tenant={props.tenant}
          />
          )}
          {selectedGroupId && (
          <LocationDetails
            locationId={selectedGroupId}
            locationRates={find(locationRates, ['locationId', selectedGroupId])?.commitments}
            customer={props.customer}
            onChange={_onLocationRateChanged}
            readOnly={props.isReadOnlyOverride || props.readOnly}
            equipment={equipment}
          />
          )}
        </Box>
      </Box>
    </Box>
  );
};

const mapStateToProps = ({
  service: {
    details: {
      customer, options, rates, locationRates, permissions, validation
    },
  },
}) => {
  const readOnly = !permissions.canEditRates;
  return {
    customer,
    options,
    rates,
    locationRates,
    permissions,
    readOnly,
    validation,
  };
};

const mapDispatchToProps = dispatch => bindActionCreators({
  setMeters,
  setRates,
  setLocationRates,
  setDirtyRates,
}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ManageRates);
