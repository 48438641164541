// (C) Copyright 2017-2024 Hewlett Packard Enterprise Development LP

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { FormField, Select } from 'grommet';

const SelectMigrationOption = ({
  options,
  values,
  onSelect,
}) => {
  const [filteredOptions, setFilteredOptions] = useState(options);

  useEffect(() => {
    setFilteredOptions(options);
  }, [options]);

  const filterOptions = (text) => {
    // Copied from grommet to avoid console errors by escaping special characters
    const escapedText = text.replace(/[-\\^$*+?.()|[\]{}]/g, '\\$&');
    const exp = new RegExp(escapedText, 'i');
    setFilteredOptions(options.filter(o => exp.test(o.name)));
  };

  return (
    <FormField
      label='Options:'
      required={true}
      name='message'
    >
      <Select
        placeholder='Select option'
        options={filteredOptions}
        value={values?.name || {}}
        labelKey='name'
        list='combobox'
        onChange={event => onSelect(event.value)}
        onSearch={text => filterOptions(text)}
      />
    </FormField>
  );
};

SelectMigrationOption.propTypes = {
  options: PropTypes.array.isRequired,
  values: PropTypes.object,
  onSelect: PropTypes.func.isRequired,
};

SelectMigrationOption.defaultProps = {
  values: undefined,
};

export default SelectMigrationOption;
