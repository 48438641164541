// (C) Copyright 2017-2024 Hewlett Packard Enterprise Development LP

import React, { useMemo } from 'react';
import {
  Box, Meter, Text,
} from 'grommet';
import { FormNextLink } from 'grommet-icons';
import PropTypes from 'prop-types';
import IDUtil from '../../shared/util/IDUtil';
import GLBMTooltip from '../../shared/component/GLBMTooltip';
import { StatusIcon } from '../../shared/component/StatusIcon';

function CapacityPlanningGroupLabel({
  band, name, unit, usageShowed = true, outputUnit = null, serviceCategory,
  used, usable, requested = undefined
}) {
  const color = useMemo(() => {
    if (!usageShowed) return undefined;
    switch (band) {
      case 'HIGH': return 'status-critical';
      case 'LOW': return '#FF9900';
      case 'OPTIMAL': return 'status-ok';
      case 'UNKNOWN': return 'status-unknown';
      default: return undefined;
    }
  }, [band, usageShowed]);

  const statusIcon = useMemo(() => {
    if (!usageShowed) return undefined;
    switch (band) {
      case 'HIGH': return <StatusIcon value='critical' size='small' tooltipContent='Critical: Actual Usage is between Requested Capacity and Installed Capacity.' />;
      case 'LOW': return <StatusIcon value='warning' size='small' tooltipContent='Low: Actual Usage is below Reserved Capacity.' />;
      case 'OPTIMAL': return <StatusIcon value='ok' size='small' tooltipContent='Optimal: Actual Usage is between Reserved Capacity and Requested Capacity.' />;
      case 'UNKNOWN': return <StatusIcon value='unknown' size='small' tooltipContent='Unknown: Missing Contract Data' />;
      default: return undefined;
    }
  }, [band, usageShowed]);

  // calculate the use value to show, based on service category:
  const usedValue = useMemo(() => ((requested !== undefined)
    ? Math.round((used / requested) * 100)
    : Math.round((used / usable) * 100)), [used, requested, usable]);

  const formNextIcon = (
    <span>
      <FormNextLink size='small' />
      {outputUnit}
    </span>
  );
  return (
    (
      <Box
        direction='row'
        margin={{ left: 'small' }}
        gap='small'
        flex={true}
        pad='medium'
        fill='horizontal'
        justify='between'
      >
        <Box flex={false} direction='row' align='center' gap='small' id={IDUtil.getId(name.replace(/ /gi, '-'))}>
          {statusIcon}
          <Box>
            <span>
              {name}
              {' '}
              (
              {serviceCategory}
              {serviceCategory ? ' - ' : ''}
              {unit}
              {outputUnit !== '' && outputUnit != null && outputUnit !== unit ? formNextIcon : ''}
              )
            </span>
          </Box>
        </Box>
        <Box direction='row' align='center' gap='small'>
          <GLBMTooltip content={`${requested !== undefined ? 'Requested' : 'Installed'} capacity is ${usedValue}% used`}>
            <Box data-e2e='percentageValue' direction='row' align='start'>
              <Text weight='bold'>{Number.isFinite(usedValue) ? usedValue.toLocaleString() : usedValue}</Text>
              <Text>%</Text>
            </Box>
          </GLBMTooltip>
          <Meter
            size='medium'
            thickness='small'
            color={color}
            value={usedValue}
          />
        </Box>
      </Box>
    )
  );
}

CapacityPlanningGroupLabel.propTypes = {
  band: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  unit: PropTypes.string.isRequired,
  used: PropTypes.number.isRequired,
  usable: PropTypes.number.isRequired,
  requested: PropTypes.number,
  usageShowed: PropTypes.bool,
  outputUnit: PropTypes.string,
  serviceCategory: PropTypes.string.isRequired,
};

export default CapacityPlanningGroupLabel;
